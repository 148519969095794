import { NewClient, CMSClient } from './NewClient';
import { ClientAdministrators } from './ClientAdministrators';
import { KeyRelationshipPartners } from './KeyRelationshipPartners';

export class CreateClientViewModel {
  client: NewClient;
  cMSClient: CMSClient;
  clientAdministrators: ClientAdministrators[];
  //relationshipPartners: KeyRelationshipPartners[];
  loggedInUserFullName: string;
}

export class AddressLine1Model {  
  accountid: string;
  orgAddress1_Line1: string;  
  address1_line1: string;
  isFieldExistsInOneView: boolean;
  fieldValidationMessage: string;
  canShowDuplicateNotFoundMessage: boolean;
  duplicateNotFoundMessage: string;
  iconPath: string;

  constructor(accountid: string, orgAddress1_Line1: string , address1_line1: string)
  {
    this.accountid = accountid;
    this.orgAddress1_Line1 = orgAddress1_Line1;
    this.address1_line1 = address1_line1;
    this.isFieldExistsInOneView = false;
    this.fieldValidationMessage ='Similar entry exists in oneView. Click the green checkbox to see potential duplicate client data.';
    this.duplicateNotFoundMessage = "Duplicate not found in oneView."
    this.iconPath ='../../../../assets/images/EDP/Engagement/unfold_more-24px.svg';
  }

}

export class TickerSymbolModel {  
  accountid: string;
  orgTickerSymbol: string;  
  tickerSymbol: string;
  isFieldExistsInOneView: boolean;
  fieldValidationMessage: string;
  canShowDuplicateNotFoundMessage: boolean;
  duplicateNotFoundMessage: string;
  iconPath: string;

  constructor(accountid:string ,orgTickerSymbol: string, tickerSymbol: string)
  {
    this.accountid = accountid;
    this.orgTickerSymbol = orgTickerSymbol;
    this.tickerSymbol = tickerSymbol;
    this.isFieldExistsInOneView = false;
    this.fieldValidationMessage ='Similar entry exists in oneView. Click the green checkbox to see potential duplicate client data.';
    this.duplicateNotFoundMessage = "Duplicate not found in oneView."
    this.iconPath ='../../../../assets/images/EDP/Engagement/unfold_more-24px.svg';
  }
}

export class Telephone1Model {  
  accountid: string;
  orgTelephone1: string;  
  telephone1: string;
  isFieldExistsInOneView: boolean;
  fieldValidationMessage: string;
  canShowDuplicateNotFoundMessage: boolean;
  duplicateNotFoundMessage: string;
  iconPath: string;

  constructor(accountid: string, orgTelephone1: string , telephone1: string)
  {
    this.accountid = accountid;
    this.orgTelephone1 = orgTelephone1;
    this.telephone1 = telephone1;
    this.isFieldExistsInOneView = false;
    this.fieldValidationMessage ='Similar entry exists in oneView. Click the green checkbox to see potential duplicate client data.';
    this.duplicateNotFoundMessage = "Duplicate not found in oneView."
    this.iconPath ='../../../../assets/images/EDP/Engagement/unfold_more-24px.svg';
  }

}

export class WebSiteModel {  
  accountid: string;
  orgWebSite: string;  
  webSite: string;
  isFieldExistsInOneView: boolean;
  fieldValidationMessage: string;
  canShowDuplicateNotFoundMessage: boolean;
  duplicateNotFoundMessage: string;
  iconPath: string;

  constructor(accountid: string, orgWebSite: string , webSite: string)
  {
    this.accountid = accountid;
    this.orgWebSite = orgWebSite;
    this.webSite = webSite;
    this.isFieldExistsInOneView = false;
    this.fieldValidationMessage ='Similar entry exists in oneView. Click the green checkbox to see potential duplicate client data.';
    this.duplicateNotFoundMessage = "Duplicate not found in oneView."
    this.iconPath ='../../../../assets/images/EDP/Engagement/unfold_more-24px.svg';
  }

}

export class BusinessContactEmailModel {  
  accountid: string;
  orgBusinesscontactemail: string;  
  businesscontactemail: string;  
  isFieldExistsInOneView: boolean;  
  fieldValidationMessage: string;
  canShowDuplicateNotFoundMessage: boolean;
  duplicateNotFoundMessage: string;
  iconPath: string;

  constructor(accountid: string, orgBusinesscontactemail: string , businesscontactemail: string )
  {
    this.accountid = accountid;
    this.orgBusinesscontactemail = orgBusinesscontactemail;
    this.businesscontactemail = businesscontactemail;    
    this.isFieldExistsInOneView = false;    
    this.fieldValidationMessage ='Similar entry exists in oneView. Click the green checkbox to see potential duplicate client data.';
    this.duplicateNotFoundMessage = "Duplicate not found in oneView."
    this.iconPath ='../../../../assets/images/EDP/Engagement/unfold_more-24px.svg';
  }
}

export class BillingContactEmailModel {  
  accountid: string;
  orgBillingContactEmail: string;  
  billingContactEmail: string;  
  isFieldExistsInOneView: boolean;  
  fieldValidationMessage: string;
  canShowDuplicateNotFoundMessage: boolean;
  duplicateNotFoundMessage: string;
  iconPath: string;

  constructor(accountid: string, orgBillingContactEmail: string , billingContactEmail: string )
  {
    this.accountid = accountid;
    this.orgBillingContactEmail = orgBillingContactEmail;
    this.billingContactEmail = billingContactEmail;    
    this.isFieldExistsInOneView = false;    
    this.fieldValidationMessage ='Similar entry exists in oneView. Click the green checkbox to see potential duplicate client data.';
    this.duplicateNotFoundMessage = "Duplicate not found in oneView."
    this.iconPath ='../../../../assets/images/EDP/Engagement/unfold_more-24px.svg';
  }
}

export class ClientNameModel {  
  accountid: string;
  orgClientName: string;  
  clientName: string;
  isFieldExistsInOneView: boolean;
  fieldValidationMessage: string;
  canShowDuplicateNotFoundMessage: boolean;
  duplicateNotFoundMessage: string;
  iconPath: string;

  constructor(accountid: string, orgClientName: string , clientName: string)
  {
    this.accountid = accountid;
    this.orgClientName = orgClientName;
    this.clientName = clientName;
    this.isFieldExistsInOneView = false;
    this.fieldValidationMessage ='Similar entry exists in oneView. Click the green checkbox to see potential duplicate client data.';
    this.duplicateNotFoundMessage = "Duplicate not found in oneView."
    this.iconPath ='../../../../assets/images/EDP/Engagement/unfold_more-24px.svg';
  }

}

export class ClientViewModel {  
  addressLine1Model: AddressLine1Model;  
  tickerSymbolModel: TickerSymbolModel;
  telephone1Model: Telephone1Model;  
  webSiteModel: WebSiteModel;
  businessContactEmailModel: BusinessContactEmailModel;  
  billingContactEmailModel: BillingContactEmailModel;  
  cmsClientAdministratorEmail: string;    
  cmsClientAdministratorFullname: string;
  clientNameModel: ClientNameModel;
}

export class SearchClientbyFieldRequestModel {  
  accountId: string =''
  clientFieldName: string;
  clientFieldSearchText: string;

  constructor(accountId: string, clientFieldName: string , clientFieldSearchText: string)
  {
    this.accountId = accountId;
    this.clientFieldName = clientFieldName;
    this.clientFieldSearchText = clientFieldSearchText;    
  }
}
export class OneViewSearchFieldClientModel {
      name: string;
      accountnumber: string;
      gt_cmsaccountname: string;
      accountid: string;
      clientFieldName: string;
      clientFieldSearchText: string;        
  }

export enum ClientExistDialogTitles {
  TickerSymbol = 'Duplicate Ticker symbol detected',
  PhoneNumber = 'Duplicate Phone number detected',
  Address1 = 'Duplicate Address 1 detected',
  WebSite = 'Duplicate Website detected',
  BusinessContactEmail = 'Duplicate Business contact email detected',  
  BillingContactEmail = 'Duplicate Billing contact email detected',
  ClientName = 'Duplicate client name detected',
}

export class ClientHeaderModel {
  header0: string;
  header1: string;
  header2: string;
  header3: string;

  constructor(firstcolumnName: string) {
    this.header0 = 'Client name';
    this.header1 = firstcolumnName;
    this.header2 = 'oneView account name';
    this.header3 = 'Client code';
  }
}

export class MonthModel {
  monthNo: number;
  monthName: string;  

  constructor(monthNo: number, monthName: string) {
    this.monthNo = monthNo;
    this.monthName = monthName;
    
  }
}

export enum ClientValidationFields {
  CMSadministratorEmail = 'CMSClientAdministratorEmail',
  BillingContactEmail = 'billingContactEmail',  
  BusinessContactEmail = 'businessContactEmail',
  //BillingContactFullName = 'BillingContactFullName',
  //BusinessContactFullName = 'BusinessContactFullName',
  Address = 'address1_line1',
  WebSite = 'hs_domainname',
  PhoneNumber = 'telephone1',
  FaxNumber = 'fax',
  TickerSymbol = 'tickersymbol',
  ClientName = 'gt_cmsaccountname'
}

export class SearchOpportunityByRequestModel {  
  primarySolutionCode: string =''
  clientCode: string ='';  
  accountId: string='';
  secSolutionCodeList: string[];  
  isAllAdvisory: boolean;
  isAllAudit: boolean;  
  isAllTax: boolean;
  solutionCodeType: string;
  interchangeableSolutionCodesList: string[];

  constructor(primarySolutionCode: string, clientCode: string,
    secSolutionCodeList: string[], accountId: string, isAllAdvisory: boolean, isAllAudit: boolean, isAllTax: boolean,
    solutionCodeType: string, interchangeableSolutionCodeList: string[]) {
    this.primarySolutionCode = primarySolutionCode;
    this.clientCode = clientCode;
    this.secSolutionCodeList = secSolutionCodeList;
    this.accountId = accountId;
    this.isAllAdvisory = isAllAdvisory;
    this.isAllAudit = isAllAudit;
    this.isAllTax = isAllTax;
    this.solutionCodeType = solutionCodeType;
    this.interchangeableSolutionCodesList = interchangeableSolutionCodeList;
  }
}

export class SearchOpportunityByOpportunityIdRequestModel {  
  primarySolutionCode: string =''
  opportunityId: string =''  
  opportunityServiceMiniModelList: OpportunityServiceMiniModel[] =[];  
  secSolutionCodeList: string[];
  isPrimaryAssignment: boolean = false;
}

export class OpportunityServiceMiniModel {
  OpportunityServiceId: string =''  
  isPrimary: boolean = false;
  isPartOfSecondary: boolean = false;
}

export class OneViewClientOpportunityViewModelWrapper {
  oneViewClientOpportunityViewModelList: OneViewClientOpportunityViewModel[];  
  isOpportunitySelected: boolean;
  isOpportunityServicelisted: boolean;
  isOpportunityServiceMatchWithPrimary: boolean;
  isOpportunityServiceMatchWithPrimaryNSecondary: boolean;
  interchangeableSolutionCodeFlag: boolean;
  constructor()
  {
    this.isOpportunitySelected = false;
    this.isOpportunityServicelisted = false;  
    this.isOpportunityServiceMatchWithPrimary = false;
    this.isOpportunityServiceMatchWithPrimaryNSecondary = false;
  }
}

export class OneViewClientOpportunityViewModel {
  opportunityId: string;
  opportunityName: string;  
  opportunityEstimatedvalue: string;   
  expectedStartDate: string;
  estCloseDate: string;
  keyDicisionMaker: string;
  pursuitTeam: string
  opportunityServiceList: OpportunityServiceModel[]=[];
  gtOfficeLocation: string;
  isAnnuityOpportunity: boolean;
  accountName: string;
  pursuitOwner:string;
 
}

export class OpportunityServiceModel {
  opportunityServiceId: string;
  previousYearOpportunityServiceId: string;
  previousOppServiceEngageAssignmentIds: string;
  solutionCode: string;
  solutionDescription: string;
  PriceAmtFormattedValue: string;
  isPrimary: boolean;
  isChecked: boolean;
  isAssigned: boolean;
  isContinuanceCarryFrwdAssignment: boolean;
  isPartOfSecondary: boolean;
  isEnabled: boolean;
  interchangeableSolutionCodeFlag: boolean;
  osaCount: number;
  priceAmt: number;
  constructor()
  {
    this.isPrimary = false;
    this.isChecked = false;    
    this.isAssigned = false;
    this.isContinuanceCarryFrwdAssignment = false;
    this.isPartOfSecondary = false;
    this.isEnabled = false;
  }
}

export class OpportunityServiceRequestModel
{
    clientCode: string
    engagementId: number;
    opportunityServiceList: OpportunityServiceList[] = [];
}
export class OpportunityServiceResponseViewModel {
  opportunityServiceList: OpportunityServiceResponseModel[] = [];
}

export class OpportunityServiceResponseModel {
  opportunityServiceId: string
  isAssigned: boolean
  isContinuanceCarryFrwdAssignment: boolean;
}

export class NewYearOpportunityUpdateRequestModel {
  clientId: number; 
  oldEngagementId: number;
  newEngagementId: number;
  opportunityId: string;
  expectedStartDate: string;
  estCloseDate: string;
  gtOfficeLocation: string;
  opportunityServiceList: NewYearOpportunityServiceUpdateModel[] = [];
}

export class NewYearOpportunityServiceUpdateModel {
  opportunityServiceId: string; 
  previousYearOpportunityServiceId: string;
  solutionCode: string;
  previousOppServiceEngageAssignmentIds: string;
}

export class SearchOpportunityByEngagementIdRequestModel {
  engagementId: number;
  primarySolutionCode: string = '';
  opportunityServiceMiniModelList: OpportunityServiceMiniModel[] = [];
  secSolutionCodeList: string[];
}
export class ClientOneViewAccountDetails {
clientId:number;
oneViewAccountId:string;
UpdatedBy: string;
}
export class OpportunityServiceRequestModelByEngagementId
{
    clientCode: string
    engagementId: number;
    opportunityServiceList:OpportunityServiceList[]=[];
}
export class OpportunityServiceList{
  opportunityServiceId: string;
  osaCount:number;
}
export class SearchClientOpportunityByRequestModel{
  primarySolutionCode:string;
  AccountId:string;
  opportunityId:string;
  engagementId:number;
}