<!-- <p>review-notes works!</p> -->
<!-- modal -->

<div class="modal modal_outer right_modal fade" [style.left.px]="position.x" id="review-Notes_modal" role="dialog" aria-labelledby="myModalLabel2" >
    <div class="modal-dialog" role="document" [style.width.px]="size.w" >      
        <div class="modal-content"  #resizeDiv>
          <div class="left" (mousedown)="startResize($event, ['left'], 'x')"></div>
            <div class="modal-header">
              <img class="mr-2" id="review-notes-icon" src="../../../../assets/images/EDP/Workspace/library_add_check-24px-gray.svg" alt="Review notes">
              <h6 class="modal-title">Review notes</h6>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeReviewNotes()">
                <span style="font-size: 15px;vertical-align: middle;margin-right: 3px;">Close</span>
                <span  style="vertical-align: top;" aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="row m-0 p-0">
              <div class="col-md-12">
                <h6 class="modal-title">{{reviewNoteEngagementData.clientName}}</h6>
                <div *ngIf="workspaceType=='C'">
                  {{this.engagementChangeOrders.changeOrders[0].changeOrderName}} | {{reviewNoteEngagementData.solutionCode}}
                </div>
                <div *ngIf="workspaceType!='C'">
                  {{reviewNoteEngagementData.engagementName}} <span *ngIf="isContinuanceEngagement">(Continuance)</span> | {{reviewNoteEngagementData.solutionCode}}
                </div>
              Active document:<br>
              <div id="drpDown" class="pt-2 pr-0" *ngIf="workspaceDocumentCount != 1 && engagementState !== 'ChangeOrder'" >                 
                <div class="selectdiv">
                  <select class="dropdown rounded-0" id="ddReviewNotesDocument" name="ddReviewNotesDocument"
                  [(ngModel)]="documentName" (change)="WorkspaceDocumentChange()">
                    <option class="activeDocOption" *ngFor="let item of workspaceDocumentList"  [value]="item.documentAliasName">
                    {{item.documentAliasName}}
                    </option>
                  </select>
                </div>
              </div>
              <div id="drpDown" class="pt-2 pr-0" *ngIf="workspaceDocumentCount > 1 && engagementState == 'ChangeOrder'" >                                   
                <div class="selectdiv">
                  <select class="dropdown rounded-0" id="ddReviewNotesDocument" name="ddReviewNotesDocument"
                  [(ngModel)]="documentName" (change)="WorkspaceDocumentChange()">
                    <option class="activeDocOption" *ngFor="let item of ChangeOrderworkspaceDocumentList"  [value]="item.documentAliasName">
                    {{item.documentAliasName}}
                    </option>
                  </select>
                </div>
              </div>
    
              <div class="pt-2 pr-0" id="divReviewDocument" *ngIf="workspaceDocumentCount == 1" >
                <span class="font-weight-bold">{{selectedWorkspaceDocumentItem.documentAliasName}}</span>
              </div><br>
              <span class="mx-1">
                <a class="link-text-blue edp-font-14 sap-bar"
                  href="{{reviewNoteEngagementData.siteURL}}" target="_blank">
                  <img src="../../../assets/images/EDP/Engagement/sharepoint-logo.svg" class="icon-logo"
                    alt="sharepoint" />
                  Go to SharePoint</a>
              </span>
              </div>
            </div>
            <div class="modal-body get_quote_view_modal_body">                
             <!-- start review notes -->
             <div class="tab-pane fade show active" id="list-review-notes" role="tabpanel"
             aria-labelledby="list-review-notes">
             <!-- <div class="section-heading pt-3">
               Review notes
             </div> -->
             <div id="reviewNotesDetails">
               <div class="mt-3 text-sm-center text-lg-center"
                 *ngIf="!(CurrentWorkspaceData.status == 'Approved'
                  || CurrentWorkspaceData.status == 'Abandoned' || CurrentWorkspaceData.status == 'Declined') && 
                  (!isPureNotificationOnlyProfessional &&(isLoggedInUserSystemAdmin || isLoggedInUserHasWorkspaceAccess || isQualityUserLoggedIn)) ">
                 <button type="button" id="teamMemberbtnEditquestionnaire "
                   class="btn customBtnWhite mr-0 w-sm-50 w-50" data-toggle="modal"
                   (click)="showHideAddReviewNote('show')">
                   <img src="assets/images/EDP/Engagement/add_box-blue.svg" alt="add note"
                     class="add-review-note-icon">
                   <span class="d-inline-block ml-2 align-middle font-weight-bold">Add review note</span>
                 </button>
               </div>

               <div class="filters">
                 <div id="divReviewNotesFilters" class="row mt-3 m-0 ">
                   <div class="res-w-100 w-100">
                     <span class="d-inline-block pr-1 label"> Filter by:</span>
                   </div>
                   <div class="selectdiv select-review-note-by-user">
                     <select id="reviewNoteFilterByUser" name="reviewNoteFilterByUser" (change)="filterReviewPoint()"
                       class="dropdown rounded-0 form-control" [(ngModel)]="ViewByUser">
                       <option value="{{reviewNoteFilterBy.All}}" selected>All</option>
                       <option value="{{reviewNoteFilterBy.CreatedByMe}}">Created by me</option>
                       <option value="{{reviewNoteFilterBy.MentionedMe}}">Mention me</option>
                     </select>
                   </div>
                 </div>

                 <div id="divReviewNotesFilters" class="row mt-3 m-0 ">
                   <div class="res-w-100 w-100">
                     <span class="d-inline-block pr-1 label"> Filter by status:</span>
                   </div>
                   <div class="selectdiv select-review-note-by-status">
                     <select id="reviewNoteFilterByStatus" name="reviewNoteFilterByStatus"
                       (change)="filterReviewPoint()" class="dropdown rounded-0 form-control"
                       [(ngModel)]="ViewByStatus">
                       <option value="{{reviewNoteStatus.All}}" selected>All</option>
                       <option value="{{reviewNoteStatus.Open}}">Open</option>
                       <option value="{{reviewNoteStatus.Resolved}}">Resolved</option>
                     </select>
                   </div>
                 </div>
               </div>

               <div id="divReviewNotesLagend" class="row mt-1 m-0 p-0">
                 <div><img src="../../../assets/images/EDP/Workspace/orange_lagend.png" alt="Open-Notes"
                     class="legend">
                   <span class="d-inline-block ml-2 mt-1  align-middle">Open</span>
                 </div>
                 <div class="pl-4">
                   <img src="../../../assets/images/EDP/Workspace/grey_lagend.png" alt="Resolved-Notes"
                     class="legend">
                   <span class="d-inline-block ml-2 mt-1 align-middle">Resolved</span>
                 </div>
               </div>

               <div id="divAddReviewNotesSection" class="row mt-3 m-0 p-0" style="display: none;">
                 <div class="row mb-2">
                   <div class="col-md-12">
                     <span class="font-weight-bold">Add review note</span>
                   </div>
                 </div>

                 <form #AddReviewPointForm="ngForm" class="w-100">
                   <div class="row m-0">
                     <div class="col-md-12 px-0 review-note-input-block">
                       <span class="note-label">Subject</span>

                       <input type="text" id="txtReviewNoteSubject" name="txtReviewNoteSubject" maxlength="50"
                         autocomplete="off" required [(ngModel)]="currentworkspaceReviewPoints.subject"
                         #subject="ngModel" class="custom-input-note my-2 review-note-input-box review-note-subject"
                         (blur)="checkSpacesatBegining($event,0)" />
                       <span class="review-note-validation-text"
                         *ngIf="displayReviewNoteValidations && !isValidReviewNoteSubject">Subject is
                         mandatory</span>
                     </div>
                   </div>
                   <div class="row m-0">
                     <div class="col-md-12 px-0 review-note-input-block">
                       <span>Message</span>
                       <div *ngIf="_reviewNoteSearchableUsers.length > 0" id="txtReviewNoteDescription"
                         class="textarea custom-input-note my-2 review-note-input-box review-note-editable-div"
                         maxlength="1000" required (blur)="checkSpacesatBegining($event,0)" name="txtDescription"
                         rows="3" (keyup)="setRemainingCharacterCount($event, 'reviewNoteRemainingCharacterLabel')"
                         (keydown)="checkIfAllowToTypeCharacter($event)"
                         (paste)="verifyPastedContent($event, 'reviewNoteRemainingCharacterLabel')"
                         style="resize: none;background-color: #fff;" contenteditable="true"
                         data-placeholder='Add a review note. Use @ to select a person to receive an email notification regarding this review note. Using "@Team" will send a notification to the entire team.'
                         [mentionConfig]="reviewNoteConfigurations" (itemSelected)="itemSelected($event, 'txtReviewNoteDescription')"
                         (onMentioned)="onMentioned($event, 'txtReviewNoteDescription', 'reviewNoteRemainingCharacterLabel')"
                         #reviewNoteDiv>
                       </div>
                       <span id="reviewNoteRemainingCharacterLabel"
                         class="review-note-character-count-indicator-label">{{
                         maxCharactersAllowedForReviewNoteDescription }} characters</span>
                       <span class="review-note-validation-text"
                         *ngIf="displayReviewNoteValidations && !isValidReviewNoteMessage">Message is
                         mandatory</span>
                     </div>
                   </div>
                   <div class="row m-0">
                     <div class="col-md-12 px-0">
                       <span>Who can resolve this note?</span>
                       <div class="div-who-can-resolve">
                         <input class="rd-first-who-can-resolve" id="rdCreator" type="radio" name="whoCanResolve"
                           value="{{whoCanResolveReviewNote.Creator}}"
                           [(ngModel)]="currentworkspaceReviewPoints.whoCanResolve">Creator
                         <input class="rd-who-can-resolve" id="rdAnyRP" type="radio" name="whoCanResolve"
                           value="{{whoCanResolveReviewNote.AnyRP}}"
                           [(ngModel)]="currentworkspaceReviewPoints.whoCanResolve">Any RP
                         <input class="rd-who-can-resolve" id="rdAnyone" type="radio" name="whoCanResolve"
                           value="{{whoCanResolveReviewNote.Anyone}}"
                           [(ngModel)]="currentworkspaceReviewPoints.whoCanResolve">Anyone
                         <span class="review-note-validation-text"
                           *ngIf="displayReviewNoteValidations && !isValidReviewNoteWhoResolve">Please select who can
                           resolve this review note</span>
                       </div>
                     </div>
                   </div>

                   <div class="row mb-5 m-0">
                     <div class="col-md-12 text-right px-0">
                       <button type="button" id="btnAddReviewNoteCancel"
                         class="btn customBtnWhite btn-reply-note-h-34" data-toggle="modal"
                         (click)="showHideAddReviewNote('hide')">
                         <span class="d-inline-block  align-middle">Cancel</span>
                       </button>

                       <button type="button" id="btnAddReviewNotePost"
                         class="btn PrimaryButton ml-3 w-95 edp-font-14 btn-reply-note-h-34" data-toggle="modal"
                         (click)="InsertReviewPoints()">
                         <span class="d-inline-block mx-2  align-middle">Post</span>
                       </button>

                     </div>
                   </div>
                 </form>
               </div>

               <div id="divReviewNotesListSection" class="row mt-3 m-0 p-0" aria-describedby="RNotes"
                 *ngIf="workspaceReviewPointsCount > 0 && !isAddReviewNoteFormOpen">
                 <div class="Review-Notes-scroll">
                   <div class="row mb-3 m-0 p-0 review-note-custom-bg"
                     *ngFor="let reviewPointModel of workspaceReviewPointModelList let i=index">

                     <div id="Row" class="row m-0 ">

                       <div id="data" class="col-md-12 p-2"
                         [ngClass]="{'grey-Band': reviewPointModel.workspaceReviewPoint.status == 2, 'orange-Band':reviewPointModel.workspaceReviewPoint.status == 1}">
                         <div class="row mt-1 m-0 cp" id="divReviewNotesSubject"
                           (click)="showHideReviewNotesContent(reviewPointModel.workspaceReviewPoint.reviewPointId)">
                           <div class="col-md-10 p-0">
                             <span class="font-weight-bold edp-font-14 Review-note-short-name"
                               title="{{reviewPointModel.workspaceReviewPoint.subject}}">{{reviewPointModel.workspaceReviewPoint.subject}}</span>
                           </div>
                           <div class="col-md-2 px-0 text-right">

                             <div class=" " *ngFor="let img of reviewPointModel.imageModelList">
                               <a id="image_{{img.imageName}}" *ngIf="img.isVisible">
                                 <img src="{{img.imagePath}}" class="review-note-collapse-img" alt="expand-collapse">
                               </a>
                             </div>
                           </div>
                         </div>

                         <div id="divReviewNotesContent_{{i}}"
                           *ngIf="reviewPointModel.imageModelList[0].isVisible == true">
                           <div id="r1" class="row m-0">
                             <div class="col-md-12 px-0">
                               <span class="review-by "
                                 title="{{reviewPointModel.workspaceReviewPoint.userName}}">By:
                                 {{reviewPointModel.workspaceReviewPoint.userName}}</span>
                             </div>
                             <div class="col-md-12 px-0">
                               <span class="review-by">{{reviewPointModel.workspaceReviewPoint.createdOn | date:
                                 "MM/dd/yyyy hh:mm a UTC"}}
                               </span>
                             </div>
                           </div>
                           <div id="r2" class="my-1 ">
                             <p class="edp-font-13 reviw-Note-wr my-0 ie-d-i-b d-inline-block"
                               [innerHTML]="reviewPointModel.workspaceReviewPoint.description | ReviewNoteCurrentUsernameTransformPipe:reviewPointModel.workspaceReviewPoint.status">
                             </p>
                           </div>
                           <div id="r3-0" class="row my-2 mx-0"
                             *ngIf="reviewPointModel.workspaceReviewPoint.status == 1">
                             <div class="col-md-6 px-0 r-n-res-checkbox"
                               *ngIf="!(CurrentWorkspaceData.status == 'Approved'
                                 || CurrentWorkspaceData.status == 'Abandoned' || CurrentWorkspaceData.status == 'Declined') && (isLoggedInUserSystemAdmin || isQualityUserLoggedIn || (!isPureNotificationOnlyProfessional && isLoggedInUserHasWorkspaceAccess))
                                 ">
                               <input *ngIf="CanResolve(reviewPointModel.workspaceReviewPoint.createdBy, reviewPointModel.workspaceReviewPoint.whoCanResolve)" type="checkbox" name="reviewNotesResolvedCheck_{{i}}"
                                 id="reviewNotesResolvedCheck_{{i}}"
                                 [ngClass]="{true: 'review-note-event-link-disabled', false: ''}[!CanResolve(reviewPointModel.workspaceReviewPoint.createdBy, reviewPointModel.workspaceReviewPoint.whoCanResolve)]"
                                 (click)="ResolveReviewPoint(reviewPointModel.workspaceReviewPoint.reviewPointId)"
                                 style="cursor: pointer;">
                               <label *ngIf="CanResolve(reviewPointModel.workspaceReviewPoint.createdBy, reviewPointModel.workspaceReviewPoint.whoCanResolve)" for="reviewNotesResolvedCheck_{{i}}"
                                 [ngClass]="{true: 'review-note-event-link-disabled', false: 'cp'}[!CanResolve(reviewPointModel.workspaceReviewPoint.createdBy, reviewPointModel.workspaceReviewPoint.whoCanResolve)]"
                                 class="d-inline-block  edp-font-12 top-resolve mb-0" [ngStyle]="{'color': CanResolve(reviewPointModel.workspaceReviewPoint.createdBy, reviewPointModel.workspaceReviewPoint.whoCanResolve) ? '#00A7B5' : '#747575'}"
                                 >Resolve</label>
                             </div>
                             <div class="px-0 text-right col-md-6"
                               *ngIf="!(CurrentWorkspaceData.status == 'Approved' || CurrentWorkspaceData.status == 'Abandoned' || CurrentWorkspaceData.status == 'Declined')
                                 && reviewPointModel.workspaceReviewPointCommentCount == 0 && (isLoggedInUserSystemAdmin || isQualityUserLoggedIn || (!isPureNotificationOnlyProfessional && isLoggedInUserHasWorkspaceAccess))">
                               <span class="edp-font-12 custom-reply cp"
                                 (click)="showHideReply(reviewPointModel.workspaceReviewPoint.reviewPointId,'show')">
                                 Reply</span>
                             </div>
                             <div class="px-0 text-right" [ngClass]="{'col-md-12':
                                   (!CanResolve(reviewPointModel.workspaceReviewPoint.createdBy, reviewPointModel.workspaceReviewPoint.whoCanResolve) &&
                                   !(CurrentWorkspaceData.status == 'Approved' ||
                                     CurrentWorkspaceData.status == 'Abandoned' || CurrentWorkspaceData.status == 'Declined')
                                   ) ||
                                   (CurrentWorkspaceData.status == 'Approved'
                                    || CurrentWorkspaceData.status == 'Abandoned' || CurrentWorkspaceData.status == 'Declined'),
                                   'col-md-6': (CanResolve(reviewPointModel.workspaceReviewPoint.createdBy, reviewPointModel.workspaceReviewPoint.whoCanResolve) &&
                                   !(CurrentWorkspaceData.status == 'Approved' || CurrentWorkspaceData.status == 'Abandoned' ||
                                     CurrentWorkspaceData.status == 'Declined')
                                 )}" *ngIf="reviewPointModel.workspaceReviewPointCommentCount > 0">
                               <a (click)="showHideReviewNotesComments(reviewPointModel.workspaceReviewPoint.reviewPointId)"
                                 class="cp">
                                 <img src="../../../assets/images/EDP/Workspace/unfold_more-24px.svg"
                                   alt="expand-collapse">
                                 <span class=" edp-font-12 custom-reply cp">
                                   {{reviewPointModel.workspaceReviewPointCommentCount}} Replies</span>
                               </a>
                             </div>
                           </div>

                           <div
                             id="reviewPointCommentsSection_{{reviewPointModel.workspaceReviewPoint.reviewPointId}}"
                             style="display: none;">
                             <div class="w-100">
                               <hr />
                             </div>
                             <div id="existingComment">
                               <div class="row m-0 p-0"
                                 *ngFor="let reviewComment of reviewPointModel.workspaceReviewPointComments  let j=index"
                                 style="background-color: #fff;">
                                 <div id='c-r1' class="row m-0">
                                   <div class="col-md-12 px-0">
                                     <span class="review-by">By: {{reviewComment.name}}</span>
                                   </div>
                                   <div class="col-md-12  px-0">
                                     <span class="review-by f-11">{{reviewComment.createdOn | date: "MM/dd/yyyy hh:mm
                                       a UTC"}}</span>
                                   </div>
                                 </div>
                                 <div id='c-r2' class="col-md-12 px-0">
                                   <p class="edp-font-13 reviw-Note-wr my-0 ie-d-i-b d-inline-block"
                                     [innerHTML]="reviewComment.comments | ReviewNoteCurrentUsernameTransformPipe:reviewPointModel.workspaceReviewPoint.status">
                                   </p>
                                 </div>
                                 <div class="w-100">
                                   <hr class="my-2" />
                                 </div>
                               </div>

                               <div class="row m-0 p-0"
                                 *ngIf="!(CurrentWorkspaceData.status == 'Approved' || CurrentWorkspaceData.status == 'Abandoned' || CurrentWorkspaceData.status == 'Declined') && (isLoggedInUserSystemAdmin || isQualityUserLoggedIn || (!isPureNotificationOnlyProfessional && isLoggedInUserHasWorkspaceAccess))">
                                 <span class="d-inline-block ml-3 edp-font-12 custom-reply cp"
                                   *ngIf="reviewPointModel.workspaceReviewPoint.status == 1"
                                   (click)="showHideReply(reviewPointModel.workspaceReviewPoint.reviewPointId,'show')">Reply</span>
                               </div>
                             </div>
                           </div>

                           <div id="divNewReviewComment_{{reviewPointModel.workspaceReviewPoint.reviewPointId}}"
                             class="row m-0 p-0 form-group" style="display: none;">
                             <form #newReviewComment="ngForm" class="w-100">
                               <div id='nc-r1' class="row m-0 p-0">
                                 <div class="col-md-12 px-0 mt-1">
                                   <div *ngIf="_reviewNoteSearchableUsers.length > 0"
                                     id="txtNewComment_{{reviewPointModel.workspaceReviewPoint.reviewPointId}}"
                                     class="textarea reviewNoteCommentDiv custom-input-note my-2 review-note-input-box review-note-comment-editable-div"
                                     maxlength="1000" required (blur)="checkSpacesatBegining($event,0)"
                                     name="txtDescription" style="resize: none;" contenteditable="true"
                                     (keyup)="setRemainingCharacterCount($event, 'reviewCommentRemainingCharacterLabel'+reviewPointModel.workspaceReviewPoint.reviewPointId)"
                                     (keydown)="checkIfAllowToTypeCharacter($event)"
                                     (paste)="verifyPastedContent($event, 'reviewCommentRemainingCharacterLabel'+reviewPointModel.workspaceReviewPoint.reviewPointId)"
                                     data-placeholder='Use @ to select a person to receive an email notification regarding this review note. Using "@Team" will send a notification to the entire team.'
                                     [mentionConfig]="reviewNoteConfigurations" (itemSelected)="itemSelected($event, 'txtNewComment_'+reviewPointModel.workspaceReviewPoint.reviewPointId)"
                                     (onMentioned)="onMentioned($event,'txtNewComment_'+reviewPointModel.workspaceReviewPoint.reviewPointId, 'reviewCommentRemainingCharacterLabel'+reviewPointModel.workspaceReviewPoint.reviewPointId)"
                                     #reviewNoteCommentDiv></div>
                                   <span
                                     id="reviewCommentRemainingCharacterLabel{{reviewPointModel.workspaceReviewPoint.reviewPointId}}"
                                     class="review-note-character-count-indicator-label">{{
                                     maxCharactersAllowedForReviewNoteDescription }} characters</span>
                                   <span
                                     id="reviewNoteCommentVaidationMessage{{reviewPointModel.workspaceReviewPoint.reviewPointId}}"
                                     class="review-note-validation-text review-Note-Reply-Hide-Error-Message">Message
                                     is mandatory</span>

                                 </div>
                               </div>
                               <div id='nc-r1' class="row m-0 p-0">
                                 <div class="col-md-12 px-0 text-right my-2">
                                   <button type="button" id="btnAddReviewNoteCancel"
                                     class="btn customBtnWhite btn-reply-note-h-34" data-toggle="modal"
                                     (click)="showHideReply(reviewPointModel.workspaceReviewPoint.reviewPointId,'hide', 'txtNewComment_'+reviewPointModel.workspaceReviewPoint.reviewPointId, 'reviewCommentRemainingCharacterLabel'+reviewPointModel.workspaceReviewPoint.reviewPointId)">
                                     <span class="d-inline-block px-1 align-middle">Cancel</span>
                                   </button>

                                   <button type="button" id="btnAddReviewNotePost"
                                     class="btn PrimaryButton ml-3 w-95 edp-font-14 btn-reply-note-h-34"
                                     data-toggle="modal" [disabled]="!newReviewComment.form.valid"
                                     (click)="InsertReviewPointComments(reviewPointModel.workspaceReviewPoint.reviewPointId, 'txtNewComment_'+reviewPointModel.workspaceReviewPoint.reviewPointId, 'reviewCommentRemainingCharacterLabel'+reviewPointModel.workspaceReviewPoint.reviewPointId)">
                                     <span class="d-inline-block px-1 align-middle">Reply</span>
                                   </button>
                                 </div>
                               </div>
                             </form>
                           </div>

                           <div id="r3-1" class="my-1 mx-0 row"
                             *ngIf="reviewPointModel.workspaceReviewPoint.status == 2">
                             <div class="col-md-12 px-0">
                               <span class="review-note-resolve-by">Resolved By:
                                 {{reviewPointModel.workspaceReviewPoint.lastModifiedBy}}
                               </span>
                             </div>

                             <div class="col-md-7 px-0">
                               <span class="review-note-resolve-by edp-font-10">
                                 {{reviewPointModel.workspaceReviewPoint.lastModifiedOn | date: "MM/dd/yyyy hh:mm a
                                 UTC"}}
                               </span>
                             </div>

                             <div class="col-md-12 text-right px-0"
                               *ngIf="reviewPointModel.workspaceReviewPointCommentCount > 0">
                               <a (click)="showHideReviewNotesComments(reviewPointModel.workspaceReviewPoint.reviewPointId)"
                                 class="cp">
                                 <img src="../../../assets/images/EDP/Workspace/unfold_more-24px.svg"
                                   alt="expand-collapse">
                                 <span class=" edp-font-12 custom-reply cp">
                                   {{reviewPointModel.workspaceReviewPointCommentCount}} Replies</span>
                               </a>
                             </div>
                           </div>


                         </div>
                       </div>

                     </div>
                   </div>
                 </div>
               </div>

               <div id="divNoReviewNotes" class="row mt-3 m-0 p-0"
                 *ngIf="workspaceReviewPointsCount == 0 && !isAddReviewNoteFormOpen">
                 <span class="no-review-notes">
                   {{NoReviewNotesMsg}}
                 </span>
               </div>

             </div>
           </div>
           <!-- end review notes -->
              </div> 
        </div><!-- modal-content -->
     </div><!-- modal-dialog -->
 </div><!--modal -->