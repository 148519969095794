export class Assignments {
    engagementId: number;
    clientId?: number;
    clientName?: string;
    assignmentName: string;
    assignmentCode: string;
    solutionCode: string;
    solutionCodeDescription?: string;
    cmsppmd: string;
    assignmentAdministrator: string;
    status: string;
    adminEmailId: string;
    cmseppmdEmailId: string;
    assignmentCodeId: number;
    siteURL: string;
    engagementName: string;
    engagementPartner: string;
    engagementPartnerName: string;
    isPrimaryAssignment?: number;
    isContingent?: string;
    engagementTypeName?: string;
    clientBillingContactTitle?: string;
    clientBillingContactName?: string;
    clientBillingContactEmail?: string;
    engagementStartDate?: string;
    engagementEndDate?: string;
    realization?: number;
    netFees?: number;
    estimatedGrossMargin?: number;
    collaborationSiteName?: string;
    officeLocationCodeName?: string;
    clientCode?: string;
    indusApproved: string;
    assignmentEQCR?: string;
    assignmentEQCREmailId?: string;
    lastActivityDate?: string;
    changeOrderNetFees?:number;
    changeOrderEstimatedEndDate?:string;
    changeOrderGrossMargin?:number;

}
export class AssignmentInfo{
    engagementId:number;
    workspaceId?:string;
}