import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from './appConfigService';
import { Applicationmaster } from '../model/ApplicationMaster';
import { TeamMemberPermission, ClientMemberPermission } from '../model/Engagement/TeamMemberPermission';
import { ContactTypes } from '../model/Client/ContactTypes';
import { ClientLocation } from '../model/Client/ClientLocation';

@Injectable({
  providedIn: 'root'
})
export class ApplicationMasterService {
  APIEndPoint: string;
  constructor(private enviornment: AppConfigService, private http: HttpClient) {
    this.APIEndPoint = enviornment.config.DATA.API_END_POINT.API_URL;
  }

  GetSubDocumentTypes(): Observable<Applicationmaster[]> {
    return this.http.get<Applicationmaster[]>(
      this.APIEndPoint + '/ApplicationMaster/SubDocumentType'
    );
  }

  getTemplatesTypes(): Observable<Applicationmaster[]> {
    return this.http.get<Applicationmaster[]>(
      this.APIEndPoint + '/ApplicationMaster/TemplateType'
    );
  }

  GetTeamMemberPermissions(): Observable<TeamMemberPermission[]> {
    return this.http.get<TeamMemberPermission[]>(this.APIEndPoint + '/ApplicationMaster/GetTeamMemberPermissions');
  }
  GetClientMemberPermissions(): Observable<ClientMemberPermission[]> {
    return this.http.get<ClientMemberPermission[]>(this.APIEndPoint + '/ApplicationMaster/GetClientMemberPermissions');
  }
  GetApplicationMasterDataById(applicationMasterId: number): Observable<Applicationmaster> {
    return this.http.get<Applicationmaster>(
      this.APIEndPoint + '/ApplicationMaster/GetApplicationMaster/' + applicationMasterId
    );
  }
  GetApplicationMasterDataByValue(applicationName: string, shortCode: string, appValue: string): Observable<Applicationmaster[]> {
    return this.http.get<Applicationmaster[]>(
      this.APIEndPoint + '/ApplicationMaster/GetApplicationMasterDataByValue/' + applicationName + '/' + shortCode + '/' + appValue
    );
  }
  GetApplicationMasterDataByNameAndValue(applicationName: string, appValue: string): Observable<Applicationmaster[]> {
    return this.http.get<Applicationmaster[]>(
      this.APIEndPoint + '/ApplicationMaster/GetApplicationMasterDataByNameAndValue/' + applicationName + '/' + appValue
    );
  }
  GetAllTeamMemberRoles(applicationName: string): Observable<Applicationmaster[]> {
    return this.http.get<Applicationmaster[]>(
      this.APIEndPoint + '/ApplicationMaster/TeamMemberRoles/' + applicationName
    );
  }

  GetContactTypes(): Observable<ContactTypes[]> {
    return this.http.get<ContactTypes[]>(this.APIEndPoint + '/ApplicationMaster/GetContactTypes');
  }
  GetClientLocations(): Observable<ClientLocation[]> {
    return this.http.get<ClientLocation[]>(this.APIEndPoint + '/ApplicationMaster/GetClientLocations');
  }

  GetAllClientPrograms(): Observable<Applicationmaster[]> {
    return this.http.get<Applicationmaster[]>(
      this.APIEndPoint + "/ApplicationMaster/GetAllClientPrograms"
    );
  }

  GetAllClientContactTypes(): Observable<Applicationmaster[]> {
    return this.http.get<Applicationmaster[]>(
      this.APIEndPoint + "/ApplicationMaster/GetAllClientContactTypes"
    );
  }

  GetSharepointFileLabels(): Observable<Applicationmaster[]> {
    return this.http.get<Applicationmaster[]>(
      this.APIEndPoint + "/ApplicationMaster/GetSharepointFileLabel"
    );
  }

  GetClientConsentDataTerm(): Observable<Applicationmaster[]> {
    return this.http.get<Applicationmaster[]>(
      this.APIEndPoint + '/ApplicationMaster/GetClientConsentDataTerm'
    );
  }
  GetApplicationMasterDataByName(applicationName: string): Observable<Applicationmaster[]> {
    return this.http.get<Applicationmaster[]>(
      this.APIEndPoint + '/ApplicationMaster/GetApplicationMasterDataByName/' + applicationName);
  }
}
