export class OneViewClientDetailModel {
    hs_segment: string =''; //ClientType--
    clientType: string = '';
    name: string =''; //ClientName
    accountnumber: string =''; //ClientCode
    gt_cmsaccountname: string =''; //AccountName
    accountid: string =''; //AccountId
    hs_accountid: string = ''; //Oneview AccountId
    hs_akadba: string =''; //AkaDba
    gt_industrygroupingid: string = ''; // Industry --
    industry = ''; // Industry UI
    hs_siccode_hs_name: string = ''; // SicCode --
    sicCode: string = '';
    hs_legaltaxstatus: string = ''; // StructureOrEntity --
    structureOrEntity: string =''; // StructureOrEntity - UI
    hs_exchange: string = ''; //StockExchange --
    stockExchange: string ='' // StockExchange UI --
    tickersymbol: string ='';  //TickerSymbol
    hs_leadsource: string ='' // ReferralSource --
    referralSource: string = ''; // ReferralSource - UI
    hs_fiscalyearend: string =''; // FiscalYearEndMonth -- 
    fiscalYearEndMonth: string = ''; // FiscalYearEndMonth UI
    telephone1: string =''; //PhoneNumber
    fax: string =''; //Fax    
    address1_line1: string =''; // Address1
    address1_line2: string ='' // Address2
    address1_line3: string =''; //Address3
    address4: string = ''; //Address4  NOT Found field in OneView
    websiteurl: string =''; //websiteurl used to prefilled from oneview
    hs_domainname: string =''; // Company Email Domain- used to validate duplicate Client  
    address1_city: string =''; // City
    address1_postalcode: string= ''; //ZipCode
    hs_address1_country: string =''; //Country --
    country: string =''; //country UI   
    address1_stateorprovince: string = ''; //state UI
    cmsClientAdministratorEmail: string ='';
    cmsClientAdministratorFullname: string ='';
    customeHeadquarter: string; 
    hs_accountsource: string =''; //accountSource Static value of (New Engage Client)
    gt_engageclientid: number; //ClientId
    hs_siccode: string ='';
    ownerid: string = ''; //ClientEmail (Lookup field. Must search for a licensed oneView user that matches the email address of the EP. If one is not found, then this will default to the To Be Assigned team.)
    OppCount: string = '';
    hs_clientstatus: string = '';
    // billingContactEmail: string ='';
    // billingContactTitle: string ='';
    // billingContactFullName: string ='';    
    // businessContactEmail: string ='';
    // businessContactTitle: string ='';
    // businessContactFullName: string ='';       
    hs_clientstatus_derivedstatus: string = '';
    city: string = '';
    stateorprovince: string = '';
}

export interface OneViewClientResponse<T> {
    statusCode: string;
    status: string;
    response: T;
}

export interface OneViewClientResponseV2<T> {
    statusCode: string;
    status: string;
    response: T;
    totalRecordCount: number;
}

export class ClientSearchInput{
    clientSearchText: string;
    noOfRecords: number;
    pageNo: number;
}
export class OneViewClientSearchOpportunityModel {
    opportunityId:string;
    opportunityName:string;
    opportunityEstimatedFees:string;
    expectedStartDate:string;
    pursuitTeam:string;   
}
