import { Component, OnInit, Input, forwardRef, Output, EventEmitter, HostListener, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import { ConfigurationService } from 'src/app/services/configuration.service';
import { ClientService } from 'src/app/services/clientservice';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { ClientSearchInput, OneViewClientDetailModel } from 'src/app/model/Client/OneViewClientDetail';
import { NewClient } from 'src/app/model/Client/NewClient';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { PagerService } from 'src/app/services/pager.service';

declare var $;

@Component({
  selector: 'oneview-client-search',
  templateUrl: './oneview-client-search.component.html',
  styleUrls: ['./oneview-client-search.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OneviewClientSearchComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OneviewClientSearchComponent),
      multi: true,
    }
  ]
})
export class OneviewClientSearchComponent implements OnInit, ControlValueAccessor, Validator, OnChanges {

  @Input() source: string;
  @Input() name: string;
  @Input('value') val: string;
  @Input() MappedSource: string;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Input() Sequence: number;
  // @Input() IsAutoPopulate: boolean;
  @Input() editMode: boolean = false;
  @Input() clientDetails: NewClient;
  @Input() MappedSourceClientNameValue: boolean;
  @Output() validation: EventEmitter<string> = new EventEmitter<string>();
  @Output() newClientOption: EventEmitter<string> = new EventEmitter<string>();
  @Output("clientChange") clientChange: EventEmitter<any> = new EventEmitter<any>();
  @Output("OnSkipAccountSearch") OnSkipAccountSearch: EventEmitter<any> = new EventEmitter();
  private parseError: boolean;
  private modalConfirmationDialogRef: Subscription = null;
  clientList: OneViewClientDetailModel[] = [];
  client: NewClient = new NewClient();
  selectedClient: OneViewClientDetailModel = new OneViewClientDetailModel();
  currentPageNo: number;
  prevPageNo: number;
  selectedValueFlag: boolean;
  IsClientNameChanged: boolean = false;
  IsPreviousClientSearchOnEnter: boolean = false;
  IsTypeAheadSearch: boolean = false;
  clientNamePlaceholderText: string = "Enter a client name, oneView account name, aka/dba name, or oneView account ID";
  filtershowFlag: boolean;
  clientSearchFeatureFlagEnabled: boolean = false;
  listViewNoOfrecordsPerPage: number = 5;
  listViewCurrentPageNumber: number = 1;
  noOfrecordsPerPageList: number[];
  totalNoOfRecords: number = 0;
  isSingleSearch: boolean = false;
  clientSearchInput: ClientSearchInput = new ClientSearchInput();
  txtPageNo: number = 0;
  clientSearchText: string = '';
  CantfindMsg: boolean = false;
  noResultFoundMsg: boolean = false;

  constructor(
    private dataService: DataService,
    private clientService: ClientService,
    private loadingScreenService: LoadingScreenService,
    private clientSearchRef: ElementRef,
    private router: Router,
    private data: DataService,
    private pagerService: PagerService
  ) {
    this.selectedValueFlag = false;
  }

  pager: any = {};
  propagateChange: any = () => { };
  onTouched: any = () => { };

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.propagateChange(val);
    this.onTouched();
  }

  ngOnInit() {
    this.getClientSearchFeatureFlag();
    this.showFilterSection('hide');
    this.setPageRecordRange();
    var isclientNameExist = false;
    this.dataService.getNewClientName.subscribe(clientName => {
      if (clientName != "clientName" && clientName != '') {
        this.client.clientName = clientName.substring(0, 40).trim();
        isclientNameExist = true;
      }
    });
    setTimeout(() => {
      this.InitalizeDataSource();
      if (isclientNameExist) {
        $('#txtValue' + this.MappedSource + this.Sequence).val(this.client.clientName);
        this.getClientList(this.client.clientName, 3, this.listViewNoOfrecordsPerPage, this.listViewCurrentPageNumber);
        this.dataService.setNewClientName('');
      }
    }, 300);

    let searchOneViewAccountId: string = ''
    this.dataService.getOneClientViewAccountId.subscribe(oneViewAccountId => {
      searchOneViewAccountId = oneViewAccountId;
    });
    if (searchOneViewAccountId != '') {
      setTimeout(() => {
        this.getOneViewData(searchOneViewAccountId);
      }, 300);
    }

    this.initiateConfirmationPopUp();

  }
  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'clientDetails') {
        if (changes.clientDetails.currentValue != undefined &&!changes.clientDetails.firstChange) {
          this.loadonewViewClientInformation();
        }
      }
    }
  }

  ngOnDestroy() {
    this.loadingScreenService.stopLoading();
    this.modalConfirmationDialogRef?.unsubscribe();
  }

  showFilterSection(action: string) {
    const divObj = $('.row.filter-list');
    if (action === 'hide') {
      this.newClientOption.emit('hide');
      divObj.hide();
      $(".link-oneview-check").addClass('hide');
    }
    else {
      this.newClientOption.emit('show');
      divObj.show();
      $(".link-oneview-check").removeClass('hide');
      const divFilterObj = $('.filter-select.' + this.MappedSource + this.Sequence + '.p-1');
      divFilterObj.scrollTop(0);
    }
  }

  InitalizeDataSource() {
    this.showFilterSection('hide');
  }

  getClientSearchFeatureFlag() {
    this.clientService.GetClientSearchFeatureFlag().subscribe({
      next: result => {
        this.clientSearchFeatureFlagEnabled = result;
      }
      , error: er => {
        this.clientSearchFeatureFlagEnabled = false;
        console.log("failed to load changeOrderFeatureFlag");
      }
    })
  }
  onSearch(filter: string, event) {
    //When client search is triggered on enter event or duplicate box click event and if clicking on outside search is happening again by blur event.
    //Below if condition code will resolve the above problem.    
    this.clientSearchText = filter;
    this.IsTypeAheadSearch = false;
    $('#inlineLoader').removeClass('loader');
    if (event != undefined && event != null && (event.code == 'Enter') || (event.type == 'click')) {
      this.showFilterSection('hide');
      this.clientList = [];
      this.isSingleSearch = false;
      this.listViewCurrentPageNumber = 1;
      this.getClientList(filter, 3, this.listViewNoOfrecordsPerPage, this.listViewCurrentPageNumber);
    }
  }

  /**
   *  This function is to return the list of clients based on the passed search term.
   *  Also handle the previous search term for tracking purpose. Clents
   * 
   */
  getClientList(filter: string, length: number, recordCount: number, pageCount: number) {
    let filterLength: number;
    filter = filter.trim();
    if (filter) {
      filterLength = filter.length;
    } else {
      filterLength = 0;
    }
    if (filterLength >= length) {
      this.parseError = true;
      if (this.IsTypeAheadSearch) {
        $('#inlineLoader').addClass('loader');
      } else {
        this.loadingScreenService.startLoading();
      }
      this.clientSearchInput.clientSearchText = filter;
      this.clientSearchInput.noOfRecords = recordCount;
      this.clientSearchInput.pageNo = pageCount;
      this.clientService.GetOneViewClientsV2(this.clientSearchInput).subscribe(result => {
        if (this.IsTypeAheadSearch) {
          $('#inlineLoader').removeClass('loader');
        } else {
          this.loadingScreenService.stopLoading();
        }

        if ($('#txtValue' + this.MappedSource + 0).val().length == 0) {
          this.showFilterSection('hide');
        }
        else if (result && result.statusCode == "200" && result.response.length === 0) {
          this.noResultFoundMsg = true;
          this.CantfindMsg = false;
          this.clientList = result.response;
          this.totalNoOfRecords = result.totalRecordCount;
          this.listViewSetPage(pageCount, recordCount);
          this.showFilterSection('show');
        } else if (result.statusCode != 'BadRequest') {
          this.noResultFoundMsg = false;
          this.CantfindMsg = true;
          this.showFilterSection('show');
          this.clientList = result.response;
          console.log('Clientlist: ', this.clientList);
          this.totalNoOfRecords = result.totalRecordCount;
          this.listViewSetPage(pageCount, recordCount);
          this.populateHeadquartes();
          if (this.clientList.length === 0) {
            this.parseError = false;
          }
          this.validation.emit('');
          this.loadingScreenService.stopLoading();
        } else {
          this.showFilterSection('show');
          this.totalNoOfRecords = result.totalRecordCount;
          this.clientList = [];
          this.isSingleSearch = false;
          this.CantfindMsg = false;
          this.noResultFoundMsg = true;
        }
      });

    }
    else {
      this.showFilterSection('hide');

    }
  }

  resetClientSelection() {
    this.parseError = false;
    $('#txtValue' + this.MappedSource + this.Sequence).val('');
    $('#hdnKey' + this.MappedSource + this.Sequence).val('');
    this.clientList = [];
    this.change.emit('');
  }

  hideSelect() {
    $('.' + this.source).hide();
    this.showFilterSection('hide');
  }

  selectedClientValue(event, item: OneViewClientDetailModel) {
    this.CantfindMsg = false;
    this.noResultFoundMsg = false;
    this.selectedValueFlag = true;
    let oneViewStatus = item.hs_clientstatus;
    let newClientStatus: string = ''
    this.clientService.GetClientDetailsByOneViewAccountId(item.hs_accountid).subscribe({
      next: client => {
        let clientStatus: string = '';
        if (client != null && client != undefined)
          clientStatus = client.clientStatus;
        if (oneViewStatus == 'Open/Active' && clientStatus == 'Active')
          newClientStatus = 'Open/Active';
        else if (oneViewStatus == 'Client code not created' && clientStatus == 'Active')
          newClientStatus = 'Open/Active';
        else if (oneViewStatus == 'Client code not created' && clientStatus == 'Awaiting approval')
          newClientStatus = 'Awaiting approval';
        else if ((oneViewStatus == 'Open/Active' && clientStatus == 'Closed')
          || (oneViewStatus == 'Closed' && clientStatus == 'Closed')
          || (oneViewStatus == 'Closed' && clientStatus == 'Active')
          || (oneViewStatus == 'Closed' && clientStatus == '')
        
        )
          newClientStatus = 'Closed';
        else if ((oneViewStatus == 'Client code not created' && (clientStatus == '' || clientStatus == 'Removed'))
          || (oneViewStatus == 'Open/Active' && (clientStatus == '' || clientStatus == 'Removed'))
        )
          newClientStatus = 'Client Not Available';

        if (newClientStatus === 'Client Not Available') { // for not yet avail
          this.CantfindMsg = true;
          this.dataService.setNewClientName(item.name);
          this.dataService.setOneViewAccountId(item.accountid);
          this.dataService.setoneClientViewAccountId('');

          this.dataService.setOneViewClientSelectedObject(item);
          this.showNotYetAvailableClientDialog();
          return;
        }
        else if (newClientStatus === 'Closed') { // for closed client
          this.CantfindMsg = true;
          this.showCloseClientDialog();
          return;
        }
        else {
          this.parseError = false;
          console.log('Selected Value ', item);
          let clientName = (item.accountnumber === null) ? item.name : item.name + ' (' + item.accountnumber + ')';
          let newClientList: OneViewClientDetailModel[] = this.clientList.filter(x => x.accountid == item.accountid);
          if (newClientList != null && newClientList.length > 0)
            newClientList[0].hs_clientstatus_derivedstatus = (clientStatus ==='Active') ?  'Open/Active' : clientStatus;
          this.clientList = newClientList;         
          this.CantfindMsg = false;
          console.log('Selected client ', this.clientList);
          let CMSclientName = this.clientList[0].name + (this.clientList[0].accountnumber == null ? '' : '(' + this.clientList[0].accountnumber + ')');
          $('#txtValue' + this.MappedSource + this.Sequence).val(CMSclientName);
          $('#hdnKeyCode' + this.MappedSource + this.Sequence).val(this.clientList[0].accountnumber);//ClientCode
          $('#hdnKey' + this.MappedSource + this.Sequence).val(client.clientId);//ClientId

          // $('#hdnKeyClientName' + this.MappedSource + this.Sequence).val(CMSclientName); //ClientName
          $('#hdnKeyClientOneViewAccountId' + this.MappedSource + this.Sequence).val(this.clientList[0].hs_accountid);//Oneview AccountId
          this.isSingleSearch = true;
          this.showFilterSection('show');
          this.filtershowFlag = true;
          event?.stopPropagation();
          item.gt_engageclientid = client.clientId;
          this.change.emit(item);
          this.clientChange.emit(item);
        }
      },
      error: er => {
        newClientStatus = '';
        console.log("failed to load  GetClientDetailsByOneViewAccountId");
      }
    });
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  validate(control: AbstractControl): ValidationErrors {
    return (!this.parseError) ? null : {
      required: {
        valid: this.parseError,
      }
    };
  }

  registerOnValidatorChange?(fn: () => void): void {
  }

  cleareErrorMessage() {
    this.validation.emit('');
  }

  populateHeadquartes() {
    // customeHeadquarter
    for (let index = 0; index < this.clientList.length; index++) {
      if (this.clientList[index].stateorprovince != undefined && this.clientList[index].stateorprovince != '') {
        this.clientList[index].customeHeadquarter = this.clientList[index].city + ', ' + this.clientList[index].stateorprovince
      } else if (this.clientList[index].city != undefined) {
        this.clientList[index].customeHeadquarter = this.clientList[index].city;
      }
    }
  }

  showNotYetAvailableClientDialog() {
    let dialogDesc: string = '';
    let bigTitle: boolean = false;
    let dialogInput = '';
    const dialogType = 'information';
    const action = 'Client not available.';
    this.displayConfirmationPopUp(dialogType, action, dialogDesc, 2, dialogInput, false, false,
      bigTitle, '0', true);
  }

  showCloseClientDialog() {
    let dialogDesc: string = '';
    let bigTitle: boolean = false;
    let dialogInput = '';
    const dialogType = 'information';
    const action = 'Client is closed.';
    dialogDesc = 'Submit a Centralized Billing Share request to re-open the client code before creating a new engagement.';
    this.displayConfirmationPopUp(dialogType, action, dialogDesc, 1, dialogInput, false, false,
      bigTitle, '0', false);
  }

  displayConfirmationPopUp(_dialogType: string, _dialogTitle: string, _dialogDescription: string, _buttonCount: number,
    _dialogInput: string, IsAbandonModal: boolean, IsDeclineModal: boolean, bigTitle: boolean, engId: string = '0', isdialogNotYetAvailableClient: boolean) {
    this.loadingScreenService.stopLoading();
    var modalObj = {
      dialogType: _dialogType,
      dialogTitle: _dialogTitle,
      dialogDescription: _dialogDescription,
      dialogRedirectUrl: '',
      dialogButton: _buttonCount,
      DeclinedReasonInput: _dialogInput,
      modalDisplay: true,
      dialogAbandonModal: IsAbandonModal,
      dialogDeclineModal: IsDeclineModal,
      dialogBigTitle: bigTitle,
      engagementId: engId,
      dialogNotYetAvailableClient: isdialogNotYetAvailableClient,
    };
    const modalJSON = JSON.stringify(modalObj);
    this.dataService.displayDialogNotification(modalJSON);
  }

  NavigateToCreateClient() {
    this.dataService.setReturnUrlForCreateClient(this.router.url);
    this.router.navigate(['/NewClient']);
  }

  initiateConfirmationPopUp() {
    this.modalConfirmationDialogRef = this.data.getModalDetails$.subscribe(
      dialogData => {
        if (dialogData) {
          const dialogModal = JSON.parse(dialogData);
          const dialogTitle = dialogModal.dialogTitle;
          const dialogButtonName = dialogModal.dialogButtonName;
          if (dialogTitle === 'Client not available.' && dialogButtonName === 'ok') {
            this.NavigateToCreateClient();
          }
        }
      });
  }

  listViewSetPage(pageNumber: number, recordsPerPage: number) {
    this.txtPageNo = pageNumber;
    // get pager object from service
    this.listViewCurrentPageNumber = pageNumber;
    const inputDrpValue = $('#drprecordsPerPage option:selected').text();
    if (inputDrpValue === '') {
      this.listViewNoOfrecordsPerPage = recordsPerPage;
    } else {
      this.listViewNoOfrecordsPerPage = Number(inputDrpValue);
    }
    if (this.listViewCurrentPageNumber != 0) {
      this.pager = this.pagerService.getPager(this.totalNoOfRecords, this.listViewCurrentPageNumber, this.listViewNoOfrecordsPerPage);
      console.log('pager ==>', this.pager);
    } else {
      $('#previous').off('click');
      $('#first').off('click');
      this.loadingScreenService.stopLoading();
    }
  }

  setPageRecordRange() {
    this.noOfrecordsPerPageList = [];
    this.noOfrecordsPerPageList.push(5);
    this.noOfrecordsPerPageList.push(10);
    this.noOfrecordsPerPageList.push(15);
    this.noOfrecordsPerPageList.push(20);
    this.noOfrecordsPerPageList.push(25);
    this.noOfrecordsPerPageList.push(30);
  }

  getStatus(client: any): string {
    return client.accountnumber ? 'active' : 'not yet available';
  }

  closeCard() {
    this.showFilterSection('hide');
    this.filtershowFlag = false;
    $('#txtValue' + this.MappedSource + this.Sequence).val('');
  }

  onGoClick() {
    if ($('#txtPageNo').val() <= this.pager.totalPages) {
      this.txtPageNo = $('#txtPageNo').val();
      this.getClientList(this.clientSearchText, 3, this.listViewNoOfrecordsPerPage, this.txtPageNo);
    } else {
      $('#txtPageNo').val(1);
      this.getClientList(this.clientSearchText, 3, this.listViewNoOfrecordsPerPage, 1);
    }
  }

  numberOnly(event): boolean {
    if ($('#txtPageNo').val() > this.totalNoOfRecords) {
      $('#txtPageNo').val(this.listViewCurrentPageNumber);
    }
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getOneViewData(selectedAccountId: string) {
    /* this.clientService.GetClientDetailByAccountId(selectedAccountId).subscribe(result => {
       let detail : OneViewClientDetailModel[] = [];
       //this.clientList = result.response;
 if(result.response!=null && result.response!= undefined)
     {  
       detail.push (result.response);
       detail[0].hs_clientstatus_derivedstatus='Awaiting approval'
      this.clientList = detail;
      this.isSingleSearch=true;
      this.showFilterSection('show');
      this.filtershowFlag = true;
    
      
     }
       this.loadingScreenService.stopLoading();
     },
       (error) => {
         this.loadingScreenService.stopLoading();
         console.log('Error while getting response GetClientDetailByAccountId func ', error);
       });*/
    let detail: OneViewClientDetailModel[] = [];

    var oneViewData = this.dataService.getOneViewClientSelectedObject();

    if (oneViewData != null && oneViewData != undefined
      && oneViewData.accountid == selectedAccountId
    ) {
      detail.push(oneViewData);
      detail[0].hs_clientstatus_derivedstatus = 'Awaiting approval'
      this.clientList = detail;
      this.isSingleSearch = true;
      this.showFilterSection('show');
      this.filtershowFlag = true;
      this.dataService.setoneClientViewAccountId('');
    }

  }
  loadonewViewClientInformation() {
    if (this.editMode) {
      this.clientSearchInput.clientSearchText = this.clientDetails.oneViewAccountId;
      this.clientSearchInput.noOfRecords = 1;
      this.clientSearchInput.pageNo = 1;
      this.clientService.GetOneViewClientsV2(this.clientSearchInput).subscribe(result => {
        this.noResultFoundMsg = false;
        this.CantfindMsg = false;
        this.clientList = result.response;
        this.totalNoOfRecords = result.totalRecordCount;
        this.isSingleSearch=true;
        // this.clientList[0].hs_clientstatus=this.clientDetails.clientStatus; // this can review after sometime
        this.selectedClientValue(undefined,this.clientList[0]);
        // this can review after sometime edit
        // let CMSclientName = this.clientList[0].name + (this.clientList[0].accountnumber == null ? '' : '(' + this.clientList[0].accountnumber + ')');
        // $('#txtValue' + this.MappedSource + this.Sequence).val(CMSclientName);
        // $('#hdnKeyCode' + this.MappedSource + this.Sequence).val(this.clientList[0].accountnumber);//ClientCode
        // $('#hdnKey' + this.MappedSource + this.Sequence).val(this.clientDetails.clientId);//ClientId

        // // $('#hdnKeyClientName' + this.MappedSource + this.Sequence).val(CMSclientName); //ClientName
        // $('#hdnKeyClientOneViewAccountId' + this.MappedSource + this.Sequence).val(this.clientList[0].hs_accountid);//Oneview AccountId
        // this.showFilterSection('show');
        // this can review after sometime  edit
      })

    }
  }
}