import { TeamMember } from './Engagement/TeamMember';
import { WorkspaceDocumentModel } from './WorkspaceDocumentModel';

export class WorkspaceModel {
  workspaceId: string;
  clientId: string;
  engagementId: number;
  userName: string;
  createdby: string;
  createdon: Date;
  updatedBy: string;
  updatedon: Date;
  hdWorkGroupId: string;
  hdWorkItemId: string;
  hdTemplatePackageId: string;
  hdCurrentTempVersion: number;
  hdLastVersionCheckedAgainst: number;
  hdLastVersionCheckedOn: Date;
  isActive: boolean;
  status: string;
  id: string;
  isPrimaryWorkspaceId: boolean;
  workspaceCode: number;
  solutionCode: string;
  clientName: string;
  engagementName: string;
  setAnsFlag: string;
  hdWorkItemVersionId: string;
  hdCoreSessionId: string;
  loggedInUserFullName: string;
  loggedInUserEmail: string;
  showFutureMessage: boolean;
  lastActivityBy: string;
  lastActivityDate: Date;
  hdTemplatePackageVersionId: string;
  lastActivityByEmail: string;
  continuanceWorkItemId: string;
  hdTemplateName: string;
  updateQuestionnaireID: string;
  orginalHDWorkItemVersionId: string;
  orginalHDWorkItemId: string;
  continuanceTemplateChangeAnsCarryForward: boolean = false;
  isTemplateChanged: boolean = false;
  updateQuestionnaireAfterAcceptance: boolean = false;
  workspaceDocumentId: number;
  documentAliasName: string;
  isAllowMultipleTemplates: boolean;
  primarySolutionCode: string;
  engagementState:string;
  clientCode:string;
  oneViewAccountId:string;
  abandonedDeclinedDate:Date;
}
export class SecondaryWorkspaceDataListModel {
  secondaryWorkspaceDataList: WorkspaceModel[];
}

export class NewWorkspaceData {
  teamMember: TeamMember[];
  workspace: WorkspaceModel;
  document: WorkspaceDocumentModel[];
}

export class SaveWorkItemAssemblySession {
  workItemId: string;
  workItemVersionId: string;
}

export class ResetWorkspaceDataModel {
  workspaceId: string;
  updatedBy: string;
  isLoggedInUserSystemAdmin: boolean;
}

export enum WorkspaceInterviewsStatus {
  //Create session when user creates interviews from I want to screens.
  //Draft session when user relaunch interviews from dashborads and summary pages.
  //Complete session when user opens interviews from workspace screen.
  //EPReviewPending when user clicks finish initerviews redirected from I want to screens
  CreateSession = 'Create',
  DraftSession = 'Draft',
  CompleteSession = 'Complete',
  EPReviewPending = 'Team Review',
  EPPMDReview = 'EPPMD review',
  RPInReview = 'RP InReview',
  SendToEPPMD = 'Send to EPPMD',
  SendToEPPMDTitle = 'Send to Engagement PPMD',
  NotStarted = 'Not started',
  Abandoned = 'Abandoned',
  AbandonedTitle = 'Are you sure you want to abandon this engagement?',
  Declined = 'Declined',
  EPDeclinedTitle = 'Decline engagement?',
  EPChangeOrderDeclinedTitle = 'Decline change order?',
  EPDeclined = 'EP Declined',
  RPDeclinedTitle = 'Decline engagement?',
  RPChangeOrderDeclinedTitle = 'Decline change order?',
  RPDeclined = 'RP Declined',
  Approved = 'Approved',
  EPApprovedTitle = 'EPPMD approval',
  EPChangeOrderAprovedTitle = 'Approve change order',
  EPApproved = 'EP-Approve',
  RPApprovedTitle = 'Approve engagement',
  RPChangeOrderAprovedTitle = 'Approve change order',
  RPApproved = 'RP-Approve',
  EditQuestionnaire = 'Are you sure you want to edit the questionnaire?',
  EditEngagement = 'Edit Engagement',
  EditChangeOrder = 'Edit Change Order',
  ReviewStatus = 'Review Status',
  OpenReviewNotes = 'Open review notes',
  NotApplicable = 'NA',
  ContinuanceSession = 'Continuance',
  Pending = 'Pending',
  EngRPRuleEngineError = 'RP rule engine error',
  EngFinalApproverRemoved = 'Final approver removed',
  InProgressWorkspace = 'InProgress',
  AbandonedChangeOrderTitle = 'Are you sure you want to abandon this change order?'
}

export enum QuestionnaireDialogTitles {

  FinishInterview = 'Save questionnaire?',
  UnAnsQuestions = 'Unanswered questions',
  InputErrors = 'Input errors',
  CancelQuestionnaire = 'Cancel questionnaire',
  QueNewVersion = 'New version',
 
}
export enum IndusDialogTitles {
  RecordINDUSconsent='Record INDUS consent',
  RemoveINDUSconsent='Remove INDUS consent',
}

export class RemoveSecondaryWorkspaceModel {
  workspaceId: string;  
  updatedBy: string;  
}

export class HdTemplateVersionBrowserSessionModel {
  hdTemplatePackageId: string;  
  hdTemplateVersionNo: number;
  hdTemplateName: string;
}

export class HotDocsResponse{
  data: object;
  engagementId: number;
  createdBy: string;
}