export class ChangeOrderAssignment {
  assignmentCodeId: number;
  assignmentCode: string;
  assignmentName: string;
  checked: boolean;
  visible: boolean;
  estimatedCompletetionDate: string;
  incrementalFees: string;
  grossMargin: string;
  StartDateCtrlId: string;
  estimatedComplationError:boolean;
  incrementalFeesError: boolean;
  grossMarginError: boolean;
  grossMarginErrorMessage: string;
  estimatedComplationRequiredError:boolean;
  incrementalFeesRequiredError: boolean;
  grossMarginRequiredError: boolean;
  estimatedStartDate: string;
  createdDate: string;
  createdBy: string;
  }