import { Component, OnInit } from '@angular/core';
import { ILeftnavList } from '../../model/ILeftnavList';
import { LeftNavService } from '../../services/leftnavService';
declare var $;


@Component({
  selector: 'app-shortcut',
  templateUrl: './shortcut.component.html',
  styleUrls: ['./shortcut.component.css']
})
export class ShortcutComponent implements OnInit {

  iLeftnavList: ILeftnavList[] = [];
  userRoleIds: string = '';
  userObject: any;

  constructor(private leftnavservice: LeftNavService) {
    const jsonString = localStorage.getItem('currentUser');
    this.userObject = JSON.parse(jsonString);
   }

  ngOnInit() {

    for (var i = 0, l = this.userObject?.role.length; i < l; i++) {
      this.userRoleIds += this.userObject.role[i].roleId + ',';
    }
    this.userRoleIds = this.userRoleIds.substring(0, (this.userRoleIds.length - 1));

    setTimeout(() => {
      $(document).ready(function() {
        $('[data-toggle="tooltip"]').tooltip();
      });
      }, 500);
    this.getILeftnavList(this.userRoleIds);
  }

  ngAfterViewInit(){
    $('[data-toggle="tooltip"]').tooltip();
  }

  ngAfterViewChecked() {
    $('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover'
    });
    $('[data-toggle="tooltip"]').on('mouseleave', function() {
      $(this).tooltip('dispose');
    });
    $('[data-toggle="tooltip"]').on('click', function() {
      $(this).tooltip('dispose');
    });
   }

  getILeftnavList(rolesList: string) {
    // console.log('getILeftnavList fun called - input ', rolesList);
    if (typeof rolesList != 'undefined' && rolesList != '0' && rolesList != 'Loading') {
      this.leftnavservice.getILeftnavList(rolesList, true).subscribe(result => {
        if (result != null) {
          this.iLeftnavList = result;
          console.log ('getILeftnavList func called ', result);
        }
      }
        ,
        err => {
          console.log('Error in fetching data from get verb of LeftNav.', err);
        });
    }
  }
}
