<div class="row pageShortcutParent" *ngIf="iShortcutCount > 6">
  <div class="col-md-12">
    <app-loading-screen></app-loading-screen>
    <app-shortcut></app-shortcut>
  </div>
</div>

<div class="row pb-2 mx-0 mt-3" [class.pageTitleParentExtraMargin]="iShortcutCount > 6"
  [class.pageTitleParent]="iShortcutCount <= 6">
  <div class="col-md-10 pl-0">
    <h2 class="pageTitle largerFontSize2">Approvals</h2>
  </div>
  <div class="col-md-2 pr-0" *ngIf="iShortcutCount <= 6">
    <app-shortcut></app-shortcut>
  </div>
</div>

<div >
  <ul role="tablist" class="nav nav-tabs approval-tabs change-order">
    <li (click)="tabChanged('Engagement')" >
     <a href="#Engagement" role="tab" data-toggle="tab"  [ngClass]="{'active':isEngagementTabActive}" 
     [attr.aria-selected]="isEngagementTabActive ? 'true' : 'false'">
      <span class="inline-block align-middle" >Engagements:&nbsp;{{allApprovalCount}}</span></a></li>
    <li (click)="tabChanged('ChangeOrder')" class="ml-4"><a href="#ChangeOrder" role="tab" data-toggle="tab" [ngClass]="{'active':isChangeOrderTabActive}" 
      [attr.aria-selected]="isChangeOrderTabActive ? 'true' : 'false'">
      <span class="inline-block align-middle">Change orders:&nbsp;{{allChangeOrderApprovalCount}}</span></a></li>
  </ul>
</div>
 
<div role="tabpanel" id="tab-panel" *ngIf="isEngagementTabActive">
  <div id="approval-tabs" class="tabbed-appr-delegate">
    <ul role="tablist" class="nav nav-tabs approval-tabs">
      <li role="presentation" class="nav-item" (click)="tabChanged('Approvals')">
        <a href="#my-approvals" class="internal-tab approvals-tab nav-link tab-my-approval" aria-controls="active-tab" role="tab" data-toggle="tab" [ngClass]="{active:isMyApprovalTabActive}">
          <span class="inline-block align-middle pl-4 ml-1">My approvals</span></a>
      </li>
      <li role="presentation" class="nav-item" (click)="tabChanged('Delegated')">
        <a href="#delegated" class="external-tab delegated-tab nav-link tab-delegated" aria-controls="bookmark-tab" role="tab" data-toggle="tab" [ngClass]="{active:isDelegateddashBoardActive}">
          <span class="inline-block align-middle pl-4 ml-1">Delegated</span></a>
      </li>
    </ul>
  </div>
  <div class="tab-content">
    <div role="tabpanel" class="tab-pane " id="my-approvals" [ngClass]="{active:isMyApprovalTabActive}">
      <div class="bg-edp-body card-container position-relative">
        <!-- List View main container start -->
        <!--ListView Design Start-->
      
        <div class="pt-3 my-approval-filters">
          <div class="my-approvals-single-select-filter">
            <div class="d-flex p-2">
              <div class="mr-auto ml-1">
                <div class="d-flex flex-row bd-highlight">
                  <a id="btn-single-filter-awaiting-approval" class="anchoroutline awaiting-tab" (click)="filterEngagementApprovals('Apply', 'Single', approverStatus.AwaitingImmediateAndTieredApproval)"
                    data-placement="bottom" target="_blank">
                    Awaiting approval
                  </a>
                  <a id="btn-single-filter-approved-approval" class="anchoroutline approved-tab" (click)="filterEngagementApprovals('Apply', 'Single', approverStatus.Approved)" data-placement="bottom" target="_blank">
                    Approved
                  </a>
                  <a id="btn-single-filter-declined-approval" class="anchoroutline declined-tab" (click)="filterEngagementApprovals('Apply', 'Single', approverStatus.Declined)" data-placement="bottom" target="_blank">
                    Declined
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="my-approvals-multi-select-filter">
            <div class="d-flex p-2">
              <div class="mr-auto ml-1">
                <div class="d-flex flex-row bd-highlight">
                  <a id="filterMyApprovalsEngagementListView" class="anchoroutline filter-tab" (click)="ShowHideListFilterPanel('Show')"
                    data-placement="bottom" target="_blank">
                    Filter
                  </a>
                  <a id="showAllMyApprovalsEngagementListView" class="anchoroutline showall-tab" (click)="ShowHideListFilterPanel('ShowAll')" data-placement="bottom" target="_blank">
                    Show all
                  </a>
                </div>
              </div>
            </div>
            <!-- Accordian view start -->
            <section id="listViewMyApprovalsFilterSection" class="filter-panel" style="display: none;">
              <div class="row m-0 bg-edp-body">
                <div class="col-md-12">
                  <div class="row m-0 pb-4">
                    <div class="col-lg-4 col-md-5">
                      <label class="font-weight-bold mb-1">Approval status</label>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" name="awaitingImmediateApprovalFlag" id="awaitingImmediateApprovalFlag"
                          [(ngModel)]="approvalFilterModel.isAwaitingImmediateApproval">
                          <label for="awaitingImmediateApprovalFlag" class="custom-control-label"></label>
                          <img alt="awaiting-immediate-approval" class="approval-colored-img"
                                src="../../../../assets/images/approvals/awaiting-immediate-approval.png">
                          <span class="filter-status-disc-label">Awaiting immediate approval</span>
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" id="awaitingTierApprovalFlag" name="awaitingTierApprovalFlag" class="custom-control-input"
                          [(ngModel)]="approvalFilterModel.isAwaitingTieredApproval">
                        <label for="awaitingTierApprovalFlag" class="custom-control-label"></label>
                        <img alt="awaiting-tiered-approval" class="approval-colored-img"
                                src="../../../../assets/images/approvals/awaiting-tiered-approval.png">
                        <span class="filter-status-disc-label">Awaiting tiered approval</span>
                      </div>
                    </div>
                    <div class="col-lg-2 col-md-4 filter-partition approved-declined-status-column">
                      <label class="font-weight-bold mb-1"></label>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" id="approved" name="approved" class="custom-control-input"
                          [(ngModel)]="approvalFilterModel.isApproved">
                        <label for="approved" class="custom-control-label"></label>
                        <img alt="approved" class="approval-colored-img"
                                src="../../../../assets/images/approvals/approved-approval.png">
                        <span class="filter-status-disc-label">Approved</span>
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" id="declined" name="declined" class="custom-control-input"
                          [(ngModel)]="approvalFilterModel.isDeclined">
                        <label for="declined" class="custom-control-label"></label>
                        <img alt="declined" class="approval-colored-img"
                                src="../../../../assets/images/approvals/declined-approval.png">
                        <span class="filter-status-disc-label">Declined</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row align-self-end text-right m-0 pr-2">
                  <div class="col-lg-8 col-md-4 filter-partition">
                    <button type="button" class="SecondaryButton filter-button-app" id="resetListViewFiliter"
                      (click)="filterEngagementApprovals('Reset', 'Multiple')">
                      <span class="d-inline-block align-middle">Reset</span></button>
                  </div>
                  <div class="col-lg-2 col-md-4 mt-md-0 mt-lg-0 px-3">
                    <button type="button" id="cancelListViewFiliter" class="SecondaryButton filter-button-app"
                      style="margin-right: 20px;" (click)="filterEngagementApprovals('Cancel', 'Multiple')">
                      <span class="d-inline-block align-middle">Cancel</span></button>
                  </div>
                  <div class="col-lg-2 col-md-4 mt-md-0 mt-lg-0 pl-2">
                    <button id="applyListViewFiliter" type="button" class="PrimaryButton filter-button-app mr-2"
                      (click)="filterEngagementApprovals('Apply', 'Multiple')">
                      <span class="d-inline-block align-middle">Apply</span></button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        
      
        <div class="Eng-ListView-Div">
          <div class="MainContainer m-3">
            <div class="tableData1">
              <div class="tableData2">
                <table class="listViewDataTable ResponsiveDataTable">
                  <thead>
                    <tr>
                      <th scope="col" class="table-sorted-desc tbl-approver-status-header">
                        <div class="row p-0 m-0 th-height">                    
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelList[8].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSort(headerModelList[8].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th scope="col" class="table-sorted-desc t-lr-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelList[0].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelList[0].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSort(headerModelList[0].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th scope="col" class="table-sorted-desc" class="t-lr-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelList[1].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelList[1].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSort(headerModelList[1].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th scope="col" class="table-sorted-desc  t-md-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-12 p-0 m-0">
                            {{headerModelList[7].headerName}}<img alt="add" 
                            src="../../../../assets/images/Client/tooltip.svg"
                            style="height: 16px; width: 16px;margin-left:8px;"
                            data-toggle="tooltip" data-custom-class="tooltipIndicators" data-html="true"
                            data-title="<div><b>Indicators legend</b><br/><br/>
                                  <span class='tooltipBgIconColor'>
                                    <img alt='add' src='../../../../assets/images/EDP/Dashboard/reviewNoteIndicator18.svg'/>
                                  </span>
                                  &nbsp;&nbsp;Open review notes<br/><br/>
          
                              <b>N/N</b>&nbsp;&nbsp;  Review notes with response / open<br/><br/>
                              
                              <span class='tooltipBgIconColorEng'>
                                <img alt='add' 
                                  src='../../../../assets/images/EDP/Dashboard/redo-24px.svg'/>
                              </span>
                              &nbsp;&nbsp;Continuance engagement<br/>
                          </div>"/>
                          </div>
                          <!-- <div class="col-md-2 p-0 m-0 text-right">                      
                          </div> -->
                        </div>
                      </th>
                      <th scope="col" class="table-sorted-desc t-mini-md-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelList[2].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelList[2].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSort(headerModelList[2].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th scope="col" class="table-sorted-desc t-md-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelList[3].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelList[3].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSort(headerModelList[3].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th scope="col" class="table-sorted-desc t-md-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelList[4].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelList[4].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSort(headerModelList[4].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th scope="col" class="table-sorted-desc t-mini-md-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelList[5].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelList[5].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSort(headerModelList[5].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}" style="margin:0px -5px;">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th scope="col" class="table-sorted-desc t-sm-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelList[6].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelList[6].imageModelList">
                            </div>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
      
                  <tbody class="display" *ngIf="allApprovalList?.length != 0">
                    <tr class="searchDatajs" *ngFor="let allApproval of allApprovalList">
                      <td data-label="ApproverStatus:" class="tbl-approver-status-header tbl-approver-status">
                        <div class="disc-awaiting-immediate-approval" *ngIf="allApproval.approverStatus == approverStatus.AwaitingImmediateApproval" data-toggle="tooltip" data-html="true" title="<div class=&quot;approvals-tooltip&quot;>Awaiting immediate approval</div>"></div>
                        <div class="disc-awaiting-tiered-approval" *ngIf="allApproval.approverStatus == approverStatus.AwaitingTieredApproval" data-toggle="tooltip" data-html="true" title="Awaiting tiered approval"></div>
                        <div class="disc-approved-approval" *ngIf="allApproval.approverStatus == approverStatus.Approved" data-toggle="tooltip" data-html="true" title="Approved"></div>
                        <div class="disc-declined-approval" *ngIf="allApproval.approverStatus == approverStatus.Declined" data-toggle="tooltip" data-html="true" title="Declined"></div>
                      </td> 
                      <td data-label="Client:" class="t-lr-col">
                        <a routerLink="/ClientDetails/{{allApproval.clientId}}" class="link">
                          {{allApproval.clientName}} </a>
                      </td>
                      <td data-label="Engagement name:" class="t-lr-col">
                          <span *ngIf="allApproval.engagementId > 0">
                            <a (contextmenu)="onRightClick(allApproval.workspaceId)" [routerLink]="['/Review/',allApproval.workspaceId]">
                              {{allApproval.engagementName}}
                            </a>
                          </span>
                          <span *ngIf="allApproval.engagementId == 0">
                              {{allApproval.engagementName}}
                          </span>
                      </td>
                      
                      <td data-label="Indicators:" class="t-md-col px-q text-left">
                        <div class="col-12 p-0 m-0">                    
                          <span *ngIf="allApproval.workspaceOpenReviewPointsCount > 0">
                            <span style="background-color: #f5f5f5;padding:3px;border-radius: 50%;">
                              <img alt="add" style="height: 18px; width: 18px;"
                                src="../../../../assets/images/EDP/Dashboard/reviewNoteIndicator.svg">
                              </span>
                              <span *ngIf="allApproval.workspaceRespondedReviewPointsCount > 0">
                                {{allApproval.workspaceRespondedReviewPointsCount}}/</span>{{allApproval.workspaceOpenReviewPointsCount}}
                                
                                            
                          </span>
                          <span class="rounded-circle" style="padding:3px; margin:1px; background-color: #F5F5F5 "
                            *ngIf="allApproval.reacceptanceEngagementId != null && allApproval.reacceptanceEngagementId > 0">
                            <img alt="add" style="padding-bottom:5px ; width: 18px;" src="../../../../assets/images/EDP/Dashboard/redo-24px.svg">
                          </span>
                        </div>                
                      </td>
      
                      <td data-label="Status:" class="t-mini-md-col"> 
                        {{allApproval.status}} 
                      </td>
                      <td data-label="Solution code:" class="t-md-col"> 
                        {{allApproval.solutionCode}} 
                      </td>
                      <td data-label="EPPMD:" class="t-md-col">
                        <span class="ePPMDcontactPopup link" id="ePPMDcontactPopup_{{allApproval.engagementId}}" 
                          (mousedown)="DisplayContactPopup(allApproval.engagementId, allApproval.eppmdEmailId)">
                          {{allApproval.eppmd}}
                        </span> 
                        <input type="hidden" id="listViewtxtengId" value="" />
                      </td>
                      <td data-label="Date:" class="t-mini-md-col pl-1 pr-1 pt-2"> 
                        {{allApproval.datePosted | date: "MM/dd/yyyy"}}
                      </td>
                      <td data-label="." class="text-center t-sm-col text-white">
                        <a *ngIf="isValidateSiteURL(allApproval.siteURL)" (contextmenu)="onRightClick1(allApproval.siteURL)"  href="{{allApproval.siteURL}}" target="_blank">
                          <img alt="sharepoint" src="../../../assets/images/EDP/Engagement/sharepoint-logo.svg" />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                  <div *ngIf="allApprovalList?.length == 0">
                    <div class="no-data-available no-data">{{DataMessage}}</div>
                </div>
                </table>
              </div>
            </div>
           <!-- ListView Pagination Start -->
           <div class="d-flex justify-content-center text-center pagination-section" *ngIf="allApprovalCount > 0">
            <ul *ngIf="pager.pages && pager.pages.length" class="pagination mx-2">
              <li>
                <a id="first" [ngClass]="{isDisabled:pager.currentPage === 1}" (click)="listViewSetPage(1)">
                  <img src="../../../../assets/images/EDP/Engagement/first_arrow.png" alt="first-page">
                </a>
              </li>
              <li>
                <a id="previous" [ngClass]="{isDisabled:pager.currentPage === 1}"
                  (click)="listViewSetPage(pager.currentPage - 1)">
                  <img src="../../../../assets/images/EDP/Engagement/previous_arrow.png" alt="previous-page">
                </a>
      
              </li>
              <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
                <a (click)="listViewSetPage(page)">{{page}}</a>
              </li>
      
              <li>
                <a [ngClass]="{isDisabled:pager.currentPage === pager.totalPages}"
                  (click)="listViewSetPage(pager.currentPage + 1)">
                  <img src="../../../../assets/images/EDP/Engagement/next_arrow.png" alt="next-page">
                </a>
              </li>
              <li>
                <a [ngClass]="{isDisabled:pager.currentPage === pager.totalPages}"
                  (click)="listViewSetPage(pager.totalPages)">
                  <img src="../../../../assets/images/EDP/Engagement/last_arrow.png" alt="last-page">
                </a>
              </li>
            </ul>
            <div>
              <select [(ngModel)]="listViewNoOfrecordsPerPage" id="drprecordsPerPage" name="drprecordsPerPage" (change)="listViewSetPage(1)" class="page-counts">
                <option *ngFor="let listViewNoOfrecordsPerPage of noOfrecordsPerPageList" value="{{listViewNoOfrecordsPerPage}}">{{listViewNoOfrecordsPerPage}}</option> 
              </select>
            </div>
          </div>
          <!-- ListView Pagination End -->
          </div><!-- MainContainer End -->
        </div><!-- List View Div End  -->
      </div>
    </div>
    <div role="tabpanel" class="tab-pane" id="delegated" [ngClass]="{active:isDelegateddashBoardActive}">
      <div class="bg-edp-body card-container position-relative">
        <!-- List View main container start -->
        <!--ListView Design Start-->
      
        
        
      
        <div class="Eng-ListViewDelegated-Div pt-3">
          <div class="MainContainer m-3">
            <div class="tableData1">
              <div class="tableData2">
                <table class="listViewDataTable ResponsiveDataTable">
                  <thead>
                    <tr>
                      <th scope="col" class="table-sorted-desc t-lr-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelListForDelegates[0].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelListForDelegates[0].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSortForDelegates(headerModelListForDelegates[0].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th scope="col" class="table-sorted-desc" class="t-lr-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelListForDelegates[1].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelListForDelegates[1].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSortForDelegates(headerModelListForDelegates[1].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                      
                      <th scope="col" class="table-sorted-desc t-md-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelListForDelegates[2].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelListForDelegates[2].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSortForDelegates(headerModelListForDelegates[2].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                      
                      <th scope="col" class="table-sorted-desc t-sm-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelListForDelegates[3].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelListForDelegates[3].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSortForDelegates(headerModelListForDelegates[3].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th scope="col" class="table-sorted-desc t-mini-md-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelListForDelegates[4].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelListForDelegates[4].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSortForDelegates(headerModelListForDelegates[4].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}" style="margin:0px -5px;">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th scope="col" class="table-sorted-desc t-sm-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelListForDelegates[5].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelListForDelegates[5].imageModelList">
                            </div>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
      
                  <tbody class="display" >
                    <tr class="searchDatajs" *ngFor="let delegates of allApprovalListForDelegates">
                      
                      <td data-label="Client:" class="t-lr-col">
                        <a routerLink="/ClientDetails/{{delegates.clientId}}" class="link">
                          {{delegates.clientName}} </a>
                      </td>
                      <td data-label="Engagement name:" class="t-lr-col">
                          <span *ngIf="delegates.engagementId > 0">
                            <a (contextmenu)="onRightClick(delegates.workspaceId)" [routerLink]="['/Review/',delegates.workspaceId]">
                              {{delegates.engagementName}}
                            </a>
                          </span>
                          <span *ngIf="delegates.engagementId == 0">
                              {{delegates.engagementName}}
                          </span>
                      </td>
                      
                      <td data-label="Delegate:" class="t-md-col">
                        <span class="ePPMDcontactPopup link" id="ePPMDcontactPopup_{{delegates.delegatedEmailId}}" 
                          (mousedown)="DisplayContactPopup(delegates.engagementId, delegates.delegatedEmailId)">
                          {{delegates.delegated}}
                        </span> 
                        <input type="hidden" id="listViewtxtengId" value="" />
                      </td>
      
                      <td data-label="Status:" class="t-sm-col"> 
                        {{delegates.status}} 
                      </td>
                      
                      
                      <td data-label="Decision Date:" class="t-mini-md-col pl-1 pr-1 pt-2"> 
                        {{delegates.decisionDate | date: "MM/dd/yyyy"}}
                      </td>
                      <td data-label="Actions" class="text-center t-sm-col text-white">
                        <a *ngIf="isValidateSiteURL(delegates.siteURL)" (contextmenu)="onRightClick1(delegates.siteURL)"  href="{{delegates.siteURL}}" target="_blank">
                          <img alt="sharepoint" src="../../../assets/images/EDP/Engagement/sharepoint-logo.svg" />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                  <div *ngIf="allApprovalListForDelegates?.length == 0">
                    <div class="no-data-available no-data">{{DataMessageForDelegates}}</div>
                </div>
                </table>
              </div>
            </div>
           <!-- ListView Pagination Start -->
           <div class="d-flex justify-content-center text-center pagination-section" *ngIf="allApprovalCountForDelegates > 0">
            <ul *ngIf="pagerForDelegates.pages && pagerForDelegates.pages.length" class="pagination mx-2">
              <li>
                <a id="firstForDelegates" [ngClass]="{isDisabled:pagerForDelegates.currentPage === 1}" (click)="listViewSetPageForDelegates(1)">
                  <img src="../../../../assets/images/EDP/Engagement/first_arrow.png" alt="first-page">
                </a>
              </li>
              <li>
                <a id="previousForDelegates" [ngClass]="{isDisabled:pagerForDelegates.currentPage === 1}"
                  (click)="listViewSetPageForDelegates(pagerForDelegates.currentPage - 1)">
                  <img src="../../../../assets/images/EDP/Engagement/previous_arrow.png" alt="previous-page">
                </a>
      
              </li>
              <li *ngFor="let page of pagerForDelegates.pages" [ngClass]="{active:pagerForDelegates.currentPage === page}">
                <a (click)="listViewSetPageForDelegates(page)">{{page}}</a>
              </li>
      
              <li>
                <a [ngClass]="{isDisabled:pagerForDelegates.currentPage === pagerForDelegates.totalPages}"
                  (click)="listViewSetPageForDelegates(pagerForDelegates.currentPage + 1)">
                  <img src="../../../../assets/images/EDP/Engagement/next_arrow.png" alt="next-page">
                </a>
              </li>
              <li>
                <a [ngClass]="{isDisabled:pagerForDelegates.currentPage === pagerForDelegates.totalPages}"
                  (click)="listViewSetPageForDelegates(pagerForDelegates.totalPages)">
                  <img src="../../../../assets/images/EDP/Engagement/last_arrow.png" alt="last-page">
                </a>
              </li>
            </ul>
            <div>
              <select [(ngModel)]="listViewNoOfrecordsPerPageForDelegates" id="drprecordsPerPageForDelegates" name="drprecordsPerPageForDelegates" (change)="listViewSetPageForDelegates(1)" class="page-counts">
                <option *ngFor="let listViewNoOfrecordsPerPageForDelegates of noOfrecordsPerPageListForDelegates" value="{{listViewNoOfrecordsPerPageForDelegates}}">{{listViewNoOfrecordsPerPageForDelegates}}</option> 
              </select>
            </div>
          </div>
          <!-- ListView Pagination End -->
          </div><!-- MainContainer End -->
        </div><!-- List View Div End  -->
      </div>
    </div>
  </div>
</div>

<div role="tabpanel" id="tab-panelchangeorder" *ngIf="isChangeOrderTabActive && changeOrderFeatureFlag">
  <div id="approval-tabs" class="tabbed-appr-delegate">
    <ul role="tablist" class="nav nav-tabs approval-tabs">
      <li role="presentation" class="nav-item" (click)="tabChanged('Approvals')">
        <a href="#my-approvals" class="internal-tab approvals-tab nav-link tab-my-approval" aria-controls="active-tab" role="tab" data-toggle="tab" [ngClass]="{active:isMyApprovalTabActive}">
          <span class="inline-block align-middle pl-4 ml-1">My approvals</span></a>
      </li>
      <li role="presentation" class="nav-item" (click)="tabChanged('Delegated')">
        <a href="#delegated" class="external-tab delegated-tab nav-link tab-delegated" aria-controls="bookmark-tab" role="tab" data-toggle="tab" [ngClass]="{active:isDelegateddashBoardActive}">
          <span class="inline-block align-middle pl-4 ml-1">Delegated</span></a>
      </li>
    </ul>
  </div>
  <div class="tab-content">
    <div role="tabpanel" class="tab-pane " id="my-approvals" [ngClass]="{active:isMyApprovalTabActive}">
      <div class="bg-edp-body card-container position-relative">
        <!-- List View main container start -->
        <!--ListView Design Start-->
      
        <div class="pt-3 my-approval-filters">
          <div class="my-approvals-single-select-filter">
            <div class="d-flex p-2">
              <div class="mr-auto ml-1">
                <div class="d-flex flex-row bd-highlight">
                  <a id="btn-single-filter-awaiting-approval" class="anchoroutline awaiting-tab" (click)="filterEngagementApprovals('Apply', 'Single', approverStatus.AwaitingImmediateAndTieredApproval)"
                    data-placement="bottom" target="_blank">
                    Awaiting approval
                  </a>
                  <a id="btn-single-filter-approved-approval" class="anchoroutline approved-tab" (click)="filterEngagementApprovals('Apply', 'Single', approverStatus.Approved)" data-placement="bottom" target="_blank">
                    Approved
                  </a>
                  <a id="btn-single-filter-declined-approval" class="anchoroutline declined-tab" (click)="filterEngagementApprovals('Apply', 'Single', approverStatus.Declined)" data-placement="bottom" target="_blank">
                    Declined
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="my-approvals-multi-select-filter">
            <div class="d-flex p-2">
              <div class="mr-auto ml-1">
                <div class="d-flex flex-row bd-highlight">
                  <a id="filterMyApprovalsEngagementListView" class="anchoroutline filter-tab" (click)="ShowHideListFilterPanel('Show')"
                    data-placement="bottom" target="_blank">
                    Filter
                  </a>
                  <a id="showAllMyApprovalsEngagementListView" class="anchoroutline showall-tab" (click)="ShowHideListFilterPanel('ShowAll')" data-placement="bottom" target="_blank">
                    Show all
                  </a>
                </div>
              </div>
            </div>
            <!-- Accordian view start -->
            <section id="listViewMyApprovalsFilterSection" class="filter-panel" style="display: none;">
              <div class="row m-0 bg-edp-body">
                <div class="col-md-12">
                  <div class="row m-0 pb-4">
                    <div class="col-lg-4 col-md-5">
                      <label class="font-weight-bold mb-1">Approval status</label>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" name="awaitingImmediateApprovalFlag" id="awaitingImmediateApprovalFlag"
                          [(ngModel)]="approvalFilterModel.isAwaitingImmediateApproval">
                          <label for="awaitingImmediateApprovalFlag" class="custom-control-label"></label>
                          <img alt="awaiting-immediate-approval" class="approval-colored-img"
                                src="../../../../assets/images/approvals/awaiting-immediate-approval.png">
                          <span class="filter-status-disc-label">Awaiting immediate approval</span>
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" id="awaitingTierApprovalFlag" name="awaitingTierApprovalFlag" class="custom-control-input"
                          [(ngModel)]="approvalFilterModel.isAwaitingTieredApproval">
                        <label for="awaitingTierApprovalFlag" class="custom-control-label"></label>
                        <img alt="awaiting-tiered-approval" class="approval-colored-img"
                                src="../../../../assets/images/approvals/awaiting-tiered-approval.png">
                        <span class="filter-status-disc-label">Awaiting tiered approval</span>
                      </div>
                    </div>
                    <div class="col-lg-2 col-md-4 filter-partition approved-declined-status-column">
                      <label class="font-weight-bold mb-1"></label>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" id="approved" name="approved" class="custom-control-input"
                          [(ngModel)]="approvalFilterModel.isApproved">
                        <label for="approved" class="custom-control-label"></label>
                        <img alt="approved" class="approval-colored-img"
                                src="../../../../assets/images/approvals/approved-approval.png">
                        <span class="filter-status-disc-label">Approved</span>
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" id="declined" name="declined" class="custom-control-input"
                          [(ngModel)]="approvalFilterModel.isDeclined">
                        <label for="declined" class="custom-control-label"></label>
                        <img alt="declined" class="approval-colored-img"
                                src="../../../../assets/images/approvals/declined-approval.png">
                        <span class="filter-status-disc-label">Declined</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row align-self-end text-right m-0 pr-2">
                  <div class="col-lg-8 col-md-4 filter-partition">
                    <button type="button" class="SecondaryButton filter-button-app" id="resetListViewFiliter"
                      (click)="filterEngagementApprovals('Reset', 'Multiple')">
                      <span class="d-inline-block align-middle">Reset</span></button>
                  </div>
                  <div class="col-lg-2 col-md-4 mt-md-0 mt-lg-0 px-3">
                    <button type="button" id="cancelListViewFiliter" class="SecondaryButton filter-button-app"
                      style="margin-right: 20px;" (click)="filterEngagementApprovals('Cancel', 'Multiple')">
                      <span class="d-inline-block align-middle">Cancel</span></button>
                  </div>
                  <div class="col-lg-2 col-md-4 mt-md-0 mt-lg-0 pl-2">
                    <button id="applyListViewFiliter" type="button" class="PrimaryButton filter-button-app mr-2"
                      (click)="filterEngagementApprovals('Apply', 'Multiple')">
                      <span class="d-inline-block align-middle">Apply</span></button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        
      
        <div class="Eng-ListView-Div">
          <div class="MainContainer m-3">
            <div class="tableData1">
              <div class="tableData2">
                <table class="listViewDataTable ResponsiveDataTable">
                  <thead>
                    <tr>
                      <th scope="col" class="table-sorted-desc tbl-approver-status-header">
                        <div class="row p-0 m-0 th-height">                    
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelList[8].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSort(headerModelList[8].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th scope="col" class="table-sorted-desc t-lr-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelList[9].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelList[0].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSort(headerModelList[0].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th scope="col" class="table-sorted-desc t-lr-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelList[0].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelList[0].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSort(headerModelList[0].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th scope="col" class="table-sorted-desc" class="t-lr-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelList[1].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelList[1].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSort(headerModelList[1].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th scope="col" class="table-sorted-desc  t-md-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-12 p-0 m-0">
                            {{headerModelList[7].headerName}}<img alt="add" 
                            src="../../../../assets/images/Client/tooltip.svg"
                            style="height: 16px; width: 16px;margin-left:8px;"
                            data-toggle="tooltip" data-custom-class="tooltipIndicators" data-html="true"
                            data-title="<div><b>Indicators legend</b><br/><br/>
                                  <span class='tooltipBgIconColor'>
                                    <img alt='add' src='../../../../assets/images/EDP/Dashboard/reviewNoteIndicator18.svg'/>
                                  </span>
                                  &nbsp;&nbsp;Open review notes<br/><br/>
          
                              <b>N/N</b>&nbsp;&nbsp;  Review notes with response / open<br/><br/>
                              
                           
                          </div>"/>
                          </div>
                          <!-- <div class="col-md-2 p-0 m-0 text-right">                      
                          </div> -->
                        </div>
                      </th>
                      <th scope="col" class="table-sorted-desc t-mini-md-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelList[2].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelList[2].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSort(headerModelList[2].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th scope="col" class="table-sorted-desc t-md-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelList[3].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelList[3].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSort(headerModelList[3].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
      
                  <tbody class="display" *ngIf="changeOrderApprovalList?.length != 0">
                    <tr class="searchDatajs" *ngFor="let ChangeOrder of changeOrderApprovalList">
                      <td data-label="ApproverStatus:" class="tbl-approver-status-header tbl-approver-status">
                        <div class="disc-awaiting-immediate-approval" *ngIf="ChangeOrder.approverStatus == approverStatus.AwaitingImmediateApproval" data-toggle="tooltip" data-html="true" title="<div class=&quot;approvals-tooltip&quot;>Awaiting immediate approval</div>"></div>
                        <div class="disc-awaiting-tiered-approval" *ngIf="ChangeOrder.approverStatus == approverStatus.AwaitingTieredApproval" data-toggle="tooltip" data-html="true" title="Awaiting tiered approval"></div>
                        <div class="disc-approved-approval" *ngIf="ChangeOrder.approverStatus == approverStatus.Approved" data-toggle="tooltip" data-html="true" title="Approved"></div>
                        <div class="disc-declined-approval" *ngIf="ChangeOrder.approverStatus == approverStatus.Declined" data-toggle="tooltip" data-html="true" title="Declined"></div>
                      </td> 
                      <td data-label="Change Order:" class="t-lr-col">
                        <a routerLink="/Review/{{ChangeOrder.workspaceId}}" class="link">
                          {{ChangeOrder.changeOrderName}} </a>
                      </td>
                      <td data-label="Client:" class="t-lr-col">
                        <a routerLink="/ClientDetails/{{ChangeOrder.clientId}}" class="link">
                          {{ChangeOrder.clientName}} </a>
                      </td>
                      <td data-label="Engagement name:" class="t-lr-col">
                          <span *ngIf="ChangeOrder.engagementId > 0">
                            <a (contextmenu)="onRightClick(ChangeOrder.workspaceId)" [routerLink]="['/Review/',ChangeOrder.workspaceId]">
                              {{ChangeOrder.engagementName}}
                            </a>
                          </span>
                          <span *ngIf="ChangeOrder.engagementId == 0">
                              {{ChangeOrder.engagementName}}
                          </span>
                      </td>
                      
                      <td data-label="Indicators:" class="t-md-col px-q text-left">
                        <div class="col-12 p-0 m-0">                    
                          <span *ngIf="ChangeOrder.workspaceOpenReviewPointsCount > 0">
                            <span style="background-color: #f5f5f5;padding:3px;border-radius: 50%;">
                              <img alt="add" style="height: 18px; width: 18px;"
                                src="../../../../assets/images/EDP/Dashboard/reviewNoteIndicator.svg">
                              </span>
                              <span *ngIf="allApproval.workspaceRespondedReviewPointsCount > 0">
                                {{ChangeOrder.workspaceRespondedReviewPointsCount}}/</span>{{ChangeOrder.workspaceOpenReviewPointsCount}}
                                
                                            
                          </span>
                        </div>                
                      </td>
      
                      <td data-label="Status:" class="t-mini-md-col"> 
                        {{ChangeOrder.status}} 
                      </td>
                      <td data-label="Solution code:" class="t-md-col"> 
                        {{ChangeOrder.solutionCode}} 
                      </td>
                    </tr>
                  </tbody>
                  <div *ngIf="changeOrderApprovalList?.length == 0">
                    <div class="no-data-available no-data">{{DataMessage}}</div> 
                </div>
                </table>
              </div>
            </div>
           <!-- ListView Pagination Start -->
           <div class="d-flex justify-content-center text-center pagination-section" *ngIf="allChangeOrderApprovalCount > 0">
            <ul *ngIf="ChangeOrderpager.pages && ChangeOrderpager.pages.length" class="pagination mx-2">
              <li>
                <a id="first" [ngClass]="{isDisabled:ChangeOrderpager.currentPage === 1}" (click)="listViewSetPage(1)">
                  <img src="../../../../assets/images/EDP/Engagement/first_arrow.png" alt="first-page">
                </a>
              </li>
              <li>
                <a id="previous" [ngClass]="{isDisabled:ChangeOrderpager.currentPage === 1}"
                  (click)="listViewSetPage(ChangeOrderpager.currentPage - 1)">
                  <img src="../../../../assets/images/EDP/Engagement/previous_arrow.png" alt="previous-page">
                </a>
      
              </li>
              <li *ngFor="let page of ChangeOrderpager.pages" [ngClass]="{active:ChangeOrderpager.currentPage === page}">
                <a (click)="listViewSetPage(page)">{{page}}</a>
              </li>
      
              <li>
                <a [ngClass]="{isDisabled:ChangeOrderpager.currentPage === ChangeOrderpager.totalPages}"
                  (click)="listViewSetPage(ChangeOrderpager.currentPage + 1)">
                  <img src="../../../../assets/images/EDP/Engagement/next_arrow.png" alt="next-page">
                </a>
              </li>
              <li>
                <a [ngClass]="{isDisabled:ChangeOrderpager.currentPage === ChangeOrderpager.totalPages}"
                  (click)="listViewSetPage(ChangeOrderpager.totalPages)">
                  <img src="../../../../assets/images/EDP/Engagement/last_arrow.png" alt="last-page">
                </a>
              </li>
            </ul>
            <div>
              <select [(ngModel)]="listViewNoOfrecordsPerPage" id="drprecordsPerPage" name="drprecordsPerPage" (change)="listViewSetPage(1)" class="page-counts">
                <option *ngFor="let listViewNoOfrecordsPerPage of noOfrecordsPerPageList" value="{{listViewNoOfrecordsPerPage}}">{{listViewNoOfrecordsPerPage}}</option> 
              </select>
            </div>
          </div>
          <!-- ListView Pagination End -->
          </div><!-- MainContainer End -->
        </div><!-- List View Div End  -->
      </div>
    </div>
    <div role="tabpanel" class="tab-pane" id="delegated" [ngClass]="{active:isDelegateddashBoardActive}">
      <div class="bg-edp-body card-container position-relative">
        <!-- List View main container start -->
        <!--ListView Design Start-->
        <div class="Eng-ListViewDelegated-Div pt-3">
          <div class="MainContainer m-3">
            <div class="tableData1">
              <div class="tableData2">
                <table class="listViewDataTable ResponsiveDataTable">
                  <thead>
                    <tr>
                      <th scope="col" class="table-sorted-desc t-lr-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelListForChangeOrdersDelegate[0].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelListForChangeOrdersDelegate[0].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSortForDelegates(headerModelListForChangeOrdersDelegate[0].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th scope="col" class="table-sorted-desc" class="t-lr-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelListForChangeOrdersDelegate[1].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelListForChangeOrdersDelegate[1].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSortForDelegates(headerModelListForChangeOrdersDelegate[1].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                      
                      <th scope="col" class="table-sorted-desc t-md-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelListForChangeOrdersDelegate[2].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelListForChangeOrdersDelegate[2].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSortForDelegates(headerModelListForChangeOrdersDelegate[2].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                      
                      <th scope="col" class="table-sorted-desc t-sm-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelListForChangeOrdersDelegate[3].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelListForChangeOrdersDelegate[3].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSortForDelegates(headerModelListForChangeOrdersDelegate[3].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th scope="col" class="table-sorted-desc t-mini-md-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelListForChangeOrdersDelegate[4].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelListForChangeOrdersDelegate[4].imageModelList">
                              <a id="image_{{img.imageName}}" *ngIf="img.isVisible"
                                (click)="HeaderSortForDelegates(headerModelListForChangeOrdersDelegate[4].headerId)" [ngClass]="{isDisabled:listCount == 0}">
                                <img alt="image_{{img.imageName}}" src="{{img.imagePath}}" style="margin:0px -5px;">
                              </a>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th scope="col" class="table-sorted-desc t-sm-col">
                        <div class="row p-0 m-0 th-height">
                          <div class="col-md-10 p-0 m-0">
                            {{headerModelListForChangeOrdersDelegate[5].headerName}}
                          </div>
                          <div class="col-md-2 p-0 m-0 text-right">
                            <div *ngFor="let img of headerModelListForChangeOrdersDelegate[5].imageModelList">
                            </div>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
      
                  <!-- <tbody class="display" >
                    <tr class="searchDatajs" *ngFor="let delegates of allApprovalListForDelegates">
                      
                      <td data-label="Client:" class="t-lr-col">
                        <a routerLink="/ClientDetails/{{delegates.clientId}}" class="link">
                          {{delegates.clientName}} </a>
                      </td>
                      <td data-label="Engagement name:" class="t-lr-col">
                          <span *ngIf="delegates.engagementId > 0">
                            <a (contextmenu)="onRightClick(delegates.workspaceId)" [routerLink]="['/Review/',delegates.workspaceId]">
                              {{delegates.engagementName}}
                            </a>
                          </span>
                          <span *ngIf="delegates.engagementId == 0">
                              {{delegates.engagementName}}
                          </span>
                      </td>
                      
                      <td data-label="Delegate:" class="t-md-col">
                        <span class="ePPMDcontactPopup link" id="ePPMDcontactPopup_{{delegates.delegatedEmailId}}" 
                          (mousedown)="DisplayContactPopup(delegates.engagementId, delegates.delegatedEmailId)">
                          {{delegates.delegated}}
                        </span> 
                        <input type="hidden" id="listViewtxtengId" value="" />
                      </td>
      
                      <td data-label="Status:" class="t-sm-col"> 
                        {{delegates.status}} 
                      </td>
                      
                      
                      <td data-label="Decision Date:" class="t-mini-md-col pl-1 pr-1 pt-2"> 
                        {{delegates.decisionDate | date: "MM/dd/yyyy"}}
                      </td>
                      <td data-label="Actions" class="text-center t-sm-col text-white">
                        <a *ngIf="isValidateSiteURL(delegates.siteURL)" (contextmenu)="onRightClick1(delegates.siteURL)"  href="{{delegates.siteURL}}" target="_blank">
                          <img alt="sharepoint" src="../../../assets/images/EDP/Engagement/sharepoint-logo.svg" />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                  <div *ngIf="allApprovalListForDelegates?.length == 0">
                    <div class="no-data-available no-data">{{DataMessageForDelegates}}</div>
                </div> -->
                </table>
              </div>
            </div>
           <!-- ListView Pagination Start -->
           <div class="d-flex justify-content-center text-center pagination-section" *ngIf="allApprovalCountForDelegates > 0">
            <ul *ngIf="pagerForDelegates.pages && pagerForDelegates.pages.length" class="pagination mx-2">
              <li>
                <a id="firstForDelegates" [ngClass]="{isDisabled:pagerForDelegates.currentPage === 1}" (click)="listViewSetPageForDelegates(1)">
                  <img src="../../../../assets/images/EDP/Engagement/first_arrow.png" alt="first-page">
                </a>
              </li>
              <li>
                <a id="previousForDelegates" [ngClass]="{isDisabled:pagerForDelegates.currentPage === 1}"
                  (click)="listViewSetPageForDelegates(pagerForDelegates.currentPage - 1)">
                  <img src="../../../../assets/images/EDP/Engagement/previous_arrow.png" alt="previous-page">
                </a>
      
              </li>
              <li *ngFor="let page of pagerForDelegates.pages" [ngClass]="{active:pagerForDelegates.currentPage === page}">
                <a (click)="listViewSetPageForDelegates(page)">{{page}}</a>
              </li>
      
              <li>
                <a [ngClass]="{isDisabled:pagerForDelegates.currentPage === pagerForDelegates.totalPages}"
                  (click)="listViewSetPageForDelegates(pagerForDelegates.currentPage + 1)">
                  <img src="../../../../assets/images/EDP/Engagement/next_arrow.png" alt="next-page">
                </a>
              </li>
              <li>
                <a [ngClass]="{isDisabled:pagerForDelegates.currentPage === pagerForDelegates.totalPages}"
                  (click)="listViewSetPageForDelegates(pagerForDelegates.totalPages)">
                  <img src="../../../../assets/images/EDP/Engagement/last_arrow.png" alt="last-page">
                </a>
              </li>
            </ul>
            <div>
              <select [(ngModel)]="listViewNoOfrecordsPerPageForDelegates" id="drprecordsPerPageForDelegates" name="drprecordsPerPageForDelegates" (change)="listViewSetPageForDelegates(1)" class="page-counts">
                <option *ngFor="let listViewNoOfrecordsPerPageForDelegates of noOfrecordsPerPageListForDelegates" value="{{listViewNoOfrecordsPerPageForDelegates}}">{{listViewNoOfrecordsPerPageForDelegates}}</option> 
              </select>
            </div>
          </div>
          <!-- ListView Pagination End -->
          </div><!-- MainContainer End -->
        </div><!-- List View Div End  -->
      </div>
    </div>
  </div>
</div>

<app-contact-popup [contactEmailId]="selectContactEmailId"></app-contact-popup>