import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Engagements } from 'src/app/model/Engagement/Engagements';
import { KeyValue } from 'src/app/model/KeyValue';
import { Clients } from 'src/app/model/clients';
import { EngagementService } from 'src/app/services/EngagementService';
import { ClientService } from 'src/app/services/clientservice';
import { DataService } from 'src/app/services/data.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { of, Observable, forkJoin } from 'rxjs';
import { TeamMemberPermission } from 'src/app/model/Engagement/TeamMemberPermission';
import { TeamMember, ValidationTeamMember } from 'src/app/model/Engagement/TeamMember';
import { RoleNames } from 'src/app/model/Role';
import { AppConfigService } from 'src/app/services/appConfigService';
import { Alert } from 'src/app/model/Alert';
import { AlertsService } from 'src/app/services/alerts.service';
import { AppCodes, Applicationmaster } from 'src/app/model/ApplicationMaster';
import { ApplicationMasterService } from 'src/app/services/application-master.service';
import { MembersList } from 'src/app/model/MembersList';
import { SearchGroupUserViewModel } from 'src/app/model/AzureADUsersArrayList';
import { AdUsersService } from 'src/app/services/adusers.service';
import { ChangeOrderService } from 'src/app/services/change-order.service';
import { ChangeOrderAssignmentModel, ChangeOrderModel, EditChangeOrderModel } from 'src/app/model/change-order/change-order-model';
import { AddWorkspaceDocumentValidationModel, MappingTemplatePackageModel } from 'src/app/model/TemplatePackageModel';
import { TemplateMaintenanceService } from 'src/app/services/template-maintenance.service';
import { TemplateMappingModel } from 'src/app/model/TemplateMappingModel';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { DocumentService } from 'src/app/services/DocumentService';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { HDWorkItemDocumentModel, HDWorkItemModel } from 'src/app/model/HDWorkItemModel';
import { HotDocsService } from 'src/app/services/HotDocsService';
import { PrimaryWorkspaceModel } from 'src/app/model/Engagement/CreateEngagementData';
import { WorkspaceDocumentModel } from 'src/app/model/WorkspaceDocumentModel';
import { CloseoutChecklistWorkspaceModel } from 'src/app/model/Workspace/CloseoutChecklistWorkspaceModel';
import { EnumTeamMemberRoleCodes, EnumKeyTeamMemberRoleCodes, TeamMemberRole } from 'src/app/model/Engagement/TeamMemberRole';
import { ChangeOrderAssignment } from 'src/app/model/ChangeOrderAssignment';
import { EngagementAssignment } from 'src/app/model/Engagement/EngagementAssignments';
import { TeamMembers } from 'src/app/model/Workspace/TeamMembers';
import { CLMService } from 'src/app/services/clm.service';
import { CLMDetails } from 'src/app/model/CLM/clm-details';
import { ADUser, UpdatePermissionsModel, UpdatePermissionsModelData, UsersListEmailModel } from 'src/app/model/SharePoiint/ActiveDirectory';
import { ActiveDirectoryService } from 'src/app/services/activedirectory.service';

declare var $;
@Component({
  selector: 'app-initiate-change-order',
  templateUrl: './initiate-change-order.component.html',
  styleUrls: ['./initiate-change-order.component.css']
})
export class InitiateChangeOrderComponent implements OnInit, OnDestroy {
  changeOrderId: number
  engagementId: number = -1;
  engagementName: string;
  startDateCtrlId: string;
  teamMemberlblCtrlName: string;
  clientId: number = -1;
  financialUpdate: boolean = false;

  isPrepopulateFromDetails: boolean = false;
  clientkeyValueList: KeyValue[] = [];
  engagementkeyValueList: KeyValue[] = [];
  clients: Clients[];

  userObject: any;
  isLoggedInUserSystemAdmin: boolean;
  isQualityUserLoggedIn: boolean;
  engagement: Engagements;
  engagements: Engagements[];
  changeOrderHistoryList: ChangeOrderModel[];

  teamMemberPermissions: TeamMemberPermission[] = [];
  uiTeamMemberList: Array<TeamMember> = [];
  arrValidationForNewTeamMember: ValidationTeamMember[] = [];
  IsValidInput: boolean = false;
  cmsDataMatch = false;
  changeOrderTeamMemberList: Array<TeamMember> = [];
  newAssignTeamMember: any = {};
  teamMemberLimit: number;
  teamMemberRoleName: string;
  alert: Alert;
  newTeamMember: any = {};
  validateTM: boolean[] = new Array(15);
  isUserAgentIE: number;
  teamMemberRoleCodes: AppCodes[] = [];
  applicationmasterList: Applicationmaster[] = [];
  applicationmasterListIE: Applicationmaster[] = [];
  teamMemberRoleFromAppMstList: Applicationmaster[] = [];
  keyteamMemberRoleFromAppMstList: Applicationmaster[] = [];
  governingAgreementOptionAppMstList: Applicationmaster[] = [];
  keyTeamMemberRoleCodes: AppCodes[] = [];
  isAudit: boolean = false;

  teamMemberCtrl: string; // people picker variables 
  teamMemberEmailCtrl: string;
  membersListArrayForTeamMembers: MembersList[];
  selectedTMIndex: string;
  currentTeamMemberIndex: number;
  teamMemberRoleList: TeamMemberRole[] = [];
  engagementTeamMembersList: TeamMember[] = [];

  existingGACheck: boolean;
  TemplateList: MappingTemplatePackageModel[] = [];
  isTemplateMappingFound: boolean;
  SelectedTemp: TemplateMappingModel = new TemplateMappingModel();
  documentCategory: string = 'Create New Engagement';
  SelectedTemplateList: MappingTemplatePackageModel[] = [];
  invalidvalues: string;
  isclientSelected: boolean = true;
  isEngagementSelected: boolean = true;
  isChangeorderTemplateSelected: boolean = true;
  selectedChangeOrderTemplate: string;
  isChangeOrderNameFilled: boolean = true;
  ChangeOrderName: string;
  isEstimationDateFilled: boolean = true;
  EstimationDate: string;
  isIncrementalFeesFilled: boolean = true;
  IncrementalFees: number;
  isGrossMarginFilled: boolean = true;
  Grossmargin: number;
  PlaceholderText: string = "Enter...";
  changeOrderModel: ChangeOrderModel = new ChangeOrderModel();
  workspaceId: string;
  addWorkspace_SelectedData: AddWorkspaceDocumentValidationModel = new AddWorkspaceDocumentValidationModel();
  addWorkspace_SiteUniqueId: string;
  createChangeorderValidation: boolean = false;
  addWorkspace_PrimaryWorkspaceStatus: string;
  createWorkItemDetails: HDWorkItemModel = new HDWorkItemModel();
  workItemDetails: HDWorkItemModel = new HDWorkItemModel();
  workspaceData: PrimaryWorkspaceModel = new PrimaryWorkspaceModel();
  addWorkspaceDocumentList: WorkspaceDocumentModel[] = [];
  addWorkspace_InsertWorspaceDeatils: CloseoutChecklistWorkspaceModel = new CloseoutChecklistWorkspaceModel();

  // ChangeOrderAssigment variables
  changeOrderAssignments: ChangeOrderAssignment[] = [];
  changeOrderAssignmentModel: ChangeOrderAssignment = new ChangeOrderAssignment();
  changeOrderAssignmentModelValue: ChangeOrderAssignmentModel = new ChangeOrderAssignmentModel();
  changeOrderAssignmentValues: ChangeOrderAssignmentModel[] = [];
  netFeesPlaceholder: string = "$0";
  marginPlaceholder: string = "0%";
  startDateCompareResult: number;
  isAssignmentSelected: boolean = false;
  editMode: boolean = false;
  editChangeOrderModel: EditChangeOrderModel = new EditChangeOrderModel();
  createdDate: string;
  changeOrderWorkspaceId: string;

  constructor(private dataService: DataService,
    private loadingService: LoadingScreenService,
    private router: Router,
    private clientService: ClientService,
    private engagementService: EngagementService,
    private activatedRoute: ActivatedRoute,
    private appService: AppConfigService,
    private alertService: AlertsService,
    private applicationMasterService: ApplicationMasterService,
    private renderer: Renderer2,
    private el: ElementRef,
    private adUsersService: AdUsersService,
    private changeOrderService: ChangeOrderService,
    private templateMaintenanceService: TemplateMaintenanceService,
    private configService: ConfigurationService,
    private documentService: DocumentService,
    private workspaceService: WorkspaceService,
    private hdService: HotDocsService,
    private clmService: CLMService,
    private activedirectoryService: ActiveDirectoryService
  ) {
    const jsonString = localStorage.getItem('currentUser');
    this.userObject = JSON.parse(jsonString);
    this.teamMemberLimit = this.appService.config.DATA.TEAM_MEMEBR_LIMIT;
    this.teamMemberRoleName = RoleNames.GTUSINDUSALL.toString();
    const IEversion = localStorage.getItem('isUserAgentIE');
    this.isUserAgentIE = JSON.parse(IEversion);
  }



  selectedPermission: number[] = [];
  ngOnInit() {
    this.editMode = false;
    const currentPath = this.activatedRoute.snapshot.url.join('/');
    if (currentPath === "NewChangeOrder") {
      this.editMode = false;
      this.selectedChangeOrderTemplate = "Select...";

    } else {
      this.editMode = true;
      if (currentPath != null && currentPath.includes('/')) {
        let currentUrlValues = currentPath.split('/');
        let ChangeOrderid = currentUrlValues[1];
        if (ChangeOrderid != null && ChangeOrderid != undefined && ChangeOrderid != "") {
          this.changeOrderService.getChangeOrderById(Number(ChangeOrderid)).subscribe({
            next: result => {
              this.editChangeOrderModel = result;
              this.clientId = this.editChangeOrderModel.clientId;
              this.engagementId = this.editChangeOrderModel.engagementId;
              this.isclientSelected = true;
              this.changeOrderWorkspaceId = this.editChangeOrderModel.workspaceId;
              this.BindEngagementData(this.clientId);
              this.engagementName = this.editChangeOrderModel.engagementName;
              this.ChangeOrderName = this.editChangeOrderModel.changeOrderName;
              this.selectedChangeOrderTemplate = this.editChangeOrderModel.templatePackageId;
              this.getChangeOrderHistory();
              this.engagement = new Engagements();
              this.engagement.parentSolutionCodeId = this.editChangeOrderModel.parentSolutionCodeId;
              this.getEngagementDetailsById(this.engagementId);
              this.btnUpdateTeamBtnClick(true);
              var changeOrdersAssignmentValues = this.editChangeOrderModel.changeOrderAssignments;
              if (changeOrdersAssignmentValues != null && changeOrdersAssignmentValues.length > 0) {
                changeOrdersAssignmentValues.forEach(element => {
                  var assignment = new ChangeOrderAssignment();
                 
                  assignment.assignmentCodeId = element.assignmentCodeId;
                  assignment.assignmentName = element.assignmentName;
                  assignment.estimatedCompletetionDate = element.estimateCompletionDate;
                  assignment.grossMargin = element.estimatedGrossMargin.toString();
                  assignment.incrementalFees = element.incrementalFees.toString();
                  assignment.estimatedStartDate = element.estimateCompletionDate;
                  assignment.StartDateCtrlId = "EstimatedStartDate" + element.assignmentCodeId;
                  assignment.createdDate = element.createdDate?.toString();
                  assignment.createdBy = element.createdBy;
                  if (assignment.estimatedCompletetionDate != null && 
                    assignment.estimatedCompletetionDate != '0001-01-01T00:00:00'){
                  assignment.visible = true;
                  assignment.checked = true;
                  }else{
                    assignment.estimatedCompletetionDate = "";
                    assignment.grossMargin = "";
                    assignment.incrementalFees = "";
                    assignment.estimatedStartDate = "";
                  }
                  this.changeOrderAssignments.push(assignment);
                });
              }
              if (this.editChangeOrderModel?.teamMembers != null && this.editChangeOrderModel?.teamMembers.length > 0) {
                var indexValue = 0;
                this.editChangeOrderModel?.teamMembers.forEach(element => {
                  if (!element.isReviewer) {
                    var teamMember = new ValidationTeamMember();
                    teamMember.memberEmailId = element.memberEmailId;
                    teamMember.memberName = element.memberName;
                    teamMember.teamMemberRole = element.teamMemberRole.toString();
                    teamMember.memeberContactNo = element.memeberContactNo;
                    teamMember.approvalStatus = element.approvalStatus;
                    teamMember.teamMemberPermissionId = element.teamMemberPermissionId;
                    teamMember.ishdnValidInputName = true;
                    teamMember.isUserExist = false;
                    teamMember.isNewUserDuplicate = false;
                    teamMember.index = indexValue;
                    this.selectedPermission.push(element.teamMemberPermissionId);
                    this.arrValidationForNewTeamMember.push(teamMember);
                    this.uiTeamMemberList.push(element);
                  }
                });


                // this.changeOrderTeamMemberList.push(ValidNewTeamMember);
                // });

              }

              //  this.changeOrderAssignments = this.editChangeOrderModel.ChangeOrderAssignments;

            }, error: err => {
              console.error("failed to load change order history", err)
            }
          })
        }
      }

      //Get the ChangeOrder Details
      // ServiceWorkerRegistration
    }
    this.startDateCtrlId = 'EstimateCompletionDate';
    this.teamMemberlblCtrlName = 'team member';
    this.activatedRoute.paramMap.subscribe(params => {
      this.changeOrderId = +params.get('changeOrderId');
      if (this.changeOrderId > 0) {
        this.editMode = true;
      }
    });
    this.checkLoggedinUserSystemAdminRole();
    this.getTeamMemberRoles();
    this.getTeamMemberRoleList(); // from appmaster getting all field values to compare on UI and team member addition
    this.getKeyTeamMemberRoleList(); // from appmaster getting all field values to compare on UI and Key team member addition
    this.getTeamMemberPermissions(); // from appmaster getting all field values to compare on UI and team member addition 
    this.getGoverningAgreementOption(); // from appmaster getting all field values to compare on UI and CLM template fetch
    this.clientService.getMyChangeOrderClients(this.userObject.loggedInUserEmail, this.isLoggedInUserSystemAdmin).subscribe({
      next: (result) => {
        this.clients = result;
        this.clientkeyValueList = this.getKeyValuePair(this.clients, 'clientId', 'clientName');
        this.checkPrefillClient();
        let prevPath = this.dataService.previousUrl.getValue();
        if (prevPath?.includes("EngagementDetail") && !(this.changeOrderId > 0)) {
          let pathItems = prevPath.split("/");
          let engagementIdTemp = +pathItems[2];
         this.getAssignmentsByEngId(engagementIdTemp, false);
         this.engagementId = engagementIdTemp;
         this.getChangeOrderHistory();
        }
      }, error: (err) => {

      }
    })
  }

  ngOnDestroy() { }
  canDeactivate(currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return of(true);
  }
  checkPrefillClient() {
    let prevPath = this.dataService.previousUrl.getValue();
    if (prevPath?.includes("EngagementDetail") && !(this.changeOrderId > 0)) {
      let pathItems = prevPath.split("/");
      let engagementIdTemp = +pathItems[2];
      this.getEngagementDetailsById(engagementIdTemp);
      this.isPrepopulateFromDetails = true;
    }
  }
  checkLoggedinUserSystemAdminRole() {
    console.log('userObject ==>', this.userObject);
    this.isLoggedInUserSystemAdmin = this.isQualityUserLoggedIn = false;
    if (this.userObject != null && this.userObject.role != null && this.userObject.role.length > 0) {
      for (let i = 0; i < this.userObject.role.length; i++) {
        if (this.userObject.role[i].shortRoleName === 'SA') {
          this.isLoggedInUserSystemAdmin = true;
        }
        if (this.userObject.role[i].shortRoleName === 'QG') {
          this.isQualityUserLoggedIn = true;
        }
      } //End of For  
    }// End of If
  }
  getKeyValuePair(array: any, keyProperty: string, valueProperty) {
    let keyValueList: KeyValue[] = [];
    array.forEach(element => {
      let kv = new KeyValue();
      kv.key = element[keyProperty];
      kv.value = element[valueProperty];
      keyValueList.push(kv);
    });
    return keyValueList;
  }
  getEngagementDetailsById(engId: number) {
    this.loadingService.startLoading();
    return this.engagementService.GetEditEngagementDetailsById(engId)
      .subscribe({
        next: result => {
          this.loadingService.stopLoading();
          this.engagement = result;
          if (this.engagement.status == "Active") {
            this.clientId = this.engagement.clientId;
            if (this.isPrepopulateFromDetails) {
              this.onClientsChange();
              this.isPrepopulateFromDetails = false;
              this.engagementName = this.engagement.engagementName;
            }
            this.engagementId = this.engagement.engagementId;
            this.checkPrimarySolutionCodeIsAudit(this.engagement.parentSolutionCodeId);
            this.onTemplateChange();// prefil the tempalte data
            if (!this.editMode){
            this.workspaceId = this.engagement.workspaceId
            }
          }
        }, error: err => {
          console.error("getEngagementDetailsById ", err);
          this.loadingService.stopLoading();
        }
      })
  }
  onClientsChange() {
    this.isclientSelected = true;
    this.BindEngagementData(this.clientId);
  }
  onEngagementsChange() {
    this.resetFileds();
    this.engagementName = this.engagements?.find(x => x.engagementId == this.engagementId).engagementName;
    this.getEngagementDetailsById(this.engagementId);// get engagement details and load templae mapping data
    this.getChangeOrderHistory();
    this.getEngagementTeamMembers();
    this.isEngagementSelected = true;
    this.getAssignmentsByEngId(this.engagementId, false);
    if (this.editMode)
      this.ChangeOrderName = '';
  }
  BindEngagementData(clientId: number) {
    this.engagementService.getMyChangeOrderEngagements(this.userObject.loggedInUserEmail, clientId, this.isLoggedInUserSystemAdmin).subscribe(result => {
      this.engagements = result;
      this.engagementkeyValueList = this.getKeyValuePair(result, 'engagementId', 'engagementName');
    });
  }
  getTeamMemberRoleList() {
    let applicationName = 'TeamMemberRole';
    this.applicationMasterService.GetAllTeamMemberRoles(applicationName).subscribe(
      result => {
        //this.applicationmasterList = result;
        result.forEach(element => {
          const teamMemberRoleCode = new AppCodes();
          teamMemberRoleCode.code = element.code;
          teamMemberRoleCode.codeValue = element.applicationMasterId;
          this.teamMemberRoleCodes.push(teamMemberRoleCode);
          this.applicationmasterList.push(element);
          if (element.code !== 'EP' && element.code !== 'C' && element.code !== 'A'
            && element.code !== 'CMSEP' && element.code !== 'PR') {
            this.applicationmasterListIE.push(element);
            this.teamMemberRoleFromAppMstList.push(element);
          }
        });
        console.log(this.teamMemberRoleCodes);
      }
    );
  }
  getKeyTeamMemberRoleList() {
    let applicationName = 'KeyTeamMemberRole';
    this.applicationMasterService.GetAllTeamMemberRoles(applicationName).subscribe(
      result => {
        result.forEach(element => {
          const keyTeamMemberRoleCode = new AppCodes();
          keyTeamMemberRoleCode.code = element.code;
          keyTeamMemberRoleCode.codeValue = element.applicationMasterId;
          this.keyTeamMemberRoleCodes.push(keyTeamMemberRoleCode);
          //this.applicationmasterListIE.push(element);
          this.keyteamMemberRoleFromAppMstList.push(element);
        });
        this.keyteamMemberRoleFromAppMstList = this.keyteamMemberRoleFromAppMstList.sort((a, b) => Number(a.applicationMasterId) - Number(b.applicationMasterId));
      }
    );
  }
  getGoverningAgreementOption() {
    let applicationName = 'GoverningAgreementOption';
    this.applicationMasterService.GetApplicationMasterDataByName(applicationName).subscribe({
      next: result => {
        this.governingAgreementOptionAppMstList = result;
      }, error: err => {
        console.error("failed to load change order GoverningAgreementOption", err)
      }
    }
    );
  }
  getTeamMemberPermissions() {
    this.applicationMasterService.GetTeamMemberPermissions().subscribe(
      result => {
        this.teamMemberPermissions = result;
      }
    );
  }
  addTeamMember() {
    $('#clientErrorMessage').text('');
    //set role name for team meber
    // if (this.isContinuanceEngagement && this.engagementCollabData.isIndus && this.engagementCollabData.carryForwadINDUSConsent) { //isindus current engagement collabsitetable == true && carryforwardindus == true && iscontinuous == true
    //   this.teamMemberRoleName = RoleNames.ICANDGIA.toString();
    // } else {
    //   this.teamMemberRoleName = RoleNames.GTUSINDUSALL.toString();
    // }
    // if (this.engagementCollabData.isIndus  || (this.isLoggedInUserSystemAdmin && this.engagement.memberFirmMappingId > 0)) {
    //   this.teamMemberRoleName = this.memberFirmData.indusConsentShortRoleName;
    // } else if (this.engagementCollabData.isIndus) {
    //   this.teamMemberRoleName = RoleNames.INDUSConsent.toString();
    // } else if (this.isMemberFirm || (this.isLoggedInUserSystemAdmin && this.engagement?.memberFirmMappingId > 0)) {
    //   this.teamMemberRoleName = this.memberFirmData?.teamMemberShortRoleName;
    // } else {
    //   this.teamMemberRoleName = RoleNames.StaffMember.toString();
    // }
    this.btnUpdateTeamBtnClick(true);
    this.teamMemberRoleName = RoleNames.StaffMember.toString();
    if (this.uiTeamMemberList != null) {
      if (this.uiTeamMemberList.length < this.teamMemberLimit) {
        //close client team members section
        // this.arrValidationForNewClientTeamMember = [];
        // this.newClientTeamMemberList = [];

        this.alertService.remove(this.alert);
        this.newTeamMember = {
          memberEmailId: '',
          memberConfirmEmail: '',
          memberName: '',
          teamMemberRole: '',
          memeberContactNo: '',
          approvalStatus: '',
          teamMemberPermissionId: 0,
          isKeyTeamMember: false,
          keyTeamMemberRoleId: 0,
          description: ''
        };

        this.uiTeamMemberList.push(this.newTeamMember);
        const ValidNewTeamMember = {
          memberEmailId: '',
          memberConfirmEmail: '',
          memberName: '',
          teamMemberRole: '',
          memeberContactNo: '',
          approvalStatus: '',
          teamMemberPermissionId: 0,
          ishdnValidInputName: true,
          isUserExist: false,
          isNewUserDuplicate: false,
          index: this.arrValidationForNewTeamMember.length
        };
        this.arrValidationForNewTeamMember.push(ValidNewTeamMember);
        console.log('addTeamMember func called', this.uiTeamMemberList, this.arrValidationForNewTeamMember);
      }
      else {
        this.alertServiceFun('You can add only ' + this.teamMemberLimit + ' team members.', 'danger');
      }
    }
  }

  removeTeamMember(index: number, teamMemberRole: string) {
    // if (this.editMode) {
    //   this.loadingService.startLoading();
    //   return this.engagementService.IsDiscussionOrReviewPointExistForUser(this.currentEngagementId, this.teamMemberList[index].memberEmailId).subscribe(
    //     res => {
    //       this.discussionandReviewPointsCount = res;
    //       this.loadingService.stopLoading();
    //       if (this.discussionandReviewPointsCount == 0) {
    //         this.teamMemberList.splice(index, 1);
    //         this.arrValidationForNewTeamMember.splice(index, 1);
    //         this.renameTeammember(index, teamMemberRole);
    //         this.alertService.remove(this.alert);
    //         return true;
    //       }
    //       else {
    //         this.alertServiceFun('Team Member you are deleting has open discussions or review points. You cannot delete this team member.', 'danger');
    //         return false;
    //       }
    //     },
    //     () => {
    //       this.loadingService.stopLoading();
    //       this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
    //     });
    // }
    // else
    {
      this.uiTeamMemberList.splice(index, 1);
      this.arrValidationForNewTeamMember.splice(index, 1);
      this.renameTeammember(index, teamMemberRole);
      this.alertService.remove(this.alert);
      return true;
    }
    // }
    // return true;
  }
  btnUpdateTeamBtnClick(idAddTeamClicked: boolean) {
    if (idAddTeamClicked == false)
      this.changeOrderTeamMemberValidation();
    if (this.IsValidInput && !this.cmsDataMatch) {
      if (this.uiTeamMemberList?.length > 0) {
        this.changeOrderTeamMemberList = [];
        for (let t = 0; t < this.uiTeamMemberList.length; t++) {
          let selectedPermissionId = 0;
          if ($('#drpTeamMemberPermissions_' + t + ' option:selected').val() != null) {
            selectedPermissionId = parseInt($('#drpTeamMemberPermissions_' + t + ' option:selected').val());
          }
          //var iskeyTeamMember = $('#chkKeyTeamMember_' + t).val();

          var teamMemberRoleValue = '';
          if( this.isAudit){
             teamMemberRoleValue= $('#drpKeyTeamMemberRole_' + t + ' option:selected').val();
          }else{
            teamMemberRoleValue= $('#drpTeamMemberRole_' + t + ' option:selected').val();
          }

          if (teamMemberRoleValue == null || teamMemberRoleValue == undefined || teamMemberRoleValue == ''){
            this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.P);
          }

          this.newAssignTeamMember = {

            memberName: $('#UserHiddenProps_' + this.teamMemberRoleName + '_' + t + ' #hdnUserName').val(),
            memberEmailId: $('#UserHiddenProps_' + this.teamMemberRoleName + '_' + t + ' #hdnUserEmail').val(),
            memeberContactNo: $('#UserHiddenProps_' + this.teamMemberRoleName + '_' + t + ' #hdnUserPhone').val(),
            //teamMemberRole: $('#drpTeamMemberRole_' + t + ' option:selected').val(),
            teamMemberRole: teamMemberRoleValue,
            teamMemberPermissionId: selectedPermissionId,
            isKeyTeamMember: this.isAudit ? this.uiTeamMemberList[t].isKeyTeamMember : false,
            keyTeamMemberRoleId: this.isAudit ? $('#drpKeyTeamMemberRole_' + t + ' option:selected').val() : '',
            description: (this.isAudit && this.getKeyTeamMemberRoleIdByCode(EnumKeyTeamMemberRoleCodes.OTH)) ? this.uiTeamMemberList[t].description : ''
          };
          this.changeOrderTeamMemberList.push(this.newAssignTeamMember);
        }
      }
    } // end check for cmsmatchdata and valid input

  }
  OnKeyRoleChange(event, id: number) {
    let memberRoleId: number;
    memberRoleId = Number(event.target.value);
    if (this.uiTeamMemberList[id].isKeyTeamMember == true &&
      memberRoleId == this.getKeyTeamMemberRoleIdByCode(EnumKeyTeamMemberRoleCodes.OTH)) {
      $('#keyTeamMemberDescription_' + id).css('display', 'block');
    } else {
      this.uiTeamMemberList[id].description = '';
      $('#keyTeamMemberDescription_' + id).css('display', 'none');
    }
  }

  changeOrderTeamMemberValidation() {
    this.cmsDataMatch = false;
    this.IsValidInput = true;
    for (let t = 0; t < this.arrValidationForNewTeamMember.length; t++) {
      this.arrValidationForNewTeamMember[t].memberName = $('#txtPeoplePicker_' + this.teamMemberRoleName + '_' + t).val();
      this.arrValidationForNewTeamMember[t].teamMemberRole = this.isAudit ? $('#drpKeyTeamMemberRole_' + t).val() : $('#drpTeamMemberRole_' + t).val();
      this.arrValidationForNewTeamMember[t].teamMemberPermissionId = Number($('#drpTeamMemberPermissions_' + t).val());
      this.arrValidationForNewTeamMember[t].ishdnValidInputName = (/true/i).test($('#hdnValidInput_' + this.teamMemberRoleName + '_' + t).val());
      this.arrValidationForNewTeamMember[t].isNewUserDuplicate = false;
      this.arrValidationForNewTeamMember[t].isUserExist = false;
      this.arrValidationForNewTeamMember[t].memberEmailId = $('#UserHiddenProps_' + this.teamMemberRoleName + '_' + t + ' #hdnUserEmail').val();

      if (this.arrValidationForNewTeamMember[t].memberName === '' ||
        this.arrValidationForNewTeamMember[t].memberName === null ||
        this.arrValidationForNewTeamMember[t].memberName === undefined) {
        $('#errorLabelMemberName' + t).removeClass('d-none');
        $('#errorLabelMemberName' + t).addClass('d-block');
      } else {
        $('#errorLabelMemberName' + t).removeClass('d-block');
        $('#errorLabelMemberName' + t).addClass('d-none');
      }
      if (this.arrValidationForNewTeamMember[t].teamMemberRole === null ||
        this.arrValidationForNewTeamMember[t].teamMemberRole === '' ||
        this.arrValidationForNewTeamMember[t].teamMemberRole === undefined ||
        this.arrValidationForNewTeamMember[t].teamMemberRole === "null") {
        $('#errorLabelMemberRole' + t).removeClass('d-none');
        $('#errorLabelMemberRole' + t).addClass('d-block');
        this.arrValidationForNewTeamMember[t].teamMemberRole = null;
      } else {
        $('#errorLabelMemberRole' + t).removeClass('d-block');
        $('#errorLabelMemberRole' + t).addClass('d-none');
      }
      if (this.arrValidationForNewTeamMember[t].teamMemberPermissionId === null ||
        this.arrValidationForNewTeamMember[t].teamMemberPermissionId === undefined ||
        this.arrValidationForNewTeamMember[t].teamMemberPermissionId === -1) {
        $('#errorLabelMemberPermission' + t).removeClass('d-none');
        $('#errorLabelMemberPermission' + t).addClass('d-block');
        this.arrValidationForNewTeamMember[t].teamMemberPermissionId = null;
      } else {
        $('#errorLabelMemberPermission' + t).removeClass('d-block');

        $('#errorLabelMemberPermission' + t).addClass('d-none');
      }
      if (this.arrValidationForNewTeamMember[t].memberName === '' ||
        this.arrValidationForNewTeamMember[t].teamMemberRole === null ||
        this.arrValidationForNewTeamMember[t].teamMemberPermissionId === null ||
        this.arrValidationForNewTeamMember[t].ishdnValidInputName === false ||
        this.arrValidationForNewTeamMember[t].memberEmailId === '' ||
        this.arrValidationForNewTeamMember[t].memberEmailId === null ||
        this.arrValidationForNewTeamMember[t].memberEmailId === undefined) {
        this.IsValidInput = false;
      }
    }
    // for loop to check duplicate
    for (let t = 0; t < this.arrValidationForNewTeamMember.length; t++) {
      if (this.CheckDuplicateElement(this.arrValidationForNewTeamMember, 'memberEmailId', this.arrValidationForNewTeamMember[t].memberEmailId)) {
        this.arrValidationForNewTeamMember[t].isNewUserDuplicate = true;
        this.cmsDataMatch = true;
        this.arrValidationForNewTeamMember[t].isUserExist = true;
        $('#userExist' + t).removeClass('d-none');
        $('#userExist' + t).attr('style', 'display:block');

      }
      if (this.cmsDataMatch === false) {
        $('#userExist' + t).attr('style', 'display:none');
      }
    }
  }

  getTeamMemberRoleIdByCode(Code: string): number {
    return this.teamMemberRoleCodes.find(c => c.code === Code).codeValue;
  }
  getKeyTeamMemberRoleIdByCode(Code: string): number {
    return this.keyTeamMemberRoleCodes.find(c => c.code === Code).codeValue;
  }
  private CheckDuplicateElement(array, property, value) {
    let foundElements = [];
    let elCount = 0;
    let isDuplicate = false;
    for (let element of array) {
      if (element[property] === value) {
        elCount = elCount + 1;
        if (elCount === 1) {
          foundElements.push(element);
        } else if (elCount > 1) {
          isDuplicate = true;
          break;
        }
      }
    }
    return isDuplicate;
  }

  selectTeamMember(index: any, member: MembersList) {
    $('#searchTeamMemberName').val(member.displayName);
    $('#tblMemberAD').on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    //this.selectedTM=member;
    this.selectedTMIndex = index;
  }
  deselectTeamMember() { // people picker cancel clicked 
    $('#' + this.teamMemberCtrl).val('');
    $('#' + this.teamMemberCtrl).innerText = '';
    $('#' + this.teamMemberEmailCtrl + ' > #hdnUserEmail').val('');
    //$('#' + this.teamMemberEmailCtrl).innerText = '';
  }



  setTeamMember() {
    let index = this.selectedTMIndex;
    if (Number(index) >= 0) {
      const selectedMemberName = $('#spMemberdisplayName' + index).text();
      const selectedMemberEmail = $('#spMembermail' + index).text();
      const selectedMemberPhone = $('#spMemberbusinessPhones' + index).text();
      $("#TeamMemberErrorMessage").text('');
      var countofAddedTM = 0;
      for (var i in this.uiTeamMemberList) {
        if (this.uiTeamMemberList[i].memberEmailId == selectedMemberEmail) {
          countofAddedTM = countofAddedTM + 1;
        }
      }
      if (countofAddedTM == 0) {
        $('#TeamMemberErrorMessage').text('');
        $('#' + this.teamMemberCtrl).val(selectedMemberName);
        $('#' + this.teamMemberEmailCtrl + ' > #hdnUserEmail').val(selectedMemberEmail);
        this.uiTeamMemberList[this.currentTeamMemberIndex].memberName = selectedMemberName;
        this.uiTeamMemberList[this.currentTeamMemberIndex].memberEmailId = selectedMemberEmail;
        this.uiTeamMemberList[this.currentTeamMemberIndex].memeberContactNo = selectedMemberPhone;
        this.validateTM.forEach((value, memberIndex, array) => array[this.currentTeamMemberIndex] = true);
        console.log('Validate Team Members ', this.validateTM);
      }
      else {
        $('#TeamMemberErrorMessage').text('Selected Team Member is already added');
      }
    }
    this.membersListArrayForTeamMembers = null;
  }

  searchUsers(searchTextBox, tableId: string) {
    const searchText = $('#' + searchTextBox).val();
    // if (tableId === 'tblEPAD' || tableId === 'tblEQCR' || tableId === 'tblGTAgreementOwner') {
    //   this.getEPList(searchText);
    // }

    // if (tableId === 'tblAssAdminAD') {
    //   this.GetAssignmentAdmins(searchText);
    // }

    if (tableId === 'tblMemberAD') {
      this.GetTeamMembers(searchText);
    }

    /*
    $('#' + tableId + ' tr').each(function (index) {
      if (!index) return;
      $(this).find('td').each(function () {
        var id = $(this).text().toLowerCase().trim();
        var not_found = (id.indexOf(searchText) === -1);
        $(this).closest('tr').toggle(!not_found);
        return not_found;
      });
    });
*/
  }


  renameTeammember(index: number, teamMemberRole: string) {
    const rows = this.el.nativeElement.querySelectorAll('#clientUserListContainer');
    if (rows.length > 0) {
      const rowToRemove = rows[index];
      this.renderer.removeChild(this.el.nativeElement, rowToRemove);
      for (let i = index; i < rows.length - 1; i++) {
        const row = rows[i + 1];
        const txtPeoplePicker = row.querySelector('input[type="text"]:first-child');
        const hdnValidInput = row.querySelector('input[type="hidden"][id^="hdnValidInput_' + teamMemberRole + '_"]');
        const hint = row.querySelector('span[id^="hint_' + teamMemberRole + '_"]');

        const loadericon = row.querySelector('em');
        const searchUserList = row.querySelector('ul');
        const UserHiddenProps = row.querySelector('div:nth-child(4)');

        if (hdnValidInput) {
          const newId = 'hdnValidInput_' + teamMemberRole + '_' + i;
          hdnValidInput.setAttribute('id', newId);
        }
        txtPeoplePicker.id = 'txtPeoplePicker_' + teamMemberRole + '_' + i;
        loadericon.id = 'loadericon_' + teamMemberRole + '_' + i;
        searchUserList.id = 'searchUserList_' + teamMemberRole + '_' + i;
        hint.id = 'hint_' + teamMemberRole + '_' + i;

        if (UserHiddenProps) {
          const divId = UserHiddenProps.getAttribute('id');
          if (divId) {
            const newDivId = divId.replace(/_\d+$/, '_' + i);
            UserHiddenProps.setAttribute('id', newDivId);
          }
        }
        const errorDivs = row.querySelectorAll('div.error-color');
        for (let j = 0; j < errorDivs.length; j++) {
          const errorDiv = errorDivs[j];
          const errorDivId = errorDiv.getAttribute('id');
          if (errorDivId) {
            const newErrorDivId = errorDivId.replace(/_\d+$/, '_' + i);
            errorDiv.setAttribute('id', newErrorDivId);
          }
        }

      }
    }

  }

  GetTeamMembers(searchTerm: string) { // function for people picker popup 
    this.loadingService.startLoading();
    let searchModel = new SearchGroupUserViewModel();
    searchModel.user = new MembersList();
    searchModel.user.displayName = searchTerm;
    // if (this.memberFirmData?.memberFirmMappingId > 0 && this.isLoggedInUserSystemAdmin) {
    //   searchModel.isMemberFirm = true;
    //   searchModel.memberFirmDomain = [];
    //   searchModel.memberFirmDomain.push(this.memberFirmData?.memberDomain)
    // }
    this.adUsersService.GetUserInformationByDisplayName(searchModel)
      .subscribe(
        userList => {
          this.loadingService.stopLoading();
          this.membersListArrayForTeamMembers = userList;
        },
        (error) => {
          console.log('Error getting result ', error);
          this.membersListArrayForTeamMembers = null;
          this.loadingService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        });
  }

  refreshTeamMemberPopup(index, textboxName: string) { // funtion to refresh team member for people picker popup 
    this.removeRowSelection('tblMemberAD', 'searchTeamMemberName', 'TeamMemberErrorMessage');
    // if (this.EditMode) {
    //   this.loadingScreenService.startLoading();
    //   return this._engService.IsDiscussionOrReviewPointExistForUser(this.currentEngagementId, this.teamMemberList[index].memberEmailId).subscribe(
    //     res => {
    //       this.discussionandReviewPointsCount = res;
    //       this.loadingScreenService.stopLoading();
    //       if (this.discussionandReviewPointsCount == 0) {
    //         this.teamMemberCtrl = textboxName + this.teamMemberRoleName + '_' + index;
    //         this.teamMemberEmailCtrl = 'UserHiddenProps_' + this.teamMemberRoleName + '_' + index;
    //         this.currentTeamMemberIndex = index;
    //         this.selectedTMIndex = null;
    //         this.validateTM.forEach((value, memberIndex, array) => array[index] = false);
    //         console.log('Validate Team Members ', this.validateTM);
    //         return true;
    //       }
    //       else {
    //         $('#modal-TeamMember').modal('hide');
    //         return false;
    //       }
    //     }, () => {
    //       this.loadingScreenService.stopLoading();
    //       this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
    //     });
    // }
    // else {
    this.teamMemberCtrl = textboxName + this.teamMemberRoleName + '_' + index;
    this.teamMemberEmailCtrl = 'UserHiddenProps_' + this.teamMemberRoleName + '_' + index;
    this.currentTeamMemberIndex = index;

    this.selectedTMIndex = null;
    this.validateTM.forEach((value, memberIndex, array) => array[index] = false);
    console.log('Validate Team Members ', this.validateTM);
    // }
  }
  removeRowSelection(tableId, textboxId, errorMessageId) {
    $('#' + textboxId).val('');
    $('#' + errorMessageId).text('');
    const searchText = $('#' + textboxId).val().toLowerCase().trim();
    this.searchResult(tableId, searchText);
    $('#' + tableId + ' tr').each(function () {
      if ($(this).hasClass('highlight')) {
        $(this).removeClass('highlight');
      }
    });
  }

  searchResult(tableId, searchText) {
    $('#' + tableId + ' tr').each(function (index) {
      if (!index) return;
      $(this).find('td').each(function () {
        var id = $(this).text().toLowerCase().trim();
        var not_found = (id.indexOf(searchText) === -1);
        $(this).closest('tr').toggle(!not_found);
        return not_found;
      });
    });
  }



  alertServiceFun(message, type) {
    this.alertService.remove(this.alert);
    this.alertService.add(message, type, null);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }


  // To check primary solutioncode is audit or not
  checkPrimarySolutionCodeIsAudit(primarySolutioncodeId: number) {
    if (primarySolutioncodeId > 0)
      this.engagementService.GetSolutionCodeMasterById(primarySolutioncodeId).subscribe(result => {
        this.isAudit = result.isAudit;
      });
  }

  getChangeOrderHistory() {
    this.changeOrderService.getChangeOrderHistory(this.engagementId).subscribe({
      next: result => {
        this.changeOrderHistoryList = result;
      }, error: err => {
        console.error("failed to load change order history", err)
      }
    })

  }
  getTemplateMappingData() {
    this.clmService.getCLMDetails(this.engagementId).subscribe({
      next: result => {
        let clmdetails: CLMDetails = result;
        let gaCategory = this.governingAgreementOptionAppMstList.find(x => x.code == clmdetails?.gaCategory);
        let gaCategoryName = ""
        if (gaCategory == undefined) {
          gaCategoryName = "None of the above."
        } else {
          gaCategoryName = gaCategory.value;
        }
        const getTemplateMappingData = this.configService.getSolutionCodeTemplateMappingData(this.documentCategory, this.engagement.parentSolutionCodeId, true);
        const getCLMTemplateMaintenanceData = this.templateMaintenanceService.getTemplateMaintenanceDataByCLMType(clmdetails.gaCategory ?? "None of the above."); // if CLM GACategory null then noMSAEL
        forkJoin([getTemplateMappingData, getCLMTemplateMaintenanceData]).subscribe({
          next: result => {
            let templateData = result[0];
            let templateDataCLM = result[1];
            if (templateData.length > 0) {
              this.TemplateList = templateData;
              this.isChangeorderTemplateSelected = true;
            } else {
              this.TemplateList = [];
              this.SelectedTemplateList = [];
            }
            if (templateDataCLM.length > 0) {
              this.SelectedTemplateList = this.TemplateList.filter(o => templateDataCLM.some(({ templatePackageId }) =>
                o.templatePackageId === templatePackageId));
            }
          }
        })

      }, error: err => {
        console.log("failed to load clm details for templates", err);
      }
    })
    // this.configService.getSolutionCodeTemplateMappingData(this.documentCategory, this.engagement.parentSolutionCodeId, true).subscribe(templateData => {
    //   if (templateData.length > 0) {
    //     this.TemplateList = templateData;
    //   } else {
    //     this.TemplateList = [];
    //     this.SelectedTemplateList = [];
    //     this.engagement.agreementType = 'undefined';
    //   }
    // });
  }

  getCLMTemplateMaintenanceData() {
    // const staticValue = 'noMSAEL'; // Define the static value
    // this.SelectedTemplateList = [];
    // this.existingGACheck = false;
    // this.templateMaintenanceService.getTemplateMaintenanceDataByCLMType(staticValue).subscribe(
    //   templateDataCLM => {
    //     console.log(templateDataCLM);
    //     if (templateDataCLM.length > 0) {
    //       this.SelectedTemplateList = this.TemplateList.filter(o => templateDataCLM.some(({ templatePackageId }) =>
    //         o.templatePackageId === templatePackageId));
    //     }
    //   }, error => {
    //     this.loadingService.stopLoading();
    //     this.alertService.add('Something went wrong! Please contact the system administrator.', 'danger', null);
    //   });
  }

  onTemplateChange() {
    this.getTemplateMappingData();
  }
  onCreateChangeOrderClicked() {
    this.EstimationDate = $('.EstimateCompletionDate').val();
    this.validateData();
    this.btnUpdateTeamBtnClick(false);
    this.addUpdateEPPMDToTeamMemberList(); // add eppmd to user list
    if (this.IsValidInput && !this.cmsDataMatch) // for team member validation
      if (this.createChangeorderValidation) { // for change order field validation
        this.addDocument();
      }
  }
  private addDocument() {
    this.addWorkspace_SelectedData.engagementId = this.engagementId;
    this.addWorkspace_SelectedData.documentAliasName = this.ChangeOrderName;
    this.addWorkspace_SelectedData.templatePackageId = this.selectedChangeOrderTemplate;
    this.addWorkspace_SiteUniqueId = this.engagement.siteUniqueID
    this.documentService.getCountOfDocumentAliasNameByEngagementId(this.addWorkspace_SelectedData).subscribe(
      count => {
        if (count > 0) {
          this.addWorkspace_SelectedData.IsDocumentAliasNameDuplicate = true;
        } else {
          this.addWorkspace_SelectedData.IsDocumentAliasNameDuplicate = false;
          this.loadingService.startLoading();
          this.createWorkitem();
        }
      },
      err => {
        this.displaySystemAlert();
      });
  }
  private createWorkitem() {
    // this.workspaceService.getWorkspaceByEngagementId(this.engagementId).subscribe(
    //   workspaceResult => {
    //     this.addWorkspace_PrimaryWorkspaceStatus = workspaceResult.status;
    this.addWorkspace_PrimaryWorkspaceStatus = "Draft";
    this.workspaceService.getMaxWorkspaceId().subscribe(
      result => {
        if (result <= 0) {
          result = 1;
        }
        this.createWorkItemDetails.workspaceCode = result + 3;
        this.createWorkItemDetails.siteUniqueId = this.addWorkspace_SiteUniqueId + '_Secondary_ChangeOrder_';
        this.createWorkItemDetails.templatePackageId = this.addWorkspace_SelectedData.templatePackageId;
        this.processWorkItemCreation();
      },
      error => {
        this.displaySystemAlert();
      });
    // },
    // error => {
    //   this.displaySystemAlert();
    // });
  }
  processWorkItemCreation() {
    this.hdService.GetCreateWorkItemDocumentData(this.createWorkItemDetails).subscribe(
      workItemResult => {
        this.workItemDetails = workItemResult.hdWorkItem;
        this.workspaceData.hdWorkGroupId = this.workItemDetails.workGroupId;
        this.workspaceData.hdWorkItemId = this.workItemDetails.id;
        this.workspaceData.hdTemplatePackageId = this.workItemDetails.templatePackageId;
        this.workspaceData.hdCurrentTempVersion = this.workItemDetails.templateCurrentVersion;
        this.workspaceData.status = this.addWorkspace_PrimaryWorkspaceStatus;
        this.workspaceData.hdTemplatePackageVersionId = this.workItemDetails.templatePackageVersionId;
        this.getDocumentListtoPreview(workItemResult);
      },
      error => {
        this.displaySystemAlert();
      });
  }
  getDocumentListtoPreview(workItemResult: HDWorkItemDocumentModel) {
    if (workItemResult.hdWorkItem.id != null) {
      this.addWorkspaceDocumentList = [];
      let workspaceDocument = new WorkspaceDocumentModel();
      workItemResult.hdWorkspaceDocumentList.forEach(x => {
        workspaceDocument.hdDocumentId = x.documentId;
        workspaceDocument.hdDocumentName = x.name;
        workspaceDocument.hdMimeType = x.mimeType;
        workspaceDocument.hdAssemblySession = x.assemblySessionId;
        workspaceDocument.isActive = true;
        workspaceDocument.hdTemplatePackageVersionId = this.workspaceData.hdTemplatePackageVersionId;
        workspaceDocument.documentAliasName = this.addWorkspace_SelectedData.documentAliasName;
        workspaceDocument.engagementState = 'ChangeOrder';
        this.addWorkspaceDocumentList.push(workspaceDocument);
      });
      this.createWorkspaceInDB();
    }
  }

  createWorkspaceInDB() {
    this.addWorkspace_InsertWorspaceDeatils.engagementId = this.engagementId;
    this.addWorkspace_InsertWorspaceDeatils.workspaceData = this.workspaceData;
    this.addWorkspace_InsertWorspaceDeatils.workspaceDocuments = this.addWorkspaceDocumentList;
    this.addWorkspace_InsertWorspaceDeatils.isCloseOutChecklist = false;
    this.workspaceService.InsertCloseoutChecklistWorkspace(this.addWorkspace_InsertWorspaceDeatils).subscribe(
      result => {
        this.loadingService.stopLoading();
        if (result != null && result != undefined && result != '') {
          this.workspaceId = result;
          this.createChangeOrder();
        }
      },
      error => {
        this.displaySystemAlert();
      });
  }


  createChangeOrder() {
    console.log("Creating change order");
    this.EstimationDate = $('.EstimateCompletionDate').val();
    // this.validateData();
    if (this.createChangeorderValidation) {
      this.changeOrderModel.engagementId = this.engagementId;
      this.changeOrderModel.changeOrderName = this.ChangeOrderName;
      this.changeOrderModel.isFinancialUpdate = this.financialUpdate;
      this.changeOrderModel.createdBy = this.userObject.loggedInUserEmail;
      this.changeOrderModel.lastModifiedBy = this.userObject.loggedInUserEmail;
      this.changeOrderModel.workspaceId = this.workspaceId;
      this.changeOrderModel.teamMembers = this.changeOrderTeamMemberList;
      this.changeOrderModel.status = "Draft";
      if (!this.changeOrderModel.isFinancialUpdate) {
        this.changeOrderModel.estimateCompletionDate = null;
        this.changeOrderModel.fee = null;
        this.changeOrderModel.grossMargin = null;
      } else {
        this.changeOrderModel.estimateCompletionDate = this.EstimationDate;
        this.changeOrderModel.fee = this.IncrementalFees;
        this.changeOrderModel.grossMargin = this.Grossmargin;
      }
      this.loadingService.startLoading();
      // Change Order Assignment values
      if (this.changeOrderAssignments.length > 0){
        this.changeOrderAssignmentValues = [];
        this.changeOrderAssignments.forEach(element => {
      
            this.changeOrderAssignmentModelValue = new ChangeOrderAssignmentModel();
            this.changeOrderAssignmentModelValue.assignmentCodeId = element.assignmentCodeId;
            this.changeOrderAssignmentModelValue.engagementId = this.engagementId;
            // changeOrderValue.estimateCompletionDate = element.estimatedCompletetionDate;
            this.changeOrderAssignmentModelValue.estimatedGrossMargin = Number(element.grossMargin);
            this.changeOrderAssignmentModelValue.incrementalFees = Number(element.incrementalFees);
            this.changeOrderAssignmentModelValue.createdBy = this.userObject.loggedInUserEmail;
            this.changeOrderAssignmentModelValue.estimateCompletionDate = $('.EstimatedStartDate' + element.assignmentCodeId).val();
            this.changeOrderAssignmentValues.push(this.changeOrderAssignmentModelValue);
          
        });
      }
      if (this.changeOrderAssignmentValues.length > 0) {
        this.changeOrderModel.ChangeOrderAssignments = [];
        this.changeOrderModel.ChangeOrderAssignments = this.changeOrderAssignmentValues;
      }
      this.changeOrderService.createChangeOrder(this.changeOrderModel).subscribe({
        next: (result: string) => {
          this.createAndUpdatePermissionsModel(this.changeOrderTeamMemberList, this.engagement.siteUniqueID)
          this.loadingService.startLoading();
          if (result  != null && result != undefined && Number(result) > 0) {
            console.log('Change order created successfully.');
            this.router.navigate(['Interviews/' + this.workspaceId]);
          } else {
            console.error('Unexpected response:', result);
            this.alertService.add('Unexpected response from server.', 'warning', null);
          }
          this.loadingService.stopLoading();
        },
        error: (error) => {
          console.error('Error creating change order:', error);
          this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
          this.loadingService.stopLoading();
        }
      });
    }

  }

  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    // Remove any non-numeric characters except for the decimal point
    input.value = input.value.replace(/[^0-9.]/g, '');
  }

  setCancelDialog() {
    this.invalidvalues = "";
    this.invalidvalues = "";
    this.RedirectOnCancel();
    this.clearValidationMessages()
  }
  RedirectOnCancel() {
    this.router.navigate(['MyEngagements']);
  }

  validateData() {
    this.isclientSelected = this.clientId !== undefined && this.clientId !== -1;
    this.isEngagementSelected = this.engagementId !== undefined && this.engagementId !== -1;
    this.isChangeorderTemplateSelected = this.selectedChangeOrderTemplate?.trim().length > 0;
    this.isChangeOrderNameFilled = this.ChangeOrderName?.trim().length > 0;

    if (this.financialUpdate) {
      this.isIncrementalFeesFilled = this.IncrementalFees !== undefined && this.IncrementalFees !== null;
      this.isGrossMarginFilled = this.Grossmargin !== undefined && this.Grossmargin !== null;
      this.isEstimationDateFilled = this.EstimationDate !== undefined && this.EstimationDate !== null && this.EstimationDate !== "";
    } else {
      this.isIncrementalFeesFilled = true;
      this.isGrossMarginFilled = true;
      this.isEstimationDateFilled = true;
    }
    this.createChangeorderValidation = this.isclientSelected && this.isEngagementSelected && this.isChangeorderTemplateSelected && this.isChangeOrderNameFilled &&
      (this.financialUpdate ? (this.isIncrementalFeesFilled && this.isGrossMarginFilled && this.isEstimationDateFilled) : true);


    // ChangeOrderAssignment Validation
    if (this.createChangeorderValidation) {
      this.isAssignmentSelected = true;
      this.changeOrderAssignments.forEach(element => {
        if (element.checked == true) {
          this.isAssignmentSelected = false;
          if (element.grossMargin === "" || element.grossMargin === null || element.grossMargin === undefined) {
            element.grossMarginError = true;
            element.grossMarginRequiredError = true;
            this.createChangeorderValidation = false;
          } else {
            if (Number(element.grossMargin) <= 100) {
              element.grossMarginError = false;
              element.grossMarginRequiredError = false;
            } else {
              element.grossMarginError = true;
              this.createChangeorderValidation = false;
            }
          }

          if (element.incrementalFees === "" || element.incrementalFees === null || element.incrementalFees === undefined) {
            element.incrementalFeesError = true;
            element.incrementalFeesRequiredError = true;
            this.createChangeorderValidation = false;
          }
          else {
            element.incrementalFeesError = false;
            element.incrementalFeesRequiredError = false;
          }
          var estimateCompletionDateVal = $('.EstimatedStartDate' + element.assignmentCodeId).val();
          if (estimateCompletionDateVal === "" || estimateCompletionDateVal === null || estimateCompletionDateVal === undefined) {
            element.estimatedComplationRequiredError = true;
            this.createChangeorderValidation = false;
          } else {
            element.estimatedComplationRequiredError = false;
            const currentDate = new Date().toDateString();
            if (this.compareDate(estimateCompletionDateVal, currentDate) == -1) {
              element.estimatedComplationError = true;
              this.createChangeorderValidation = false;
            }
          }
        }
      });
    }
    if (this.isAssignmentSelected == true) {
      this.createChangeorderValidation = false;
    }
  }

  enableAllValidationFlag() {

  }

  clearValidationMessages() {
    this.isGrossMarginFilled = false;
    this.isEstimationDateFilled = false;
    this.isIncrementalFeesFilled = false;
    this.isChangeOrderNameFilled = false;
    this.isChangeorderTemplateSelected = false;
  }
  displaySystemAlert() {
    this.alertService.remove(new Alert());
    this.loadingService.stopLoading();
    this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
  }

  onChangeOrderAssinmentChange(item: ChangeOrderAssignment) {

    if (item.visible == true) {
      item.visible = false;
    } else {
      item.visible = true;
    }

    if (this.changeOrderAssignments != null) {
      this.changeOrderAssignments.forEach(element => {
        if (element.checked == false) {
          element.grossMargin = "";
          element.incrementalFees = "";

        }
      });
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getAssignmentsByEngId(engId: number, isEdit: boolean) {
    var engAssignments: EngagementAssignment[];
    this.changeOrderAssignments = [];
    this.engagementService.GetEngagementAssignmentsById(engId, isEdit)
      .subscribe(
        result => {
          engAssignments = result;
          if (engAssignments != null && engAssignments != undefined) {
            engAssignments.forEach(element => {
              this.changeOrderAssignmentModel = new ChangeOrderAssignment();
              this.changeOrderAssignmentModel.assignmentName = element.assignmentName;
              this.changeOrderAssignmentModel.assignmentCode = element.assignmentCode;
              this.changeOrderAssignmentModel.assignmentCodeId = element.assignmentCodeId;
              this.changeOrderAssignmentModel.estimatedCompletetionDate = "";
              this.changeOrderAssignmentModel.StartDateCtrlId = "EstimatedStartDate" + element.assignmentCodeId;
              // this.changeOrderAssignmentModel.incrementalFees = 0;
              // this.changeOrderAssignmentModel.grossMargin = 0;
              this.changeOrderAssignmentModel.checked = false;
              this.changeOrderAssignmentModel.visible = false;
              this.changeOrderAssignmentModel.estimatedComplationError = false;
              this.changeOrderAssignmentModel.grossMarginError = false;
              this.changeOrderAssignmentModel.incrementalFeesError = false;
              this.changeOrderAssignmentModel.estimatedComplationRequiredError = false;
              this.changeOrderAssignmentModel.grossMarginRequiredError = false;
              this.changeOrderAssignmentModel.incrementalFeesRequiredError = false;
              this.changeOrderAssignments.push(this.changeOrderAssignmentModel);
            });
          }

        });
  }

  private compareDate(date1: string, date2: string): number {
    // With Date object we can compare dates them using the >, <, <= or >=.
    // The ==, !=, ===, and !== operators require to use date.getTime(),
    // so we need to create a new instance of Date with 'new Date()'
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    // Check if the dates are equal
    const same = d1.getTime() === d2.getTime();
    if (same) {
      return 0;
    }

    // Check if the first is greater than second
    if (d1 > d2) {
      return 1;
    }

    // Check if the first is less than second
    if (d1 < d2) {
      return -1;
    }
  }

  getTeamMemberRoles() {
    return this.engagementService.GetTeamMemberRoles().subscribe(
      res => {
        this.teamMemberRoleList = res;
      },
      () => {
        this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
      });

  }
  getEngagementTeamMembers() {
    this.engagementService.GetEngagementTeamMembersById(this.engagementId, true).subscribe({
      next: result => {
        if (result.length > 0)
          this.engagementTeamMembersList = result
      }, error: err => {
        console.error("failed to load team members for engagement"), err;
      }
    })
  }

  addUpdateEPPMDToTeamMemberList() { // this method add eppmd from engagment to change order if user is added then mark this as eppmd team member
    let eppmdRoleId = this.teamMemberRoleList.find(element => element.typeValue == "Engagement Partner").id;
    let eppmdTeamMember = this.engagementTeamMembersList.find(element => element.teamMemberRole == eppmdRoleId);
    let alreadyTeamMember = this.changeOrderTeamMemberList.find(element => element.memberEmailId?.trim().toLocaleLowerCase() == eppmdTeamMember?.memberEmailId.trim().toLocaleLowerCase());
    if (alreadyTeamMember == undefined && eppmdTeamMember != null && eppmdTeamMember != undefined) { // eppmd  team member not found in change order team member
      this.newAssignTeamMember = {
        memberName: eppmdTeamMember.memberName,
        memberEmailId: eppmdTeamMember.memberEmailId,
        memeberContactNo: eppmdTeamMember.memeberContactNo ?? "",
        teamMemberRole: eppmdTeamMember.teamMemberRole,
        teamMemberPermissionId: eppmdTeamMember.teamMemberPermissionId ?? "",
        isKeyTeamMember: eppmdTeamMember.isKeyTeamMember == true ? true : false,
        keyTeamMemberRoleId: eppmdTeamMember.keyTeamMemberRoleId != undefined ? eppmdTeamMember.keyTeamMemberRoleId : '',
        description: eppmdTeamMember.description ?? ""
      };
      this.changeOrderTeamMemberList.push(this.newAssignTeamMember);
    }
    else { // update team mmber role to eppmd if alraeady found in change order team member
      for (let i = 0; i < this.changeOrderTeamMemberList.length; i++) {
        if (this.changeOrderTeamMemberList[i].memberEmailId?.trim().toLocaleLowerCase() == eppmdTeamMember?.memberEmailId?.trim().toLocaleLowerCase()) {
          this.changeOrderTeamMemberList[i].teamMemberRole = eppmdRoleId;
          break;
        }
      }
    }
  }


  onUpdateChangeOrderClicked() {
    this.assignValueChangeOrder();
    this.changeOrderService.updateChangeOrder(this.changeOrderModel).subscribe({
      next: (result: string) => {
        this.loadingService.startLoading();
        if (result.toLocaleLowerCase() === 'success') {
          this.router.navigate(['Interviews/' + this.changeOrderWorkspaceId]);
        } else {
          this.alertService.add('Unexpected response from server.', 'warning', null);
        }
        this.loadingService.stopLoading();
      },
      error: (error) => {
        this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        this.loadingService.stopLoading();
      }
    });
  }

  assignValueChangeOrder() {
    this.changeOrderAssignmentValues = [];
    this.changeOrderModel.changeOrderId = this.changeOrderId;
    this.changeOrderModel.templatePackageId = this.selectedChangeOrderTemplate;
    this.changeOrderModel.engagementId = this.engagementId;
    this.changeOrderModel.changeOrderName = this.ChangeOrderName;
    this.changeOrderModel.isFinancialUpdate = this.financialUpdate;
    this.changeOrderModel.createdBy = this.userObject.loggedInUserEmail;
    this.changeOrderModel.lastModifiedBy = this.userObject.loggedInUserEmail;
    if (!this.editMode){
    this.changeOrderModel.workspaceId = this.workspaceId;
    }else{
      this.changeOrderModel.workspaceId = this.changeOrderWorkspaceId;
    }
    // Added for team members 
    this.changeOrderModel.teamMembers = [];
    if (this.uiTeamMemberList != null && this.uiTeamMemberList.length > 0) {
      var teamMemberWorkspaceId  = "";
      if (!this.editMode){
        teamMemberWorkspaceId = this.workspaceId;
        }else{
          teamMemberWorkspaceId = this.changeOrderWorkspaceId;
        }
      var teamMberValueswithWorkSpace = [];
      this.uiTeamMemberList.forEach(element => {
        var teamMemberWorkSpace = new TeamMember();
        teamMemberWorkSpace = element;
        teamMemberWorkSpace.workspaceId = teamMemberWorkspaceId;
        teamMberValueswithWorkSpace.push(teamMemberWorkSpace);
      });
      // this.btnUpdateTeamBtnClick(false);
      for (let t = 0; t < this.uiTeamMemberList.length; t++) {
        let selectedPermissionId = 0;
        if ($('#drpTeamMemberPermissions_' + t + ' option:selected').val() != null) {
          selectedPermissionId = parseInt($('#drpTeamMemberPermissions_' + t + ' option:selected').val());
        }
        if (this.uiTeamMemberList[t].memberName == null || this.uiTeamMemberList[t].memberName == "" || this.uiTeamMemberList[t].memberName == undefined) {
          this.uiTeamMemberList[t].memberName = $('#UserHiddenProps_' + this.teamMemberRoleName + '_' + t + ' #hdnUserName').val();
          this.uiTeamMemberList[t].memberEmailId = $('#UserHiddenProps_' + this.teamMemberRoleName + '_' + t + ' #hdnUserEmail').val(),
            this.uiTeamMemberList[t].memeberContactNo = $('#UserHiddenProps_' + this.teamMemberRoleName + '_' + t + ' #hdnUserPhone').val(),
            this.uiTeamMemberList[t].teamMemberRole = this.isAudit ? this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.P) : $('#drpTeamMemberRole_' + t + ' option:selected').val(),
            this.uiTeamMemberList[t].teamMemberPermissionId = selectedPermissionId,
            this.uiTeamMemberList[t].isKeyTeamMember = this.isAudit ? this.uiTeamMemberList[t].isKeyTeamMember : false,
            this.uiTeamMemberList[t].keyTeamMemberRoleId = this.isAudit ? $('#drpKeyTeamMemberRole_' + t + ' option:selected').val() : '',
            this.uiTeamMemberList[t].description = (this.isAudit && this.getKeyTeamMemberRoleIdByCode(EnumKeyTeamMemberRoleCodes.OTH)) ? this.uiTeamMemberList[t].description : ''
        }


      }
      this.changeOrderModel.teamMembers = teamMberValueswithWorkSpace;
    }
    this.changeOrderModel.createdDate = this.editChangeOrderModel?.createdDate;
    this.changeOrderModel.status = "Update";
    if (this.changeOrderAssignments.length > 0)
      this.changeOrderAssignments.forEach(element => {
        if (element.checked == true) {
          this.changeOrderAssignmentModelValue = new ChangeOrderAssignmentModel();
          this.changeOrderAssignmentModelValue.assignmentCodeId = element.assignmentCodeId;
          this.changeOrderAssignmentModelValue.engagementId = this.engagementId;
          // changeOrderValue.estimateCompletionDate = element.estimatedCompletetionDate;
          this.changeOrderAssignmentModelValue.estimatedGrossMargin = Number(element.grossMargin);
          this.changeOrderAssignmentModelValue.incrementalFees = Number(element.incrementalFees);
          this.changeOrderAssignmentModelValue.createdBy = this.userObject.loggedInUserEmail;
          this.changeOrderAssignmentModelValue.estimateCompletionDate = $('.EstimatedStartDate' + element.assignmentCodeId).val();
          this.changeOrderAssignmentModelValue.createdBy = element.createdBy;
          if (element.createdDate != null && element.createdDate != "") {
            this.changeOrderAssignmentModelValue.createdDate = new Date(element.createdDate);
          }

          this.changeOrderAssignmentValues.push(this.changeOrderAssignmentModelValue);
        }
      });
    if (this.changeOrderAssignmentValues.length > 0) {
      this.changeOrderModel.ChangeOrderAssignments = [];
      this.changeOrderModel.ChangeOrderAssignments = this.changeOrderAssignmentValues;
    }
  }

  resetFileds() {
    this.uiTeamMemberList = [];
    this.selectedChangeOrderTemplate = "Select ...";
    if (this.changeOrderAssignments != null) {
      this.changeOrderAssignments.forEach(element => {
        if (element.checked == true) {
          element.grossMargin = "";
          element.incrementalFees = "";
          element.checked = false;
        }
      });
    }
  }


  createAndUpdatePermissionsModel(editedTeamMembers: TeamMember[], siteUniqueID: string) {
    // Get List of Groups based on Permission Drop down(GROUP By) //
    let siteMembersGroups = editedTeamMembers.reduce((r, a) => {
      r[a.teamMemberPermissionId] = [...r[a.teamMemberPermissionId] || [], a];
      return r;
    }, {});

    console.log("siteMembersGroups", siteMembersGroups);
    let onwwerList: TeamMember[] = [];
    let memberList: TeamMember[] = [];
    let siteGroupTypes = Object.keys(siteMembersGroups);

    siteGroupTypes.forEach(type => {
      if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site owner') {
        onwwerList = editedTeamMembers.filter(member =>
          Number(member.teamMemberPermissionId) === Number(type));
      } else if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site member') {
        memberList = editedTeamMembers.filter(member =>
          Number(member.teamMemberPermissionId) === Number(type));
      }
    })
    // Collect site onwer email List //
    let siteOwnerEmailIds: string[] = [];
    onwwerList.forEach(onwer => { siteOwnerEmailIds.push(onwer.memberEmailId); });

    // Collect site member email List //
    let siteMemberEmailIds: string[] = [];
    memberList.forEach(member => { siteMemberEmailIds.push(member.memberEmailId); });

    let EngagementSiteUsersUpdatePermissionModel: UpdatePermissionsModel = new UpdatePermissionsModel();
    let OwnerListEmailModel: UsersListEmailModel = new UsersListEmailModel();
    let MemberListEmailModel: UsersListEmailModel = new UsersListEmailModel();
    let updatePermissionsModelList: UpdatePermissionsModel[] = []

    OwnerListEmailModel.groupType = 'Owner';
    OwnerListEmailModel.groupUsersEmail = [];
    siteOwnerEmailIds.forEach(emailid => {
      let adUser: ADUser = new ADUser();
      adUser.firstName = '';
      adUser.lastName = '';
      adUser.userEmailId = emailid;
      OwnerListEmailModel.groupUsersEmail.push(adUser);
    })

    MemberListEmailModel.groupType = 'Member';
    MemberListEmailModel.groupUsersEmail = [];
    siteMemberEmailIds.forEach(emailid => {
      let adUser: ADUser = new ADUser();
      adUser.firstName = '';
      adUser.lastName = '';
      adUser.userEmailId = emailid;
      MemberListEmailModel.groupUsersEmail.push(adUser);
    })

    EngagementSiteUsersUpdatePermissionModel.siteType = "EngagementSite";
    EngagementSiteUsersUpdatePermissionModel.siteUniqueID = siteUniqueID;
    EngagementSiteUsersUpdatePermissionModel.usersListEmail.push(OwnerListEmailModel);
    EngagementSiteUsersUpdatePermissionModel.usersListEmail.push(MemberListEmailModel);
    updatePermissionsModelList.push(EngagementSiteUsersUpdatePermissionModel);
    //Add client users
    // updatePermissionsModelList.push(this.GenerateClientSiteUpdatePermissionModel());
    console.log('updatePermissionsModelList ==>', updatePermissionsModelList);
    //1. Final Call Call Active Directory API to update latest collaborationSite Member Ids
    this.UpdatePermissions(updatePermissionsModelList);
    //redirect call only for edit engagement site member modification only.
    // this.dataService.setRedirectedFromEditEngagement(true);
    this.loadingService.stopLoading();
    // this.unsavedAlertfeature_IsSaveChangesOrNextBtnClicked = true;
    // this.router.navigate(this.redirectUrl);
  }

  UpdatePermissions(updatePermissionsModeList: UpdatePermissionsModel[]) {
    let updatePermissionsModelData: UpdatePermissionsModelData = new UpdatePermissionsModelData();
    updatePermissionsModelData.updatePermissionsModel = updatePermissionsModeList;
    this.activedirectoryService.UpdatePermissions(updatePermissionsModelData).subscribe(
      result => {
        //this.resultText = result;
      },
      error => {
        this.loadingService.stopLoading();
        this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }

}
