import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { LogService } from '../../../services/log.service';
import { DataService } from 'src/app/services/data.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { ConveneApiLogModel, ConveneRequestTypes, RequestMethods } from 'src/app/model/ConveneIntegration/convene-api-log-model';
import { ConveneProject, ConveneProjectRequestModel } from 'src/app/model/ConveneIntegration/convene-projects';
import { ClosureService } from 'src/app/services/Integration/closure.service';
import { EngagementDialogTitles } from 'src/app/model/Engagement/Engagements';
import { QuestionnaireDialogTitles, WorkspaceInterviewsStatus } from 'src/app/model/WorkspaceModel';
import { ClientService } from 'src/app/services/clientservice';
import { ClientExistDialogTitles, ClientHeaderModel, ClientValidationFields, OneViewSearchFieldClientModel, SearchClientbyFieldRequestModel } from 'src/app/model/Client/CreateClientViewModel';
declare var $;

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css']
})
export class MessageDialogComponent implements OnInit, OnChanges {  

  @Input() messageType: string; // success, warning, danger, information
  @Input() messageTitle: string;
  @Input() messageDescription: string;
  @Input() successRedirect: string;
  @Input() modalDisplay: boolean;
  @Input() modalButtons: number; // 1, 2, 3
  @Output() okClick: EventEmitter<string> = new EventEmitter<string>();
  isHidden: boolean;
  @Output() cancelClick: EventEmitter<string> = new EventEmitter<string>();
  @Input() IsDeclineModal: boolean;
  @Input() IsAbandonModal: boolean;
  @Input() IsCloseEngagementModal: boolean;
  @Input() IsBigDialogTitle: boolean;
  @Input() engagementId: string;
  @Input() loggedInUserEmail: string; 
  @Input() isContinuanceEngagementExists: boolean;
  @Input() continuanceDashBoardUrl: string;
  @Input() isAdministrativeClose: boolean;
  isConveneProjectsExists: boolean = false;
  messageInput: string = '';
  IsDialogTypeReview: boolean;
  conveneProjectList: ConveneProject[] = [];  
  @Input() IsVoidEngagementModal: boolean = false;
  @Input() isUpdateQuestionnaireExecutionPhase: boolean = false;
  @Input() searchClientReqModel: SearchClientbyFieldRequestModel
  matchingClientList: OneViewSearchFieldClientModel[] = [];
  clientHeaderModel: ClientHeaderModel = new ClientHeaderModel('');
  selectedAccountId: string ='';
  selectedClientCode: string ='';
  iWantToUnsavedMessageTitle: string = EngagementDialogTitles.UnsavedChangesConfirmationIWantTo;
  unsavedMessageTtitle: string = EngagementDialogTitles.UnsavedChangesConfirmation;
  workspaceInterviewsStatus=WorkspaceInterviewsStatus.ReviewStatus;
  @Input() isdialogNotYetAvailableClient: boolean;

  public get QuestionnaireDialogTitles(): typeof QuestionnaireDialogTitles {
    return QuestionnaireDialogTitles; 
  }
  @Output() closeClick: EventEmitter<string> = new EventEmitter<string>();

  constructor(private router: Router, private logService: LogService,
    private dataService: DataService, private loadingScreenService: LoadingScreenService
    ,private closureService: ClosureService, private clientService: ClientService) {
    this.isHidden = false;
  }

  ngOnInit() {
    console.log('ngOnInit of Message Dialog ', this.modalDisplay);
    this.isHidden = this.modalDisplay;
    this.searchClientReqModel = undefined;
    this.selectedAccountId = '';
    this.selectedClientCode = '';
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(' change engagementId ==> ', this.engagementId);    
    console.log(' change searchClientReqModel ==> ', this.searchClientReqModel);
    console.log(' change loggedInUserEmail ==> ', this.loggedInUserEmail);
    console.log(' change isAdministrativeClose ==> ', this.isAdministrativeClose);
    this.isConveneProjectsExists = false;
    if (Number(this.engagementId) > 0) {
      this.GetConveneProjects();
    }   
    
    if (this.searchClientReqModel != undefined) {
      this.GetMatchingClientList();
    }

    for (const propName in changes) {
      // console.log('ngOnChange ', propName);
      if (propName === 'modalDisplay') {
        const change = changes[propName];
        let curVal = JSON.stringify(change.currentValue);
        const prevVal = JSON.stringify(change.previousValue);
        // console.log('ngOnChange func called in Message Dialog Component', curVal, prevVal);
        if (curVal) {
          curVal = curVal.replace(/['"]+/g, '');
          if (curVal !== prevVal) {
            this.isHidden = (/true/i).test(curVal);
          }
        }
      }
    }
  }

  GetConveneProjects() {
    this.loadingScreenService.startLoading();
    let conveneApiLogModel: ConveneApiLogModel = new ConveneApiLogModel();
    let conveneProjectRequestModel: ConveneProjectRequestModel = new ConveneProjectRequestModel();    
    conveneProjectRequestModel.engageEngagementId = Number(this.engagementId);//this.engagementId//2439
    conveneProjectRequestModel.initiatedBy = this.loggedInUserEmail;

    this.closureService.GetProjects(conveneProjectRequestModel).subscribe(conveneProjectViewModel => {
      conveneApiLogModel.requestPayload = JSON.stringify(conveneProjectViewModel.conveneProjectRequest);
      conveneApiLogModel.responseBody = JSON.stringify(conveneProjectViewModel.conveneProjectResponse);
      this.conveneProjectList = conveneProjectViewModel.conveneProjectResponse.data;
      if (this.conveneProjectList != null)
      {
        this.isConveneProjectsExists = true;       
      }
      this.dataService.setConveneProjectDetails(this.isConveneProjectsExists);
      
      conveneApiLogModel.engagementId = Number(this.engagementId);
      conveneApiLogModel.httpMethod = RequestMethods.RequestHttpGet;
      conveneApiLogModel.requestType = ConveneRequestTypes.GetConveneProjects;
      conveneApiLogModel.loggedInUser = this.loggedInUserEmail;
      conveneApiLogModel.status = conveneProjectViewModel.conveneProjectResponse.status;
      conveneApiLogModel.statusCode = conveneProjectViewModel.conveneProjectResponse.statusCode;
      conveneApiLogModel.statusDescription = conveneProjectViewModel.conveneProjectResponse.errorMessage;

      console.log("conveneApiLogModel ==>", conveneApiLogModel);
      this.loadingScreenService.startLoading();
      this.closureService.SaveClosureNotificationResponse(conveneApiLogModel).subscribe(affectedRows => {
        if (affectedRows > 0) {
          //success
          console.log("affectedRows ==>", affectedRows);          
        }
        this.loadingScreenService.stopLoading();
      },
        err => {
          this.loadingScreenService.stopLoading();
          console.error(err);
        });
    },
      (err) => {
        conveneApiLogModel.requestPayload = JSON.stringify(conveneProjectRequestModel);
        conveneApiLogModel.responseBody = JSON.stringify(err);
        conveneApiLogModel.engagementId = Number(this.engagementId);
        conveneApiLogModel.httpMethod = RequestMethods.RequestHttpGet;
        conveneApiLogModel.requestType = ConveneRequestTypes.GetConveneProjects;
        conveneApiLogModel.isSentSuccessfully = false;
        conveneApiLogModel.loggedInUser = this.loggedInUserEmail;
        conveneApiLogModel.status = false;
        conveneApiLogModel.statusCode = '400';
        conveneApiLogModel.statusDescription = err;
        this.loadingScreenService.startLoading();
        this.closureService.SaveClosureNotificationResponse(conveneApiLogModel).
        subscribe(affectedRows => {
          if (affectedRows > 0) {
            //success
          }
          this.loadingScreenService.stopLoading();
        },
          (errorDetails) => {
            this.loadingScreenService.stopLoading();
            console.log(err);
          });
      });
  }

 fillFieldHeader(clientFieldName: string) {
  switch (clientFieldName) {
    case ClientValidationFields.PhoneNumber:
      this.clientHeaderModel  = new ClientHeaderModel('Phone number');
      break;
    case ClientValidationFields.WebSite:
      this.clientHeaderModel  = new ClientHeaderModel('Website');
      break;
    case ClientValidationFields.BusinessContactEmail:
      this.clientHeaderModel  = new ClientHeaderModel('Business contact email');
      break;        
    case ClientValidationFields.BillingContactEmail:
      this.clientHeaderModel  = new ClientHeaderModel('Billing contact email');
      break;
    case ClientValidationFields.TickerSymbol:
        this.clientHeaderModel  = new ClientHeaderModel('Ticker symbol');
        break; 
    case ClientValidationFields.Address:
      this.clientHeaderModel  = new ClientHeaderModel('Address 1');
      break;
    case ClientValidationFields.ClientName:
      this.clientHeaderModel = new ClientHeaderModel('Client name');
      break;     
    default:
      this.clientHeaderModel  = new ClientHeaderModel('Header1');
      break;
  }
 }

  GetMatchingClientList() 
  {
    if (this.searchClientReqModel.clientFieldName != '' && this.searchClientReqModel.clientFieldName.length > 0) {
      this.loadingScreenService.startLoading();
      this.clientService.GetClientsBySearchTextField(this.searchClientReqModel).subscribe(result => {
        this.matchingClientList = result.response;
        console.log('Pop up matchingClientList ==>', this.matchingClientList);
        this.fillFieldHeader(this.matchingClientList[0].clientFieldName);
        this.loadingScreenService.stopLoading();
      },
        (error) => {
          this.loadingScreenService.stopLoading();
          console.log('Error while getting response GetClientsBySearchTextField func ', error);
        });
    }
    
  }
  
  closeDialog() {
    console.log('closeDialog button called');
    let result : any;
    if(this.messageTitle === EngagementDialogTitles.CloseEng){      
      result = { buttonName: 'Cancel', dialogTitle: this.messageTitle };
      this.closeClick.emit(JSON.stringify(result));
      this.hideModal(result.dialogTitle, result.buttonName);
    } else{
      result = { buttonName: 'close', dialogTitle: this.messageTitle };
      this.closeClick.emit(JSON.stringify(result));
      this.hideModal();
    }
    
  }

  navigateUrl() {
    const result = { buttonName: 'ok', declineReason: this.messageInput};
    this.okClick.emit(JSON.stringify(result));
    if (this.successRedirect !== '') {
      this.router.navigate([this.successRedirect]);
    }
    this.hideModal();
  }
  
  // navigateClientUrl(_selectedAccountId: string, _selectedClientCode: string) {
  //   const result = { buttonName: 'ok', selectedAccountId: _selectedAccountId, selectedClientCode: _selectedClientCode };
  //   this.okClick.emit(JSON.stringify(result));
    
  //   this.hideModal();
  // }

  clickOnSelectedClient(accountid: string, accountnumber: string) {
    this.selectedAccountId = accountid;
    this.selectedClientCode = accountnumber;
    console.log('selectedAccountId =>',this.selectedAccountId);
    console.log('selectedClientCode =>',this.selectedClientCode);
  }


  Ok() {
    if (this.IsDeclineModal || this.IsVoidEngagementModal) {
      const declineInput = 'msg-dialog-declinedValidationText';
      if (this.messageInput !== 'undefined' && this.messageInput !== null) {
        this.messageInput = this.messageInput.trim();
        if (this.messageInput.length === 0) {
          $('#' + declineInput).removeClass('d-none');
          $('#' + declineInput).addClass('d-block');
        } else {
          $('#' + declineInput).removeClass('d-block');
          $('#' + declineInput).addClass('d-none');
          this.navigateUrl();
        }
      } else { // messageInput = null
        $('#' + declineInput).removeClass('d-none');
        $('#' + declineInput).addClass('d-block');
      }
    } else { // Other than decline
      if (this.messageTitle === EngagementDialogTitles.EngagementClosed || this.messageTitle === EngagementDialogTitles.UpdateInProgress) {
        window.location.reload();
        this.navigateUrl();
      } else if ((this.messageTitle === ClientExistDialogTitles.TickerSymbol ||
        this.messageTitle === ClientExistDialogTitles.PhoneNumber ||
        this.messageTitle === ClientExistDialogTitles.Address1 ||
        this.messageTitle === ClientExistDialogTitles.WebSite ||
        this.messageTitle === ClientExistDialogTitles.BillingContactEmail ||        
        this.messageTitle === ClientExistDialogTitles.BusinessContactEmail ||
        this.messageTitle === ClientExistDialogTitles.ClientName) 
      ) {
        if (this.modalButtons == 4) {
          //let _selectedAccountId: string;
          // if (this.selectedClientCode == '' ||
          //   this.selectedClientCode == undefined ||
          //   this.selectedClientCode.length == 0) {
          // //  this.successRedirect = '';
          //  // _selectedAccountId = this.selectedAccountId;
          //   this.selectedClientCode = '';
          // }
          $('#msg-dialog-oneviewAccount-notselected').addClass('d-none');
          $('#msg-dialog-oneviewAccount-notselected').removeClass('d-block');
          $('#msg-dialog-oneviewAccount-closedclient').addClass('d-none');
          $('#msg-dialog-oneviewAccount-closedclient').removeClass('d-block');

          const result = { buttonName: 'ok', selectedAccountId: this.selectedAccountId, selectedClientCode: this.selectedClientCode };
          if (this.selectedAccountId == '') {
            $('#msg-dialog-oneviewAccount-notselected').removeClass('d-none');
            $('#msg-dialog-oneviewAccount-notselected').addClass('d-block');
          } else if (this.selectedClientCode != '') {
            this.HandleSelectedOneViewAccountWithClientCode(this.selectedClientCode);
          } else {
            this.okClick.emit(JSON.stringify(result));
            $('#msg-dialog-oneviewAccount-notselected').addClass('d-none');
            $('#msg-dialog-oneviewAccount-notselected').removeClass('d-block');
            $('#msg-dialog-oneviewAccount-closedclient').addClass('d-none');
            $('#msg-dialog-oneviewAccount-closedclient').removeClass('d-block');
            this.hideModal();
          }
         // this.navigateClientUrl(this.selectedAccountId,this.selectedClientCode);
        }
        else {
          this.Skip();
        }      
      }
      
      else {
        this.navigateUrl();
      }
    }
  }

  HandleSelectedOneViewAccountWithClientCode(clientCode: string) {
    this.loadingScreenService.startLoading();
    this.clientService.GetClientDetailsByClientCode(clientCode).subscribe(result => {
      this.loadingScreenService.stopLoading();
      let engageClient = result;
      if (engageClient?.clientCode != null && engageClient?.clientCode == clientCode) {
        if (engageClient.clientStatus === 'Closed') {
          $('#msg-dialog-oneviewAccount-closedclient').text('Client is closed. Submit a Centralized Billing Share request to re-open the client code before creating a new client.');
          $('#msg-dialog-oneviewAccount-closedclient').removeClass('d-none');
          $('#msg-dialog-oneviewAccount-closedclient').addClass('d-block');
        } else {
          const data = { buttonName: 'ok', selectedAccountId: this.selectedAccountId, selectedClientCode: this.selectedClientCode };
          this.okClick.emit(JSON.stringify(data));
          $('#msg-dialog-oneviewAccount-notselected').addClass('d-none');
          $('#msg-dialog-oneviewAccount-notselected').removeClass('d-block');
          $('#msg-dialog-oneviewAccount-closedclient').addClass('d-none');
          $('#msg-dialog-oneviewAccount-closedclient').removeClass('d-block');
          this.hideModal();
        }
      } else {
        $('#msg-dialog-oneviewAccount-closedclient').text('The client code for this client is listed in oneView but does not appear in Engage. Please contact support to have this issue resolved.');
        $('#msg-dialog-oneviewAccount-closedclient').removeClass('d-none');
        $('#msg-dialog-oneviewAccount-closedclient').addClass('d-block');
    }
    }
    );
  }

  Cancel() {
    console.log('Cancel button called');
    this.cancelClick.emit('Cancel');
    let title: string ='';;
    let buttonName: string ='';
    if(this.messageTitle === EngagementDialogTitles.ReEditQuestionnaire){
      this.successRedirect = '';
    }
    if (this.successRedirect !== '') {
      this.router.navigate([this.successRedirect]);
    }
    if(this.messageTitle === EngagementDialogTitles.CloseEng){
      title = this.messageTitle;
      buttonName ='Cancel';
    }   
    this.hideModal(title, buttonName);
  }  
  
  Skip() {
    console.log('Skip button called');
    this.cancelClick.emit('Cancel'); 
    this.hideModal();
  }

  hideModal(title: string = '', buttonName: string= '') {
    this.messageInput = null;
    this.conveneProjectList = null;
    this.engagementId = "0";
    this.isConveneProjectsExists = false;
    this.loggedInUserEmail = '';
    this.isAdministrativeClose = false;
    this.searchClientReqModel = undefined;
    this.selectedAccountId = '';
    this.selectedClientCode = '';
    this.matchingClientList = [];

    const modalObj = {
      dialogType: '',
      dialogTitle: '',
      dialogDescription: '',
      dialogRedirectUrl: '',
      dialogButton: 0,
      modalDisplay: false,
      declineReason: '',
      selectAccountId: ''
    };
    const modalJSON = JSON.stringify(modalObj);
    this.dataService.displayDialogNotification(modalJSON);
    const modalData = {
      dialogTitle: title,
      dialogButtonName: buttonName,
      dialogInput: ''
    };
    const dialogJSON = JSON.stringify(modalData);
    this.dataService.setDialogDetails(dialogJSON);
  }
}
