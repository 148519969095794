import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConfigService } from './appConfigService';
import { AuthService } from './auth.service';
import { TeamMembers, TeamMemberEPModel, ManualRPAddModel, WorkspaceMemberRemoveModel, AutoRPReplaceModel } from '../model/Workspace/TeamMembers';
import { WorkspaceDiscussion } from '../model/Workspace/WorkspaceDiscussion';
import { WorkspaceComments } from '../model/Workspace/WorkspaceComments';
import { WorkspaceSupplementalDoc } from '../model/Workspace/WorkspaceSupplementalDoc';
import { SupplementalDocCategory } from '../model/Workspace/SupplementalDocCategory';
import { User } from '../model/User';
import { WorkspaceModel, NewWorkspaceData, ResetWorkspaceDataModel, SecondaryWorkspaceDataListModel, RemoveSecondaryWorkspaceModel, HotDocsResponse } from '../model/WorkspaceModel';
import { WorkspaceDocumentModel, HdWorkspaceDocumentModel } from '../model/WorkspaceDocumentModel';
import { RPNotificationModel, WorkspaceStatusUpdateModel } from '../model/WorkspaceStatusUpdateModel';
import { WorkspaceApprovalRejectionDetails } from 'src/app/model/WorkspaceApprovalRejectionDetails'
import { QuestionAnswer } from '../model/Workspace/QuestionAnswer';
import { AssignmentRequest } from '../model/Workspace/AssignmentRequest';
import { AssignmentResponseList } from '../model/Workspace/AssignmentResponse';
import { CollaborationSiteViewModel, CollaborationSiteMembers, RemoveIndusCollaborationSiteMember } from 'src/app/model/CollaborationSite';
import { UpdateStatusAddHDAnsToEngage } from '../model/HotDocsVariableMapping';
import { DecisionsRPListModel } from '../model/Workspace/DecisionsRPListModel';
import { DecisionsNotificationProfessionalsListModel } from '../model/Workspace/DecisionsNotificationProfessionalsListModel';
import { SummaryReport } from '../model/SummaryReport/SummaryReport';
import { ReviewResponseModel } from '../model/Workspace/ReviewResponseModel';
import { ErrorLog } from '../model/Workspace/ErrorLog';
import { ApproveRejectWorkspaceResultModel, AutoRpMembersWithDelegateModel } from '../model/Workspace/DecisionsRPRequestModel';
import { QnARecordForDelete } from '../model/Workspace/QnARecordForDelete';
import { CloseoutChecklistWorkspaceModel } from '../model/Workspace/CloseoutChecklistWorkspaceModel';
import { CloseoutResponseModel } from '../model/Workspace/CloseoutResponseModel';
import { AuditPartnerDetailsForEngagementModel, AuditPartnerDetailsModel, RemoveAuditPartnerModel } from '../model/Workspace/ap-hotdocs-answer-and-count.model';
import { AuditPartnerModel, AuditPartnerStatusModel, WorkspaceAuditPartner } from '../model/Workspace/audit-partner.model';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {
  APIEndPoint: string;

  workspaceDocumentData: WorkspaceDocumentModel = new WorkspaceDocumentModel();
  user: User;
  userName: string;
  userInfo: any;
  userFullName: string;
  sharepointSiteURL: string;
  workspaceStatusUpdateModel: WorkspaceStatusUpdateModel = new WorkspaceStatusUpdateModel();

  constructor(environment: AppConfigService, private http: HttpClient, private authService: AuthService) {
    this.APIEndPoint = environment.config.DATA.API_END_POINT.API_URL;
    this.getAuthUser();
    const nameJSON = JSON.parse(localStorage.getItem('currentUser'));
    const jsonString = localStorage.getItem('currentUser');
    this.userInfo = JSON.parse(jsonString);
    if (nameJSON != null) {
      this.userName = nameJSON.userName;
      this.userFullName = nameJSON.loggedInUserFullName;
    }
  }

  getReviewers(workspaceId: string): Observable<TeamMembers[]> {
    return this.http.get<TeamMembers[]>(
      this.APIEndPoint + '/Workspace/reviewersV2/' + workspaceId
    );
  }

  getCollabrationTeamMembers(workspaceId: number): Observable<CollaborationSiteMembers[]> {
    return this.http.get<CollaborationSiteMembers[]>(
      this.APIEndPoint + '/Workspace/collabrationTeamMembers/' + workspaceId
    );
  }

  getTeamMembers(workspaceId: string): Observable<TeamMemberEPModel> {
    return this.http.get<TeamMemberEPModel>(
      this.APIEndPoint + '/Workspace/teamMembers/' + workspaceId
    );
  }

  getMembers(workspaceId: string): Observable<TeamMembers[]> {
    return this.http.get<TeamMembers[]>(
      this.APIEndPoint + '/Workspace/membersV2/' + workspaceId
    );
  }

  GetWorkspaceDiscussions(workspaceId: string): Observable<WorkspaceDiscussion[]> {
    return this.http.get<WorkspaceDiscussion[]>(
      this.APIEndPoint + '/Workspace/discussions/' + workspaceId
    );
  }

  AddWorkspaceDiscussion(workspaceDiscussion: WorkspaceDiscussion) {
    const httpHeaders = new HttpHeaders().set(
      "Content-Type",
      "application/json"
    );
    const options = {
      headers: httpHeaders
    };
    workspaceDiscussion.userName = this.user.userName;
    workspaceDiscussion.createdby = this.user.userName;
    workspaceDiscussion.loggedInUserFullName = this.user.loggedInUserFullName;
    return this.http.post<WorkspaceDiscussion>(
      this.APIEndPoint + '/Workspace/addDiscussion',
      workspaceDiscussion,
      options
    );
  }
  AddWorkspaceComment(workspaceComments: WorkspaceComments, comment: string) {
    const httpHeaders = new HttpHeaders().set(
      "Content-Type",
      "application/json"
    );
    const options = {
      headers: httpHeaders
    };
    workspaceComments.description = comment;
    workspaceComments.userName = this.user.userName;
    workspaceComments.createdby = this.user.userName;


    return this.http.post<WorkspaceComments>(
      this.APIEndPoint + "/Workspace/addComment",
      workspaceComments,
      options
    );
  }
  getAuthUser() {
    console.log('WorkspaceService - User Info before repopulate - ', this.user);
    this.user = this.authService.getUserInfo();
    console.log('WorkspaceService - User Info after repopulate - ', this.user);
  }
  GetSupplementalDocCategories(): Observable<SupplementalDocCategory[]> {
    return this.http.get<SupplementalDocCategory[]>(
      this.APIEndPoint + '/Workspace/supplementalDocCategories'
    );
  }
  uploadFile(file: File): Observable<object> {
    // create multipart form for file
    let formData: FormData = new FormData();
    formData.append('fileInput', file, file.name);

    const headers = new HttpHeaders().append('Content-Type', 'mulipart/form-data');

    // POST
    return this.http
      .post(this.APIEndPoint + '/Workspace/UploadFile', { formData, }, { headers });
  }

  postFile(fileName: string, selectedCategory: string, workspaceId: string,
    workspaceSupplementalDoc: WorkspaceSupplementalDoc) {
    const endpoint = this.APIEndPoint + '/Workspace/UploadFile';
    // .set('accept', 'application/json');
    workspaceSupplementalDoc.userName = this.user.loggedInUserEmail;
    workspaceSupplementalDoc.workspaceId = workspaceId;
    workspaceSupplementalDoc.createdby = this.user.loggedInUserFullName;
    workspaceSupplementalDoc.updatedBy = this.user.loggedInUserFullName;
    workspaceSupplementalDoc.categoryId = selectedCategory;
    workspaceSupplementalDoc.sourceFileName = fileName;
    // TODO: file form data need to be passed to api.

    return this.http
      .post<WorkspaceSupplementalDoc>(endpoint, workspaceSupplementalDoc);
  }

  addUpdateWorkspace(newWorkspaceData: NewWorkspaceData): Observable<WorkspaceModel> {
    newWorkspaceData.workspace.userName = this.user.userName;
    for (var i = 0; i < newWorkspaceData.teamMember.length; i++) {
      newWorkspaceData.teamMember[i].updatedBy = this.user.userName;
      newWorkspaceData.teamMember[i].createdby = this.user.userName;
    }
    newWorkspaceData.workspace.createdby = this.user.userName;
    newWorkspaceData.workspace.loggedInUserFullName = this.user.loggedInUserFullName;
    let FetchUrl: string = this.APIEndPoint + "/Workspace/AddUpdateWorksapceData";
    return this.http.post<WorkspaceModel>(FetchUrl, newWorkspaceData, { responseType: 'json' });
  }

  addWorkspaceDocument(workspaceDocument: HdWorkspaceDocumentModel): Observable<WorkspaceDocumentModel> {
    var workspaceDocumentData = new WorkspaceDocumentModel();
    workspaceDocumentData.workspaceId = workspaceDocument.workspaceId;
    workspaceDocumentData.hdAssemblySession = workspaceDocument.assemblySessionId;
    workspaceDocumentData.hdDocumentId = workspaceDocument.documentId;
    workspaceDocumentData.hdDocumentName = workspaceDocument.name;
    workspaceDocumentData.hdMimeType = workspaceDocument.mimeType;
    workspaceDocumentData.isActive = true;
    workspaceDocumentData.createdBy = this.user.userName;
    let FetchUrl: string = this.APIEndPoint + "/Workspace/AddWorkspaceDocument";
    return this.http.post<WorkspaceDocumentModel>(FetchUrl, workspaceDocumentData);
  }

  UpdateWorkspaceStatus(workspace: WorkspaceModel) {
    workspace.updatedBy = this.user.userName;
    workspace.createdby = this.user.userName;
    workspace.loggedInUserFullName = this.user.loggedInUserFullName;
    workspace.loggedInUserEmail = this.user.loggedInUserEmail;
    let FetchUrl: string = this.APIEndPoint + "/Workspace/UpdateWorkspaceStatus";
    return this.http.post<boolean>(FetchUrl, workspace);
  }

  /*
  ReviewWorkspace(workspace: WorkspaceModel) {
    workspace.updatedBy = this.userName;
    workspace.createdby = this.userName;
    workspace.loggedInUserFullName = this.userFullName;
    workspace.loggedInUserEmail = this.user.loggedInUserEmail;
    let FetchUrl: string = this.APIEndPoint + "/Workspace/ReviewWorkspace";
    return this.http.post<string>(FetchUrl, workspace);
  }
  */

  getWorkspaceDocumentById(workspaceId: string): Observable<WorkspaceDocumentModel[]> {
    return this.http.get<WorkspaceDocumentModel[]>(this.APIEndPoint + "/Workspace/GetWorkspaceDocument/" + workspaceId);
  }

  getMaxWorkspaceId(): Observable<number> {
    return this.http.get<number>(this.APIEndPoint + "/Workspace/GetMaxWorkspaceCode");
  }

  getWorkspaceDetails(workspaceId: string): Observable<WorkspaceModel> {
    return this.http.get<WorkspaceModel>(
      this.APIEndPoint + '/Workspace/GetWorkspaceDetailsById/' + workspaceId
    );
  }

  addWorkspaceMember(manualRPAddModel: ManualRPAddModel) {
    const httpHeaders = new HttpHeaders().set(
      'Content-Type',
      'application/json'
    );
    const options = {
      headers: httpHeaders
    };
    manualRPAddModel.member.createdBy = this.user.userName;
    manualRPAddModel.member.updatedBy = this.user.userName;
    manualRPAddModel.member.loggedInUserFullName = this.user.loggedInUserFullName;
    return this.http.post<TeamMembers>(
      this.APIEndPoint + '/Workspace/add-member',
      manualRPAddModel,
      options
    );
  }

  replaceWorkspaceMember(autoRPReplaceModel: AutoRPReplaceModel) {
    const httpHeaders = new HttpHeaders().set(
      'Content-Type',
      'application/json'
    );
    const options = {
      headers: httpHeaders
    };
    autoRPReplaceModel.member.createdBy = this.user.userName;
    autoRPReplaceModel.member.updatedBy = this.user.userName;
    autoRPReplaceModel.member.loggedInUserFullName = this.user.loggedInUserFullName;
    return this.http.post<TeamMembers>(
      this.APIEndPoint + '/Workspace/replace-member',
      autoRPReplaceModel,
      options
    );
  }
  addWorkspaceTeamMember(member: TeamMembers) {
    const httpHeaders = new HttpHeaders().set(
      'Content-Type',
      'application/json'
    );
    const options = {
      headers: httpHeaders
    };
    member.createdBy = this.user.userName;
    member.updatedBy = this.user.userName;
    member.loggedInUserFullName = this.user.loggedInUserFullName;
    return this.http.post<TeamMembers>(
      this.APIEndPoint + '/Workspace/add-teamMember',
      member,
      options
    );
  }
  getWorkspaceById(workspaceId: string): Observable<WorkspaceModel> {
    return this.http.get<WorkspaceModel>(this.APIEndPoint + "/Workspace/GetWorkspaceData/" + workspaceId);
  }

  getSecondaryWorkspaceDataByEngId(engagementId: number, workspaceId: string): Observable<SecondaryWorkspaceDataListModel> {
    return this.http.get<SecondaryWorkspaceDataListModel>(this.APIEndPoint + "/Workspace/GetSecondaryWorkspaceDataByEngId/" + engagementId+ "/" + workspaceId);
  }

  removeMember(workspaceMemberRemoveModel: WorkspaceMemberRemoveModel) {

    return this.http.post(
      this.APIEndPoint + '/Workspace/remove-member', workspaceMemberRemoveModel
    );
  }

  getPrimaryWorkspaceForEng(engagementId: string, clientId: string): Observable<WorkspaceModel> {
    var workspaceDetail = new WorkspaceModel();
    workspaceDetail.engagementId = Number(engagementId);
    workspaceDetail.clientId = clientId;
    return this.http.post<WorkspaceModel>(this.APIEndPoint + "/Workspace/GetPrimaryWorkspaceData/", workspaceDetail);
  }
  checkCurrentLoggedinUserIsCreatedBy(createdBy: string) {
    if (this.user.userName == createdBy) {
      return true;
    }
    else {
      return false;
    }
  }

  getAllMembers(workspaceId: string): Observable<TeamMembers[]> {
    return this.http.get<TeamMembers[]>(
      this.APIEndPoint + '/Workspace/GetAllmembersV2/' + workspaceId
    );
  }

  UpdateWorkspaceStatusData(workspaceStatusUpdateModeldata) {
    this.workspaceStatusUpdateModel = workspaceStatusUpdateModeldata;
    let shortRoleName = '';
    // for (let i = 0, l = this.userInfo.role.length; i < l; i++) {
    //   shortRoleName = shortRoleName + this.userInfo.role[i].shortRoleName + ',';
    // }
    this.workspaceStatusUpdateModel.loggedInUser = this.user.userName;
    // this.workspaceStatusUpdateModel.roleShortName = shortRoleName.trim();
    return this.http.post<ApproveRejectWorkspaceResultModel>(this.APIEndPoint + '/Workspace/ApproveRejectWorkspace', this.workspaceStatusUpdateModel);
  }

  // IsApproveRejectButtonVisibleForMe(workspaceStatusUpdateModeldata): Observable<WorkspaceApprovalRejectionDetails>{
  //   this.workspaceStatusUpdateModel = workspaceStatusUpdateModeldata;
  //   this.workspaceStatusUpdateModel.loggedInUser = this.userName;
  //   if(this.userInfo.role.filter(e => e.shortRoleName === 'EP').length = 1)
  //   {
  //     this.workspaceStatusUpdateModel.roleShortName = RoleNames.EngagementPartner;
  //   }
  //   else if(this.userInfo.role.filter(e => e.shortRoleName === 'RP').length = 1)
  //   {
  //     this.workspaceStatusUpdateModel.roleShortName = RoleNames.ReviewingProfessional;
  //   }
  //   else if(this.userInfo.role.filter(e => e.shortRoleName === 'SM').length = 1){
  //     this.workspaceStatusUpdateModel.roleShortName = RoleNames.StaffMember
  //   }
  //   return this.http.post<WorkspaceApprovalRejectionDetails>(this.APIEndPoint + '/Workspace/IsApproveRejectButtonVisibleForMe' , this.workspaceStatusUpdateModel);

  //   // console.log('Service called');
  //   // var i=0;
  //   // var firstRole;
  //   // do {
  //   //   if(this.userInfo.role[i].shortRoleName == RoleNames.EngagementPartner || this.userInfo.role[i].shortRoleName == RoleNames.ReviewingProfessional)
  //   //   {
  //   //     this.workspaceStatusUpdateModel.loggedInUser = this.userName;
  //   //     this.workspaceStatusUpdateModel.roleShortName = this.userInfo.role[i].shortRoleName;
  //   //     firstRole = true;
  //   //   }
  //   //   if(firstRole === true){
  //   //     break;
  //   //   }
  //   //   i++;
  //   // }while (i < this.userInfo.role.length)
  //   // console.log('Call to API' , this.workspaceStatusUpdateModel);
  // }
  IsApproveRejectButtonVisibleForMe(workspaceStatusUpdateModeldata): Observable<WorkspaceApprovalRejectionDetails> {
    this.workspaceStatusUpdateModel = workspaceStatusUpdateModeldata;
    console.log('Service called');
    var shortRoleName = '';
    for (var i = 0, l = this.userInfo.role.length; i < l; i++) {
      shortRoleName = shortRoleName + this.userInfo.role[i].shortRoleName + ',';
    }
    this.workspaceStatusUpdateModel.loggedInUser = this.user.userName;
    this.workspaceStatusUpdateModel.roleShortName = shortRoleName.trim();

    console.log('Call to API', this.workspaceStatusUpdateModel);
    return this.http.post<WorkspaceApprovalRejectionDetails>(this.APIEndPoint + '/Workspace/IsApproveRejectButtonVisibleForMe', this.workspaceStatusUpdateModel);
  }

  IsLoggedInUserLastApprover(workspaceId: string, loggedInMemberEmailId: string): Observable<boolean> {
    return this.http.post<boolean>(this.APIEndPoint + '/Workspace/IsLoggedInUserLastApprover', { workspaceId: workspaceId, loggedInMemberEmailId: loggedInMemberEmailId });
  }

  updateWorkspaceReviewers(reviewer: TeamMembers) {

    reviewer.createdBy = this.user.userName;
    reviewer.updatedBy = this.user.userName;

    return this.http.post(this.APIEndPoint + '/Workspace/UpdateWorkspaceReviewers', reviewer, { responseType: 'text' });
  }
  updateTemplateVersionData(workspaceData: WorkspaceModel) {
    workspaceData.createdby = this.user.userName;
    workspaceData.updatedBy = this.user.userName;
    return this.http.post(this.APIEndPoint + '/Workspace/UpdateTemplateVersionData', workspaceData);
  }

  resetWorkspaceAprovalData(resetWorkspaceData: ResetWorkspaceDataModel) {
    resetWorkspaceData.updatedBy = this.user.userName;
    return this.http.post(this.APIEndPoint + '/Workspace/ResetWorkspaceAproval', resetWorkspaceData);
  }

  getQuestionAnswer(workspaceId: string): Observable<QuestionAnswer[]> {
    return this.http.get<QuestionAnswer[]>(this.APIEndPoint + '/Workspace/QuestionAnswer/' + workspaceId);
  }

  getQuestionAnswerProcessingStatus(workspaceId: string): Observable<string> {
    const requestOptions: Object = {
      responseType: 'text'
    }
    return this.http.get<string>(this.APIEndPoint + '/Workspace/QuestionAnswerProcessingStatus/' + workspaceId, requestOptions);
  }

  getPrimaryAssignment(reviewId: string, assignmentCodeId: number, assignmentName: string): Observable<AssignmentRequest[]> {
    return this.http.get<AssignmentRequest[]>(this.APIEndPoint + '/Assignment/PrimaryAssignment/'
      + reviewId + '/' + assignmentCodeId + '/' + assignmentName);
  }

  storeCMSLog(response: AssignmentResponseList): Observable<string> {
    return this.http.post<string>(this.APIEndPoint + '/Log/CMSLog/', response);
  }

  updateFirstAssignmentStatus(responseStatus: string, assignmentCodeId: number): Observable<string> {
    const requestOptions: object = {
      responseType: 'text'
    };
    return this.http.get<string>(this.APIEndPoint + '/Assignment/UpdateFirstAssignmentStatus/'
      + assignmentCodeId + '/' + responseStatus, requestOptions);
  }

  updateAssignmentStatus(responseStatus: string, engagementId: string): Observable<string> {
    return this.http.get<string>(this.APIEndPoint + '/Assignment/UpdateAssignmentStatus/'
      + engagementId + '/' + responseStatus);
  }

  GetWorkspaceCurrentStatus(workspaceId: string): Observable<string> {
    const requestOptions: Object = {
      responseType: 'text'
    }
    return this.http.get<string>(
      this.APIEndPoint + '/Workspace/GetWorkspaceCurrentStatus/' + workspaceId, requestOptions);
  }

  getWorkspaceByEngagementId(engagementId: number): Observable<WorkspaceModel> {
    return this.http.get<WorkspaceModel>(
      this.APIEndPoint + '/Workspace/GetWorkspaceByEngagementId/' + engagementId
    );
  }
  addCollaborationTeamMembers(collaborationSiteViewModel: CollaborationSiteViewModel) {
    return this.http.post(this.APIEndPoint + '/Workspace/addCollaborationTeamMembers', collaborationSiteViewModel, { responseType: 'text' });
  }
  updateCollaborationTeamMembers(collaborationSiteViewModel: CollaborationSiteViewModel) {
    return this.http.post(this.APIEndPoint + '/Workspace/updateCollaborationTeamMembers', collaborationSiteViewModel, { responseType: 'text' });
  }
  AddUpdateStatusHDAnsToEngage(addUpdateData: UpdateStatusAddHDAnsToEngage) {
    addUpdateData.workspace.createdby = this.user.loggedInUserEmail;
    addUpdateData.workspace.updatedBy = this.user.userName;
    addUpdateData.workspace.loggedInUserFullName = this.user.loggedInUserFullName;
    addUpdateData.workspace.loggedInUserEmail = this.user.loggedInUserEmail;
    const FetchUrl: string = this.APIEndPoint + '/Workspace/AddUpdateStatusHDAnsToEngage';
    return this.http.post<WorkspaceAuditPartner>(FetchUrl, addUpdateData);
  }

  AddUpdateStatusHDAnsToEngageFileCloseout(addUpdateData: UpdateStatusAddHDAnsToEngage) {
    addUpdateData.workspace.createdby = this.user.loggedInUserEmail;
    addUpdateData.workspace.updatedBy = this.user.userName;
    addUpdateData.workspace.loggedInUserFullName = this.user.loggedInUserFullName;
    addUpdateData.workspace.loggedInUserEmail = this.user.loggedInUserEmail;
    const FetchUrl: string = this.APIEndPoint + '/Workspace/AddUpdateStatusHDAnsToEngageFileCloseout';
    return this.http.post<boolean>(FetchUrl, addUpdateData);
  }

  SaveDecisionDetails(decisionsRPListModel: DecisionsRPListModel) {
    return this.http.post(this.APIEndPoint + '/Workspace/AddDecisionsAutoRPList', decisionsRPListModel);
  }

  HasSiteProvisionedForWorkspace(workspaceId: string) {
    return this.http.get<boolean>(this.APIEndPoint + '/Workspace/GetSiteProvisionStatusByWorkspaceId/' + workspaceId);
  }

  SendCMSRequestOnSignature(engagementId: number) {
    return this.http.get<boolean>(this.APIEndPoint + '/Workspace/SendCMSRequestOnSignature/' + engagementId);
  }

  CheckRequestSentForClient(workspaceId: string, assignmentCodeId: number, isAcceptanceRequest: number) {
    return this.http.get<number>(this.APIEndPoint + '/Workspace/CheckRequestSentForClient/'
      + workspaceId + '/' + assignmentCodeId + '/' + isAcceptanceRequest);
  }
  removeIndusMembers(indusMembers: TeamMemberEPModel) {
    return this.http.post(this.APIEndPoint + '/Workspace/RemoveIndusteamMembers', indusMembers);
  }
  removeIndusCollabMembers(indusMembers: RemoveIndusCollaborationSiteMember) {
    return this.http.post(this.APIEndPoint + '/Workspace/RemoveIndusCollabteamMembers', indusMembers);
  }
  ProcessCMSErrorRequest(workspaceId: string) {
    return this.http.post<boolean>(this.APIEndPoint + '/Workspace/ProcessCMSErrorRequest',
      { workspaceId: workspaceId, createdBy: this.user.loggedInUserEmail });
  }

  SaveNotificationsOnlyProfessionals(notificationOnlyProfessionals: DecisionsNotificationProfessionalsListModel): Observable<any> {
    return this.http.post(this.APIEndPoint + '/Workspace/AddNotificationOnlyProfessionals', notificationOnlyProfessionals);
  }

  GetEngageSummaryReport(workspaceId: string): Observable<SummaryReport> {
    return this.http.get<SummaryReport>(this.APIEndPoint + '/Workspace/EngageSummaryReport/'
      + workspaceId);
  }

  storeReviewResponseLog(response: ReviewResponseModel): Observable<string> {
    return this.http.post<string>(this.APIEndPoint + '/Log/ReviewResponseLog/', response);
  }

  storeErrorLog(errorMessage: string): Observable<string> {
    const log = new ErrorLog();
    log.message = errorMessage;
    return this.http.post<string>(this.APIEndPoint + '/Log/UIErrorLog/', log);
  }

  UpdateContinuanceAssignmentStatus(responseStatus: string, assignmentCodeId: number): Observable<string> {
    const requestOptions: object = {
      responseType: 'text'
    };
    return this.http.get<string>(this.APIEndPoint + '/Assignment/UpdateContinuanceAssignmentStatus/'
      + assignmentCodeId + '/' + responseStatus, requestOptions);
  }

  ReplaceRpMembersWithDelegate(autoRpMembersWithDelegateModel: AutoRpMembersWithDelegateModel) {
    return this.http.post(this.APIEndPoint + '/Workspace/replace-RPWithDelegate', autoRpMembersWithDelegateModel, { responseType: 'text' }
    );
  }

  SendEmailNotificationAllAutoRPs(rPNotificationModel: RPNotificationModel) {
    return this.http.post(this.APIEndPoint + '/Workspace/SendEmailNotificationAllAutoRPs', rPNotificationModel, { responseType: 'text' });
  }

  MarkQnARecordAsDeleted(record: QnARecordForDelete): Observable<string> {
    return this.http.post<string>(this.APIEndPoint + '/Log/MarkQnAasDeleted', record);
  }

  UpdateEngagementStatusOnSignatureCompletion(engagementId: number): Observable<string> {
    const requestOptions: object = {
      responseType: 'text'
    };
    return this.http.get<string>(this.APIEndPoint + '/Workspace/UpdateEngagementStatusOnSignatureCompletion/'
      + engagementId, requestOptions);
  }
  InsertCloseoutChecklistWorkspace(insertWorkspace: CloseoutChecklistWorkspaceModel): Observable<string> {
    const requestOptions: object = {
      responseType: 'text'
    };
    insertWorkspace.createdBy = this.user.loggedInUserEmail;
    return this.http.post<string>(this.APIEndPoint + '/Workspace/InsertCloseoutChecklistWorkspace'
      , insertWorkspace, requestOptions);
  }

  GetCloseoutChecklistWorkspaceData(engagementId: number, code: string): Observable<WorkspaceModel> {
    return this.http.get<WorkspaceModel>(
      this.APIEndPoint + '/Workspace/GetCloseoutChecklistWorkspaceData/' + engagementId + '/' + code
    );
  }

  GetWorkspaceDocumentCountbyEngId(engagementId: number, engagementState: string, workspaceId: string): Observable<number> {
    return this.http.get<number>(this.APIEndPoint + "/Workspace/GetWorkspaceDocumentCountbyEngId/" + engagementId + "/" + engagementState + "/" + workspaceId);
  }

  RemoveSecondaryWorkspace(removeSecondaryWorkspaceModel: RemoveSecondaryWorkspaceModel) {
    return this.http.post(this.APIEndPoint + '/Workspace/RemoveSecondaryWorkspace', removeSecondaryWorkspaceModel, { responseType: 'text' });
  }

  StoreCloseoutResponceLog(response: CloseoutResponseModel): Observable<string> {
    const requestOptions: object = {
      responseType: 'text'
    };
    return this.http.post<string>(this.APIEndPoint + '/Log/CloseoutResponseLog/', response, requestOptions);
  }

  StoreCloseoutQualityCheckListResponseLog(response: CloseoutResponseModel): Observable<string> {
    const requestOptions: object = {
      responseType: 'text'
    };
    return this.http.post<string>(this.APIEndPoint + '/Log/CloseoutQualityCheckListResponseLog/', response, requestOptions);
  }
  UploadSharepointFile(workspace: WorkspaceSupplementalDoc) {
    const requestOptions: object = {
      responseType: 'text'
    };
    return this.http.post(this.APIEndPoint + '/Workspace/UploadSharepointFile', workspace, requestOptions);
  }
  DeleteSharepointFile(workspace: WorkspaceSupplementalDoc) {
    return this.http.post(this.APIEndPoint + '/Workspace/DeleteSharepointFile/', workspace, { responseType: 'text' });
  }
  GetSharepointFiles(workspaceId: string): Observable<WorkspaceSupplementalDoc[]> {
    return this.http.get<WorkspaceSupplementalDoc[]>(
      this.APIEndPoint + '/Workspace/GetSharepointFile/' + workspaceId);
  }
  GetQualityChecklistWorkspaceData(engagementId: number, hdTemplatePackageId: string): Observable<WorkspaceModel> {
    return this.http.get<WorkspaceModel>(
      this.APIEndPoint + '/Workspace/GetQualityChecklistWorkspaceData/' + engagementId + '/' + hdTemplatePackageId
    );
  }

  getisAuditFlagByWorkspaceId(workspaceId: string): Observable<boolean> {
    return this.http.get<boolean>(this.APIEndPoint + "/Workspace/GetisAuditFlagByWorkspaceId/" + workspaceId);
  }

  getValidAuditPartnerForEngagement(workSpaceId: string): Observable<AuditPartnerDetailsForEngagementModel> {
    return this.http.get<AuditPartnerDetailsForEngagementModel>(this.APIEndPoint + "/Workspace/GetValidAuditPartnerForEngagement/" + workSpaceId);
  }

  AddAuditPartner(auditPartnerModel: AuditPartnerModel): Observable<AuditPartnerStatusModel> {

    auditPartnerModel.loggedInUser = this.user.userName;
    auditPartnerModel.loggedInUserFullName = this.user.loggedInUserFullName;
    return this.http.post<AuditPartnerStatusModel>(this.APIEndPoint + '/Workspace/AddAuditPartner', auditPartnerModel);
  }
  RemoveAuditPartnerByWorkSpaceId(removeAuditPartnerModel: RemoveAuditPartnerModel) {
    return this.http.post(this.APIEndPoint + '/Workspace/RemoveAuditPartnerByWorkSpaceId', removeAuditPartnerModel, { responseType: 'text' });
  }

  getAuditPartnerDetails(workSpaceId: string): Observable<AuditPartnerDetailsModel> {
    return this.http.get<AuditPartnerDetailsModel>(this.APIEndPoint + "/Workspace/GetAuditPartnerDetails/" + workSpaceId);
  }

  StoreHotDocsAnswers(hotDocsResponse: HotDocsResponse): Observable<string> {
    const requestOptions: Object = {
      responseType: 'text'
    }
    return this.http.post<string>(this.APIEndPoint + '/Workspace/StoreHotDocsAnswers', hotDocsResponse, requestOptions);
  }
  getChangeOrderFeatureFlag(): Observable<boolean> {
    return this.http.get<boolean>(this.APIEndPoint + "/Workspace/GetChangeOrderFeatureFlag/");
  }
  getActivityTypeByWorkSpaceId(workspaceId: string): Observable<string> {
    const requestOptions: Object = {
      responseType: 'text'
    }
    return this.http.get<string>(this.APIEndPoint + "/Workspace/GetActivityTypeByWorkSpaceId/" + workspaceId, requestOptions);
  }
}
