import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShortcutComponent } from './shortcut.component';


@NgModule({
  declarations: [ShortcutComponent],
  imports: [CommonModule],
  exports: [ShortcutComponent] // Ensure you export it if it's used in other modules
})
export class ShortCutModule {}