import { TeamMember } from "../Engagement/TeamMember";

export class ChangeOrderModel {
    changeOrderId: number;
    engagementId: number;
    changeOrderName: string;
    workspaceId: string;
    isFinancialUpdate: boolean;
    estimateCompletionDate: string;
    fee: number;
    grossMargin: number;
    createdBy: string;
    createdDate: Date;
    lastModifiedBy: string;
    lastModifiedDate: Date;
    ApprovedDate: Date;
    status: string;
    teamMembers: TeamMember[]
    ChangeOrderAssignments: ChangeOrderAssignmentModel[];
    templatePackageId: string;
}

export class ChangeOrderAssignmentModel {
    assignmentName: string;
    changeOrderId: number;
    engagementId: number;
    assignmentCodeId:number;
    estimateCompletionDate: string;
    incrementalFees: number;
    estimatedGrossMargin: number;
    createdBy: string;
    createdDate: Date;
    updatedBy: string;
    updatedDate: Date;
    estimatedStartDate: string;
  
}

export class EditChangeOrderModel {
    changeOrderId: number;
    engagementId: number;
    engagementName: string;
    changeOrderName: string;
    workspaceId: string;
    isFinancialUpdate: boolean;
    estimateCompletionDate: string;
    fee: number;
    grossMargin: number;
    createdBy: string;
    createdDate: Date;
    lastModifiedBy: string;
    lastModifiedDate: Date;
    ApprovedDate: Date;
    status: string;
    teamMembers: TeamMember[]
    changeOrderAssignments: ChangeOrderAssignmentModel[];
    clientId:number;
    templatePackageId: string;
    parentSolutionCodeId: number;
}