import { CollaborationSiteService } from './../../services/collaboration-site.service';
import { SharePointMetadataMapping } from './../../model/sharepoint-metadata-mapping';
import { SPSiteMetadata } from './../../model/sp-site-metadata';
import { Component, ComponentFactoryResolver, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { InterviewModel } from 'src/app/model/InterviewModel';
import { HotDocsService } from 'src/app/services/HotDocsService';
import { Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { WorkspaceModel, WorkspaceInterviewsStatus, QuestionnaireDialogTitles, SaveWorkItemAssemblySession, ResetWorkspaceDataModel, HotDocsResponse } from 'src/app/model/WorkspaceModel';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { AppConfigService } from 'src/app/services/appConfigService';
import { DataService } from 'src/app/services/data.service';
import { TemplatePackageModel } from 'src/app/model/TemplatePackageModel';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { AlertsService } from 'src/app/services/alerts.service';
import { Alert } from 'src/app/model/Alert';
import { Subscription, throwError, Observable, forkJoin, of } from 'rxjs';
import { ReacceptanceClientsService } from 'src/app/services/reacceptanceClientsService';
import { ContinuanceEngagementWorkItemModel, ContinuanceStatus } from 'src/app/model/ReacceptanceClientListModel';
import { EngagementList } from 'src/app/model/EngagementListModel';
import { EngagementService } from 'src/app/services/EngagementService';
import { SharepointService } from 'src/app/services/sharepoint.service';
import { SPDocumentList, SPDocument, SharepointSiteDocumentModel, ContactCardControl, ContactCardClick, SharepointUploadSiteDocumentModel } from 'src/app/model/SharePoiint/SharePointSite';
import { HDQuestionBankData, HDQuestionnaireModel } from 'src/app/model/HDWorkItemModel';
import { HotDocsVariableMapping, HotDocsVariableMappingList, UpdateStatusAddHDAnsToEngage, HDSessionAnsToEngModel } from 'src/app/model/HotDocsVariableMapping';
import { PrimaryWorkspaceModel } from 'src/app/model/Engagement/CreateEngagementData';
import { DecisionsRuleEngineService } from 'src/app/services/Integration/decisions-rule-engine.service';
import { DecisionsRPRequestModel } from 'src/app/model/Workspace/DecisionsRPRequestModel';
import { TeamMemberEPModel, TeamMembers } from 'src/app/model/Workspace/TeamMembers';
import { EnumRoles, RevievingProfessionalType, RoleNames } from 'src/app/model/Role';
import { DecisionsRPListModel } from 'src/app/model/Workspace/DecisionsRPListModel';
import { AdUsersService } from 'src/app/services/adusers.service';
import { DecisionsRPReponseViewModel as DecisionsRPResponseViewModel } from 'src/app/model/Workspace/DecisionsRPResponseViewModel';
import { DecisionsRequestTypes, NotificationOnlyRemoveRemarks, NotificationWorkflowStage } from 'src/app/model/Workspace/DecisionsApiLogModel';
import { UpdateEngagementSiteRequestModel } from 'src/app/model/CollaborationSite';
import { DatePipe } from '@angular/common';
import { SharePointWrapper } from 'src/app/model/sharepoint-metadata-wrapper';
import { Engagement, NotificationOnlyRequestModel } from 'src/app/model/decisions/notifications/notification-request';
import { NotificationResponseViewModel } from 'src/app/model/decisions/notifications/notification-response-viewmodel';
import { DecisionsNotificationProfessionalsListModel } from 'src/app/model/Workspace/DecisionsNotificationProfessionalsListModel';
import { RPPermissionModel } from 'src/app/model/engagements';
import { ActiveDirectoryService } from 'src/app/services/activedirectory.service';
import { DataModificationModuleNames, EngagementDialogTitles } from 'src/app/model/Engagement/Engagements';
import { DataModificationService } from 'src/app/services/datamodification.service';
import { ReviewResponseModel } from 'src/app/model/Workspace/ReviewResponseModel';
import { ReviewNoteSearchableUser } from 'src/app/model/WorkspaceReviewPoint';
import { AzureADGroups } from 'src/app/model/AzureADUsersArrayList';
import { GraphService } from 'src/app/services/Integration/graphservice.service';
import { ReviewNotesComponent } from '../review/review-notes/review-notes.component';
import { WorkspaceReviewPointService } from 'src/app/services/workspaceReviewPointService';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Applicationmaster } from 'src/app/model/ApplicationMaster';
import { WorkspaceSupplementalDoc } from 'src/app/model/Workspace/WorkspaceSupplementalDoc';
import { ApplicationMasterService } from 'src/app/services/application-master.service';
import { DataModification } from 'src/app/model/data-modification-model';
import { WorkspaceAuditPartner } from '../../model/Workspace/audit-partner.model';
import { ADGroup, ADUser } from '../../model/SharePoiint/ActiveDirectory';
import { CLMService } from 'src/app/services/clm.service';
import { GoverningAgreementNameAndStatusRequest } from 'src/app/model/CLM/governing-agreemen-request';
import { GoverningAgreementNameAndStatusResponse } from 'src/app/model/CLM/governing-agreement-response';
import { parse } from 'path';
import { CLMApiLog } from 'src/app/model/CLM/clm-api-log';
import { CLMApiLogrRequestType } from 'src/app/model/enums/clm-api-log-request-type.enum';
import { EngagementStatusViewModel } from 'src/app/model/Engagement/engagement-status-view-model';
import { WorkspaceStatusUpdateModel } from 'src/app/model/WorkspaceStatusUpdateModel';
import { ApproveRejectWorkspaceResultModel } from 'src/app/model/Workspace/DecisionsRPRequestModel';
import { ClientDeleteModel } from 'src/app/model/Client/client-delete-model';
import { QnARecordForDelete } from 'src/app/model/Workspace/QnARecordForDelete';
import { ConveneApiLogModel, ConveneRequestTypes, RequestMethods } from 'src/app/model/ConveneIntegration/convene-api-log-model';
import { ClosureRequestModel } from 'src/app/model/ConveneIntegration/closure-request-model';
import { ClosureService } from 'src/app/services/Integration/closure.service';
import { ClientService } from '../../services/clientservice';
import { ChangeOrderService } from 'src/app/services/change-order.service';
import { ChangeOrderModel } from 'src/app/model/change-order/change-order-model';
import { EngagementChangeOrdersModel } from 'src/app/model/change-order/engagement-change-orders-model';

declare var HD$;
declare var $;
declare const InitializeEcIntegrationWithData: Function;

interface RPDeterminationAssignmentsDetail {
  assignmentCodeId: number;
  engagementId: number;
  solutionCodeId: number;
  solutionCode: string;
  netFees: number;
  estimatedGrossMargin?: number;
  cmsppmdEmailId: string;
  eqcrEmailId: string;
  engagementPartnerEmailId: string;
}

@Component({
  selector: 'app-interviews',
  templateUrl: './interviews.component.html',
  styleUrls: ['./interviews.component.css'],
  providers: [HotDocsService]
})
export class InterviewsComponent implements OnInit, OnDestroy {
  isContinuanceEligible = false;
  private modalConfirmationDialogRef: Subscription = null;
  currentfileName: string;
  spDocument: SPDocument = new SPDocument();
  sharepointSiteDocumentModel: SharepointSiteDocumentModel = new SharepointSiteDocumentModel();
  sharepointUploadSiteDocumentModel: SharepointUploadSiteDocumentModel = new SharepointUploadSiteDocumentModel();//upload 100MB supplement file model 
  userInfo: any;
  decisionRPRequest: DecisionsRPRequestModel = new DecisionsRPRequestModel();
  decisionsRPListModel: DecisionsRPListModel = new DecisionsRPListModel();
  decisionsRPResponseViewModel: DecisionsRPResponseViewModel = new DecisionsRPResponseViewModel();
  engagementId = 0;
  sharePointURL = '';
  CurrentDateTime: string = this.datePipe.transform(new Date(), "MMM d, y, h:mm:ss a");
  memberList: TeamMembers[];
  IsUserEP: boolean = false;
  ResetApprovals: boolean = true;
  EditEngagementBtnClicked: boolean = false;
  FinishSessionFlagCheck: boolean = false;
  questionnaireInProgressHDSessionMembers: string = "";

  skipRedirect: boolean = false;
  dataModificationDetails: DataModification = new DataModification();
  ForceUserToCompleteSession: boolean = false;
  forceCompleteSession_RequestedNavigationUrl: string;
  isLoggedInUserSystemAdmin: boolean = false;
  IsUserTM: boolean = false;
  AppliedTnC: boolean = false;
  TnCWorkspaceDetails: WorkspaceModel = new WorkspaceModel();
  supplementalDocCountOnLoad: number = 0;
  isTemplateVersionChanged: boolean = false;
  isPureNotificationOnlyProfessional: boolean = false;
  IsUserRP: boolean = false;
  uploadFileValidationError: boolean;
  uploadFileNameFormat: RegExp = /["*:;<>?\/|#%~&\\]/;
  uploadFileSize: number = 20971520  //In Bytes (=20MB) Default value can be changed by flag upto 100MB
  uploadMaxFileSize: number  //value can be changed by flag

  isSharepointBigFileFeatureFlagEnabled: boolean = false;
  isSharepointFileLabelFeatureFlagEnabled: boolean = false;
  sharePointSelectedFileLabel = ""
  sharePointSelectedFileLabelForFilter = "All";
  sharePointSelectedFile: SPDocumentList;
  sharePointApplicationMasterLabels: Applicationmaster[] = [];
  sharePointApplicationMasterLabelsFiltered: Applicationmaster[] = [];
  sharePointAddFileFlag: boolean = false;

  subscription: Subscription;
  isCollapsibleLeftNavVisible: boolean;
  workspaceAuditPartner: WorkspaceAuditPartner = new WorkspaceAuditPartner();
  @ViewChild('clmGoverningAgreementStatusDiv') clmGoverningAgreementStatusDiv: ElementRef;
  isCLMFeatureEnabled: boolean = false;
  gaNameAndStatusResponse: GoverningAgreementNameAndStatusResponse;
  gaNameAndStatusRequest: GoverningAgreementNameAndStatusRequest;
  gaNameAndStatusRequestTimmerFlag: boolean = false;

  CurrentWorkspaceData: WorkspaceModel = new WorkspaceModel();
  isContinuanceEngagement: boolean = false;
  AbandonedTitle = 'warning';
  reacceptanceEngagementId: number;
  workspaceStatusUpdateViewModel: WorkspaceStatusUpdateModel = new WorkspaceStatusUpdateModel();
  approveRejectWorkspaceResultModel: ApproveRejectWorkspaceResultModel = new ApproveRejectWorkspaceResultModel();
  isContinuanceButtonEnabled: boolean = false;
  isApprove: boolean = false;
  engagementStatusViewModel: EngagementStatusViewModel;
  hotDocsResponseModel: HotDocsResponse = new HotDocsResponse();

  isInterviewLoadingStarted: boolean = false;

  // ChangeOrderVariables
  changeOrderFeatureFlag: boolean = true;
  workspaceType: string = "E";
  engagementName: string;
  engagementChangeOrders: EngagementChangeOrdersModel;
  changeOrders: ChangeOrderModel[];
  selectedChangeOrderId: number=-1;
  parentEngagementId: number = 0;
  isPreviousChangeorderSelected: boolean = false;
  previousChangeOrderWSId:string;
  constructor(private hdService: HotDocsService,
    private router: Router,
    private workspaceService: WorkspaceService,
    private applicationMasterService: ApplicationMasterService,
    private environment: AppConfigService,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private loadingService: LoadingScreenService,
    private alertService: AlertsService,
    private reacceptanceService: ReacceptanceClientsService,
    private engService: EngagementService,
    private sharepointService: SharepointService,
    private data: DataService,
    private decisionRuleEngineService: DecisionsRuleEngineService,
    private adUsersService: AdUsersService,
    private datePipe: DatePipe,
    private collaborationSiteService: CollaborationSiteService,
    private activedirectoryService: ActiveDirectoryService,
    private dataModification: DataModificationService,
    private graphSerivce: GraphService,
    private viewContainerRef: ViewContainerRef,
    private cfr: ComponentFactoryResolver,
    private httpClient: HttpClient, private _sharepointService: SharepointService,
    private workspaceReviewPointService: WorkspaceReviewPointService,
    private el: ElementRef,
    private closureService: ClosureService,
    private _engService: EngagementService,
    private _clientService: ClientService,
    private clmService: CLMService, private changeOrderService: ChangeOrderService) {
  }

  HDInProgressAlertLimitInHours: any;
  InterviewList: InterviewModel;
  interviewOptions: any;
  IntegrationAPIEndPoint: string;
  alert: Alert;
  WorkspaceDetails: WorkspaceModel = new WorkspaceModel();
  SaveSessionFlagCheck = false;
  CurrentWorkspaceId: string;
  TemplatePackageDetails: TemplatePackageModel = new TemplatePackageModel();

  isFinishInterviews = false;
  private modalFinishDialogRef: Subscription = null;
  CurrentWorkspaceStatus = '';
  dialogTitle = '';
  dialogButtonName = '';
  CurrentInterviewsStatus: any;
  isInterviewsScreen = false;
  displayNewVersionDialog = true;
  redirectedFromEditEngagement = false;
  continuanceWorkItemData: ContinuanceEngagementWorkItemModel = new ContinuanceEngagementWorkItemModel();
  currentEngagement: EngagementList = new EngagementList();
  supplementalDocumentList: SPDocumentList[] = [];
  currentDocument: SPDocumentList = new SPDocumentList();
  questionBankData: HDQuestionBankData = new HDQuestionBankData();
  questionnaireModel: HDQuestionnaireModel = new HDQuestionnaireModel();
  hdVariableMappingData: HotDocsVariableMapping[] = [];
  hdSessionAnswersToEngage: HDSessionAnsToEngModel = new HDSessionAnsToEngModel();
  hdVariableMappingList: HotDocsVariableMappingList = new HotDocsVariableMappingList();
  primaryWorkspaceData: PrimaryWorkspaceModel = new PrimaryWorkspaceModel();
  addUpdateStatusHDAnswers: UpdateStatusAddHDAnsToEngage = new UpdateStatusAddHDAnsToEngage();
  selectContactEmailId: string;
  applyFilterOnceAfterDataLoad: boolean;
  isHiddenProgressModal: boolean = false;
  supplementalDocCount: number = null;
  DataMessage: string;
  lastdateTime: any;
  isInterviewLoaded: boolean = false;
  myQuestionnaireLoadingInterval: any = null;
  reviewNoteSearchableUsers: ReviewNoteSearchableUser[] = [];
  reviewNoteSearchableUsersFromQualityGroups: ReviewNoteSearchableUser[] = [];
  reviewerList: TeamMembers[] = [];
  reviewNoteQueComponentRef: any;
  workspaceOpenReviewPointsCount: number;
  isCurrentWorkspacePrimary: boolean = true;
  primaryWorkspaceId: string = null;
  public get WorkspaceInterviewsStatus(): typeof WorkspaceInterviewsStatus {
    return WorkspaceInterviewsStatus;
  }
  IsUserAccessPermission: boolean = false;
  isQualityUserLoggedIn: boolean = false;
  IsMember: boolean = false;

  workspaceId: string;
  hasSiteProvisioned: boolean = false;
  isEngagmentRPRuleEngineError: boolean = false;
  isWorkspaceScreen: boolean = false;
  workItemIdForReviewResponse: string;
  workItemVersionIdReviewResponse: string;
  clientId: number = 0;
  // engagementId: number = 0;
  shouldNotifyEPPMD: boolean = false;
  isConveneProjectsExists: boolean = false;

  ngOnInit() {
    const contactPopup = $('#contact-popup');
    contactPopup.hide();

    this.applyFilterOnceAfterDataLoad = false;
    const jsonString = localStorage.getItem('currentUser');
    this.userInfo = JSON.parse(jsonString);
    this.getChangeOrderFeatureFlagLoadWorkspaceDetails();// main method starting point to load interview page apis
    this.data.getConveneProject$.subscribe(isExists => this.isConveneProjectsExists = isExists);
    this.alertService.remove(this.alert);
    this.IntegrationAPIEndPoint = this.environment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
    this.activatedRoute.paramMap.subscribe(params => {
      this.CurrentWorkspaceId = (params.get('workspaceId')).toString();

      console.log('CurrentWorkspaceId', this.CurrentWorkspaceId);
    });
    this.PerformDialogAction();
    this.CheckLoggedinUserSystemAdminRole();
    this.dataService.getNewVersionDialogVisibility.subscribe(
      isVisible => {
        this.displayNewVersionDialog = isVisible;
      });

    this.dataService.isRedirectedFromEditEng.subscribe(
      isRedirected => {
        if (isRedirected) {
          this.redirectedFromEditEngagement = isRedirected;
        }
      });

    // Configure the interview options; this registers the 'OnSessionComplete' event that fires when the user clicks the
    // interview 'Finish' button. It then posts the data from the interview to your 'CompleteAssemblySession' endpoint.
    // You will add the 'CompleteAssemblySession' endpoint in the next topic, 'Completing an Assembly Session'.
    // Note that the value for the 'Container' property must match the ID of your interview container div at the top of the page.

    // Attach the assembly session to the interview
    this.currentEngagement.siteRequestStatus = '';
    // this.GetEngagementDetailsByWorkspaceId(this.CurrentWorkspaceId);
    this.initiateConfirmationPopUp();

    //Set loader for buttons section while interview gets loaded.
    $('#questionnaireLoadSpinner').addClass('spinner');

    // to get review notes count
    this.getWorkspaceReviewPointsCount();

    const myInterval = setInterval(() => {
      // API Call Goes here
      this.engService.GetEngagementDetailsByworkspaceId(this.CurrentWorkspaceId).subscribe(
        result => {
          this.currentEngagement = result;
          this.dataService.setClientIdForPrePopulate(this.currentEngagement.clientId);
          if (this.currentEngagement.requestId != undefined
            && !(this.currentEngagement.siteRequestStatus === 'Completed' ||
              this.currentEngagement.siteRequestStatus === 'PartialCompleted')) {
            this.GetSiteStatus(this.currentEngagement.requestId);
            console.log('GetSiteStatus in time interval');
          }
          // clear myInterval based on condition
          if (this.currentEngagement.siteRequestStatus === 'Completed' ||
            this.currentEngagement.siteRequestStatus === 'PartialCompleted') {
            clearInterval(myInterval);
          }
        });
    }, 60 * 3000);
    this.myQuestionnaireLoadingInterval = setInterval(() => {
      // Check if interviews loaded 
      if (!this.isInterviewLoaded && (this.IsUserAccessPermission)) {
        $('#questionnaireLoadSpinner').removeClass('spinner');
        this.alertService.add('Issue in loading questionnaire. Please refresh questionnaire screen.', 'danger', null);
        this.loadingService.stopLoading();
        clearInterval(this.myQuestionnaireLoadingInterval);
      }
    }, 60 * 1000);
    this.getSharepointSupplementFileUploadLimit();
    this.getSharepointFileLabelFeatureFlag();
    this.getSharepointFileLabels();
    this.getSharepointBigFileFeatureFlag();
    this.setUnsavedChangesForIwantTo();

    this.subscription =
      this.dataService.isCollapsibleLeftNavVisible$.subscribe(value => {
        this.isCollapsibleLeftNavVisible = value
      });
    this.invokeUpdateUserDetails();
    this.dataService.getCLMGALinkRequestCompleted$.subscribe(result => { // load CLM if CLM update completed from initiate engagement screen
      if (result == "OK") {
        if (!this.dataService.CLMGALinkRequestProcessing.getValue())
          this.getCLMGoverningAgrementNameStatus();
      }
    })
  }

  invokeUpdateUserDetails() {
    this.workspaceService.getAuthUser();
  }

  CheckLoggedinUserSystemAdminRole() {
    console.log('userObject ==>', this.userInfo);
    if (this.userInfo != null && this.userInfo.role != null && this.userInfo.role.length > 0) {
      for (let i = 0; i < this.userInfo.role.length; i++) {
        if (this.userInfo.role[i].shortRoleName === 'SA') {
          this.isLoggedInUserSystemAdmin = true;
        }
      } //End of For  
    }// End of If
  }

  ngAfterViewChecked() {
    // Below functionality is called once data is loaded. Here ideal solution is implement promise in loading of data and apply.
    if (this.applyFilterOnceAfterDataLoad) {
      this.BindContactPopup();
      this.applyFilterOnceAfterDataLoad = false;
    }
  }

  PerformDialogAction() {
    this.modalFinishDialogRef = this.dataService.getModalDetails$.subscribe(
      dialogData => {
        if (dialogData && this.isInterviewsScreen) {
          const modal = JSON.parse(dialogData);
          const dialogTitle = modal.dialogTitle;
          const buttonName = modal.dialogButtonName;
          if (buttonName.length > 0 &&
            (dialogTitle === QuestionnaireDialogTitles.FinishInterview ||
              dialogTitle === QuestionnaireDialogTitles.UnAnsQuestions ||
              dialogTitle === QuestionnaireDialogTitles.CancelQuestionnaire ||
              dialogTitle === WorkspaceInterviewsStatus.ReviewStatus ||
              dialogTitle === QuestionnaireDialogTitles.QueNewVersion ||
              dialogTitle === QuestionnaireDialogTitles.InputErrors ||
              dialogTitle === EngagementDialogTitles.UnsavedChangesConfirmation)) {
            this.dialogTitle = dialogTitle;
            this.dialogButtonName = buttonName;
            this.processDialogAction(this.dialogTitle, this.dialogButtonName);
          }
          console.log('Display Finish Dialog message in interviews screen ', dialogTitle);
        }
      });
  }
  processDialogAction(title: string, btnName: string) {
    switch (title) {
      case QuestionnaireDialogTitles.FinishInterview:
        if (btnName === 'ok') {
          this.FindOkButton('FinishBtn');
        }
        break;
      case QuestionnaireDialogTitles.UnAnsQuestions:
        if (btnName === 'ok') {
          this.SaveAssemblySession();
        } else {
          this.ResetCompleteSessionFlag();
          this.processHDButton('cancel');
        }
        break;
      case QuestionnaireDialogTitles.CancelQuestionnaire:
      case WorkspaceInterviewsStatus.ReviewStatus:
        if (btnName === 'ok') {
          this.RedirectUser();
        }
        break;
      case QuestionnaireDialogTitles.QueNewVersion:
        if (btnName === 'ok') {
          this.UpdateTemplateVersionAndReloadPage(true);
        }
        break;
      case QuestionnaireDialogTitles.InputErrors:
        if (btnName === 'ok') {
          this.processHDButton(btnName);
        }
        break;
      case EngagementDialogTitles.UnsavedChangesConfirmation:
        if (!this.ForceUserToCompleteSession && btnName === 'ok') {
          this.FindOkButton('CloseSessionBtn');
        } else if (btnName === 'cancel' || btnName === 'close') {
          this.ResetCompleteSessionFlag();
        }
        this.ResetIwantToUnsavedFlag();
        break
      default:
        break;
    }

  }
  ResetCompleteSessionFlag() {
    if (this.ForceUserToCompleteSession) {
      this.ForceUserToCompleteSession = false;
      this.forceCompleteSession_RequestedNavigationUrl = '';
    }
  }
  processHDButton(btnName: string) {
    let i = 0;
    if (btnName === 'cancel') {
      i = 1;
    }
    const buttonSet = $('.ui-dialog').children().children()[4];
    if (buttonSet != null || buttonSet == undefined) {
      let okButton = $(buttonSet).children()[i];
      $(okButton).attr('id', 'btnok');
      $(okButton).click();
    }
  }

  processFinishInterviews() {
    this.loadingService.stopLoading();
    this.alertService.remove(this.alert);
    if (this.isValidWorkspaceStatus()) {
      // let docCount : number = this.supplementalDocumentList.length;
      // if(docCount <= this.supplementalDocCountOnLoad && this.AppliedTnC && 
      //   this.CurrentWorkspaceStatus === WorkspaceInterviewsStatus.DraftSession) {
      //   this.alertService.add('Revised document(s) must be attached.', 'danger', null);
      // } else {
      this.FindOkButton('FinishBtn');
      //}
    } else {
      this.setDialogContent('danger', WorkspaceInterviewsStatus.ReviewStatus);
    }
  }
  ngOnDestroy() {
    this.ResetIwantToUnsavedFlag();
    this.CloseHDSession();
    this.UpdateDataModification(this.WorkspaceDetails.engagementId);
    this.CleartTimerAlert();
    this.modalFinishDialogRef?.unsubscribe();
    this.dataService.setRedirectedFromEditEngagement(false);
    this.redirectedFromEditEngagement = false;
    this.alertService.alerts = [];
    this.loadingService.stopLoading();
    this.reviewNoteQueComponentRef = null;
    this.subscription?.unsubscribe();
    this.modalConfirmationDialogRef?.unsubscribe();
  }
  canDeactivate(currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
    if (!this.FinishSessionFlagCheck && !this.SaveSessionFlagCheck && !this.EditEngagementBtnClicked) {
      if (!this.ForceUserToCompleteSession) {
        this.forceCompleteSession_RequestedNavigationUrl = nextState.url;
        let oldurl = this.dataService.previousUrl.getValue();
        //this.FindOkButton('CloseSessionBtn');
        if (!this.IsUserAccessPermission) {

          return of(true);
        }
        this.setDialogContent("information", EngagementDialogTitles.UnsavedChangesConfirmation);
        return of(false);


      }

      else {
        return of(true);
      }
    }
    else {
      return of(true);
    }
  }
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    if ($.event) {
      event.stopImmediatePropagation();
    } else {
      return true;
    }
  }

  getTnCWorkspaceDetails(workspaceDetails: WorkspaceModel) {
    if (this.AppliedTnC) {
      //set TnC workitem id of isActive flase workspaces
      this.workspaceService.getWorkspaceById(this.WorkspaceDetails.updateQuestionnaireID).subscribe(
        TnCWorkspaceData => {
          this.TnCWorkspaceDetails = TnCWorkspaceData;
          this.questionnaireModel.tnCWorkItemId = this.TnCWorkspaceDetails.hdWorkItemId;
          this.questionnaireModel.appliedTnC = this.AppliedTnC;
          this.getInterviewsSession(workspaceDetails);
        });
    } else {
      this.getInterviewsSession(workspaceDetails);
    }
  }

  getInterviewsSession(workspaceDetails) {
    if (workspaceDetails.hdWorkItemId != null && this.questionBankData != null) {
      this.questionnaireModel.questionBankData = this.questionBankData;
      if (!this.isCurrentWorkspacePrimary && this.CurrentInterviewsStatus === WorkspaceInterviewsStatus.ContinuanceSession) {
        //This is only for first time load of secondary document
        workspaceDetails.continuanceWorkItemId = this.currentEngagement.primaryWorkitemId;
      }
      this.questionnaireModel.workspace = workspaceDetails;
      this.hdService.getInterviews(this.questionnaireModel).subscribe({
        next: result => {
          if (this.isCLMFeatureEnabled) {
            if (!this.dataService.CLMGALinkRequestProcessing.getValue()) // check CLM is not updating for only new engagment  case
              this.getCLMGoverningAgrementNameStatus();
          }
          workspaceDetails.hdCoreSessionId = result.coreSessionId;
          workspaceDetails.hdWorkItemVersionId = result.hdaSessionId;
          workspaceDetails.id = workspaceDetails.workspaceId;
          this.WorkspaceDetails.status = this.CurrentWorkspaceStatus;
          this.WorkspaceDetails.hdWorkItemVersionId = result.hdaSessionId;
          this.WorkspaceDetails.hdCoreSessionId = result.coreSessionId;
          this.InterviewList = result;
          if (this.CurrentInterviewsStatus === WorkspaceInterviewsStatus.CreateSession ||
            this.CurrentInterviewsStatus === WorkspaceInterviewsStatus.ContinuanceSession) {
            this.workspaceService.UpdateWorkspaceStatus(this.WorkspaceDetails).subscribe(workspaceUpdateResult => {

              //Validate template cache and reload if needed -  after answers are prefilled from previous year engagement and saved into hotdocs for continuance engagement
              //We are doing this here to address the issue - answers are not prefilled from prev year for continuance engagements
              //Added WorkspaceInterviewsStatus.CreateSession condition to address if old template version is already downloaded in browser and user tries to create new engagement with latest version.
              if ((this.isContinuanceEligible && this.CurrentInterviewsStatus === WorkspaceInterviewsStatus.ContinuanceSession)
                || this.CurrentInterviewsStatus === WorkspaceInterviewsStatus.CreateSession
              ) {
                this.ValidateHdTemplateCache();
              }
              this.AttachInterviewSession();
            },
              error => {
                this.DisplaySystemAlert();
              });
          } else {
            this.AttachInterviewSession();
          }
        },
        error: err => {
          this.DisplaySystemAlert();
        }
      }
      );
    }
  }

  getWorkspaceDetailsById() {
    if (this.CurrentWorkspaceId != null) {
      this.loadingService.startLoading();

      this.workspaceService.getWorkspaceById(this.CurrentWorkspaceId).subscribe(result => {
        if (result.isPrimaryWorkspaceId) {
          this.isCurrentWorkspacePrimary = true;
          this.primaryWorkspaceId = this.CurrentWorkspaceId;
        } else {
          this.isCurrentWorkspacePrimary = false;
        }
        this.WorkspaceDetails = result;

        if (this.displayNewVersionDialog == false) {
          this.UpdateTemplateVersionAndReloadPage(true);
        }
        else {
          this.RestoreUIStateAfterPageLoaded();
        }

        this.processGetWorkspaceDetailsById();

      }, error => {
        this.DisplaySystemAlert();
      });
    }
  }
  processGetWorkspaceDetailsById() {
    if (this.WorkspaceDetails !== null && this.WorkspaceDetails !== undefined) {
      this.engagementId = this.WorkspaceDetails.engagementId;
      this.CurrentWorkspaceStatus = this.WorkspaceDetails.status;
      if (this.WorkspaceDetails.updateQuestionnaireID != null &&
        this.WorkspaceDetails.updateQuestionnaireID != undefined &&
        this.WorkspaceDetails.updateQuestionnaireID != '') {
        this.AppliedTnC = true;
      }
      this.getCLMFeatureFlag();
    }
    if (this.isValidWorkspaceStatus()) {
      if (this.WorkspaceDetails.engagementId > 0 || this.WorkspaceDetails.engagementId != null) {
        setTimeout(() => {
          this.CheckUserIsPrimaryWorkspaceMember(this.WorkspaceDetails.engagementId);
        }, 200);
        this.dataModification.IsInProgressDataModificationExists(this.WorkspaceDetails.engagementId, DataModificationModuleNames.Questionnaire).subscribe(
          questionnaireInprogressResult => {
            this.dataModificationDetails = questionnaireInprogressResult;
            if (this.dataModificationDetails.editStatus == true) {
              this.questionnaireInProgressHDSessionMembers = this.dataModificationDetails.editingMembers?.join(", ");
              if (this.dataModificationDetails.editingMembers?.length == 1) {
                this.skipRedirect = true;
                this.questionnaireInProgressHDSessionMembers = this.questionnaireInProgressHDSessionMembers + '.';
              } else {
                this.questionnaireInProgressHDSessionMembers = this.questionnaireInProgressHDSessionMembers + '....';
              }
            }
            this.GetEngagementDetailsByWorkspaceId(this.CurrentWorkspaceId);
          }, error => {
            this.DisplaySystemAlert();
          });



      } else {
        this.DisplaySystemAlert();
      }
    } else {
      this.loadingService.stopLoading();
      // this.setDialogContent('danger', WorkspaceInterviewsStatus.ReviewStatus);  // not need to show based on new change
    }
  }
  AttachInterviewSession() {
    console.log(this.InterviewList);
    this.loadingService.stopLoading();
    this.interviewOptions = {
      Container: 'hdPreviewDiv',
      Theme: 'default.css',
      OnInit: () => {
        HD$.RegisterHandler('OnSessionComplete',
          (e) => {
            console.log('Finish Session called on OnSessionComplete complete handler');
            let xhr = new XMLHttpRequest();
            const url = this.IntegrationAPIEndPoint + '/Hotdocs/CompleteAssemblySession/' + this.WorkspaceDetails.hdWorkItemId;

            xhr.open('POST', url, true);

            xhr.setRequestHeader('Content-Type', 'application/json');

            xhr.onreadystatechange = function () {

              if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                console.log(this.status);
              }
            };
            xhr.send(JSON.stringify({ WorkItemId: this.WorkspaceDetails.hdWorkItemId }));
            if (!this.AppliedTnC) {
              this.StoreReviewResponse();
              this.StoreHotDocsInterview();
            }

          });
        $('button.hd-nav-btn.hd-nav-finish.hd-nav-lff-3').css('display', 'none');

        HD$.RegisterHandler('OnHDStart',
          (eventArgs: any) => {
            HD$.SetVisibility('Toolbar', false);
            $('#questionnaireLoadSpinner').removeClass('spinner');
            this.isInterviewLoaded = true;
            this.isInterviewLoadingStarted = false;
            this.clearHodocsVariable();

            setTimeout(() => { // adding delay to load html
              InitializeEcIntegrationWithData(this.engagementId, this.userInfo.userName);
            }, 1000);

          });

      },
      DisableAnswerSummary: true,
      OutlineWidth: 0,
      TemplateTitleOverride: ' ',
      LeaveWarning: false,
      RequiredAsterisk: true,
    };
    if (this.isInterviewLoadingStarted == false) {
      this.isInterviewLoadingStarted = true;
      HD$.AttachSession(
        this.InterviewList.coreSessionId,
        this.InterviewList.serviceMetadataUrl,
        this.interviewOptions
      );
    }
  }
  clearHodocsVariable() {
    setTimeout(() => { // adding delay to load html
      var effectiveDateElelement = $('input[data-test="input_existing_agreement_effective_date"]');
      if (effectiveDateElelement != undefined) {
        let effecrtiveDatevalue = effectiveDateElelement.val();
        if (effecrtiveDatevalue == "1/1/1900")
          effectiveDateElelement.val("")
      }
    }, 1000);
  }
  FindOkButton(BtnValue: string) {
    this.EditEngagementBtnClicked = false;
    this.FinishSessionFlagCheck = false;
    this.SaveSessionFlagCheck = false;
    this.ForceUserToCompleteSession = false;
    this.isHiddenProgressModal = true;
    if (BtnValue == 'SaveBtn') {
      this.SaveSessionFlagCheck = true;
    } else if (BtnValue == 'FinishBtn') {
      this.FinishSessionFlagCheck = true;
    } else if (BtnValue == 'EditBtn') {
      this.EditEngagementBtnClicked = true;
    } else if (BtnValue === 'CloseSessionBtn') {
      this.ForceUserToCompleteSession = true;
    }
    this.setUnsavedChangesForIwantTo();
    $('button.hd-nav-btn.hd-nav-finish.hd-nav-lff-3').click();
    let uiDialog: any;
    let divTitleBar: any;
    let spanTitle: any;
    let uiDialogTitle = '';

    setTimeout(() => {
      this.UpdateTemplateVersionAndReloadPage(false);
      uiDialog = $('.ui-dialog');
      if (uiDialog.length == 1) {
        divTitleBar = uiDialog.children()[0];

      } else {
        const visibleDialog = $('.ui-dialog ').filter(function () {
          return $(this).css('display') !== 'none';
        });
        divTitleBar = visibleDialog.children()[0];
      }
      spanTitle = $(divTitleBar).children()[1];
      uiDialogTitle = $(spanTitle).html();
      if (uiDialogTitle === 'Input errors') {
        $('.ui-dialog').css('display', 'none');
        $('.ui-front').css('display', 'none');
        this.loadingService.stopLoading();
        this.setDialogContent('danger', QuestionnaireDialogTitles.InputErrors);
      } else if (uiDialogTitle === 'Unanswered questions') {
        $('.ui-dialog').css('display', 'none');
        $('.ui-front').css('display', 'none');
        this.loadingService.stopLoading();
        this.setDialogContent('warning', QuestionnaireDialogTitles.UnAnsQuestions);
      } else {
        this.isHiddenProgressModal = false;
        $('.ui-dialog').css('display', 'none');
        $('.ui-front').css('display', 'none');
        this.loadingService.stopLoading();
        if (BtnValue == 'FinishBtn') {
          this.GetAllHDVariableMapping();
        } else if (BtnValue == 'SaveBtn') {
          this.SaveAssemblySession();
        } else if (BtnValue == 'EditBtn') {
          this.EditEngagement();
        } else if (BtnValue === 'CloseSessionBtn') {
          this.RedirectToNextState();
        }
      }
    }, 5000);

  }

  UpdateWorkspaceInDB() {
    this.loadingService.startLoading();
    setTimeout(() => {
      this.addUpdateStatusHDAnswers.hDAnswersToEngage = this.hdSessionAnswersToEngage.hdAnstoEngList;
      this.WorkspaceDetails.hdTemplatePackageVersionId = this.hdSessionAnswersToEngage.templatePackageVersionId;
      this.WorkspaceDetails.hdWorkItemVersionId = this.hdSessionAnswersToEngage.workItemVersionId;
      this.addUpdateStatusHDAnswers.hdDocumentId = this.hdSessionAnswersToEngage.documentId;
      this.addUpdateStatusHDAnswers.hdDocumentTitle = this.hdSessionAnswersToEngage.title;
      this.addUpdateStatusHDAnswers.workspace = this.WorkspaceDetails;
      this.workspaceService.AddUpdateStatusHDAnsToEngage(this.addUpdateStatusHDAnswers).subscribe(result => {
        console.log(result);
        this.workspaceAuditPartner = result;
        this.updateSharePointMetadata();
        this.UpdateDecisionsRPList();

        if (this.workspaceAuditPartner.isToDeleteAuditPartner && this.workspaceAuditPartner.deletedAuditPartnerEmailId?.length > 0) {
          this.deleteAuditPartnerFromADGroup();
        }
      }, error => {
        this.DisplaySystemAlert();
      });
    }, 3000);
  }

  SaveAssemblySession() {
    this.loadingService.startLoading();
    setTimeout(() => {
      let buttonSet = $('.ui-dialog').children().children()[4];
      if (buttonSet != null || buttonSet == undefined) {
        let okButton = $(buttonSet).children()[0];
        $(okButton).attr('id', 'btnOk');
        $(okButton).click();
        setTimeout(() => {
          $('.ui-dialog-content .ui-widget-content').css('display', 'none');
          $('.ui-dialog.hd-interview-base.hd-popup.ui-widget').remove();
          $('.ui-widget-overlay.ui-front').remove();
          if (this.SaveSessionFlagCheck) {
            setTimeout(() => {
              this.processSaveSession();
            }, 50);
          } else if (this.FinishSessionFlagCheck) {
            this.GetAllHDVariableMapping();
          } else if (this.EditEngagementBtnClicked) {
            this.EditEngagement();
          } else if (this.ForceUserToCompleteSession) {
            this.RedirectToNextState();
          }
          // delay time for answer processing then will close the session.
        }, 4000);
      }
    }, 1000);
    this.UpdateTemplateVersionAndReloadPage(false);
  }

  RedirectToNextState() {
    this.router.navigate([this.forceCompleteSession_RequestedNavigationUrl]);
  }

  SaveAssemblySessionofVersion() {
    let saveSessionData: SaveWorkItemAssemblySession = new SaveWorkItemAssemblySession();
    saveSessionData.workItemId = this.WorkspaceDetails.hdWorkItemId;
    saveSessionData.workItemVersionId = this.WorkspaceDetails.hdWorkItemVersionId;
    this.hdService.saveAssemblySessionofVersionId(saveSessionData).subscribe(saveResult => {
      console.log('SaveAssembly session of version id result = ' + saveResult)
      $('#interview-save-success').removeClass('d-none');
      $('#interview-save-success').addClass('d-block');
      this.loadingService.stopLoading();
    });
  }

  CloseHDSession() {
    HD$.DetachSession();
  }
  RedirectUser() {
    this.loadingService.stopLoading();
    this.CleartTimerAlert();
    if (this.CurrentWorkspaceStatus != WorkspaceInterviewsStatus.DraftSession &&
      this.CurrentWorkspaceStatus != '' &&
      this.CurrentWorkspaceStatus != WorkspaceInterviewsStatus.Abandoned &&
      this.CurrentWorkspaceStatus != WorkspaceInterviewsStatus.Approved &&
      this.CurrentWorkspaceStatus != WorkspaceInterviewsStatus.Declined) {
      this.router.navigate(['/Review/' + this.primaryWorkspaceId]);
    } else {
      this.router.navigate(['MyEngagements']);
    }
  }
  RedirectToWorkspace() {
    this.loadingService.stopLoading();
    this.CleartTimerAlert();
    if (this.workspaceType == "E") {
      this.router.navigate(['/Review/' + this.primaryWorkspaceId]);
    } else {
      this.router.navigate(['/Review/' + this.CurrentWorkspaceId]);
    }
  }

  CheckTemplateVersion(currentWorkspaceData) {
    if (this.displayNewVersionDialog) {
      this.hdService.checkTemplateVersion(currentWorkspaceData).subscribe(result => {
        this.isTemplateVersionChanged = result;
        if (this.isTemplateVersionChanged) {
          this.loadingService.stopLoading();
          if ((this.workspaceType == "E" && (this.engagementStatusViewModel.status == "Team review" || this.engagementStatusViewModel.status == "EPPMD review"
            || this.engagementStatusViewModel.status == "RP InReview" || this.engagementStatusViewModel.status == "InReview"
            || this.engagementStatusViewModel.status == "Draft")
            || this.workspaceType == "C")
          ) {
            this.setDialogContent('warning', QuestionnaireDialogTitles.QueNewVersion);
          }
          else {
            this.UpdateTemplateVersionAndReloadPage(true);
          }
        } else {
          //For continuance engagements we are validating template cache in different place after we prefill/save interview answers into hotdocs.
          //Hence we exclude validating template for continuance engagements here.
          if (!this.isContinuanceEligible && this.CurrentInterviewsStatus != WorkspaceInterviewsStatus.ContinuanceSession) {
            this.ValidateHdTemplateCache();
          }
          this.GetQuestionBankData(currentWorkspaceData);
        }
      }, error => {
        this.DisplaySystemAlert();
      });
    } else {
      //this.isTemplateVersionChanged = true;
      this.GetQuestionBankData(currentWorkspaceData);
    }
  }

  UpdateTemplateVersionAndReloadPage(isReload: boolean) {
    this.loadingService.startLoading();
    this.hdService.getTemplatePackageDetails(this.WorkspaceDetails.hdTemplatePackageId).subscribe(
      TemplateDetail => {
        this.TemplatePackageDetails = TemplateDetail;
        console.log('Template Live version data on template version check', this.TemplatePackageDetails.liveVersionNumber);
        this.WorkspaceDetails.showFutureMessage = false;
        this.WorkspaceDetails.hdLastVersionCheckedAgainst = this.TemplatePackageDetails.liveVersionNumber;
        this.WorkspaceDetails.hdCurrentTempVersion = this.TemplatePackageDetails.liveVersionNumber;
        this.workspaceService.updateTemplateVersionData(this.WorkspaceDetails).subscribe(
          result1 => {
            if (isReload)
              this.ReloadPage(this.WorkspaceDetails, true);
          }, error => {
            this.DisplaySystemAlert();
          });
      });

  }

  setDialogContent(type: string, title: string) {
    this.isInterviewsScreen = true;
    let description: string;
    let button = 2;
    let bigTitle = false;
    switch (title) {
      case QuestionnaireDialogTitles.InputErrors:
        this.isHiddenProgressModal = false;
        description = 'You must resolve one or more input errors before you can proceed.';
        button = 1;
        this.DisplayDialog(type, title, description, button, bigTitle);
        break;
      case QuestionnaireDialogTitles.FinishInterview:
        description = 'Based on the information gathered and the evaluation of engagement acceptance risk,' +
          ' it is recommended that this client engagement be accepted by the firm.' +
          ' A workspace will be created and all acceptance documentation is ready for EPPMD review.';
        this.DisplayDialog(type, title, description, button, bigTitle);
        break;
      case QuestionnaireDialogTitles.UnAnsQuestions:
        this.isHiddenProgressModal = false;
        description = 'You have unanswered questions that may affect the review process. Click “OK” if you would like to proceed anyway. Click “Cancel” to return to the questionnaire.';
        this.DisplayDialog(type, title, description, button, bigTitle);
        break;
      case QuestionnaireDialogTitles.CancelQuestionnaire:
        description = 'Data entered during this session will be lost. Press OK to continue.';
        this.DisplayDialog(type, title, description, button, bigTitle);
        break;
      case WorkspaceInterviewsStatus.ReviewStatus:
        this.isHiddenProgressModal = false;
        description = 'The status of this engagement changed during your review.  Click OK to view the most current information.';
        button = 1;
        this.DisplayDialog(type, title, description, button, bigTitle);
        break;
      case QuestionnaireDialogTitles.QueNewVersion:
        this.isHiddenProgressModal = false;
        description = 'This engagement questionnaire must be updated to the current version. ' +
          'Previous responses will be preserved where possible. Click OK to view the updated questionnaire and responses.';
        button = 1;
        this.DisplayDialog(type, title, description, button, bigTitle);
        break;
      case EngagementDialogTitles.UnsavedChangesConfirmation:
        description = 'While the questionnaire responses are autosaved, leaving now may result in recent changes not being saved. Click “Cancel” to go back and manually save your updates. Click “OK” to proceed without manually saving them.';
        button = 2;
        bigTitle = true;
        this.DisplayDialog(type, title, description, button, bigTitle);
        break;
      case EngagementDialogTitles.AbandonedEngagement:
        description = 'This cannot be undone. This engagement will be be removed from the client details screen and no record will be visible on the Continuance dashboard. Submit a support ticket to recreate a continuance record, if necessary.';
        button = 2;
        this.DisplayDialog(type, title, description, button, bigTitle);
        break;
      default:
        break;
    }

  }

  DisplayDialog(type: string, title: string, description: string
    , button: number, bigTitle: boolean) {
    const modalObj = {
      dialogType: type,
      dialogTitle: title,
      dialogDescription: description,
      dialogRedirectUrl: '',
      dialogButton: button,
      modalDisplay: true,
      dialogInput: '',
      dialogBigTitle: bigTitle
    };
    const modalJSON = JSON.stringify(modalObj);
    console.log(' showDialog func called ', type);
    this.dataService.displayDialogNotification(modalJSON);
  }

  getWorkspaceStatus(btnName: string) {
    this.dataService.setCollapsibleLeftNav(false);
    this.dataService.setCloseReviewNotes(true);
    $('.margin-all').removeClass('compressDiv');
    $('.containerDiv').removeClass('compresscontainerDiv');
    //$('.modal-open').removeClass('modal-open');    
    this.loadingService.startLoading();
    this.workspaceService.GetWorkspaceCurrentStatus(this.CurrentWorkspaceId).subscribe(status => {
      if (btnName === 'FinishBtn') {
        this.CurrentWorkspaceStatus = status;
        this.processFinishInterviews();
      } else if (btnName === 'SaveBtn' && status === WorkspaceInterviewsStatus.DraftSession) {
        this.SaveAssemblySessionofVersion();
      } else if (btnName === 'SaveBtn' && status != WorkspaceInterviewsStatus.DraftSession) {
        this.loadingService.stopLoading();
        this.setDialogContent('danger', WorkspaceInterviewsStatus.ReviewStatus);
      } else if (btnName === 'EditBtn') {
        this.loadingService.stopLoading();
        this.FindOkButton(btnName);
      }
    }, error => {
      this.DisplaySystemAlert();
    });
    this.dataService.setCloseReviewNotes(false);
  }

  isValidateSiteURL(siteURL: string) {
    return (siteURL !== null && siteURL !== undefined && siteURL !== '');
  }

  isValidWorkspaceStatus() {
    if (this.CurrentWorkspaceStatus === WorkspaceInterviewsStatus.Abandoned ||
      this.CurrentWorkspaceStatus === WorkspaceInterviewsStatus.Declined ||
      this.CurrentWorkspaceStatus === WorkspaceInterviewsStatus.Approved) {
      return false;
    } else {
      return true;
    }
  }
  DisplayCancelDialog() {
    this.setDialogContent('information', QuestionnaireDialogTitles.CancelQuestionnaire);
  }

  resetApprovalData(Workspace: WorkspaceModel) {
    if (this.CurrentWorkspaceStatus !== WorkspaceInterviewsStatus.DraftSession) {
      let resetWorkspcaeDataModel: ResetWorkspaceDataModel = new ResetWorkspaceDataModel();
      resetWorkspcaeDataModel.workspaceId = Workspace.workspaceId;
      resetWorkspcaeDataModel.isLoggedInUserSystemAdmin = this.isLoggedInUserSystemAdmin;
      this.workspaceService.resetWorkspaceAprovalData(resetWorkspcaeDataModel).subscribe(resetResult => {
        this.RedirectToWorkspace();
      }, error => {
        this.DisplaySystemAlert();
      });
    } else {
      this.RedirectToWorkspace();
    }
  }

  getContinuanceData() {
    this.reacceptanceService.getContinuanceEngWorkItemData(this.WorkspaceDetails.engagementId).subscribe(
      result => {
        this.continuanceWorkItemData = result;
        console.log('Continuance eng workitem data', this.continuanceWorkItemData);
        // get workitem data of continuance reacceptance to load PY Q&A data by passing workitem id of PY Workspace
        if (this.continuanceWorkItemData.continuanceStatus === ContinuanceStatus.Draft &&
          this.continuanceWorkItemData.reacceptanceEngagementId > 0 &&
          this.continuanceWorkItemData.engagementId > 0) {
          this.isContinuanceEligible = true;
          this.WorkspaceDetails.continuanceWorkItemId = this.continuanceWorkItemData.continuanceWorkItemId;
          this.WorkspaceDetails.continuanceTemplateChangeAnsCarryForward = this.continuanceWorkItemData.continuanceTemplateChangeAnsCarryForward;
          this.WorkspaceDetails.isTemplateChanged = this.continuanceWorkItemData.isTemplateChanged;
        } else {
          this.isContinuanceEligible = false;
        }
        this.processGetInterviews();
        if (this.continuanceWorkItemData.reacceptanceEngagementId > 0 && this.continuanceWorkItemData.reacceptanceEngagementId != null) {
          this.isContinuanceEngagement = true;
        } else {
          this.isContinuanceEngagement = false;
        }
        if (this.CurrentWorkspaceStatus != null && this.CurrentWorkspaceStatus != '' && this.CurrentWorkspaceStatus != undefined) {
          if (this.CurrentWorkspaceStatus == WorkspaceInterviewsStatus.DraftSession && this.isContinuanceEngagement) {
            this.isContinuanceButtonEnabled = true;
          }
        }
      }, error => {
        this.DisplaySystemAlert();
      });
  }

  setCurrentInterviewStatus() {
    switch (this.WorkspaceDetails.status) {
      case WorkspaceInterviewsStatus.DraftSession:
        this.CurrentInterviewsStatus = WorkspaceInterviewsStatus.DraftSession;
        break;
      case WorkspaceInterviewsStatus.EPReviewPending:
      case WorkspaceInterviewsStatus.EPPMDReview:
      case WorkspaceInterviewsStatus.RPInReview:
        this.CurrentInterviewsStatus = WorkspaceInterviewsStatus.CompleteSession;
        break;
      default:
        this.CurrentInterviewsStatus = WorkspaceInterviewsStatus.DraftSession;
        break;
    }
    if (this.CurrentInterviewsStatus === WorkspaceInterviewsStatus.DraftSession &&
      (this.WorkspaceDetails.hdCoreSessionId === null ||
        this.WorkspaceDetails.hdCoreSessionId === 'undefined' ||
        this.WorkspaceDetails.hdCoreSessionId === '')) {
      this.CurrentInterviewsStatus = WorkspaceInterviewsStatus.CreateSession;
    }
    if (this.isContinuanceEligible && this.redirectedFromEditEngagement) {
      this.CurrentInterviewsStatus = WorkspaceInterviewsStatus.ContinuanceSession;
    }
    if (this.CurrentInterviewsStatus === WorkspaceInterviewsStatus.CompleteSession &&
      this.redirectedFromEditEngagement) {
      this.CurrentInterviewsStatus = WorkspaceInterviewsStatus.CreateSession;
    }
    if (this.CurrentInterviewsStatus === WorkspaceInterviewsStatus.ContinuanceSession &&
      this.WorkspaceDetails.hdCoreSessionId != null &&
      this.WorkspaceDetails.hdCoreSessionId !== 'undefined' &&
      this.WorkspaceDetails.hdCoreSessionId !== '' &&
      !this.redirectedFromEditEngagement) {
      this.CurrentInterviewsStatus = WorkspaceInterviewsStatus.DraftSession;
    }
    this.WorkspaceDetails.setAnsFlag = this.CurrentInterviewsStatus;
    //For secondary workspace always set ans flag to create
    if (!this.isCurrentWorkspacePrimary) {
      if (this.WorkspaceDetails.hdCoreSessionId == null ||
        this.WorkspaceDetails.hdCoreSessionId === 'undefined' ||
        this.WorkspaceDetails.hdCoreSessionId === '') {
        //For first time load of secondary document pass base xml of primary workitem 
        this.CurrentInterviewsStatus = WorkspaceInterviewsStatus.ContinuanceSession;

      }
      else {
        this.WorkspaceDetails.setAnsFlag = WorkspaceInterviewsStatus.CreateSession;
      }
    }


  }
  processGetInterviews() {
    if (this.WorkspaceDetails.hdWorkItemId != null) {
      this.setCurrentInterviewStatus();
      this.CurrentWorkspaceStatus = this.WorkspaceDetails.status;

      // If Engagement is in EP or RP review and User edits engagement then we need to check template version mismatch.
      // If mismatch found, new version popup will be displayed and clicking on OK will update the latest template version in db and reloads page.
      // Other use case fixed: Since latest version is updated in db, when EP edits engagement (RP review or EP review status), it will not reset to team review. The status will now be set to EP Review which is expected.
      var CanCheckTemplateVersion = false;
      if (this.redirectedFromEditEngagement &&
        (this.CurrentWorkspaceStatus === WorkspaceInterviewsStatus.EPPMDReview || this.CurrentWorkspaceStatus === WorkspaceInterviewsStatus.RPInReview || this.CurrentWorkspaceStatus === WorkspaceInterviewsStatus.EPReviewPending)
      ) {
        CanCheckTemplateVersion = true; // this condition need to revisit for change order
      }

      // if review status is In Review then reset approvals and set review status to Team review on Finish Interviews click
      if (this.redirectedFromEditEngagement && this.CurrentWorkspaceStatus === WorkspaceInterviewsStatus.RPInReview) {
        this.redirectedFromEditEngagement = false;
      }
      // if review status is In Review then reset approvals and set review status to Team review on Finish Interviews click
      if (this.redirectedFromEditEngagement && this.CurrentWorkspaceStatus === WorkspaceInterviewsStatus.DraftSession) {
        this.redirectedFromEditEngagement = false;
      }
      console.log('Current Workspace Status :', this.CurrentWorkspaceStatus);
      if (CanCheckTemplateVersion) {
        this.CheckTemplateVersion(this.WorkspaceDetails);
      } else if (this.CurrentInterviewsStatus === WorkspaceInterviewsStatus.CreateSession ||
        (!this.isCurrentWorkspacePrimary && this.CurrentInterviewsStatus === WorkspaceInterviewsStatus.ContinuanceSession)) {
        this.GetQuestionBankData(this.WorkspaceDetails);
      } else {
        this.CheckTemplateVersion(this.WorkspaceDetails);
      }
    }
  }
  processSaveSession() {
    HD$.DetachSession();
    this.WorkspaceDetails.status = WorkspaceInterviewsStatus.DraftSession;
    this.workspaceService.UpdateWorkspaceStatus(this.WorkspaceDetails).subscribe(result => {
      console.log(result);
      this.getWorkspaceDetailsById();
      this.updateSharePointMetadata();
      $('#interview-save-success').removeClass('d-none');
      $('#interview-save-success').addClass('d-block');
    }, error => {
      this.DisplaySystemAlert();
    });
  }


  updateSharePointMetadata() {
    const collaborationSiteIdRequest = this.collaborationSiteService.getCollaborationSiteId(this.engagementId);
    const sharePointMetadataRequest = this.engService.getMetadataList();
    forkJoin([collaborationSiteIdRequest, sharePointMetadataRequest]).subscribe(responseList => {
      const metadata: SPSiteMetadata[] = responseList[1];
      const collaborationSiteId = responseList[0];
      const sharepointMetadata: SPSiteMetadata[] = [];
      this.engService.getSharePointMetadataMapping(this.engagementId, collaborationSiteId)
        .subscribe((sharePointData: SharePointMetadataMapping) => {
          sharePointData.propertiesMapping.forEach(item => {
            metadata.forEach(element => {
              if (item.value === element.name) {
                element.value = sharePointData.metadata[item.key];
                sharepointMetadata.push(element);
              }
            });
          });
          const sharePointWrapper = new SharePointWrapper();
          sharePointWrapper.siteURL = sharePointData.metadata.siteUrl;
          this.sharePointURL = sharePointData.metadata.siteUrl;
          console.log(this.sharePointURL);
          sharePointWrapper.metadataList = sharepointMetadata;
          this.engService.updateSiteMetadata(sharePointWrapper).subscribe(status => {
            console.log('Metadata saved in interviews component', status);
          });
        });
    });
  }

  onSelectFile(Files: FileList) {
    this.uploadFileValidationError = false;
    let fileToUpload = Files[0];
    this.currentfileName = fileToUpload.name;
    let isSpecialCharFound = this.uploadFileNameFormat.test(this.currentfileName)
    if (fileToUpload.size > this.uploadFileSize || isSpecialCharFound) {
      this.uploadFileValidationError = true;
      $("#input-file").val('');
      return true;
    }
    let intArrayObj;
    intArrayObj = new Promise((resolve, reject) => {
      let fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.readAsArrayBuffer(fileToUpload);
    }).then(
      (result: ArrayBuffer) => {
        $("#uploadfileDiv").removeClass('invisible');
        $('#uploadfileDiv').addClass('visible');
        $("#fileuploadlbl").removeClass('cursor-pointer');
        $('input[type=file]').attr('disabled', true);

        $('#fileuploadlbl, [for="fileuploadlbl"]').addClass('disabled');
        $("#modal-SharepointFileLabel").modal('hide');
        intArrayObj = new Uint8Array(result);
        if (this.isSharepointBigFileFeatureFlagEnabled) {
          let bufferresult = Array.from(intArrayObj);
          this.uploadSupplementalFileToSharepointBigFile(fileToUpload.name, bufferresult);
        } else {
          this.uploadSupplementalFileToSharepoint(fileToUpload.name, Array.from(intArrayObj));
        }
        $("#input-file").val('');
      }
    );
  }


  ngAfterViewInit() {
    this.closeContactPopupForSuppDoc();
  }

  closeContactPopupForSuppDoc() {
    $('html').on('click', function (e) {
      console.log('first', $(e.target).parents().is('.popover.in'));
      console.log('second', $(e.target).data('original-title'));

      if (typeof $(e.target).data('original-title') == 'undefined' &&
        !$(e.target).parents().is('.popover.in')) {
        const id = $('#' + ContactCardClick.suppDocModifiedBy).val();
        $('#suppDocModifiedByPopup_' + id).popover('hide');
        console.log('xxx11', $('#suppDocModifiedByPopup_' + id));
        console.log('xxx', '#' + ContactCardControl.suppDocdiv + id);
      }
    });

    $('body').on('click', function (e) {
      console.log('body click event fired', $(e.target).is('.' + ContactCardControl.close));
      let target = e.target;
      let parent = target.parentElement;
      if ($(target).is('.' + ContactCardControl.close) || $(parent).is('.' + ContactCardControl.close)) {
        if ($(e.target).data('toggle') !== 'popover'
          && $(e.target).parents('.popover.in').length === 0) {
          const id = $('#' + ContactCardClick.suppDocModifiedBy).val();
          $('#suppDocModifiedByPopup_' + id).popover('hide');
        }
      }
    });

  }

  hideAllPopUp() {
    if (this.supplementalDocumentList.length > 0) {
      for (let i = 0; i < this.supplementalDocumentList.length; i++) {
        $('#suppDocModifiedByPopup_' + this.supplementalDocumentList[i].documentId).popover('hide');
      }
    }
  }

  checkForContactContent(contact) {
    const timer = setTimeout(() => {
      const contactHtml = $('#' + contact).html();
      console.log('after 5 second', contact, contactHtml);
      if (contactHtml) {
        $('.popover-body').html(contactHtml);
        clearTimeout(timer);

      } else {
        this.checkForContactContent(contact);
      }
    }, 2000);
  }

  DisplayContactPopup(index: number, emailId: string) {
    this.selectContactEmailId = emailId;
    $('#modifiedByIndex').val(index);
    // Close the pop up if any pop up open earlier
    this.hideAllPopUp();
    this.checkForContactContent('contact-popup');
    $('#suppDocModifiedByPopup_' + index).popover('show');
  }

  BindContactPopup() {
    if (this.supplementalDocumentList.length > 0) {
      for (let i = 0; i < this.supplementalDocumentList.length; i++) {
        $('#suppDocModifiedByPopup_' + this.supplementalDocumentList[i].documentId).popover('hide');
        this.createContactPopupDiv('suppDocModifiedByPopup_', this.supplementalDocumentList[i].documentId);
      }
    }
  }

  createContactPopupDiv(controlIdText: string, id: number) {
    console.log('index ==>', id);
    $('#' + controlIdText + id).popover({
      container: 'body',
      html: true,
      placement: 'top',
      trigger: 'manual',
      boundary: 'viewport',
      content: function () {
        console.log('BindContactPopup func called ', $('#contact-popup').html());
        return '<div class=\"mx-auto\" style=\"width: 200px;\">Loading ...</div> ';
      }
    });
  }

  uploadSupplementalFileToSharepoint(filename: string, fileByteArray: any) {
    this.spDocument = {
      fileName: filename,
      fileByteArray
    };
    this.sharepointSiteDocumentModel.files = [];
    this.sharepointSiteDocumentModel.files[0] = this.spDocument;
    this.sharepointSiteDocumentModel.documentType = 'Supplemental';
    this.sharepointSiteDocumentModel.documentFolderPath = this.currentEngagement.supplementalFolderPath;
    this.sharepointSiteDocumentModel.siteURL = this.currentEngagement.siteUrl;
    this.sharepointSiteDocumentModel.useremailId = this.userInfo.userName;
    // call Sharepoiint API
    this.sharepointService.uploaddocumentstolib(this.sharepointSiteDocumentModel).subscribe(
      result => {
        const strResult = result;
        console.log('strResult==>', strResult);
        if (strResult.toLowerCase().trim() === 'success') {
          this.GetAllDocumentsfromSPLibraryForSuppl(this.currentEngagement.siteUrl, this.currentEngagement.supplementalFolderPath);
        }
      },
      error => {
        this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }

  GetAllDocumentsfromSPLibraryForSuppl(siteURL: string, documentFolderPath: string) {
    this.sharepointService.getAllDocumentsfromSPLibrary(siteURL, documentFolderPath).subscribe(
      result => {
        this.supplementalDocumentList = result;
        if (this.sharePointAddFileFlag) {
          this.prepareSharepointFileLabelForNewFile();
        } else {
          this.attacheLabelToFiles();
        }
        this.supplementalDocCount = this.supplementalDocumentList.length;
        $("#fileuploadlbl").addClass('cursor-pointer');
        if (this.supplementalDocCount === 0) {
          this.DataMessage = 'No supplemental documents available';
        } else if (this.supplementalDocCount >= 1) {
          this.DataMessage = 'Loading...';
        } else {
          console.log('supplementalDocCount', this.supplementalDocCount);
          this.DataMessage = '';
        }

        console.log('supplementalDocumentList ==>', this.supplementalDocumentList);
        $('#uploadfileDiv').addClass('invisible');
        $('#uploadfileDiv').removeClass('visible');
        $('#input-file').removeAttr('disabled');

        $('#fileuploadlbl, [for="fileuploadlbl"]').addClass('enabled').removeClass('disabled');
      },
      error => {
        this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }
  attacheLabelToFiles() {
    this.workspaceService.GetSharepointFiles(this.primaryWorkspaceId).subscribe(workspaceDocs => {
      if (workspaceDocs && workspaceDocs.length > 0)
        for (let i = 0; i < this.supplementalDocumentList?.length; i++) {
          workspaceDocs.forEach(workspaceDoc => {
            if (workspaceDoc.sourceFileName == this.supplementalDocumentList[i].documentName && workspaceDoc.sourceFilePath == this.supplementalDocumentList[i].documentUrl) {
              this.supplementalDocumentList[i].label = workspaceDoc.label;
              this.supplementalDocumentList[i].workspaceId = workspaceDoc.workspaceId;
              return;
            }
          });
        }
      this.updateSpFilterFiles();
    }, error => {
      console.error("unable to load sharepoint label data", error);
    })
  }
  updateSpFilterFiles() {
    let unlabelFound = false;
    this.sharePointApplicationMasterLabelsFiltered = []
    this.supplementalDocumentList.forEach(spdoc => {
      this.sharePointApplicationMasterLabels.forEach(spAml => {
        if (spdoc.label == spAml.value) {
          this.sharePointApplicationMasterLabelsFiltered.push(spAml);
          return;
        } else {
          unlabelFound = true;
        }
      })
    });
    this.sharePointApplicationMasterLabelsFiltered.forEach(result => {
      if (result.value != this.sharePointSelectedFileLabelForFilter) {
        this.sharePointSelectedFileLabelForFilter = 'All';
      }
    });
    this.sharePointApplicationMasterLabelsFiltered = [...new Set(this.sharePointApplicationMasterLabelsFiltered)];
    this.sharePointApplicationMasterLabelsFiltered.sort((a, b) => a.value.localeCompare(b.value))
    // this.sharePointSelectedFileLabelForFilter = this.sharePointSelectedFileLabelForFilter;
  }

  /*Sharepoint Lable Feature */
  displaySharepointFileLabelPopup(document: SPDocumentList) {  //UI event on file select
    if (this.isCollapsibleLeftNavVisible)
      this.dataService.setCloseReviewNotes(true);

    this.sharePointSelectedFile = document;
    this.sharePointSelectedFileLabel = document.label;
    this.sharePointAddFileFlag = false;
    $('#modal-SharepointFileLabel').modal({ backdrop: 'static', keyboard: false })
    $("#modal-SharepointFileLabel").modal('show');
  }
  okSharepointFileLabel(labelName: string) {
    this.sharePointSelectedFileLabel = labelName;
    if (this.sharePointAddFileFlag == false) {
      let workspace = new WorkspaceSupplementalDoc();
      workspace.workspaceId = this.currentEngagement.primaryWorkspaceId;
      workspace.sourceFilePath = this.sharePointSelectedFile.documentUrl;
      workspace.sourceFileName = this.sharePointSelectedFile.documentName;
      workspace.label = this.sharePointSelectedFileLabel;
      workspace.createdby = this.userInfo.userName;
      workspace.WorkspaceSupplementalDocId = this.sharePointSelectedFile.documentId;
      this.addSharepointFileLabel(workspace);
      $("#modal-SharepointFileLabel").modal('hide');
    }
    else {
      $("#modal-SharepointFileLabel").modal('hide');
      $("#input-file").click();
    }
  }

  prepareSharepointFileLabelForNewFile() {
    let workspace = new WorkspaceSupplementalDoc();
    workspace.workspaceId = this.currentEngagement.primaryWorkspaceId;
    if (this.isSharepointBigFileFeatureFlagEnabled) {
      this.supplementalDocumentList.forEach(doc => {
        if (doc.documentName == this.currentfileName && doc.documentUrl.indexOf(this.sharepointUploadSiteDocumentModel.documentFolderPath) >= 0 &&
          doc.documentUrl.indexOf(this.sharepointUploadSiteDocumentModel.siteURL) >= 0) {
          workspace.sourceFilePath = doc.documentUrl
          workspace.sourceFileName = doc.documentName
          workspace.label = this.sharePointSelectedFileLabel;
          workspace.WorkspaceSupplementalDocId = doc.documentId;
          return;
        }
      });
    } else {
      this.supplementalDocumentList.forEach(doc => {
        if (doc.documentName == this.currentfileName && doc.documentUrl.indexOf(this.sharepointSiteDocumentModel.documentFolderPath) >= 0 &&
          doc.documentUrl.indexOf(this.sharepointSiteDocumentModel.siteURL) >= 0) {
          workspace.sourceFilePath = doc.documentUrl
          workspace.sourceFileName = doc.documentName
          workspace.label = this.sharePointSelectedFileLabel;
          workspace.WorkspaceSupplementalDocId = doc.documentId;
          return;
        }
      });
    }
    workspace.createdby = this.userInfo.userName;
    this.addSharepointFileLabel(workspace);
  }
  addSharepointFileLabel(workspace: WorkspaceSupplementalDoc) {
    this.workspaceService.UploadSharepointFile(workspace).subscribe(success => {
      this.attacheLabelToFiles();
      this.clearSharepointFileUploadData();
    }, err => {
      console.error("Unable to upload sharepoint file label.", err);
      this.clearSharepointFileUploadData();
    });
  }
  sharePointLabelSelected(labelName: string) {
    this.sharePointSelectedFileLabel = labelName;
  }
  setSharepointFileLabelUI(documentId: number) {
    for (let i = 0; i < this.supplementalDocumentList?.length; i++) {
      if (this.supplementalDocumentList[i].documentId == documentId) {
        this.supplementalDocumentList[i].label = this.sharePointSelectedFileLabel;
      }
    }
  }
  cancelSharepointFileLabel() {

    this.clearSharepointFileUploadData();
  }
  clearSharepointFileUploadData() {
    this.sharePointSelectedFile = undefined;
    this.sharePointSelectedFileLabel = "";
    this.sharePointAddFileFlag == false;
  }

  getSharepointFileLabels() {
    this.applicationMasterService.GetSharepointFileLabels().subscribe(applicationMaster => {
      if (applicationMaster && applicationMaster?.length > 0) {
        this.sharePointApplicationMasterLabels = applicationMaster;
        this.sharePointApplicationMasterLabelsFiltered = applicationMaster;

      }
    }, err => {
      console.error('Not able to load the sharepoint file label application master', err);
    })
  }
  getSharepointFileLabelFeatureFlag() {
    this.engService.GetSharepointFileLabelFeatureFlag().subscribe(result => {
      this.isSharepointFileLabelFeatureFlagEnabled = result;
    }, error => {
      console.error("unable to get sharepoint file label feature flag." + error)
    });
  }
  AddNewFileClicked() {
    if (this.isCollapsibleLeftNavVisible)
      this.dataService.setCloseReviewNotes(true);

    this.sharePointSelectedFileLabel = "";
    if (this.isSharepointFileLabelFeatureFlagEnabled) {
      this.sharePointAddFileFlag = true;
      $('#modal-SharepointFileLabel').modal({ backdrop: 'static', keyboard: false })
      $("#modal-SharepointFileLabel").modal('show');
    }
    else {
      $("#input-file").click();
    }

  }

  RemoveSupplDoc() {
    this.loadingService.startLoading();
    let workspace = new WorkspaceSupplementalDoc()
    workspace.workspaceId = this.currentEngagement.primaryWorkspaceId;
    workspace.sourceFilePath = this.currentDocument.documentUrl;
    workspace.sourceFileName = this.currentDocument.documentName

    this.sharepointService.deleteDocument(this.currentDocument.siteURL, this.currentDocument.documentUrl).subscribe(
      result => {
        const strResult = result;
        console.log("strResult==>", strResult);
        if (strResult.toLowerCase().trim() === 'success') {
          this.deleteSharepointFileLabel(workspace);
          this.sharepointService.getAllDocumentsfromSPLibrary(this.currentEngagement.siteUrl, this.currentEngagement.supplementalFolderPath).subscribe(
            docResult => {
              this.supplementalDocumentList = docResult;
              this.attacheLabelToFiles();
              this.supplementalDocCount = this.supplementalDocumentList.length;
              if (this.supplementalDocCount === 0) {
                this.DataMessage = 'No supplemental documents available';
              } else if (this.supplementalDocCount >= 1) {
                this.DataMessage = 'Loading...';
              } else {
                console.log('supplementalDocCount', this.supplementalDocCount);
                this.DataMessage = '';
              }
              this.applyFilterOnceAfterDataLoad = true;
              this.loadingService.stopLoading();
            },
            error => {
              this.DisplaySystemAlert();
              this.applyFilterOnceAfterDataLoad = false;
            });
        } else {
          this.loadingService.stopLoading();
          console.error('Error in removing suplement document.', this.currentDocument);
          this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        }

      },
      error => {
        this.loadingService.stopLoading();
        this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }

  deleteSharepointFileLabel(workspace: WorkspaceSupplementalDoc) {
    this.workspaceService.DeleteSharepointFile(workspace).subscribe(result => {
      const strResult = result;
      console.log("strResult==>", strResult);
    }, err => {
      console.error("Unable to delete sharepoint file label.", err);

    });
  }
  GetEngagementDetailsByWorkspaceId(workspaceId: string) {
    this.lastdateTime = this.datePipe.transform(new Date(), "MMM d, y, h:mm:ss a");
    return this.engService.GetEngagementDetailsByworkspaceId(workspaceId).subscribe(
      result => {
        console.log('GetEngagementDetailsByworkspaceId==>', result);
        if (result !== null) {
          this.currentEngagement = result;
          this.primaryWorkspaceId = result.primaryWorkspaceId;
          this.AppliedTnC = this.currentEngagement.appliedTnC;
          console.log("this.this.currentEngagement====>", this.currentEngagement.engagementId);
          this.sharePointURL = this.currentEngagement.siteUrl;
          if (this.currentEngagement.siteRequestStatus === 'Completed' ||
            this.currentEngagement.siteRequestStatus === 'PartialCompleted') {
            this.GetAllDocumentsfromSPLibrary(this.currentEngagement.siteUrl, this.currentEngagement.supplementalFolderPath);
          }
          this.getContinuanceData();
          this.SetLoggedInUserRole();
          this.getWorkspaceMembers();
          this.getWorkspaceReviewPoints();

        }
      },
      error => {
        this.currentEngagement = null;
        this.DisplaySystemAlert();
      }
    );
  }
  CheckUserIsPrimaryWorkspaceMember(engagementId: number) {
    this.IsLoggedinUserSystemAdmin();

    return this.engService.CheckUserIsMember(engagementId,
      this.userInfo.loggedInUserEmail).subscribe(member => {
        if (member) {
          this.IsMember = member;
        }

        this.IsUserAccessPermission = (this.IsMember ? true :
          (this.isLoggedInUserSystemAdmin ? true :
            (this.isQualityUserLoggedIn ? true : false)));

        if (this.IsUserAccessPermission) {
          this.StoreDataModification(this.WorkspaceDetails.engagementId);
        }

        if (!this.IsUserAccessPermission) {

          this.alertService.add('You do not have access to view details !', 'danger', null);
        }


      })
  }
  IsLoggedinUserSystemAdmin() {
    console.log('userObject ==>', this.userInfo);
    if (this.userInfo != null && this.userInfo.role != null && this.userInfo.role.length > 0) {
      for (let i = 0; i < this.userInfo.role.length; i++) {
        if (this.userInfo.role[i].shortRoleName === RoleNames.SystemAdministrator) {
          this.isLoggedInUserSystemAdmin = true;
        }
        if (this.userInfo.role[i].shortRoleName == RoleNames.QualityGroup) {
          this.isQualityUserLoggedIn = true;
        }

      } //End of For  
    }// End of If
  }


  GetSiteStatus(requestId: string) {
    this.lastdateTime = this.datePipe.transform(new Date(), "MMM d, y, h:mm:ss a");
    $('#lastdateTimeSpinner').addClass('loader');
    this.sharepointService.GetSiteStatus(requestId).subscribe(
      result => {
        if (result === 'Completed') {
          this.currentEngagement.siteRequestStatus = 'PartialCompleted';
          let updateEngagementSiteRequestData: UpdateEngagementSiteRequestModel = new UpdateEngagementSiteRequestModel();
          updateEngagementSiteRequestData.engagementId = this.currentEngagement.engagementId;
          updateEngagementSiteRequestData.engagementSiteRequestId = this.currentEngagement.engagementSiteRequestId;
          updateEngagementSiteRequestData.statusResult = this.currentEngagement.siteRequestStatus;
          this.engService.UpdateEngagementSiteRequestStatus(updateEngagementSiteRequestData).subscribe(
            updateEngResult => {
              if (updateEngResult === 'success') {
                $('#lastdateTimeSpinner').removeClass('loader');
                console.log('UpdateEngagementSiteRequestStatus successfully');
              }
            },
            error => {
              $('#lastdateTimeSpinner').removeClass('loader');
              this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
            });
        }
        else {
          $('#lastdateTimeSpinner').removeClass('loader');
        }

      },
      error => {
        this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }


  GetAllDocumentsfromSPLibrary(siteURL: string, documentFolderPath: string) {
    this.loadingService.startLoading();
    this.sharepointService.getAllDocumentsfromSPLibrary(siteURL, documentFolderPath).subscribe(
      result => {
        this.supplementalDocumentList = result;
        this.attacheLabelToFiles();
        this.supplementalDocCountOnLoad = this.supplementalDocumentList.length;
        this.supplementalDocCount = this.supplementalDocumentList.length;
        if (this.supplementalDocCount === 0) {
          this.DataMessage = 'No supplemental documents available';
        } else if (this.supplementalDocCount >= 1) {
          this.DataMessage = 'Loading...';
        } else {
          console.log('supplementalDocCount', this.supplementalDocCount);
          this.DataMessage = '';
        }

        this.applyFilterOnceAfterDataLoad = true;
        this.loadingService.stopLoading();
      },
      error => {
        this.DisplaySystemAlert();
        this.applyFilterOnceAfterDataLoad = false;
      });
  }

  confirmModalRemoveSuppDoc(document: SPDocumentList) {
    if (this.isCollapsibleLeftNavVisible)
      this.dataService.setCloseReviewNotes(true);

    this.currentDocument = document;
    let dialogDesc = '';
    const dialogInput = '';
    const dialogType = 'information';
    const action = 'Remove document';
    dialogDesc = 'Are you sure you want to delete this file ?';
    this.displayConfirmationPopUp(dialogType, action, dialogDesc, 2, dialogInput, false);
  }

  displayConfirmationPopUp(type: string, title: string, description: string,
    buttonCount: number, dialogInput: string, IsDeclineModal: boolean) {
    const modalObj = {
      dialogType: type,
      dialogTitle: title,
      dialogDescription: description,
      dialogRedirectUrl: '',
      dialogButton: buttonCount,
      DeclinedReasonInput: dialogInput,
      modalDisplay: true,

      dialogDeclineModal: IsDeclineModal
    };
    const modalJSON = JSON.stringify(modalObj);
    this.data.displayDialogNotification(modalJSON);
  }
  GetQuestionBankData(workspaceData: WorkspaceModel) {
    this.loadingService.startLoading();
    this.engService.GetQuestionBankData(this.WorkspaceDetails.engagementId).subscribe(
      result => {
        if (result.engageDataList.length > 0) {
          this.questionBankData = result;
          this.questionnaireModel.tnCWorkItemId = this.WorkspaceDetails.orginalHDWorkItemId;
          this.questionnaireModel.appliedTnC = this.AppliedTnC;
          this.getInterviewsSession(workspaceData);
        }
      },
      error => {
        this.DisplaySystemAlert();
      });
  }
  DisplaySystemAlert() {
    this.alertService.remove(this.alert);
    this.loadingService.stopLoading();
    clearInterval(this.myQuestionnaireLoadingInterval);
    this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
  }

  GetAllHDVariableMapping() {
    this.loadingService.startLoading();
    this.engService.GetAllHDVariableMapping(this.WorkspaceDetails.hdTemplatePackageId).subscribe(mappingData => {
      if (mappingData.length > 0) {
        this.hdVariableMappingData = mappingData;
        this.hdVariableMappingData.forEach(x => x.engagementId = this.WorkspaceDetails.engagementId);
        this.hdVariableMappingData.forEach(x => x.workItemId = this.WorkspaceDetails.hdWorkItemId);
      } else {
        this.hdVariableMappingData = [];
      }
      this.GetHDAnswersToVariableMapping();
    },
      error => {
        this.DisplaySystemAlert();
      });
  }
  GetHDAnswersToVariableMapping() {
    this.hdVariableMappingList.hDVariableMappingList = this.hdVariableMappingData;
    this.hdVariableMappingList.workItemId = this.WorkspaceDetails.hdWorkItemId;
    this.hdVariableMappingList.engagementId = this.engagementId;
    this.hdService.GetHDAnswersToEngage(this.hdVariableMappingList).subscribe(hdAnsData => {
      if (hdAnsData != null) {
        this.hdSessionAnswersToEngage = hdAnsData;
        if (this.hdSessionAnswersToEngage.hdAnstoEngList == null ||
          this.hdSessionAnswersToEngage.hdAnstoEngList == undefined ||
          this.hdSessionAnswersToEngage.hdAnstoEngList.length <= 0) {
          this.hdSessionAnswersToEngage.hdAnstoEngList = [];
        }
      }
      this.SetWorkspaceDataToStatusUpdate();
    },
      error => {
        this.DisplaySystemAlert();
      });
  }

  SetWorkspaceDataToStatusUpdate() {
    //if logged in suer is EP and stage is eppmd review then do not reset approvals
    this.hdService.getTemplatePackageDetails(this.WorkspaceDetails.hdTemplatePackageId).subscribe(
      TemplateDetail => {
        this.TemplatePackageDetails = TemplateDetail;
        console.log('Template Live version data on template version check', this.TemplatePackageDetails.liveVersionNumber);
        //set status & reset flag stage wise
        switch (this.CurrentWorkspaceStatus) {
          case WorkspaceInterviewsStatus.EPReviewPending:
          case WorkspaceInterviewsStatus.DraftSession:
            this.SetResetApprovalsValue();
            break;
          case WorkspaceInterviewsStatus.EPPMDReview:
            if (this.redirectedFromEditEngagement || ((this.IsUserEP || (this.isLoggedInUserSystemAdmin && !this.IsUserTM)) && !this.redirectedFromEditEngagement)) {
              this.WorkspaceDetails.status = this.CurrentWorkspaceStatus;
              this.ResetApprovals = false;
            } else if (this.IsUserTM && !this.redirectedFromEditEngagement && !this.IsUserEP) {
              this.SetResetApprovalsValue();
            }
            break;
          case WorkspaceInterviewsStatus.RPInReview:
            if (this.redirectedFromEditEngagement) {
              this.WorkspaceDetails.status = this.CurrentWorkspaceStatus;
              this.ResetApprovals = false;
            } else if ((this.IsUserEP || (this.isLoggedInUserSystemAdmin && !this.IsUserTM)) && !this.redirectedFromEditEngagement) {
              this.WorkspaceDetails.status = WorkspaceInterviewsStatus.EPPMDReview;
              this.ResetApprovals = true;
            } else if (this.IsUserTM && !this.redirectedFromEditEngagement && !this.IsUserEP) {
              this.SetResetApprovalsValue();
            }
            break;
          default:
            break;
        }
        //when New  version is launched then reset status & approvals
        if (this.WorkspaceDetails.hdCurrentTempVersion != this.TemplatePackageDetails.liveVersionNumber) {
          this.SetResetApprovalsValue();
        }
        this.UpdateWorkspaceInDB();
      });
  }
  SetResetApprovalsValue() {
    this.ResetApprovals = true;
    this.WorkspaceDetails.status = WorkspaceInterviewsStatus.EPReviewPending;
  }
  ProcessResetApproval() {
    // if template version is released and loaded then always reset approvals
    if (!this.displayNewVersionDialog && this.redirectedFromEditEngagement) {
      this.redirectedFromEditEngagement = false;
      this.ResetApprovals = true;
    }
    if (this.ResetApprovals) {
      if (this.displayNewVersionDialog) {
        this.resetApprovalData(this.WorkspaceDetails);
      } else {
        this.hdService.getTemplatePackageDetails(this.WorkspaceDetails.hdTemplatePackageId).subscribe(
          TemplateDetail => {
            this.TemplatePackageDetails = TemplateDetail;
            console.log('Template Live version data on template version check', this.TemplatePackageDetails.liveVersionNumber);
            this.WorkspaceDetails.showFutureMessage = false;
            this.WorkspaceDetails.hdLastVersionCheckedAgainst = this.TemplatePackageDetails.liveVersionNumber;
            this.WorkspaceDetails.hdCurrentTempVersion = this.TemplatePackageDetails.liveVersionNumber;
            this.workspaceService.updateTemplateVersionData(this.WorkspaceDetails).subscribe(
              result1 => {
                this.resetApprovalData(this.WorkspaceDetails);
              }, error => {
                this.DisplaySystemAlert();
              });
          });
      }
    } else {
      this.RedirectToWorkspace();
    }
  }

  UpdateDecisionsRPList() {
    if (this.questionBankData.engageDataList && this.questionBankData.engageDataList.length > 0) {
      this.decisionRPRequest.decisionsRPRequest.engageRequest.engagementPartnerEmail = this.questionBankData.engageDataList.filter(keys => keys.key == "EngagementPartner")[0].value;
    }
    else {
      this.decisionRPRequest.decisionsRPRequest.engageRequest.engagementPartnerEmail = "";
    }

    this.decisionRPRequest.decisionsRPRequest.engageRequest.hotDocsDocumentId = this.workspaceType == 'C' ? this.WorkspaceDetails.hdWorkItemId : this.currentEngagement.primaryWorkitemId;
    this.decisionRPRequest.decisionsRPRequest.engageRequest.clientCode = this.currentEngagement.clientCode;

    this.adUsersService.getDecisionUserDetailsByEmailId(this.decisionRPRequest.decisionsRPRequest.engageRequest.engagementPartnerEmail).subscribe(user => {
      this.decisionRPRequest.decisionsRPRequest.engageRequest.engagementPartnerOffice = user.officeLocation;

      let continuanceStatus: boolean = this.currentEngagement.reacceptanceEngagementId > 0;
      this.decisionRPRequest.decisionsRPRequest.engageRequest.continuanceFlag = continuanceStatus;

      this.decisionRPRequest.decisionsRPRequest.engageRequest.solutionCodeAssignments = [];
      let rpWorkSpaceId = this.workspaceType == 'C' ? this.CurrentWorkspaceId : this.primaryWorkspaceId;
      this.engService.GetRPDeterminationAssignmentsDetailByWorkspaceId(rpWorkSpaceId).subscribe((response: RPDeterminationAssignmentsDetail[]) => {

        response.forEach(assignmentDetail => {
          this.decisionRPRequest.decisionsRPRequest.engageRequest.solutionCodeAssignments.push({
            cmsppmdEmail: assignmentDetail.cmsppmdEmailId.split(','),
            eqcrEmail: assignmentDetail.eqcrEmailId.split(','),
            estimatedGrossMargin: assignmentDetail.estimatedGrossMargin,
            estimatedNetFees: assignmentDetail.netFees,
            solutionCode: assignmentDetail.solutionCode
          });
        });

        this.decisionRPRequest.decisionsRPRequest.engageRequest.engagementID = response[0].engagementId.toString();

        this.decisionRuleEngineService.GetReviewingProfessionals(this.decisionRPRequest).subscribe(result => {
          this.decisionsRPResponseViewModel = result;

          let decisionsRPListModel: DecisionsRPListModel;
          decisionsRPListModel = this.BuildAutoRPListFromResponse(this.decisionsRPResponseViewModel)
          this.InsertAutoRPList(decisionsRPListModel);
        },
          error => {
            console.error('GetReviewingProfessionals ', error);
            this.loadingService.stopLoading();
            this.DisplaySystemAlert();
          });
      },
        err => {
          console.error('GetRPDeterminationAssignmentsDetailByWorkspaceId ', err);
          this.loadingService.stopLoading();
          this.DisplaySystemAlert();
        });
    },
      ex => {
        console.error('getDecisionUserDetailsByEmailId ', ex);
        this.loadingService.stopLoading();
        this.DisplaySystemAlert();
      });
  }

  BuildAutoRPListFromResponse(autoRPResponseViewModel: DecisionsRPResponseViewModel) {
    this.decisionsRPListModel.decisionsRPList = [];
    this.decisionsRPListModel.isSuccess = autoRPResponseViewModel.autoRPResponse.engageReturn.isSuccess;
    this.decisionsRPListModel.decisionsApiLogModel.workspaceId = this.workspaceType == 'C' ? this.CurrentWorkspaceId : this.primaryWorkspaceId;
    this.decisionsRPListModel.decisionsApiLogModel.loggedInUser = this.userInfo.userName;
    this.decisionsRPListModel.decisionsApiLogModel.requestType = DecisionsRequestTypes.AutoRP;
    this.decisionsRPListModel.decisionsApiLogModel.requestPayload = JSON.stringify(autoRPResponseViewModel.autoRPRequest);
    this.decisionsRPListModel.decisionsApiLogModel.responseBody = JSON.stringify(autoRPResponseViewModel.autoRPResponse);
    this.decisionsRPListModel.workspaceId = this.workspaceType == 'C' ? this.CurrentWorkspaceId : this.primaryWorkspaceId;
    this.decisionsRPListModel.riskPoints = autoRPResponseViewModel.autoRPResponse.engageReturn.riskPoints;
    this.decisionsRPListModel.errorCode = autoRPResponseViewModel.autoRPResponse.engageReturn.errorCode;
    this.decisionsRPListModel.errorDescription = autoRPResponseViewModel.autoRPResponse.engageReturn.errorDescription;
    this.decisionsRPListModel.loggedInUserFullName = this.userInfo.loggedInUserFullName;
    this.decisionsRPListModel.isDecisionsValuePersisted = this.IsUserEP ? false : (this.IsUserTM ? true : (this.isLoggedInUserSystemAdmin ? false : true));

    if (autoRPResponseViewModel != null && autoRPResponseViewModel.autoRPResponse.engageReturn.reviewingProfessionals != null && autoRPResponseViewModel.autoRPResponse.engageReturn.reviewingProfessionals.length > 0) {
      autoRPResponseViewModel.autoRPResponse.engageReturn.reviewingProfessionals.forEach(role => {
        role.users.forEach(user => {
          let obj: TeamMembers = new TeamMembers();
          // obj.workspaceId = this.primaryWorkspaceId;
          obj.workspaceId = this.workspaceType == 'C' ? this.CurrentWorkspaceId : this.primaryWorkspaceId;
          obj.memberName = user.name;
          obj.isReviewer = 1;
          obj.roleId = 0;
          obj.createdBy = this.userInfo.userName;
          obj.memberEmailId = user.email;
          obj.collaborationSiteId = 0;
          obj.memberRoleName = role.roleName;
          obj.memberRoleDescription = role.roleDescription;
          obj.memberType = RevievingProfessionalType.Auto;
          obj.tier = role.tier;
          this.decisionsRPListModel.decisionsRPList.push(obj);
        });
      });
    }

    return this.decisionsRPListModel;
  }

  getReviewersAndUpdateRPPermission() {
    let reviewerList: TeamMembers[] = [];
    let rpWorkSpaceId = this.workspaceType == 'C' ? this.CurrentWorkspaceId : this.primaryWorkspaceId;
    this.workspaceService.getReviewers(rpWorkSpaceId).subscribe(
      result => {
        reviewerList = result;
        reviewerList = reviewerList.filter(member => member.isReviewer == 1
          && member.memberType != RevievingProfessionalType.AutoNotificationOnlyProfessional);
        let rpPermissionModel: RPPermissionModel = new RPPermissionModel();
        rpPermissionModel.primarySiteUniqueId = this.currentEngagement.siteUniqueID;

        if (reviewerList && reviewerList.length > 0) {
          //Populate Decision RP's into rpPermissionModel
          reviewerList.forEach(member => {
            if (!rpPermissionModel.rpApprovers.includes(member.memberEmailId)) {
              rpPermissionModel.rpApprovers.push(member.memberEmailId);
            }
          });
        }
        //Pass rpPermissionModel to AD group updation
        this.InitinateUpdateRPPermission(rpPermissionModel);

      },
      error => {
        this.loadingService.stopLoading();
        this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      }
    );
  }

  InsertAutoRPList(decisionRPListModel: DecisionsRPListModel) {
    decisionRPListModel.engageUIStatus = this.CurrentWorkspaceStatus;
    this.workspaceService.SaveDecisionDetails(decisionRPListModel).subscribe(result => {
      //-----new code for ReviewersAndUpdateRPPermission
      this.getReviewersAndUpdateRPPermission();

      if (result as number > 0) {
        this.GetDecisionsNotificationPayload(this.WorkspaceDetails.engagementId);
      }
      else {
        console.warn('Inside Else ', result, decisionRPListModel);
        this.loadingService.stopLoading();
        this.DisplaySystemAlert();
      }
    },
      error => {
        console.error('InsertAutoRPList ', error);
        this.loadingService.stopLoading();
        this.DisplaySystemAlert();
      });
  }

  //Get notification request payload
  GetDecisionsNotificationPayload(engagementId: number) {

    this.engService.GetNotificationPayload(engagementId, this.CurrentWorkspaceId).subscribe((payload: Engagement) => {
      var notificaitonRequest: NotificationOnlyRequestModel = new NotificationOnlyRequestModel();
      notificaitonRequest.notificationRequest = payload;

      this.adUsersService.getDecisionUserDetailsByEmailId(notificaitonRequest.notificationRequest.engagementPartnerEmail).subscribe(user => {
        notificaitonRequest.notificationRequest.engagementPartnerOffice = user.officeLocation;

        let continuanceStatus: boolean = this.currentEngagement.reacceptanceEngagementId > 0;
        notificaitonRequest.notificationRequest.continuanceFlag = continuanceStatus;

        //Get notification only professionals from decisions
        this.decisionRuleEngineService.GetNotifications(notificaitonRequest).subscribe((notificationResponse: NotificationResponseViewModel) => {

          //Save notification only professionals
          this.workspaceService.SaveNotificationsOnlyProfessionals(this.BuildNotificationOnlyProfessionalsList(notificationResponse)).subscribe((responseCount: number) => {
            this.ProcessResetApproval();
          },
            error => {
              this.loadingService.stopLoading();
              console.error(error);
            });
        },
          error => {
            this.loadingService.stopLoading();
            console.error(error);
          });
      },
        error => {
          this.loadingService.stopLoading();
          console.error(error);
        });
    },
      error => {
        this.loadingService.stopLoading();
        console.error(error);
      });
  }


  BuildNotificationOnlyProfessionalsList(notificationResponseViewModel: NotificationResponseViewModel): DecisionsNotificationProfessionalsListModel {
    let decisionsNotificationProfessionalsListModel: DecisionsNotificationProfessionalsListModel = new DecisionsNotificationProfessionalsListModel();
    decisionsNotificationProfessionalsListModel.decisionsRPList = [];
    decisionsNotificationProfessionalsListModel.workspaceId = this.workspaceType == 'C' ? this.CurrentWorkspaceId : this.primaryWorkspaceId;
    decisionsNotificationProfessionalsListModel.isSuccess = notificationResponseViewModel.response.done.notificationOnlyReviewingProfessionals.isSuccess;
    decisionsNotificationProfessionalsListModel.decisionsApiLogModel.workspaceId = this.workspaceType == 'C' ? this.CurrentWorkspaceId : this.primaryWorkspaceId;
    decisionsNotificationProfessionalsListModel.decisionsApiLogModel.loggedInUser = this.userInfo.userName;
    decisionsNotificationProfessionalsListModel.decisionsApiLogModel.requestType = DecisionsRequestTypes.GetNotification;
    decisionsNotificationProfessionalsListModel.decisionsApiLogModel.remark = NotificationOnlyRemoveRemarks.TeamReview;
    decisionsNotificationProfessionalsListModel.decisionsApiLogModel.action = notificationResponseViewModel.request.request.notificationRequest.action;
    decisionsNotificationProfessionalsListModel.decisionsApiLogModel.requestPayload = JSON.stringify(notificationResponseViewModel.request);
    decisionsNotificationProfessionalsListModel.decisionsApiLogModel.responseBody = JSON.stringify(notificationResponseViewModel.response);
    decisionsNotificationProfessionalsListModel.decisionRequestType = DecisionsRequestTypes.GetNotification;
    decisionsNotificationProfessionalsListModel.errorCode = notificationResponseViewModel.response.done.notificationOnlyReviewingProfessionals.errorCode;
    decisionsNotificationProfessionalsListModel.errorDescription = notificationResponseViewModel.response.done.notificationOnlyReviewingProfessionals.errorDescription;

    if (notificationResponseViewModel.response.done.notificationOnlyReviewingProfessionals.isSuccess && notificationResponseViewModel.response.done.notificationOnlyReviewingProfessionals.notifications) {
      if (notificationResponseViewModel.response.done.notificationOnlyReviewingProfessionals.notifications
        && notificationResponseViewModel.response.done.notificationOnlyReviewingProfessionals.notifications.length > 0) {
        notificationResponseViewModel.response.done.notificationOnlyReviewingProfessionals.notifications.forEach(notification => {
          if (notification.recipients && notification.recipients.length > 0) {
            notification.recipients.forEach(recipient => {
              if (recipient.users && recipient.users.length > 0) {
                recipient.users.forEach(user => {
                  let obj: TeamMembers = new TeamMembers();
                  // obj.workspaceId = this.primaryWorkspaceId;
                  obj.workspaceId = this.workspaceType == 'C' ? this.CurrentWorkspaceId : this.primaryWorkspaceId; // failsafe check for change order
                  obj.memberName = user.name;
                  obj.isReviewer = 1;
                  obj.roleId = 0;
                  obj.createdBy = this.userInfo.userName;
                  obj.memberEmailId = user.email;
                  obj.collaborationSiteId = 0;
                  obj.memberRoleName = recipient.role;
                  obj.memberRoleDescription = recipient.role;
                  obj.notificationTrigger = notification.notificationTrigger;
                  obj.memberType = RevievingProfessionalType.AutoNotificationOnlyProfessional;
                  obj.engageWorkflowStage = this.GetNotificationWorkflowStage(notification.notificationTrigger);
                  decisionsNotificationProfessionalsListModel.decisionsRPList.push(obj);
                });
              }
            });
          }
        });
      }
    }

    return decisionsNotificationProfessionalsListModel;
  }

  GetNotificationWorkflowStage(action: string) {
    switch (action.toLowerCase()) {
      case WorkspaceInterviewsStatus.EPReviewPending.toLowerCase():
        return NotificationWorkflowStage.FinishQuestionnaire;
      case WorkspaceInterviewsStatus.EPPMDReview.toLowerCase():
        return NotificationWorkflowStage.TeamReviewApproved;
      case WorkspaceInterviewsStatus.RPInReview.toLowerCase():
        return NotificationWorkflowStage.EPPMDReviewApproved;
      case WorkspaceInterviewsStatus.Approved.toLowerCase():
        return NotificationWorkflowStage.FinalApproved;
    }
  }

  InitinateUpdateRPPermission(rpPermissionModel: RPPermissionModel) {
    this.activedirectoryService.UpdateRPPermission(rpPermissionModel).subscribe(
      result => {
        console.log('UpdateRPPermissionResult ==>', result);
      },
      error => {
        this.loadingService.stopLoading();
        this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }
  CleartTimerAlert() {
    clearInterval(this.myQuestionnaireLoadingInterval);
    this.alertService.remove(this.alert);
  }
  EditEngagement() {
    this.router.navigate(['EditEngagement/' + this.WorkspaceDetails.engagementId]);
  }
  SetLoggedInUserRole() {
    this.workspaceService.getMembers(this.CurrentWorkspaceId).subscribe(
      memberResult => {
        this.memberList = memberResult;
        console.log(this.memberList.length);
        console.log(this.memberList);
        if (this.memberList.length > 0) {
          for (var i in this.memberList) {
            if (this.memberList[i].memberEmailId.toLowerCase() == this.userInfo.userName.toLowerCase()) {
              if (this.memberList[i].teamMemberRole == EnumRoles.EngagementPartner) {
                this.IsUserEP = true;
                this.IsUserTM = true;
              } else if (this.memberList[i].teamMemberRole === EnumRoles.TeamMember ||
                this.memberList[i].teamMemberRole === EnumRoles.EQCR ||
                this.memberList[i].teamMemberRole === EnumRoles.Creator ||
                this.memberList[i].teamMemberRole === EnumRoles.StaffMember) {
                this.IsUserTM = true;
              }
            }
          }
        }
      });
  }

  StoreDataModification(engagementId: number) {
    this.dataModification.StoreDataModification(engagementId, DataModificationModuleNames.Questionnaire).subscribe(
      result => {
        console.log('Store Engagement Id ', engagementId, ' Modification Status ', result);
      }, error => {
        console.error('StoreDataModification error ', error);
      }
    );
  }

  UpdateDataModification(engagementId: number) {
    if (engagementId > 0)
      this.dataModification.UpdateDataModification(engagementId, DataModificationModuleNames.Questionnaire).subscribe(
        result => {
          console.log('Update Engagement Id ', engagementId, ' Modification Status ', result);
        }, error => {
          console.error('UpdateDataModification error ', error);
        }
      );
  }

  StoreReviewResponse() {
    let reviewResponseHtml = HD$.GetAnswerSummary();
    const responseModel = new ReviewResponseModel();
    responseModel.createdBy = this.userInfo.userName;
    responseModel.reviewResponseHtml = reviewResponseHtml;
    responseModel.engagementId = this.engagementId;
    responseModel.workspaceId = this.WorkspaceDetails.workspaceId;
    responseModel.workItemId = this.WorkspaceDetails.hdWorkItemId;
    responseModel.isUpdatedQuestionnaire = this.currentEngagement.appliedTnC;
    this.workspaceService.storeReviewResponseLog(responseModel).subscribe(
      result => {
        console.log('storeReviewResponseLog func ', result);
      },
      error => {
        console.error('storeReviewResponseLog func ', error);
        this.workspaceService.storeErrorLog(error);
      }
    );
  }

  //#region floating review note code 
  ConfigureReviewNoteSearch() {
    this.reviewNoteSearchableUsers = [];

    setTimeout(() => {

      if (this.reviewerList && this.reviewerList.length > 0) {
        this.reviewerList.forEach(user => {
          if (user.memberType != RevievingProfessionalType.AutoNotificationOnlyProfessional && this.reviewNoteSearchableUsers.filter(item => item.email == user.memberEmailId).length == 0) {
            this.reviewNoteSearchableUsers.push({ key: user.memberName, value: user.memberName, email: user.memberEmailId });
          }
        })
      }

      if (this.memberList && this.memberList.length > 0) {
        this.memberList.forEach(user => {
          if (this.reviewNoteSearchableUsers.filter(item => item.email == user.memberEmailId).length == 0) {
            this.reviewNoteSearchableUsers.push({ key: user.memberName, value: user.memberName, email: user.memberEmailId });
          }
        })
      }

      if (this.reviewNoteSearchableUsersFromQualityGroups && this.reviewNoteSearchableUsersFromQualityGroups.length > 0) {
        this.reviewNoteSearchableUsersFromQualityGroups.forEach(user => {
          if (this.reviewNoteSearchableUsers.filter(item => item.email == user.email).length == 0) {
            this.reviewNoteSearchableUsers.push({ key: user.key, value: user.value, email: user.email });
          }
        })
      }
    }, 1000);
  }

  getWorkspaceMembers() {
    const getRps = this.workspaceService.getReviewers(this.CurrentWorkspaceId); //changed to CurrentWorkspaceId from primaryworkspaceId conseidering changeOrders
    const getQualityADGroups = this.adUsersService.GetQaulityADGroupsByWorkspaceId(this.CurrentWorkspaceId);
    forkJoin([getRps, getQualityADGroups]).subscribe(
      responses => {
        this.reviewerList = responses[0];
        this.isLoggedInUserIsPureNotificationOnlyProfesional();
        let qaulityGroups: AzureADGroups = new AzureADGroups();
        if (responses[1] && responses[1].length > 0) {
          qaulityGroups.azureADUsers = responses[1];
          this.graphSerivce.getAzureAdUsersByGroups(qaulityGroups, RoleNames.QualityGroup).subscribe(
            qaulityUsers => {
              if (qaulityUsers && qaulityUsers.length > 0) {
                qaulityUsers.forEach(user => {
                  if (this.reviewNoteSearchableUsersFromQualityGroups.filter(addedUser => addedUser.email == user.mail).length == 0) {
                    this.reviewNoteSearchableUsersFromQualityGroups.push({ key: user.displayName, value: user.displayName, email: user.mail });
                  }
                });
                this.ConfigureReviewNoteSearch();
              }
            },
            error => {
              this.DisplaySystemAlert();
            });
        } else {
          this.ConfigureReviewNoteSearch();
        }
      },
      error => {
        this.DisplaySystemAlert();
      });
  }
  async DisplayReviewNotes() {
    //To collapse left nav
    this.dataService.setCollapsibleLeftNav(true);
    this.dataService.setCloseReviewNotes(false);
    $('.margin-all').addClass('compressDiv');
    $('.containerDiv').addClass('compresscontainerDiv');
    $('#supplDoc').addClass('supplDocTable');

    //Load review notes component
    this.loadingService.startLoading();
    setTimeout(() => {
      const componentFactorya = this.cfr.resolveComponentFactory(ReviewNotesComponent);
      this.reviewNoteQueComponentRef = this.viewContainerRef.createComponent(componentFactorya);
      this.reviewNoteQueComponentRef.instance.isUserEP = this.IsUserEP;
      this.reviewNoteQueComponentRef.instance.isUserRP = this.IsUserRP;
      this.reviewNoteQueComponentRef.instance.isPureNotificationOnlyProfessional = this.isPureNotificationOnlyProfessional;
      this.reviewNoteQueComponentRef.instance.isUserTM = this.IsUserTM;
      this.reviewNoteQueComponentRef.instance.reviewId = this.engagementId;
      this.reviewNoteQueComponentRef.instance.reviewNoteSearchableUsers = this.reviewNoteSearchableUsers;
      this.reviewNoteQueComponentRef.instance.selectedWorkspaceDocumentId = this.WorkspaceDetails.documentAliasName;
      this.reviewNoteQueComponentRef.instance.showReviewNoteModal();
      this.loadingService.stopLoading();
      this.reviewNoteQueComponentRef.instance.closeReviewNotesModal.subscribe(isTrue => {
        this.loadingService.stopLoading();
        this.viewContainerRef.clear();
      });
    }, 1000);

  }

  getWorkspaceReviewPointsCount() {
    this.workspaceReviewPointService.getReviewNotesCount().subscribe(points => {
      this.workspaceOpenReviewPointsCount = points;
    });
  }
  isLoggedInUserIsPureNotificationOnlyProfesional() {
    let IsTeamMember = false;
    let IsRP = false;
    let IsNOP = false;
    let isLoggedInUserIsAutoOrManualRP = false;

    isLoggedInUserIsAutoOrManualRP = false;
    var i = 0, j = 0;
    if (this.memberList && this.memberList.length > 0) {
      for (i = 0; i < this.memberList.length; i++) {
        if (this.memberList[i].memberEmailId.toLowerCase() == this.userInfo.userName.toLowerCase()) {
          IsTeamMember = true;
          break;
        }
      }
    }

    if (this.reviewerList && this.reviewerList.length > 0) {
      for (j = 0; j < this.reviewerList.length; j++) {
        if (this.reviewerList[j].memberEmailId?.toLowerCase() == this.userInfo.userName.toLowerCase()
          && this.reviewerList[j].teamMemberRole == EnumRoles.NotificationOnlyProfessional
          && this.reviewerList[j].memberType == RevievingProfessionalType.AutoNotificationOnlyProfessional) {
          IsNOP = true;
        } else if (this.reviewerList[j].memberEmailId?.toLowerCase() == this.userInfo.userName.toLowerCase()) {
          IsRP = true;
          this.IsUserRP = true;
        }
      }
    }
    isLoggedInUserIsAutoOrManualRP = IsRP;
    this.isPureNotificationOnlyProfessional = (!IsTeamMember && !IsRP && IsNOP && !this.isLoggedInUserSystemAdmin);
  }
  getWorkspaceReviewPoints() {
    return this.workspaceReviewPointService
      .getReviewPointsWithComments(this.CurrentWorkspaceId, false)
      .subscribe(
        result => {
          var list = result.reviewPointList.filter(x => x.status == 1);
          this.workspaceReviewPointService.setReviewNotesCount(list.length);
          this.getWorkspaceReviewPointsCount();
        },
        error => {
          this.DisplaySystemAlert();
        }
      );
  }

  setUnsavedChangesForIwantTo() {
    if (!this.FinishSessionFlagCheck && !this.SaveSessionFlagCheck && !this.EditEngagementBtnClicked) {
      if (!this.ForceUserToCompleteSession) {
        const dialogData = {
          fromComponent: 'interviews',
          isUnsavedChangesExist: true
        };
        const dialogJSON = JSON.stringify(dialogData);
        this.data.setUnsavedChangesModel(dialogJSON);
      }
    }
  }
  ResetIwantToUnsavedFlag() {
    const dialogData = {
      fromComponent: '',
      isUnsavedChangesExist: false
    };
    const dialogJSON = JSON.stringify(dialogData);
    this.dataService.setUnsavedChangesModel(dialogJSON);
    this.dataService.setClientIdForPrePopulate(-1);
    this.dataService.setEngagementIdForPrePopulate(-1);

  }

  //#endregion

  /* START sharepoint supplement big file upload through graph api*/

  getSharepointBigFileFeatureFlag() {
    this.engService.getSharepointBigFileFeatureFlag().subscribe(result => {
      this.isSharepointBigFileFeatureFlagEnabled = result;
      if (this.isSharepointBigFileFeatureFlagEnabled) {
        this.uploadFileSize = this.uploadMaxFileSize //In Bytes (=100MB)
      }
    }, error => {
      console.error("unable to get sharepoint file upload limit feature flag." + error)
    });
  }

  getSharepointSupplementFileUploadLimit() {
    this.engService.getSharepointSupplementFileUploadLimit().subscribe(result => {
      if (result) {
        this.uploadMaxFileSize = result * 1024 * 1024 //In Bytes (=100MB)
        if (this.isSharepointBigFileFeatureFlagEnabled) {
          this.uploadFileSize = this.uploadMaxFileSize //In Bytes (=100MB)
        }
      }
    }, error => {
      console.error("unable to get sharepoint file upload limit." + error)
    });
  }
  convertDataURIToBinary(dataURI) {
    var base64Index = dataURI.indexOf(';base64,') + ';base64,'.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for (var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }
  uploadSupplementalFileToSharepointBigFile(filename: string, fileByteArray: any) {
    //Add updated File Upload 
    this.sharepointUploadSiteDocumentModel.SiteId = this.currentEngagement.siteUrl.split('/')[4];
    this.sharepointUploadSiteDocumentModel.DocumentPath = "Supplemental"; //this.currentEngagement.supplementalFolderPath;
    this.sharepointUploadSiteDocumentModel.DocumentName = filename;
    this.sharepointUploadSiteDocumentModel.DocumentLibraryName = "Acceptance/Continuance";
    this.sharepointUploadSiteDocumentModel.DocumentByteArray = fileByteArray
    this.sharepointUploadSiteDocumentModel.documentFolderPath = this.currentEngagement.supplementalFolderPath;
    this.sharepointUploadSiteDocumentModel.siteURL = this.currentEngagement.siteUrl;
    this.sharepointUploadSiteDocumentModel.useremailId = this.userInfo.userName;
    // call Sharepoiint API
    this.sharepointService.uploadDocumentToSharepoint(this.sharepointUploadSiteDocumentModel).subscribe(
      result => {
        const strResult = result;
        if (strResult.toLowerCase().trim() === 'true') {
          this.GetAllDocumentsfromSPLibraryForSuppl(this.currentEngagement.siteUrl, this.currentEngagement.supplementalFolderPath);
        }
      },
      error => {
        this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }
  /* END sharepoint supplement big file upload through graph api*/

  /** HotDocs Caching issue fixes START*/

  ValidateHdTemplateCache() {
    var canReload = this.hdService.canReloadPageForHotdocsTemplate(
      this.WorkspaceDetails.documentAliasName,
      this.WorkspaceDetails.hdTemplatePackageId,
      this.WorkspaceDetails.hdCurrentTempVersion
    );
    if (canReload) {
      this.ReloadPage(this.WorkspaceDetails);
    }
  }

  private SetUIStateBeforePageReload() {
    if (this.displayNewVersionDialog == false) {
      sessionStorage.setItem('canDisplayNewVersionDialog', "false");

    }
    if (this.redirectedFromEditEngagement) {
      sessionStorage.setItem('isRedirectedFromEditEngagement', "true");
    }
  }

  private ReloadPage(selectedWorkspacedocModel: WorkspaceModel, forcefull = false) {
    //Reload page so the saved hotdocs template version with answers will be displayed in UI properly. 
    let result = sessionStorage.getItem('isPageReloaded');
    if (result != 'true' || forcefull) {
      this.SetUIStateBeforePageReload();
      this.hdService.RemoveHdTemplateVersionListInBrowserSession();
      this.hdService.UpdateHdTemplateVersionInBrowserSession(
        selectedWorkspacedocModel.documentAliasName,
        selectedWorkspacedocModel.hdTemplatePackageId,
        selectedWorkspacedocModel.hdCurrentTempVersion
      );
      sessionStorage.setItem('isPageReloaded', "true");
      HD$.ReattachSession();
    }
  }

  private RestoreUIStateAfterPageLoaded() {
    var isPageReloadedSession = sessionStorage.getItem("isPageReloaded");
    if (isPageReloadedSession != null && isPageReloadedSession != undefined) {

      var canDisplayNewVersionDialog = sessionStorage.getItem('canDisplayNewVersionDialog');
      if (canDisplayNewVersionDialog != null && canDisplayNewVersionDialog != undefined) {
        this.displayNewVersionDialog = false;
      }

      var isRedirectedFromEditEngagement = sessionStorage.getItem('isRedirectedFromEditEngagement');
      if (isRedirectedFromEditEngagement != null && isRedirectedFromEditEngagement != undefined) {
        this.redirectedFromEditEngagement = true;
      }

      sessionStorage.removeItem('canDisplayNewVersionDialog');
      sessionStorage.removeItem('isRedirectedFromEditEngagement');
    }
  }


  deleteAuditPartnerFromADGroup() {
    let adUser: ADUser = new ADUser();
    adUser.firstName = '';
    adUser.lastName = '';
    adUser.userEmailId = this.workspaceAuditPartner.deletedAuditPartnerEmailId;

    let UserADGroups: ADGroup = {
      groupUsersEmail: [adUser],
      groupType: 'Member',
    };
    this.activedirectoryService.DeleteUsersToADGroup(this.currentEngagement.siteUniqueID + "_Owners", UserADGroups).subscribe(
      () => {
        console.log('User deleted successfully');
      },
      (error) => {
        console.error('Error deleting user:', error);
      }
    );
  }
  /** HotDocs Caching issue fixes END*/

  public getCLMGoverningAgrementNameStatus() {
    this.gaNameAndStatusRequest = new GoverningAgreementNameAndStatusRequest();
    let engagementIdTemp = this.engagementId.toString();
    this.gaNameAndStatusRequest.engagementID = engagementIdTemp;
    if (this.engagementId > 0)
      this.clmService.getGoverningAgreementNameAndStatusByEngagementId(this.gaNameAndStatusRequest).subscribe(result => {
        if (result.statusCode == "OK") {
          this.gaNameAndStatusResponse = result;
          this.clmGoverningAgreementStatusDiv.nativeElement.innerHTML = this.gaNameAndStatusResponse.htmlData;
          this.PopupDisplay(this.gaNameAndStatusResponse.htmlData);
          this.injectCLMCloseButtonDynamically();
        }
        this.insertCLMApiLog(this.engagementId);
      });
  }

  injectCLMCloseButtonDynamically() {// inject CLM close buttton if secof and third popup clicked
    setTimeout(() => { // wating  to load clm popup html
      var subject = document.querySelector(".clmmodal-body");
      const elc = document.getElementById('clmpopupCloseButton')
      if (elc == undefined) {
        subject?.insertAdjacentHTML(
          "afterbegin",
          `<button id="clmpopupCloseButton" type="button" (click)="clmPopupClosebuttonClicked()"  class="close clm-close"><span  class="close-text mr-1">Close </span><span  class="modal-close-btn">×</span></button>`,
        );
        setTimeout(() => { // wating  to load clm popup lose button html
          const el = document.getElementById('clmpopupCloseButton');
          el?.addEventListener('click', this.clmPopupClosebuttonClicked.bind(this))
        }, 100);
      }
    }, 500);
  }
  clmPopupClosebuttonClicked(event) { //close butto clicked of clm Popup
    document.getElementById('myModal').style.display = 'none';
  }

  insertCLMApiLog(engagementId: number) {
    let clmApiLog = new CLMApiLog();
    clmApiLog.engagementId = engagementId;
    clmApiLog.requestType = CLMApiLogrRequestType.GoverningAgreementStatus;
    clmApiLog.requestPayload = JSON.stringify(this.gaNameAndStatusRequest);
    clmApiLog.responseBody = JSON.stringify(this.gaNameAndStatusResponse);
    clmApiLog.createdBy = this.userInfo.loggedInUserEmail;
    if (this.gaNameAndStatusResponse.statusCode != "OK") {
      clmApiLog.errorCode = this.gaNameAndStatusResponse.statusCode;
      clmApiLog.errorDescription = "Failed to get Governing Agreement Name And Status Response  on Interview Page.";
    }
    else {
      clmApiLog.errorCode = "";
      clmApiLog.errorDescription = "";
    }
    this.clmService.insertCLMApiLog(clmApiLog).subscribe({
      next: clmApiLogId => {
      }, error: err => {
        console.error("Failed to insert api log for Governing Agreement Name And Status Response")
      }
    })
  }

  PopupDisplay(htmlurl: string) {
    var newDiv = document.createElement("div");
    newDiv.innerHTML = htmlurl;
    var scripts = newDiv.getElementsByTagName('script');
    for (var ix = 0; ix < scripts.length; ix++) {
      let clmScript: HTMLScriptElement = document.createElement('script');
      clmScript.async = true;
      clmScript.innerHTML = scripts[ix].text;
      document.head.prepend(clmScript);
    }
  }
  getCLMFeatureFlag() {
    this.clmService.getCLMFeatureFlag().subscribe(result => {
      this.isCLMFeatureEnabled = result;
    });
  }

  redirectToCorrectEngagementState(workspaceId: string) {
    let request = new EngagementStatusViewModel();
    request.workspaceId = workspaceId;
    let isInProgressDataModificationExistsSub = this.dataModification.IsInProgressDataModificationExists(this.WorkspaceDetails.engagementId, DataModificationModuleNames.Questionnaire, workspaceId);
    let getEngagementStatusSub = this.engService.getEngagementStatus(request);
    forkJoin([isInProgressDataModificationExistsSub, getEngagementStatusSub]).subscribe({
      next: result => {
        let questionnaireInprogressResult = result[0];
        this.engagementStatusViewModel = result[1];
        if (questionnaireInprogressResult.editStatus == true && questionnaireInprogressResult.editingMembers?.length > 0)
          this.skipRedirect = true;
        let prevPath = this.data.previousUrl.getValue();
        let isPageReloaded = sessionStorage.getItem('isPageReloaded');
        let isHardRefreshPageReloaded = sessionStorage.getItem('isHardRefreshPageReloadedI');
        let redirectURL = this.engService.getRedirectPathByEngagementStatus(this.engagementStatusViewModel);
        let isLoginLogoutURL = false;
        if (prevPath?.includes("Login?returnUrl=")) {
          isLoginLogoutURL = true;
        }
        if (!this.skipRedirect && !isPageReloaded && !isHardRefreshPageReloaded && this.workspaceType == "E") {
          this.activatedRoute.snapshot;
          if (redirectURL?.length > 0 && !prevPath?.includes(redirectURL) && (prevPath == undefined || isLoginLogoutURL)) {
            sessionStorage.removeItem('isPageReloaded');
            sessionStorage.removeItem('isHardRefreshPageReloadedI');
            this.router.navigate([redirectURL]);
          } else {
            sessionStorage.setItem('isHardRefreshPageReloadedI', "true");
            this.getWorkspaceDetailsById();
          }
        } else {
          this.getWorkspaceDetailsById();
        }

      }
    })
  }

  StoreHotDocsInterview() {

    let Interviewdata = HD$.GetInterviewStateInfo();
    let EngagementId = this.engagementId;
    this.hotDocsResponseModel.data = Interviewdata;
    this.hotDocsResponseModel.engagementId = EngagementId;
    this.hotDocsResponseModel.createdBy = this.userInfo.loggedInUserEmail;

    this.workspaceService.StoreHotDocsAnswers(this.hotDocsResponseModel).subscribe(result => {
      console.log('result: ', result);
    });
  }

  CloseReviewNotesPanel() {
    if (this.isCollapsibleLeftNavVisible) {
      this.data.setCloseReviewNotes(true);
      this.data.setCollapsibleLeftNav(false);
      $('.containerDiv').removeClass('compressRevContainer');
    }
  }
  workspaceActionButtonClick(action: string, isDeclineModal: boolean) {
    console.log(action);
    this.CloseReviewNotesPanel();
    // this.loadingService.startLoading();
    this.workspaceService.GetWorkspaceCurrentStatus(this.CurrentWorkspaceId).subscribe(
      result => {
        this.CurrentWorkspaceStatus = result;
        console.log('GetWorkspaceCurrentStatus func called ', this.CurrentWorkspaceStatus, result);
        this.setDialogContent(action, 'Are you sure you want to abandon this engagement?');
      },
      err => {
        console.log('Error in updating workspace status.', err);
        this.loadingService.stopLoading();
      }
    );
  }
  initiateConfirmationPopUp() {
    this.modalConfirmationDialogRef = this.data.getModalDetails$.subscribe(
      dialogData => {
        if (dialogData) {
          const dialogModal = JSON.parse(dialogData);
          const dailogTitle = dialogModal.dialogTitle;
          // Only handle the AbandonedTitle case
          if (dailogTitle === EngagementDialogTitles.AbandonedEngagement) {
            this.startProcessforAbandon(dialogModal);
          }
          else if (dailogTitle === 'Remove document') {
            this.RemoveSupplDoc();
          } else {
            console.log('No action for this dialog title:', dailogTitle);
          }
        }
      });
  }
  startProcessforAbandon(dialogModal: any) {
    if (dialogModal != null) {
      const btnName = dialogModal.dialogButtonName;
      if (btnName === 'ok') {
        this.initinateProcessforAbandon();
      } else if (btnName === 'cancel' || btnName === 'close') {
        this.isApprove = false;
        console.log('this.isApprove status set to false');
      }
    }
  }
  findCurrentUserRolesInWorkspace() {
    let shortRoleName = '';
    if (this.IsUserRP === true) {
      shortRoleName = shortRoleName + 'RP' + ',';
    }
    if (this.IsUserEP === true) {
      shortRoleName = shortRoleName + 'EP' + ',';
      shortRoleName = shortRoleName + 'SM' + ',';
    } else if (this.IsUserTM === true) {
      shortRoleName = shortRoleName + 'SM' + ',';
    }
    return shortRoleName;
  }
  initinateProcessforAbandon() {
    let resuletext: string = '';
    let shortRoleName = '';
    this.loadingService.startLoading();
    this.workspaceStatusUpdateViewModel.status = WorkspaceInterviewsStatus.Abandoned;
    this.workspaceStatusUpdateViewModel.workspaceId = this.CurrentWorkspaceId;
    shortRoleName = this.findCurrentUserRolesInWorkspace();
    this.workspaceStatusUpdateViewModel.roleShortName = shortRoleName.trim();
    //Set System Admin role as short name when admin dose sent to eppmd
    if ((!this.IsUserTM && !this.IsUserEP) && this.isLoggedInUserSystemAdmin) {
      this.workspaceStatusUpdateViewModel.roleShortName = 'SA';
    }
    this.workspaceService.UpdateWorkspaceStatusData(this.workspaceStatusUpdateViewModel).subscribe(
      result => {
        this.approveRejectWorkspaceResultModel = result;
        this.deleteAwaitingApprovalClientsForNoEngagement();
        this.loadingService.stopLoading();
        if (this.approveRejectWorkspaceResultModel.approveRejectResult === 'Success') {
          this.MarkQnARecordDeleted(this.continuanceWorkItemData.reacceptanceEngagementId, '');
          sessionStorage.removeItem('isHardRefreshPageReloadedW');
        } else {
          this.shouldNotifyEPPMD = false;
        }
        if (this.isConveneProjectsExists) {
          this.SendEngagementClosureNotification();
        }
        else {
          // skip to Send Engagement ClosureNotification in convene
          this.loadingService.stopLoading();
        }
        sessionStorage.removeItem('isHardRefreshPageReloadedW');
        this.router.navigate(['MyEngagements']);
      },
      err => {
        console.log('Error in updating workspace status.', err);
        this.loadingService.stopLoading();
      }
    );
  }
  deleteAwaitingApprovalClientsForNoEngagement() {
    let cm = new ClientDeleteModel();
    cm.clientId = this.clientId;
    cm.engagementId = this.engagementId;
    this._clientService.deleteClientsForEngagement(cm).subscribe({
      next: result => {
      }, error: err => {
        console.error("Error in soft deleting the client", err);
      }
    })
  }
  MarkQnARecordDeleted(engagementId: number, workItemId: string) {
    let record = new QnARecordForDelete();
    record.engagementId = engagementId;
    record.workItemId = workItemId;
    record.updatedBy = this.userInfo.userName;
    this.workspaceService.MarkQnARecordAsDeleted(record).subscribe(
      result => {
        console.log('MarkQnARecordDeleted response as ', result);
      },
      error => {
        console.error('Error in MarkQnARecordDeleted', error)
      }
    )
  }
  SendEngagementClosureNotification() {
    this.loadingService.startLoading();
    let conveneApiLogModel: ConveneApiLogModel = new ConveneApiLogModel();
    let closureRequestModel: ClosureRequestModel = new ClosureRequestModel();
    this._engService.getRetentionSecAppliedAndSuccessorFirmFlags(this.currentEngagement.siteUniqueID).subscribe(result => {
      this._engService.getEngagementActualCloseoutInDays(result.isRetentionSectionApplied).subscribe(noofdays => {
        closureRequestModel.engageEngagementId = this.CurrentWorkspaceData.engagementId;
        closureRequestModel.closeOutInitiatedBy = this.userInfo.loggedInUserEmail;
        closureRequestModel.closingOutTimespan = noofdays.actualCloseoutInDays;
        closureRequestModel.engageEngagementStatus = this.workspaceStatusUpdateViewModel.status;
        this.closureService.Notify(closureRequestModel).subscribe(closureViewModel => {
          conveneApiLogModel.requestPayload = JSON.stringify(closureViewModel.closureNotificationRequest);
          conveneApiLogModel.responseBody = JSON.stringify(closureViewModel.closureNotificationResponse);
          conveneApiLogModel.engagementId = this.CurrentWorkspaceData.engagementId;
          conveneApiLogModel.httpMethod = RequestMethods.RequestHttpPost;
          conveneApiLogModel.requestType = ConveneRequestTypes.SendClosureNotification;
          conveneApiLogModel.loggedInUser = this.userInfo.loggedInUserEmail;
          conveneApiLogModel.status = closureViewModel.closureNotificationResponse.status;
          conveneApiLogModel.statusCode = closureViewModel.closureNotificationResponse.statusCode;
          conveneApiLogModel.statusDescription = closureViewModel.closureNotificationResponse.errorMessage;
          //  this.loadingService.startLoading();
          this.closureService.SaveClosureNotificationResponse(conveneApiLogModel).subscribe(affectedRows => {
            if (affectedRows > 0) {
              //success      
              this.loadingService.stopLoading();
            }
          },
            err => {
              this.loadingService.stopLoading();
              console.error(err);
            });
        });
      });
    },
      (err) => {
        conveneApiLogModel.requestPayload = JSON.stringify(closureRequestModel);
        conveneApiLogModel.responseBody = JSON.stringify(err);
        conveneApiLogModel.engagementId = this.CurrentWorkspaceData.engagementId;
        conveneApiLogModel.httpMethod = RequestMethods.RequestHttpPost;
        conveneApiLogModel.requestType = ConveneRequestTypes.SendClosureNotification;
        conveneApiLogModel.isSentSuccessfully = false;
        conveneApiLogModel.loggedInUser = this.userInfo.loggedInUserEmail;
        conveneApiLogModel.status = false;
        conveneApiLogModel.statusCode = '400';
        conveneApiLogModel.statusDescription = err;
        this.loadingService.startLoading();
        this.closureService.SaveClosureNotificationResponse(conveneApiLogModel).subscribe(affectedRows => {
          if (affectedRows > 0) {
            //success
          }
          this.loadingService.stopLoading();
        },
          (errorDetails) => {
            this.loadingService.stopLoading();
            console.log(err);
          });
      });
  }
  getChangeOrderFeatureFlagLoadWorkspaceDetails() {
    this.workspaceService.getChangeOrderFeatureFlag().subscribe({
      next: result => {
        this.changeOrderFeatureFlag = result;
        this.getWorkspaceTypeByWorkSpaceId();
      }
      , error: er => {
        this.changeOrderFeatureFlag = false;
        console.log("failed to load changeOrderFeatureFlag");
      }
    })
  }
  getWorkspaceTypeByWorkSpaceId() {
    this.workspaceService.getActivityTypeByWorkSpaceId(this.CurrentWorkspaceId).subscribe({
      next: result => {
        this.workspaceType = result;
        if (this.workspaceType == "E")
          this.redirectToCorrectEngagementState(this.CurrentWorkspaceId);
        else if (this.workspaceType == "C") {
          this.getEngagementChangeOrdersByWorkSpaceId(this.CurrentWorkspaceId);
          this.getWorkspaceDetailsById();
        }
      },
      error: err => {
        console.log("failed to load workspace type", this.CurrentWorkspaceId);
      }
    })
  }
  getEngagementChangeOrdersByWorkSpaceId(workSpaceId: string) {

    return this.changeOrderService.getEngagementChangeOrdersByWorkSpaceId(workSpaceId)
      .subscribe({
        next: result => {
          this.engagementChangeOrders = result;
          if (this.engagementChangeOrders != null) {
            this.engagementId = this.engagementChangeOrders.engagementId;
            this.engagementName = this.engagementChangeOrders.engagementName;
            this.changeOrders = this.engagementChangeOrders.changeOrders?.filter(x => x.status == 'Approved');
          }
        }, error: err => {
          console.error("failed  to load Engagement ChangeOrders -getEngagementChangeOrdersByWorkSpaceId ", err);

        }
      })
  }
  formatDate(date: any) {
    const formattedDate = new Date(date);
    return (this.datePipe.transform(formattedDate, 'MM/dd/yyyy'));
  }
  onChangeOrdersChange() {
    if (this.selectedChangeOrderId != undefined && this.selectedChangeOrderId !=-1) {

       let selectedChangedOrder =  this.engagementChangeOrders.changeOrders?.find(x => x.changeOrderId == this.selectedChangeOrderId)
        if (selectedChangedOrder!= undefined && selectedChangedOrder!=null)
          {
           this.isPreviousChangeorderSelected=true;
           this.previousChangeOrderWSId = selectedChangedOrder.workspaceId;
           
          }
    }
    else
    {
      this.isPreviousChangeorderSelected=false;
      this.previousChangeOrderWSId ='';
    }
    }
  changeOrderEditClick(){
    const currentPath = this.activatedRoute.snapshot.url.join('/'); 
    if (currentPath != null && currentPath != undefined && currentPath != ""){
       if (this.engagementChangeOrders != null && this.engagementChangeOrders?.changeOrders != null 
           && this.engagementChangeOrders?.changeOrders?.length > 0){
           var pathValues = currentPath.split('/'); 
           var changeOrderValue =  this.engagementChangeOrders?.changeOrders.find( x=> x.workspaceId == pathValues[1]);
           var changeOrderid = changeOrderValue.changeOrderId;
           this.router.navigate(['EditChangeOrder/' + changeOrderid]);
       }
    }
   
  }

}