export class 
NewClient {
  key: string;
  value: string;
  clientId: number;
  engagementId: number;
  clientName: string;
  clientProgram: string;
  clientCode: string;
  referralSource: string;
  clientType: string;
  stockExchange: string;
  tickerSymbol: string;
  fye: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  city: string;
  stateId: number;
  countryId: number;
  zipCode: string;
  phoneNumber: string;
  fax: string;
  status: string;
  clientStatusId: number;
  sicCodeId: string;
  sicCode: string;
  industryType: string;
  websiteAddress: string;
  isAudit: boolean;
  isTax: boolean;
  isAdvisory: boolean;
  userName: string;
  createdby: string;
  createdon: string;
  updatedBy: string;
  updatedon: string;
  clientTypeId: number;
  clientProgramId: number;
  industryTypeId: number;
  stockExchangeId: number;
  referralSourceId: number;
  fyeMonth: number;
  fyeDay: number;
  isAgreement: boolean;
  isMSA: boolean;
  msaType: string;
  elType: string;
  msaDocDate: string;
  msaExpDate: string;
  clientShortName: string;
  isClientDisabled: boolean;
  firstName: string;
  middleName: string;
  lastName: string;
  clientContactName: string;
  contactTypeCodeId: number;
  clientContactEmail: string;
  clientBillingName: string;
  billingContactTitleCodeId: number;
  clientBillingEmail: string;
  structEntityId: number;
  international: string;
  transnational: string;
  billingRegion: string;
  oneViewAccountId: string;
  clientStatus: string;
}


export class ClientStatus {
  id: number;
  typeValue: string;
}

export class CMSClient {
  client_code_name: string;
  is_audit_client: string;
  client_international_flag: string;
  is_inactive: string;
  service_line_name: string;
  client_status_code: string;
  client_administrator_code: string;
  client_administrator_name: string;
  location_code: string;
  address_line_1: string;
  client_type_code: string;
  client_type_description: string;
  is_client_transnational: string;
  address_line_3: string;
  address_line_2: string;
  source_system_id: string;
  client_name: string;
  address_line_4: string;
  is_ril_list: string;
  address_code: string;
  client_category_description: string;
  client_status_description: string;
  location_description: string;
  service_line_code: string;
  client_background_check_date: string;
  client_close_date: string;
  client_open_date: string;
  region_description: string;
  year_end_month: string;
  source_modified_date: string;
  client_category_code: string;
  line_of_business_code: string;
  client_code: string;
  line_of_business_description: string;
  region_code: string;

}

export enum ClientDialogTitles {
  createClient = 'Client created successfully',
  createClientDescription = 'This client has been created in the Engage. The client’s details will be available in CMS after this engagement has been approved. Proceed to create the new engagement.',
  OneViewAccountLinkage ="Oneview account selection",
  OneViewAcctLinkageDesc ="Oneview accountId is not linked"
}
