<div class="containerDiv">
  <div id="engSummary" class="mb-3" *ngIf="(engagementList!=null && engagementList.engagementId && !isTnCInProgress)">

    <div class="row m-0 mb-4 d-table position-relative">
      <div id="engInfo" class="col-xl-8 col-md-12 px-0 d-table-cell">
        <div><span class="font-weight-bold"> {{engagementList.status}}</span></div>
        <div id="headerBlock">
          <div id="engagementBlock">
            <div class="row pl-3" id="engagementNameDiv">
              <div class="col-8 p-0 m-0" style="text-align: left; ">
                <span class="engName mr-4" *ngIf="!isEditEngName">{{engagementList.engagementName}} </span>
              </div>
              <div class="col-1 p-0 m-0" style="text-align: left;">
                <span class="reviewer" class="clickableLink" *ngIf="!isEditEngName && !isEngagementClosed"
                  (click)="EditEngagementName()">Edit</span>
                <span class="reviewer" class="nonClickableLink" *ngIf="!isEditEngName && isEngagementClosed">Edit</span>
              </div>

              <div class="col-3 p-0 m-0">
              </div>
            </div>

            <div class="px-0 my-1" *ngIf="isEditEngName">
              <div class="d-flex align-items-center">
                <input type="text" name="txtEngName" id="txtEngName" class="form-control rounded-0 input-width"
                  value="{{engagementList.engagementName}}" required="required" [(ngModel)]="updatedEngName"
                  maxlength="25" (change)="invalidCharacterDetectionEngagementName()"
                  (keyup)="invalidCharacterDetectionEngagementName()">
                <span class="ml-4 cursor-pointer link-text-blue" (click)="CancelEditEngagementName()">Cancel</span>
                <span class="ml-4 font-weight-bold cursor-pointer link-text-blue"
                  (click)="UpdateEngagementName()">Save</span>
              </div>
              <div id="EngagementNameHint" class="span-hint mt-1 mb-0" *ngIf="!EngagementNameRequired && validEngagementName && !checkEngagementExists &&
                                    !(invalidvalues != '' && invalidvalues != null)">
                <p>Allowable: . - _ & ' , ( )</p>
              </div>
              <span class="error-color" *ngIf="EngagementNameRequired"> Engagement name required </span>
              <div class="form-control-feedback error-color" *ngIf="!validEngagementName">
                <p>Invalid engagement name </p>
              </div>
              <div class="form-control-feedback error-color" *ngIf="invalidvalues != '' && invalidvalues != null ">
                <p>'{{invalidvalues}}' <span *ngIf="invalidvalues.length === 1">is not allowed.</span><span
                    *ngIf="invalidvalues.length > 1">are not allowed.</span></p>
              </div>
              <div class="form-control-feedback error-color" *ngIf="checkEngagementExists">
                <p>Engagement name is a duplicate</p>
              </div>
            </div>
          </div>
          <div class="" id="scode">
            Primary solution code: {{engagementList.solutionCode}} - {{engagementList.solutionCodeDescription}}
          </div>

          <div class="row pl-3" id="PPMDDiv">
            <div class="col-8 p-0 m-0" style="text-align: left;">
              Engagement PPMD:
              <span class="reviewer contactUser cursor-pointer mr-4" id="EPPMD_{{engagementList.engagementId}}"
                (mousedown)="displayContactPopup(engagementList.engagementPartner, 'EPPMD_'+ engagementList.engagementId)"
                *ngIf="!isEditEPPMD"> {{engagementList.engagementPartnerName}} </span>
            </div>
            <div class="col-4 p-0 m-0" style="text-align: left;">
              <span class="reviewer" class="clickableLink" *ngIf="!isEditEPPMD && !isEngagementClosed && !isMemberFirm"
                (click)="editEPPMD()" style="text-align: right;">Edit</span>
              <span class="reviewer" class="nonClickableLink"
                *ngIf="!isEditEPPMD && isEngagementClosed && isMemberFirm">Edit</span>
            </div>

            <div class="px-0 my-1" *ngIf="isEditEPPMD">
              <div class="d-flex align-items-center">
                <div class="input-group mw-100 w-46-sm">
                  <app-people-picker class="eng-people-picker" [roleShortName]="epRoleName"
                    [labelCtrlName]="eplblCtrlName" [isLabelVisible]="false" [InputCtrlIdIndex]="peoplepickerIndex"
                    [PeoplePickerModel]="selectedEngagementPartner" [userEmail]="selectedEngagementPartnerEmail"
                    [IsValidateUserWithADGroup]="validateEP"
                    (validPeopleSelected)="updateTeamMembersList($event,epRoleName)">
                  </app-people-picker>

                  <div class="input-group-append input-height">
                    <button type="button" id="PPIcon_{{peoplepickerIndex}}" data-toggle="modal"
                      data-target="#modal-EPPMD" data-keyboard="false" data-backdrop="static"
                      class="mw-100 people-picker-button text-center" (click)="refreshEPPopup('txtPeoplePicker_')">
                      <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="Engagement PPMD" />
                    </button>
                  </div>
                </div>
                <span class="ml-4 cursor-pointer link-text-blue" (click)="cancelEPPMD()">Cancel</span>
                <span class="ml-4 font-weight-bold  link-text-blue" [ngClass]="eppmdClassName"
                  (click)="updateEngagementEPPMD()">Save</span>
              </div>
              <span class="error-color" *ngIf="isRequiredEngagementPartner"> Engagement PPMD is a required field </span>
              <div id="EPMatchWithEQCRdiv" *ngIf="isEPMatchWithEQCR" class="form-control-feedback error-color">
                <p>EPPMD cannot be same as assignment EQCR</p>
              </div>
            </div>

          </div>

          <div id="continuanceDiv">
            <span>Continuance:
              <span *ngIf="IsContinuanceEligible"> Eligible</span>
              <span *ngIf="IsContinuanceEligible == false"> Not Eligible</span>
            </span>
          </div>
          <div *ngIf="engagementList.taxYear != null" id="periodendDiv">
            <span>Period end:
              <span> {{engagementList.taxYear | date: 'MM/dd/yyyy'}}</span>

            </span>
          </div>
          <!-- Completion Date DIV -->
          <div class="row pl-3 pb-2" id="CompletionDateDiv"
            *ngIf="engagementList.serviceLine == 'Tax' || engagementList.serviceLine == 'Advisory'">
            <div class="col-8 p-0 m-0" style="text-align: left;" *ngIf="this.engagementList.completionDate == null">
              Estimated completion date:
              <span class="mr-4" id="CompletionDate_{{engagementList.engagementId}}">
                {{engagementList.plannedClosureDate | date: 'MM/dd/yyyy'}} </span>
            </div>
            <div class="col-8 p-0 m-0" style="text-align: left;" *ngIf="this.engagementList.completionDate != null">
              Completion date:
              <span class="mr-4" id="CompletionDate_{{engagementList.engagementId}}">
                {{engagementList.completionDate | date: 'MM/dd/yyyy'}} </span>
            </div>
            <div class="col-4 p-0 m-0" style="text-align: left;">
              <span class="reviewer" class="clickableLink" *ngIf="!engCompletionDtEditViewModel.completionDateEditMode && !isEngagementClosed
                           && this.engagementList.completionDate == null" (click)="editCompletionDate()"
                style="text-align: right;">Edit</span>
              <span class="reviewer" class="nonClickableLink"
                *ngIf="!engCompletionDtEditViewModel.completionDateEditMode && isEngagementClosed">Edit</span>
            </div>
            <div class="error-color" *ngIf="!isCompletionDateIndicated  && isCloseButtonClicked">
              <p class="m-0">Estimated completion date must be updated to the actual completion date.</p>
            </div>

            <div class="px-0 my-1 row pl-3" *ngIf="engCompletionDtEditViewModel.completionDateEditMode">
              <div class="d-flex align-items-right row">
                <div class="col-5 col-sm-9 col-lg-5 pr-1 pl-3">
                  <app-calendercontrol id="engCompletionDate" [txtDate]="engCompletionDateCtrlId"
                    [selectedDate]="engCompletionDtEditViewModel.editedcompletionDate" class="eng-period-date">
                  </app-calendercontrol>
                </div>
                <div class="col-7 col-sm-9 col-lg-5 pt-3 px-1">
                  <span class="ml-4 cursor-pointer link-text-blue" (click)="cancelCompletionDate()">Cancel</span>
                  <span class="ml-4 font-weight-bold  link-text-blue" (click)="updateEngCompletionDate()">Save</span>
                </div>
              </div>
              <div class="d-flex align-items-right row pl-3">
                <div class="error-color" *ngIf='!engCompletionDtEditViewModel.isEngCompletionDateRequired'>
                  <p class="m-0">Completion date is a required field</p>
                </div>
                <!-- <div class="error-color" *ngIf="!engCompletionDtEditViewModel.isEngCompletionDateFutureDt">
                            <p>Completion date should be future date</p>
                          </div> -->
                <div class="error-color" *ngIf="engCompletionDtEditViewModel.isEngCompletionDateSame">
                  <p class="m-0">Completion date should be different than existing date</p>
                </div>
                <div class="error-color" *ngIf="!engCompletionDtEditViewModel.isEngCompletionDateValid">
                  <p class="m-0">Invalid completion date</p>
                </div>
                <div class="error-color" *ngIf="UpdateEngCompletionDateError">
                  <p class="m-0">Error while saving completion date </p>
                </div>
              </div>
            </div>
          </div>
          <!-- Completion Date DIV End -->
          <div class="row p-0 pt-2 pb-2 m-0" id="borderDiv">
            <div class="col-9" style="border-bottom: 1px solid #C7C7C7;"></div>
            <div class="col-3"></div>
          </div>
        </div>
        <div class="col-12 p-0">
          <a class="mt-2 d-inline-block engDetailHeading-inline"
            [routerLink]="['/ClientDetails', engagementList.clientId]">
            <span>{{engagementList.clientName}} </span> </a>
        </div>

        <div>Client code: {{engagementList.clientCode}}</div>
        <div> Client contact: {{engagementList.clientBillingName}}</div>
        <div *ngIf="isPermLibraryFeatureFlagEnabled">
          <div *ngIf="!(engagementList.clientCode == '' || engagementList.clientCode == null)">
            <a href="{{engagementList.siteUrl}}/SitePages/PermLibrary.aspx" target="_blank">
              <img src="../../../../assets/images/EDP/Dashboard/folder_open.svg" style="vertical-align: sub;"
                alt="Perm Library" />
              Perm Library</a>
          </div>
          <div *ngIf="(engagementList.clientCode == '' || engagementList.clientCode == null)">

            <img src="../../../../assets/images/EDP/Workspace/report_problem-24px.svg"
              style="height: 20px; width: 20px;" class="icons-s-doc" alt="MessageDialog" data-toggle="tooltip"
              data-html="true"
              title='<div class=&quot;customTooltipPerm&quot;><p>A Perm Library will be created after the client code is received from CMS.</p></div>'>
            Perm Library
          </div>

        </div>
      </div>

      <div class="col-xl-4 col-md-6 text-right align-button d-table-cell pr-0">

        <div class="row p-0 m-0">
          <div class="col-10 p-0 m-0">
            <app-shortcut></app-shortcut>
          </div>
          <div class="col-2 p-0 m-0">
            <div class="btn-group">
              <a id="customDropdown" class="btn btn-light btn-sm customDropdown dropdown-toggle" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img src="assets/images/EDP/Engagement/more_horiz-24px.svg" alt="">
              </a>
              <div class="dropdown-menu" aria-labelledby="customDropdown">
                <span class="dropdown-menu-arrow"></span>
                <a class="dropdown-item" *ngIf="isloggedInUserAdmin == 'yes' && !isEngagementClosed"
                  (click)="AdministrativeClose()">Administrative close</a>
                <a class="dropdown-item" *ngIf="!isEngagementClosed"
                  [ngClass]="isEngagementCloseoutDisable?'disabled':''" (click)="engagementClosure()">Closeout
                  engagement</a>
                <a class="dropdown-item" (click)="SetWorkspaceIdForEngageSummaryReport(true)">Create report</a>
                <a class="dropdown-item" (click)="CurrentEngagementStatus()" *ngIf="engagementList.updateQuestionnaireAfterAcceptance
                        && engagementList.updateQuestionnaireAfterAcceptanceTemplateMaintainance
                        && !engagementList.appliedTnC
                        && (engagementList.status === EngagementStatus.EngActivePendingCMSCode || 
                            engagementList.status === EngagementStatus.EngActivePendingSignature ||
                            engagementList.status === EngagementStatus.EngActive)
                        && (isTeamMember || isloggedInUserAdmin == 'yes') ">Change T & Cs</a>
                <a class="dropdown-item" *ngIf="(engagementList.status === EngagementStatus.EngActivePendingCMSCode || 
                  engagementList.status === EngagementStatus.EngActivePendingSignature ||
                  engagementList.status === EngagementStatus.EngActive)
                  && (isTeamMember || isloggedInUserAdmin == 'yes')
                  && !isSignatureCompleted" (click)="DisplayVoidEngagementDialog(engagementList.engagementId)">Void
                  engagement</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="engagementList==null">
      <span>
        You do not have access to view details!
      </span>
    </div>
    <!--popovers/popups-->
    <div id="popover-content-EPContact" class="hide">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <img src="../../../../assets/images/listview/profile_photo.png" alt="Contact Name" />
            <span>&nbsp;<span class="headingFont">{{engagementList.engagementPartnerName}}</span>
            </span>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <span><img src="../../../../assets/images/listview/mail.svg" alt="Mail" />&nbsp;&nbsp;
              <span class="emailLabel">
                <a>{{engagementList.engagementPartner}}</a>
              </span>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <span>
              <img src="../../../../assets/images/listview/call.svg" alt="Phone No or Mobile" />&nbsp;&nbsp;
              <span class="callLbel">{{engagementList.epContactno}}</span>
            </span>
          </div>
        </div>
        <br />
      </div>
    </div>
    <!-- end popover-content-Engsummary popover -->

    <div id="popover-content-SolutionCodeDetails">
      <div id="my-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="my-modal-title"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="my-modal-title">Solution Code: {{engagementList.solutionCode}}</h5>
              <button class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Partnership General Compliance-Form 1065</p>
              <p>TRUE Compliance 401 Partnership Taxation</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div role="tabpanel">
      <!-- Nav tabs -->
      <div id="engsummarytabs">
        <ul role="tablist" class="nav nav-tabs ">
          <li role="presentation" class="nav-item">
            <a href="#acceptance" class="acceptance-tab nav-link" [ngClass]="{'active' : isSelectedAcceptanceImg}"
              aria-controls="acceptance-tab" role="tab" data-toggle="tab" (click)="switchTabPanelIcons('acceptance')">
              <span class="inline-block align-middle pl-1">Acceptance</span></a>
          </li>
          <li role="presentation" class="nav-item">
            <a href="#assignments" class="assignment-tab nav-link" aria-controls="assignment-tab" role="tab"
              data-toggle="tab" [ngClass]="{'active' : isSelectedAssignmentImg, 'disabled' : !IsHDQuestionAnswerLoaded}"
              (click)="switchTabPanelIcons('assignments')">
              <span class="inline-block align-middle pl-1">Assignments</span></a>
          </li>
          <li role="presentation" class="nav-item">
            <a href="#collaborations" (click)="enableDraggable(true)" class="collaboration-tab nav-link"
              [ngClass]="{'active' : isSelectedCollabImg, 'disabled' : !IsHDQuestionAnswerLoaded}"
              aria-controls="collaboration-tab" role="tab" data-toggle="tab"
              (click)="switchTabPanelIcons('collaborations')">
              <span class="inline-block align-middle pl-1">Collaboration spaces</span></a>
          </li>
          <li role="presentation" class="nav-item">
            <a href="#executionDocuments" (click)="switchTabPanelIcons('executionDocuments')" id="executionDocumentsTab"
              class="acceptance-tab nav-link"
              [ngClass]="{'active' : isSelectedExecutionDocumentsImg, 'disabled' : !IsHDQuestionAnswerLoaded}"
              aria-controls="document-tab" role="tab" data-toggle="tab">
              <span class="inline-block align-middle pl-1">Documents</span></a>
          </li>


          <li role="presentation" class="nav-item">
            <a href="#consent" (click)="switchTabPanelIcons('consent')" id="consentTab" class="consent-tab nav-link"
              [ngClass]="{'active' : isSelectedConsentImg, 'disabled' : !IsHDQuestionAnswerLoaded}"
              aria-controls="consent-tab" role="tab" data-toggle="tab" *ngIf="isConsentFeatureEnabled">
              <span class="inline-block align-middle pl-1">Consent</span></a>
          </li>


          <li role="presentation" class="nav-item" *ngIf="isClientVoiceFeatureFlagEnabled">
            <a href="#engCloseoutCheckList" (click)="switchTabPanelIcons('engCloseoutCheckList')"
              class="collaboration-tab nav-link"
              [ngClass]="{'active' : isSelectedEngCloseoutCheckListImg, 'disabled' : !IsHDQuestionAnswerLoaded}"
              aria-controls="collaboration-tab" role="tab" data-toggle="tab">
              <span class="inline-block align-middle pl-1">Closeout</span></a>
          </li>

        </ul>
      </div>

      <!-- Tab panes -->
      <div class="tab-content">




        <!-- START Tab Documents  panes -->

        <div role="tabpanel" class="tab-pane" *ngIf="isSelectedExecutionDocumentsImg==true"
          [ngClass]="{'active' : isSelectedExecutionDocumentsImg}" id="executionDocuments">
          <h5 class="my-3 edp-font-color font-weight-bold">
            Documents
          </h5>
          <div class="row " *ngIf="executionWorkspaceDocumentList?.length>0">
            <div id="drpDownExceutionDocs" class="col-md-6 ">
              <div class="selectdiv w-50" *ngIf="executionWorkspaceDocumentList?.length>1">
                <select class="dropdown rounded-0" id="exeWorkspaceDocument" name="exeWorkspaceDocument"
                  (change)="exeWorkspaceDocumentChange()" [(ngModel)]="selectedExeWorkspaceDocumentEorkspaceId">
                  <option *ngFor="let item of executionWorkspaceDocumentList" [value]="item.workspaceId">
                    <pre> {{item.documentAliasName}}</pre>
                  </option>
                </select>
              </div>
              <div class="nav-item " id="divexecutionworkspaceDocument"
                *ngIf="executionWorkspaceDocumentList.length == 1">
                <span class="font-weight-bold">{{selectedSecondaryWorkspaceDocument?.documentAliasName}}</span>
              </div>
            </div>
          </div>
          <div class="row mx-0 mx-0 mt-5" *ngIf="executionWorkspaceDocumentList?.length>0">

            <div class="col-md-12 col-xl-12 pl-0 pr-0-tablet mb-4">
              <!--tab -->
              <div role="tabpanel" class="tabpanel panel-box-shadow">
                <!-- Nav tabs -->
                <div class="DocPreviewTabs">
                  <ul class="nav nav-tabs" role="tablist">
                    <li role="presentation" class="nav-item float-right review-tab nav-content">
                      <a href="#executionreview" class="nav-link active" aria-controls="executionreview" role="tab"
                        data-toggle="tab" (click)="exeQuestionSummaryDisplay()">
                        <span>Review responses</span>
                      </a>
                    </li>
                    <li role="presentation" class="nav-item float-right review-tab">
                      <a href="#executionDocumentView" class="nav-link " aria-controls="executionDocumentView"
                        role="tab" data-toggle="tab" id="documentName" (click)="exePreviewDocument()">
                        Document preview
                      </a>
                    </li>
                  </ul>
                  <!--tab end -->
                  <div class="tab-content bg-white tab-panel-content">
                    <div class="ReviewResponsesSection tab-pane fade show active" id="executionreview" role="tabpanel">
                      <div class="ReviewResponsesSectionContainer">
                        <app-review-response [hdWorkItemVersionId]="engDetailWorkItemVersionId"
                          [hdWorkItemId]="engDetailWorkItemId" [isAppliedTnC]="false">
                        </app-review-response>
                      </div>
                    </div>
                    <div role="tabpanel" class="embed-responsive DocumentPreviewIFrame tab-pane"
                      id="executionDocumentView">
                      <div>


                        <div class="DocumentPreviewTab" *ngIf="(!(engagementList.status=='Closed'||engagementList.status=='Voided'))&&sharepointExeDocumentPreviewNotFound==false
                          &&!(isQualityUserLoggedIn === 'yes' && isloggedInUserAdmin === 'no')">
                          <a id="customDropdownExecutionDocs" class="customDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src="assets/images/EDP/Engagement/more_horiz-24px.svg" alt="">
                          </a>
                          <div class="dropdown-menu" aria-labelledby="customDropdown">
                            <span class="dropdown-menu-arrow"></span>
                            <a style="font-size: 15px;width:auto;" class="dropdown-item"
                              (click)="confirmModalToremoveSecondaryWorkspace()">Delete document</a>
                            <a style="font-size: 15px;width:auto;" hidden class="dropdown-item">Print</a>
                            <a style="font-size: 15px;width:auto;" class="dropdown-item"
                              (click)="exportSPDocConfirmationPopup()">Export to document library</a>
                          </div>
                        </div>


                        <iframe id="executionDocumentPreview" SameSite="None" title="Document"
                          class="embed-responsive-item ">
                          This is an embedded
                          <a target='_blank' href='http://office.com' rel="noopener noreferrer">Microsoft Office</a>
                          document, powered by <a target='_blank' href='http://office.com/webapps'
                            rel="noopener noreferrer">
                            Office Online</a>.

                        </iframe>
                      </div>
                      <div class="docprev" *ngIf="sharepointExeDocumentPreviewNotFound">
                        <div class="d-flex flex-column">
                          <div class="p-2 d-flex justify-content-center">
                            <h5>Document preview unavailable.</h5>
                          </div>
                          <div class="p-2 d-flex justify-content-center">
                            <h6>The original file has either been moved or renamed</h6>
                          </div>
                          <div class="p-2 d-flex justify-content-center" id="linkDocumentBtnTag"
                            *ngIf="!(isQualityUserLoggedIn === 'yes' && isloggedInUserAdmin === 'no')">
                            <button class="btn info" (click)="openSharepointFilePicker()">Link Document</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-xl-12 pl-0 pr-0-tablet mb-4 center" *ngIf="!isEngagementClosed ">
              <button id="btnEditQuestionnaire" class="btn btn-width SecondaryButton text-center" type="button"
                (click)="editExecutionQuestionnaire()">
                <img src="assets/images/EDP/Engagement/caret-left-blue.svg" alt="caret-left"
                  style="width: 18px;height:18px;margin-right:2px;">
                <span class="d-inline-block align-middle">Edit Questionnaire </span>
              </button>
            </div>
          </div>
          <div *ngIf="executionWorkspaceDocumentList?.length==0" class="row mb-3">
            <div class="col-md-8 ">
              <div class="bg-white d-flex justify-content-center p-3" style="height:100vh;">
                <p class="font-weight-bold text-center" style="font-size: 20px;top:5%;position:relative;">
                  No additional documents have been added in Engage.
                  <br>Select create new document under the I want to feature to add an additional document.
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- END Tab Documents  panes -->


        <!-- START Tab Consent  panes -->

        <div role="tabpanel" class="tab-pane" *ngIf="isSelectedConsentImg==true"
          [ngClass]="{'active' : isSelectedConsentImg}" id="consent">
          <h5 class="my-3 edp-font-color font-weight-bold">
            Consent
          </h5>
          <hr />
          <div class="d-flex justify-content-between">
            <div class="float-left">
              <h6 class="edp-font-color font-weight-bold">Engagement data consent options</h6>
              <p class="mb-0">Until the EPPMD indicates whether modifications were made to the client data consent
                language, any data gathered from the client for this engagement may only be used by this engagement team
                and is to be used for this engagement only. Also, should the initial client data consent options change
                during the course of the engagement,
                these updated terms must be reviewed with RRLA and appropriately modified by the EPPMD in Engage.</p>
              <br>
              <p> <span style="font-weight: bold;">PLEASE NOTE</span>: If the engagement is subject to attorney-client
                privilege, then the client data consent option must remain "<span style="font-style: italic;">Engagement
                  Team Only.</span>"
                (Select "Contract does not include client data consent terms").
              </p>
            </div>
          </div>
          <div class="col-md-12 pt-2 mt-1 ml-0 pl-0">
            <div class="float-left mb-4"
              [ngClass]="{true: 'nonClickableDiv'}[(!isEPPMD && isloggedInUserAdmin =='no') || engagementList.status == 'Voided' ||engagementList.status == 'CMS code error']">
              <p class="mb-3">Select the appropriate client data consent terms:
                <!-- <img alt="add" src="../../../../assets/images/EDP/Workspace/icon-material-info-outline.svg"
                  style="height: 16px; width: 16px;margin-left:6px;vertical-align: sub;" data-toggle="tooltip"
                  data-custom-class="tooltipIndicators" data-html="true" /> -->
              </p>
              <span *ngFor="let ClientConsentData of clientConsentdataterm">
                <label class="custom-radio">{{ClientConsentData.value}}
                  <input type="radio" id="{{ClientConsentData.applicationMasterId}}"
                    (change)="EngagementDataConsent($event)" name="defaultConsentLanguage"
                    value="{{ClientConsentData.code}}"
                    [checked]="ClientConsentData.applicationMasterId==clientConsentFlagId"
                    [disabled]="!isEPPMD  && isloggedInUserAdmin =='no'">
                  <span class="checkmark .radio-default-consent"></span>
                </label><br />
              </span>
            </div>
          </div>
          <br />
          <div class="row mt-3 mb-3 ml-0 pl-0"
            [ngClass]="{true: 'nonClickableDiv'}[(!isEPPMD && isloggedInUserAdmin =='no') || engagementList.status=='Voided' ||engagementList.status == 'CMS code error']">
            <div class="col-md-12 ml-0 pl-0 pr-0 mb-2">
              <div class="row ml-0 pl-0 bg-white d-flex p-3 mb-3">
                <div class="col-md-12 mb-2" [disabled]="!isEPPMD && isloggedInUserAdmin =='no'">
                  <span class="float-right">
                    <a *ngIf="applicationMasterCode =='Yes'" id="openEngagementDataConsent"
                      (click)="EditEngagementDataConsent()" style="
                    background-color: #f5f5f5; border-radius: 50%; padding: 0.3rem;">
                      <img style="width: 18px;height: 18px;margin-top:-5px"
                        src="../../../../assets/images/EDP/Engagement/edit-24px.svg" alt="Edit">
                    </a>
                  </span>
                </div>
                <div><br /></div>
                <div class="col-md-12 clear-both mb-3">
                  <div [ngClass]="{'borderConsent' : clientConsentOptionsSecondary.length > 0}">
                    <span class="spanInlineConsent float-left col-md-4 r-n-res-checkboxNew px-0"
                      *ngFor="let PrimaryConsent of clientConsentOptionsPrimary">
                      <img src="assets/images/EDP/Workspace/icon-check-green-circle.svg" alt="chk_UnrelatedService"
                        width="20" height="20" class="img-responsive" *ngIf="PrimaryConsent.isChecked"
                        style="vertical-align: sub;  margin-right: 5px; ">
                      <img src="assets/images/EDP/Workspace/icon-check-grey-circle.svg" alt="chk_UnrelatedService"
                        width="20" height="20" class="img-responsive" *ngIf="!PrimaryConsent.isChecked"
                        style="vertical-align: sub;  margin-right: 5px; ">
                      <label id="{{PrimaryConsent.clientConsentId}}">{{PrimaryConsent.clientConsentOption}}</label>
                      <img alt="add" src="../../../../assets/images/EDP/Workspace/icon-material-info-outline.svg"
                        style="height: 16px; width: 16px;margin-left:6px;vertical-align: sub;" data-toggle="tooltip"
                        data-custom-class="tooltipIndicators" data-html="true"
                        data-title="{{PrimaryConsent.clientConsentOptionDescription}}" />
                    </span>
                  </div>
                  <div style="clear:both;">
                    <span style="width:650px; padding:15px 0px;" class="float-left col-md-4 r-n-res-checkboxNew px-0"
                      *ngFor="let SecondaryConsent of clientConsentOptionsSecondary">
                      <img src="assets/images/EDP/Workspace/icon-check-green-square.svg" alt="chk_UnrelatedService"
                        width="20" height="20" class="img-responsive" *ngIf="SecondaryConsent.isChecked"
                        style="vertical-align: sub;  margin-right: 5px; ">
                      <img src="assets/images/EDP/Workspace/icon-check-grey-square.svg" alt="chk_UnrelatedService"
                        width="20" height="20" class="img-responsive" *ngIf="!SecondaryConsent.isChecked"
                        style="vertical-align: sub;  margin-right: 5px; ">
                      <label id="{{SecondaryConsent.clientConsentId}}">{{SecondaryConsent.clientConsentOption}}</label>
                      <img alt="add" src="../../../../assets/images/EDP/Workspace/icon-material-info-outline.svg"
                        style="height: 16px; width: 16px;margin-left:6px;vertical-align: sub;" data-toggle="tooltip"
                        data-custom-class="tooltipIndicators" data-html="true"
                        data-title="{{SecondaryConsent.clientConsentOptionDescription}}" />
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div hidden>
            <hr />
            <!--Start Collaboration spaces data in Client Consent -->
            <h6 class="edp-font-color font-weight-bold">Collaboration spaces access consent </h6>
            <div class="d-flex p-0">
              <div class="card-deck w-100 mb-3">
                <div class="card" *ngFor="let collaborationList of collaborationSiteViewModelList" let i="index">
                  <div class="card-body text-align-left position-relative">
                    <div class="CardViewContent">
                      <div class="w-100 mt-2" id="clientConsentCollaborationSiteList"
                        *ngIf="collaborationSiteViewModelList.length > 0 ">
                        <div class="row m-0 my-2 currentCollaboration">
                          <div class="col-xl-12 col-lg-12 col-md-12 px-0">
                            <span class="d-table edp-font-14">
                              <img src="../../../assets/images/EDP/Engagement/supervised_user_circle.svg"
                                alt="Superwise user" class="d-table-cell align-top" />
                              <span class="d-table-cell align-top pl-2">
                                {{collaborationList.collaborationSite.collaborationSiteName}}
                              </span>
                            </span>
                          </div>
                          <!-- assignment details -->
                          <div class="col-md-12 ml-0 pl-0 mt-3 mb-3" style="height:80px; overflow-y:auto;">
                            <div id="clientConsentAssignmentdetails_{{i}}" style="display:block;"
                              class="row mx-0 pl-0 assignmentsection"
                              *ngFor="let assignment of collaborationList.engagementAssignmentList">
                              <div id="clientConsentAssignmentCollaboration_{{assignment.assignmentCodeId}}"
                                class="assignmentDetail col-md-12 px-1">
                                <span class="d-inline-block edp-font-14">{{assignment.assignmentName}}</span>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-12 ml-0 pl-0 mt-2 float-left align-button">
                            <p class="mb-0">INDUS access
                              <label class="custom-radio ml-4 mr-4">Yes
                                <input type="radio"
                                  name="{{collaborationList.collaborationSite.collaborationSiteName}}_{{collaborationList.collaborationSite.collaborationSiteId}}"
                                  value="Y" [checked]="collaborationList.collaborationSite.isIndus"
                                  (change)="UpdateIndusConsentInConsentTab(true,collaborationList.collaborationSite.collaborationSiteId)">
                                <span class="checkmark radio-indus-consent-yes"></span>
                              </label>
                              <label class="custom-radio">No
                                <input type="radio"
                                  name="{{collaborationList.collaborationSite.collaborationSiteName}}_{{collaborationList.collaborationSite.collaborationSiteId}}"
                                  value="N" [checked]="!collaborationList.collaborationSite.isIndus"
                                  (change)="UpdateIndusConsentInConsentTab(false,collaborationList.collaborationSite.collaborationSiteId)">
                                <span class="checkmark radio-indus-consent-no"></span>
                              </label>
                            </p>
                          </div>
                          <!-- end assignment details -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div><!-- end for loop card collaborationSiteList with index i-->
              </div>
            </div>
            <!--End Collaboration spaces data in Client Consent -->
          </div>
        </div>
        <!-- END Tab Consent  panes -->

        <!-- Start EngagementDataConsent Pop-Up -->

        <div id="modal-EgagementDataConentPopUp" class="modal fade modal-window" tabindex="-1" role="dialog"
          aria-hidden="true" data-backdrop="static" data-keyboard="false">
          <div class="modal-dialog" role="document">
            <div class="modal-content w-50" style="max-height: 550px;">
              <div class="modal-header border-bottom-0">
                <h5 class="modal-title">Engagement data consent options</h5>
                <button type="button" class="close edp-font-12" data-dismiss="modal" aria-hidden="true"
                  (click)="CancelEgagementDataConsentPopUp();">
                  <span class="close-text mr-1">Close </span>
                  <span class="modal-close-btn" style="margin-top: -5px;">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="margin-top:-18px;">
                <div class="row m-0">
                  <div class="col-md-12">
                    <div class="row">
                      <p style="color: #707070; font-size: 15px;margin-top: -5px;">
                        Set data consent options for the entire engagement.
                      </p>
                    </div>
                    <div class="row">
                      <h6 style="color: #707070;font-weight: bold;"> Data consent</h6>
                    </div>
                    <div class="row">
                      <span class="mb-2">Must select one.</span>
                    </div>
                    <div class="sectionConsentOptPopup">
                      <div class="row m-0">
                        <div style="width:650px;" class="float-left col-md-6 p-0"
                          *ngFor="let primaryData of clientConsentOptionsPrimaryForPopUp">
                          <label class="custom-radio mb-2">
                            <h6 style="color: #707070;font-weight: bold;">{{primaryData.clientConsentOption}}</h6>
                            {{primaryData.clientConsentOptionDescription}}
                            <input type="radio" name="primaryConsent" id="{{primaryData.clientConsentId}}"
                              value="IsPrimary" [checked]="primaryData.isChecked"
                              (change)="getPrimaryConsentOptionsVal($event)">
                            <span class="checkmark radio-default-consent-isPrimary"></span>
                          </label>
                        </div>
                      </div>
                      <div>
                        <hr *ngIf="clientConsentOptionsSecondaryForPopUp.length > 0" />
                      </div>
                      <div class="row m-0" *ngIf="clientConsentOptionsSecondaryForPopUp.length > 0">
                        <span class="mb-2">Select additional data consent options based on client agreement.</span>
                      </div>
                      <div class="row m-0" *ngIf="clientConsentOptionsSecondaryForPopUp.length > 0">
                        <div style="width:650px;" class="float-left col-md-6 custom-control custom-checkbox pl-4"
                          *ngFor="let secondaryData of clientConsentOptionsSecondaryForPopUp">
                          <input style="z-index: 1;" class="custom-control-input" type='checkbox'
                            id="{{secondaryData.clientConsentId}}" value='{{secondaryData.isChecked}}'
                            [(ngModel)]='secondaryData.isChecked'>
                          <span *ngIf="secondaryData.isChecked"></span>
                          <h6 style="color: #707070;font-weight: bold;" class="custom-control-label">
                            {{secondaryData.clientConsentOption}}</h6>
                          <label>{{secondaryData.clientConsentOptionDescription}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-control-feedback error-color pr-5" *ngIf="!IsCheckedVal"
                style="text-align: right; margin-right: 50px!important;">
                <p>Select atleast one data consent option.</p>
              </div>
              <div class="modal-footer ml-3 mr-3">
                <div class="m-0 p-0 float-right">
                  <button type="button" class="SecondaryButton mr-2" data-dismiss="modal"
                    id="btnCancelEgagementDataConent" style="width: 150px; text-align: center;"
                    (click)="CancelEgagementDataConsentPopUp();">
                    <span class="d-inline-block align-middle">Cancel</span>
                  </button>
                  <button type="button" class="PrimaryButton" style="width: 150px; text-align: center; "
                    id="btnUpdateEgagementDataConent" (click)="updateClientConsentOptions();">
                    <span class="d-inline-block align-middle">Update</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- End EngagementDataConsent Pop-Up -->


        <div role="tabpanel" class="tab-pane" *ngIf="isSelectedAcceptanceImg==true"
          [ngClass]="{'active' : isSelectedAcceptanceImg}" id="acceptance">
          <h5 class="my-3 edp-font-color font-weight-bold" *ngIf="engagementList.reacceptanceEngagementId == null 
          || engagementList.reacceptanceEngagementId == 0">
            Acceptance
          </h5>
          <h5 class="my-3 edp-font-color font-weight-bold" *ngIf="engagementList.reacceptanceEngagementId != null 
          && engagementList.reacceptanceEngagementId > 0">
            Continuance
          </h5>
          <div id="drpDownAcceptanceDocs" class="col-md-3 pl-0 pr-0 " *ngIf="workspaceDocumentList?.length > 1">
            <div class="selectdiv">
              <select class="dropdown rounded-0" id="ddWorkspaceDocument" name="ddWorkspaceDocument"
                (change)="WorkspaceDocumentChange()">
                <option *ngFor="let item of workspaceDocumentList" [value]="item.workspaceDocumentId">
                  <pre> {{item.documentAliasName}}</pre>
                </option>
              </select>
            </div>
          </div>

          <div class="nav-item " id="divacceptanceworkspaceDocument" *ngIf="workspaceDocumentList?.length == 1">
            <span class="font-weight-bold">{{selectedWorkspaceDocumentItem.documentAliasName}}</span>
          </div>

          <div class="row mx-0 mx-0 mt-5">

            <div class="col-md-12 col-xl-8 pl-0 pr-0-tablet mb-4">
              <!--tab -->
              <div role="tabpanel" class="tabpanel panel-box-shadow">
                <!-- Nav tabs -->
                <div class="DocPreviewTabs">
                  <ul class="nav nav-tabs" role="tablist">

                    <li role="presentation" class="nav-item float-right review-tab nav-content">
                      <a href="#review" class="nav-link active" aria-controls="ReviewReponses" role="tab"
                        data-toggle="tab" (click)="QuestionSummaryDisplay()">
                        <span *ngIf="engagementList.appliedTnC">Revised responses</span>
                        <span *ngIf="!engagementList.appliedTnC">Review responses</span>
                      </a>
                    </li>
                    <li *ngIf="engagementList.appliedTnC" role="presentation" class="nav-item float-right review-tab">
                      <a href="#original" class="nav-link" aria-controls="#Originals" role="tab" data-toggle="tab"
                        (click)="QuestionSummaryDisplayForOriginal()">Original responses</a>
                    </li>
                  </ul>
                  <!--tab end -->
                  <div class="tab-content bg-white tab-panel-content">
                    <div class="ReviewResponsesSection tab-pane fade show active" id="review" role="tabpanel">
                      <div class="ReviewResponsesSectionContainer">
                        <app-review-response [hdWorkItemVersionId]="engDetailWorkItemVersionId"
                          [hdWorkItemId]="engDetailWorkItemId" [isAppliedTnC]="false"
                          (OnQuestionAnswerLoaded)="OnHDQuestionAnswerLoaded($event)">
                        </app-review-response>
                        <div class="d-none ReviewResponsesSection">
                          <app-summary-report [workspaceId]="workspaceIdForSummaryReport"
                            [siteUrl]="siteUrlForSummaryReport" [clmDetails]="clmDetails"
                            (onGenerateStatus)="UpdateReviewResponseDisplay($event)">
                          </app-summary-report>
                        </div>
                      </div>
                    </div>
                    <div class="ReviewResponsesSection  tab-pane fade" role="tabpanel" id="original">
                      <div class="ReviewResponsesSectionContainer">
                        <app-review-response [hdWorkItemVersionId]="engDetailOriginalworkItemVersionIdReviewResponse"
                          [hdWorkItemId]="engDetailOriginalworkItemIdForReviewResponse" [isAppliedTnC]="true">
                        </app-review-response>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-xl-4 pl-0 px-0">
              <div class="area-tablet">
                <div class="row m-0">
                  <div class="col-md-12 px-0" style="margin-top: -65px;">
                    <div class="signatures-section" *ngIf="isCLMFeatureEnabled">
                      <div #clmGoverningAgreementStatusDivDP></div>
                    </div>
                    <!--Signature/Approver Section Start-->
                    <div class="signatures-section mt-3"
                      [ngClass]="{true: 'nonClickableDiv'}[isEngagementClosed || initialEngagementESignature.envelopeStatusCode=='Completed']">
                      <h5> Acceptance signatures</h5>
                      <h6 class="mt-3">Select signature type</h6>
                      <label class="custom-radio mr-5">DocuSign via Engage
                        <input type="radio" name="signatureType"
                          [attr.checked]="engagementESignature.signatureTypeCode=='E'" (change)="ChangeSignatureType()"
                          [(ngModel)]="engagementESignature.signatureTypeCode" value="E">
                        <span class="checkmark radio-signing-type-esign"></span>
                      </label>
                      <label class="custom-radio">Other
                        <input type="radio" name="signatureType"
                          [attr.checked]="engagementESignature.signatureTypeCode=='P'" (change)="ChangeSignatureType()"
                          [(ngModel)]="engagementESignature.signatureTypeCode" value="P">
                        <span class="checkmark radio-signing-type-printed"></span>
                      </label>
                      <label class="wrap-esign-doc lbl-signing-note"
                        *ngIf="engagementESignature.signatureTypeCode == 'P'">
                        NOTE: Please ensure the current date has been placed at the top of the letter or SOW prior to
                        sending
                        to the client. Once complete, upload the fully signed document to the Acceptance folder and
                        record
                        client signature below.
                      </label>
                      <label class="wrap-esign-doc lbl-signing-note"
                        *ngIf="engagementESignature.signatureTypeCode == 'E'">
                        The GT signer must type the current/effective date at the top of the letter or SOW when
                        signing using DocuSign
                      </label>
                      <div class="signature-handler"
                        *ngIf="engagementESignature.signatureTypeCode=='' || engagementESignature.signatureTypeCode=='E'">
                        <div class="my-3">
                          <div *ngIf="showElectronicSignerName
                      && (engagementESignature.signatureTypeCode=='E'
                      && engagementESignature.handlerEmail != null)">
                            <span class="d-inline-block mr-2"> Signature handler:<span class="label mandatory">
                                *</span>
                            </span>
                            <span class="authorityName mr-4 signing-section-link">{{
                              engagementESignature.handlerName
                              }}</span>
                            <span class="signing-section-link"
                              (click)="ShowSignatureHandlerPeoplePicker()">Change</span>
                          </div>
                          <div class="d-flex align-items-center px-0" *ngIf="!showElectronicSignerName
                      || (
                      engagementESignature.signatureTypeCode=='E'
                      && engagementESignature.handlerEmail == null)">
                            <div class="w-ie-75">
                              <div>
                                <label><span class="label">Signature handler:</span> <span class="label mandatory">
                                    *</span></label>
                                <div class="input-group mw-100">
                                  <app-people-picker class="eng-assign-people-picker"
                                    [roleShortName]="signatureHandlerRoleName" [labelCtrlName]="handerlblCtrlName"
                                    [isLabelVisible]="false" [InputCtrlIdIndex]="peoplepickerIndex"
                                    [PeoplePickerModel]="engagementESignature.handlerName"
                                    [IsValidateUserWithADGroup]="validateCMSEP">
                                  </app-people-picker>

                                  <div class="input-group-append input-height">
                                    <button type="button" id="PPIcon_{{peoplepickerIndex}}" data-toggle="modal"
                                      data-target="#modal-ESignatureHandler" data-keyboard="false"
                                      data-backdrop="static" class="mw-100 people-picker-button text-center"
                                      (click)="refreshSignatureHandlerPopup(i,'txtPeoplePicker_')">
                                      <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="Signature handler" />
                                    </button>
                                  </div>
                                </div>
                                <div class="form-control-feedback error-color" *ngIf="isEmptySignatureHandlerName">
                                  <p>Select signature handler</p>
                                </div>
                                <div class="form-control-feedback error-color" *ngIf="isInvalidSignatureHandler">
                                  <p>Handler should be valid</p>
                                </div>
                              </div>
                            </div>
                            <div>
                              <span class="ml-4 cursor-pointer link-text-blue"
                                (click)="CancelHandlerSelection()">Cancel</span>
                              <span class="ml-4 font-weight-bold cursor-pointer link-text-blue"
                                (click)="ShowSelectedSignatureHandler()">Save</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="acceptance-data">
                      <label [ngClass]="{true: 'lbl-select-doc-to-sign'}[engagementESignature.signatureTypeCode == 'P']"
                        *ngIf="engagementESignature.signatureTypeCode=='P'">Select the document signed by the client:
                        <span class="label mandatory"> *</span></label>
                      <label
                        [ngClass]="{true: 'lbl-select-doc-to-sign'}[engagementESignature.signatureTypeCode == 'E' || engagementESignature.signatureTypeCode == null]"
                        *ngIf="engagementESignature.signatureTypeCode==null ||engagementESignature.signatureTypeCode=='E'">Select
                        document for signature: <span class="label mandatory"> *</span></label>

                      <div>
                        <span class="my-3 error-color"
                          *ngIf="engagementESignature.signatureTypeCode == 'P' && hasNoPrintedDocumentSelected">Select
                          a
                          document</span>
                      </div>
                      <div *ngIf="spDocumentList.length==0">
                        No document available
                      </div>
                      <div class=" mb-1" *ngIf="isSharepointFileLabelFeatureFlagEnabled && spDocumentList.length > 0">
                        <label for="filterSharepointFileLabel">Filter by label: <span class="mandatory">*</span></label>
                        <div class="selectdiv">
                          <select class="dropdown rounded-0 " name="filterSharepointFileLabel" id="sharepointFileLabel"
                            [(ngModel)]="sharePointSelectedFileLabelForFilter">
                            <option [value]="'All'" selected>
                              All
                            </option>
                            <option [value]="'Unlabeled'">
                              Unlabeled
                            </option>
                            <option
                              *ngFor="let sharePointApplicationMasterLabel of sharePointApplicationMasterLabelsFiltered"
                              [value]="sharePointApplicationMasterLabel.value">
                              {{sharePointApplicationMasterLabel.value}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="docscrollbary">
                        <table class="table" *ngIf="spDocumentList.length > 0" aria-describedby="acceptance-data">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">File</th>
                              <th scope="col" class="text-left" style="width:80px;">Last Modified</th>
                              <th scope="col" *ngIf="isSharepointFileLabelFeatureFlagEnabled"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="let document of spDocumentList | filterSpfileLabel : sharePointSelectedFileLabelForFilter ; let i=index"
                              class="doc-sign-tr">
                              <td>
                                <label class="r-n-res-checkboxNew mt-1">
                                  <input id="document_{{i}}" [value]='document.documentId' type="checkbox"
                                    *ngIf="isEngagementClosed || initialEngagementESignature.envelopeStatusCode=='Completed'"
                                    name="acceptanceDocument" [checked]="document.isChecked"
                                    (change)='DocumentChanged(document.documentId)' disabled />

                                  <input id="document_{{i}}" [value]='document.documentId' type="checkbox"
                                    *ngIf="!isEngagementClosed && initialEngagementESignature.envelopeStatusCode!='Completed'"
                                    name="acceptanceDocument" class="cursor-pointer" [checked]="document.isChecked"
                                    (change)='DocumentChanged(document.documentId)' />
                                  <span class="checkmark radio-signing-doc"></span>
                                </label>
                              </td>
                              <td class="alert-pre doc-sign-td">
                                <label class="mb-0">
                                  <a *ngIf="document.type =='pdf'" href="{{document.documentUrl}}" target="_blank"
                                    class="cursor-pointer wrap-esign-doc"
                                    [ngClass]="{true: 'docusign-selected-document'}[document.isChecked]">
                                    {{document.documentName}}</a>

                                  <a *ngIf="document.type == 'docx' || document.type =='doc'                        
                                || document.type =='pptx' || document.type =='ppt'
                                || document.type =='xlsx' || document.type =='xls'" href="{{document.hyperLink}}"
                                    target="_blank" class="cursor-pointer wrap-esign-doc"
                                    [ngClass]="{true: 'docusign-selected-document'}[document.isChecked]">
                                    {{document.documentName}}</a>

                                  <a *ngIf="document.type!='docx' && document.type!='doc'
                                && document.type!='pptx' && document.type!='ppt' 
                                && document.type!='pdf' && document.type !='xlsx' && document.type !='xls'"
                                    href="{{document.documentUrl}}" target="_blank"
                                    class="cursor-pointer wrap-esign-doc"
                                    [ngClass]="{true: 'docusign-selected-document'}[document.isChecked]">
                                    {{document.documentName}}
                                  </a>

                                </label>
                                <div *ngIf="isSharepointFileLabelFeatureFlagEnabled">
                                  <span style="color:#707070;font-weight: bold;position: relative;top:-7px;"
                                    *ngIf="document.label else fileLabel">{{document.label}}</span>
                                  <ng-template #fileLabel>
                                    <button class="anchor-button"
                                      *ngIf="!(engagementList.status=='Voided'||engagementList.status=='Closed'||engagementList.status=='CMS code error')"
                                      (click)="displaySharepointFileLabelPopup(document)">Add label</button>
                                  </ng-template>
                                </div>
                              </td>
                              <td class="text-left" style="width:80px;color:#707070;">{{ document.modifiedDate |
                                date:
                                'MM/dd/yyyy'
                                }}
                              </td>
                              <td *ngIf="isSharepointFileLabelFeatureFlagEnabled">
                                <div class="btn-group d-block SharepointDropdown" [hidden]="!document.label"
                                  style="margin-top:-5px;">
                                  <a id="customDropdownSharepointLabel" class="customDropdown" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src="assets/images/EDP/Engagement/more_horiz-24px.svg" alt="">
                                  </a>
                                  <div class="dropdown-menu" aria-labelledby="customDropdown">
                                    <span class="dropdown-menu-arrow"></span>
                                    <a style="font-size: 15px;width:auto;" class="dropdown-item"
                                      href="#modal-SharepointFileLabel"
                                      (click)="displaySharepointFileLabelPopup(document)" data-toggle="modal"
                                      data-keyboard="false" data-backdrop="static">Label</a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div>
                      <div
                        [ngClass]="{true: 'nonClickableDiv'}[isEngagementClosed || initialEngagementESignature.envelopeStatusCode=='Completed']"
                        *ngIf="engagementESignature.signatureTypeCode=='' || engagementESignature.signatureTypeCode=='E'">
                        <div class="sec-heading">
                          DocuSign signature status:
                        </div>
                        <div class="sec-heading font-weight-bold"
                          *ngIf="initialEngagementESignature.envelopeId == null || !initialEngagementESignature.envelopeId">
                          Document has not been sent for signing
                        </div>
                        <div class="d-flex justify-content-between font-weight-bold"
                          *ngIf="initialEngagementESignature.signatureTypeCode == 'E'  && initialEngagementESignature.envelopeStatusCode == 'Sent'">
                          <div>
                            <div class="col-md-12 px-0">
                              <span>Sent for signature</span>
                            </div>
                            <div class="col-md-12 px-0">
                              <span> Date submitted for signature </span>
                            </div>
                          </div>
                          <div>
                            <div class="col-md-4 px-0 text-right">
                              <span>{{engagementESignature.createDate | date: 'MM/dd/yy' }}</span>
                            </div>
                            <div class="col-md-4 px-0 text-right">
                              <span>{{engagementESignature.dateSubmitted | date: 'MM/dd/yy' }}</span>
                            </div>
                          </div>

                        </div>
                        <div class="d-flex justify-content-between font-weight-bold"
                          *ngIf="initialEngagementESignature.signatureTypeCode == 'E' && initialEngagementESignature.envelopeStatusCode == 'Awaiting signature'">
                          <div>
                            <div class="col-md-12 px-0">
                              <span>Awaiting signature</span>
                            </div>
                            <div class="col-md-12 px-0">
                              <span> Date submitted for signature </span>
                            </div>
                          </div>
                          <div>
                            <div class="col-md-4 px-0 text-right">
                              <span>{{engagementESignature.modifiedDate | date: 'MM/dd/yy' }}</span>
                            </div>
                            <div class="col-md-4 px-0 text-right">
                              <span>{{engagementESignature.dateSubmitted | date: 'MM/dd/yy' }}</span>
                            </div>
                          </div>

                        </div>
                        <div class="d-flex justify-content-between font-weight-bold"
                          *ngIf="initialEngagementESignature.signatureTypeCode == 'E' && initialEngagementESignature.envelopeStatusCode == 'Voided'">
                          <div>
                            <div class="col-md-12 px-0">
                              <span>Voided</span>
                            </div>
                            <div class="col-md-12 px-0">
                              <span> Date submitted for signature </span>
                            </div>
                          </div>
                          <div>
                            <div class="col-md-4 px-0 text-right">
                              <span>{{engagementESignature.modifiedDate | date: 'MM/dd/yy' }}</span>
                            </div>
                            <div class="col-md-4 px-0 text-right">
                              <span>{{engagementESignature.dateSubmitted | date: 'MM/dd/yy' }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between font-weight-bold"
                          *ngIf="initialEngagementESignature.envelopeStatusCode == 'Completed'">
                          <div>
                            <div class="col-md-12 px-0">
                              <span>Completed</span>
                            </div>
                            <div class="col-md-12 px-0">
                              <span> Date submitted for signature </span>
                            </div>
                          </div>
                          <div>
                            <div class="col-md-4 px-0 text-right">
                              <span>{{engagementESignature.modifiedDate | date: 'MM/dd/yy' }}</span>
                            </div>
                            <div class="col-md-4 px-0 text-right">
                              <span>{{engagementESignature.dateSubmitted | date: 'MM/dd/yy' }}</span>
                            </div>
                          </div>

                        </div>
                        <div class="my-3 error-color" *ngIf="initialEngagementESignature.signatureTypeCode=='E' &&
                        initialEngagementESignature.sharePointDocuments.length > 0
                        && !DocumentsValidation()">
                          Changing the signature document requires resending to the signature handler.
                        </div>
                        <div class="my-3 error-color" *ngIf="engagementESignature.signatureTypeCode=='E' &&
                        initialEngagementESignature.handlerEmail
                        && initialEngagementESignature.handlerEmail != engagementESignature.handlerEmail">
                          Changing the signature handler requires resending to the new signature handler.
                        </div>
                        <div class="error-color" *ngIf="IsSigningReqeuestFailed">
                          <span>{{ SigningErrorMessage }}</span>
                        </div>
                        <div class="d-flex justify-content-end mt-2">
                          <button id="btnSendToHandler" class="btn PrimaryButton rounded-0" type="button"
                            [disabled]="IsElectronicSendButtonDisabled" *ngIf="IsElectronicSendButtonDisabled">
                            Send to handler
                          </button>
                          <button id="btnSendToHandler" class="btn PrimaryButton rounded-0" type="button"
                            (click)="SendToHandler()" *ngIf="!IsElectronicSendButtonDisabled">
                            Send to handler
                          </button>
                        </div>
                      </div>
                      <div class="printed-section" *ngIf="engagementESignature.signatureTypeCode == 'P'">

                        <div class="d-flex justify-content-end my-2">
                          <button id="btnSendToHandler" class="btn PrimaryButton rounded-0 btn-printed-document"
                            type="button" (click)="PrintDocument()">
                            <img src="../../../assets/images/EDP/Engagement/print-24px.svg" alt="Print Status" />
                            <span class="d-inline-block pl-2">Print</span>
                          </button>
                        </div>

                        <label>Date of client signature:</label>
                        <div class="sec-heading font-weight-bold row m-0"
                          [ngClass]="{true: 'nonClickableDiv'}[isEngagementClosed || initialEngagementESignature.envelopeStatusCode=='Completed']"
                          *ngIf="initialEngagementESignature.signatureTypeCode=='P' && showPrintedSignerName">
                          <div class="col-md-7 px-0">
                            <span>Signed by {{ initialEngagementESignature.handlerName }}</span>
                          </div>
                          <div class="col-md-4 px-0 text-right">
                            <span> {{engagementESignature.createDate | date: 'MM/dd/yy' }}</span>
                          </div>
                          <div class="col-md-7 px-0">
                            <span> Date recorded in Engage</span>
                          </div>
                          <div class="col-md-4 px-0 text-right">
                            <span> {{engagementESignature.dateSubmitted | date: 'MM/dd/yy' }}</span>
                          </div>
                          <div class="col-md-2 px-0 text-right"
                            *ngIf="initialEngagementESignature.envelopeStatusCode!='Completed'">
                            <span class="signing-section-link text-right"
                              (click)="ShowPrintedSignerAndDatePicker()">&nbsp;Change</span>
                          </div>
                          <span class="font-weight-bold cursor-pointer link-text-blue"
                            (click)="ProcessPrintRequest(true)" *ngIf="showPrintedSignerName && initialEngagementESignature.sharePointDocuments.length > 0
                          && !DocumentsValidation()">&nbsp;Save</span>
                        </div>
                        <div class="row m-0 docu-sign-calender"
                          [ngClass]="{true: 'nonClickableDiv'}[isEngagementClosed || initialEngagementESignature.envelopeStatusCode=='Completed']">
                          <div class="col-md-12 d-flex align-items-center px-0 printed-signature-date" *ngIf="!initialEngagementESignature.signatureTypeCode || (initialEngagementESignature.signatureTypeCode=='P' && !showPrintedSignerName)
                          || (initialEngagementESignature.signatureTypeCode=='E' )">
                            <input type="text" class="form-control rounded-0 mr-2" name="txtSigner" id="txtSigner"
                              [(ngModel)]="engagementESignature.handlerName"
                              (keypress)="validationForSignerField($event)"
                              (blur)="copyPasteTextValidationForSignerField()" placeholder="Client signer" />
                            <app-calendercontrol id="printedSigningDate" [txtDate]="printedSigningDateId"
                              [showCustomPlaceholder]='true' [placeholder]="'Select date signed'" class="w-50">
                            </app-calendercontrol>
                          </div>
                          <div class="col-md-12 text-right d-flex justify-content-end px-0 mt-2" *ngIf="!initialEngagementESignature.signatureTypeCode || (initialEngagementESignature.signatureTypeCode=='P' && !showPrintedSignerName)
                          || (initialEngagementESignature.signatureTypeCode=='E' )">
                            <p class="cursor-pointer link-text-blue mr-2" (click)="CancelPrintedSigningDetails()">
                              Reset
                            </p>
                            <p class="font-weight-bold cursor-pointer link-text-blue"
                              (click)="ProcessPrintedSigningDetails()">&nbsp;Save</p>
                          </div>
                          <div class="printed-signature-err-messages">
                            <div class="my-3 error-color" *ngIf="isEmptyPrintedSinger">Signer is required</div>
                            <div class="my-3 error-color" *ngIf="isEmptyPrintedDate">Signed date is required</div>
                            <div class="my-3 error-color" *ngIf="isFutureDateSelected && !isEmptyPrintedDate">Future
                              dates are not allowed</div>
                            <div class="my-3 error-color" *ngIf="isPrintedSectionESignDiscardFailed">Unable to void
                              previous ESign request</div>
                            <div class="my-3 error-color" *ngIf="initialEngagementESignature.signatureTypeCode == 'P'
                            && initialEngagementESignature.sharePointDocuments && initialEngagementESignature.sharePointDocuments.length > 0
                            && !DocumentsValidation()">
                              Changing the signature document requires printing the document and obtaining a new
                              signature.
                            </div>
                          </div>
                        </div>
                        <div *ngIf="cmsCodeRequestError" class="row m-0 alert alert-danger">
                          <span>Error while sending CMS code request. </span>
                        </div>
                      </div>
                    </div>

                    <hr />
                    <div class="area-tablet">
                      <div class="sec-heading mt-2 ">Approver</div>
                      <div id="professionalsDetails">

                        <div id="tblProfessionals" aria-describedby="RP"
                          *ngIf="eppmdApproverDispalyModel.length > 0 || autoRPDisplayModel.length > 0 || manualRPDisplayModel.length > 0">
                          <ul *ngFor="let roleGroup of eppmdApproverDispalyModel"
                            style="list-style-type: none!important;">
                            <p class="font-weight-bold">{{ roleGroup.roleDescription }}</p>
                            <div *ngFor="let member of roleGroup.teamMembers">
                              <li class="reviewer">
                                <div>
                                  <span style="width: 20px; text-align: center;" class="d-inline-block align-top">
                                    <img *ngIf="member.approvalStatus=='Approved'"
                                      src="assets/images/EDP/Workspace/check_circle-24px.svg" class="img-responsive"
                                      alt="approved" style="vertical-align: text-top;" width="20" height="20" />
                                    <span *ngIf="member.approvalStatus!='Approved'" class="reviewerStatus">
                                      <img src="../../../assets/images/EDP/Workspace/pending-icon.svg" alt="pending"
                                        class="Pending-status" />
                                    </span>
                                  </span>
                                  <span
                                    class="d-inline-block ml-1 mb-1 font-weight-bold reviewer-name-width l-spacing-1 edp-font-14 contactUser"
                                    id="teamMember_{{member.id}}"
                                    (click)="DisplayTMContactPopup(member.id,member.memberEmailId)">{{
                                    member.memberName }}</span>
                                </div>
                              </li>
                              <li class="ml-4 edp-font-12">
                                <span class="approve-stat" *ngIf="member.approvalStatus=='Approved'">
                                  {{member.updatedOn | date: "MM/dd/yyyy"}}
                                </span>
                              </li>
                            </div>
                          </ul>
                          <ul *ngFor="let roleGroup of autoRPDisplayModel" style="list-style-type: none!important;">
                            <p class="font-weight-bold">{{ roleGroup.roleDescription }}</p>
                            <div *ngFor="let member of roleGroup.teamMembers">
                              <li class="reviewer">
                                <div>
                                  <span style="width: 20px; text-align: center;" class="d-inline-block align-top">
                                    <img *ngIf="member.approvalStatus=='Approved'"
                                      src="assets/images/EDP/Workspace/check_circle-24px.svg" class="img-responsive"
                                      alt="approved" style="vertical-align: text-top;" width="20" height="20" />
                                    <span *ngIf="member.approvalStatus!='Approved'" class="reviewerStatus">
                                      <img src="../../../assets/images/EDP/Workspace/pending-icon.svg" alt="pending"
                                        class="Pending-status" />
                                    </span>
                                  </span>
                                  <span
                                    class="d-inline-block ml-1 mb-1 font-weight-bold reviewer-name-width l-spacing-1 edp-font-14 contactUser"
                                    id="RPcontactPopup_{{member.id}}"
                                    (click)="DisplayRPContactPopup(member.id, member.memberEmailId)">{{
                                    member.memberName }}</span>
                                </div>
                              </li>
                              <li class="ml-4 edp-font-12">
                                <span class="approve-stat" *ngIf="member.approvalStatus=='Approved'">
                                  {{member.updatedOn | date: "MM/dd/yyyy"}}
                                </span>
                              </li>
                            </div>
                          </ul>
                          <div *ngIf="manualRPDisplayModel[0]?.teamMembers?.length > 0">
                            <div *ngFor="let roleGroup of manualRPDisplayModel">
                              <p class="font-weight-bold">{{ roleGroup.roleDescription }}</p>
                              <ul *ngFor="let member of roleGroup.teamMembers" style="list-style-type: none!important;">
                                <li class="reviewer">
                                  <div>
                                    <span style="width: 20px; text-align: center;" class="d-inline-block align-top">
                                      <img *ngIf="member.approvalStatus=='Approved'"
                                        src="assets/images/EDP/Workspace/check_circle-24px.svg" class="img-responsive"
                                        alt="approved" style="vertical-align: text-top;" width="20" height="20" />
                                      <span *ngIf="member.approvalStatus!='Approved'" class="reviewerStatus">
                                        <img src="../../../assets/images/EDP/Workspace/pending-icon.svg" alt="pending"
                                          class="Pending-status" />
                                      </span>
                                    </span>
                                    <span
                                      class="d-inline-block ml-1 mb-1 font-weight-bold reviewer-name-width l-spacing-1 edp-font-14 contactUser"
                                      id="RPcontactPopup_{{member.id}}"
                                      (click)="DisplayRPContactPopup(member.id, member.memberEmailId)">{{
                                      member.memberName }}</span>
                                  </div>
                                </li>
                                <li class="ml-4 edp-font-12">
                                  <span class="approve-stat" *ngIf="member.approvalStatus=='Approved'">
                                    {{member.updatedOn | date: "MM/dd/yyyy"}}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="eppmdApproverDispalyModel.length == 0">
                          <span>
                            No Data Available
                          </span>
                        </div>
                      </div>
                    </div>
                    <!--Signature/Approver Section End-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div role="tabpanel" class="tab-pane" id="assignments" [ngClass]="{'active' : isSelectedAssignmentImg}">
          <!-- Start Engagement Assignment -->
          <div class="my-3">
            <div id="assignmentHeaderDiv" class="row col-md-12">
              <h5 class="edp-font-color font-weight-bold">Engagement assignments</h5>
            </div>
            <!--<div *ngIf="changeOrderHistoryLength > 0" class="row col-md-12">
              <p class="mb-0">
                The assignment data reflected here represents the current state of assignment data in CMS. The impact of any change order will only be reflected here if that change order information has been entered into Centralized Billing.
              </p>
            </div>-->
            <div id="viewAssignmentDiv" *ngIf="arrEngagementAssignment.length > 0" class="acceptance-view">
              <div *ngFor="let assignmentItem of arrEngagementAssignment; let i = index" class="seperator py-4">
                <div id="row_{{i}}" class="row m-0 p-0 position-relative viewAssignmentsDetails">
                  <p class="position-absolute"><strong>{{assignmentItem?.assignmentName}}&nbsp;</strong>
                    <span *ngIf="engagementList.clientCode!=null">- {{engagementList?.clientCode}}</span>
                    <span *ngIf="assignmentItem.assignmentCode!=null">.{{assignmentItem?.assignmentCode}}</span>
                    <span *ngIf="assignmentItem.isPrimaryAssignment"> - Primary</span>
                  </p>
                  <div class="col-xl-3 col-md-6 pl-0 mt-4">
                    <div class="mb-1">{{((assignmentItem || {}).selectedengagementType || {}).value}}</div>

                    <div class="mb-1">{{assignmentItem?.selectedsolutionCode.solutionCode}} -
                      {{assignmentItem?.selectedsolutionCode.solutionCodeDescription}}</div>
                    <div class="mb-1">Estimated start date:
                      {{assignmentItem?.engagementStartDate | date: "MM/dd/yyyy"}}
                    </div>
                    <div class="mb-1">Collaboration space: <span class="font-weight-bold">
                        {{(assignmentItem?.collaborationSiteName)}}</span>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6 pl-md-0 px-xl-2 mt-4">
                    <div class="mb-1">CMS PPMD:&nbsp;
                      <span id="cmsEPPMDContactPopup_{{assignmentItem.assignmentCodeId}}"
                        class="link-text-blue cms-eppmd-contact-popover"
                        (mousedown)="displayCMSEPPMDContactPopup(assignmentItem)">{{assignmentItem?.cmsEPPMDName}}</span>
                    </div>
                    <div class="mb-1">Assignment administrator:&nbsp;
                      <span id="adminContactPopup_{{assignmentItem.assignmentCodeId}}"
                        class="link-text-blue admin-contact-popover"
                        (mousedown)="displayAdminContactPopup(assignmentItem)">{{assignmentItem?.assignmentAdministrator}}</span>
                    </div>
                    <div class="mb-1">Estimated end date: {{assignmentItem?.engagementEndDate | date: "MM/dd/yyyy"}}
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6 pl-md-0 px-xl-2 mt-4">
                    <div class="mb-1">Realization %: <strong>{{assignmentItem?.realization}}%</strong></div>
                    <div class="mb-1">Estimated net fees: <strong>{{assignmentItem?.netFeesInCurrencyFormat
                        }}</strong>
                    </div>
                    <div class="mb-1">Billing contact:
                      <span class="font-weight-bold"
                        *ngIf="assignmentItem.clientBillingContactName!='' && assignmentItem.clientBillingContactTitle!=''">
                        {{assignmentItem?.clientBillingContactName}},
                        {{assignmentItem?.clientBillingContactTitle}}
                      </span>
                    </div>
                    <div class="mb-1">Billing contact email:
                      <span class="font-weight-bold" style="word-break: break-all;"
                        *ngIf="assignmentItem.clientBillingContactEmail!='' && assignmentItem.clientBillingContactEmail!=null">
                        {{assignmentItem?.clientBillingContactEmail}}
                      </span>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6 pl-md-0 px-lg-2 mt-4">
                    <div class="mb-1" *ngIf="assignmentItem.isContingent">Contingent fee: <strong>Yes</strong></div>
                    <div class="mb-1" *ngIf="!assignmentItem.isContingent">Contingent fee: <strong>No</strong></div>
                    <div class="mb-1">Estimated gross margin:
                      <strong>{{assignmentItem?.estimatedGrossMargin}}%</strong>
                    </div>
                    <div class="mb-1">Closest GT office: <strong>{{assignmentItem?.officeLocationCodeName}}</strong>
                    </div>

                    <div class="mb-1">
                      EQCR:&nbsp;
                      <span class="reviewer contactUser cursor-pointer mr-2"
                        id="eqcrNameContactPopup_{{assignmentItem.assignmentCodeId}}"
                        (mousedown)="displayEQCRContactPopup(assignmentItem)" *ngIf="!assignmentItem.isEditEQCR">
                        {{assignmentItem?.eqcrName}} </span>

                      <span class="reviewer" class="clickableLink" *ngIf="assignmentItem.assignmentCodeId > 0 && !assignmentItem.isEditEQCR && !isEngagementClosed && 
                                          assignmentItem.eqcrName != ''" (click)="addEditEQCR(i)">Edit</span>

                      <span class="reviewer" class="clickableLink" *ngIf="assignmentItem.assignmentCodeId > 0 && !assignmentItem.isEditEQCR && !isEngagementClosed && 
                                            assignmentItem.eqcrName == ''" (click)="addEditEQCR(i)">Add</span>


                      <span class="reviewer" class="nonClickableLink"
                        *ngIf="!assignmentItem.isEditEQCR && isEngagementClosed">Edit</span>

                      <div class="px-0 my-1" *ngIf="assignmentItem.isEditEQCR">
                        <div class="d-flex align-items-center">
                          <div class="input-group mw-100 w-46-sm w-75">

                            <app-people-picker class="eng-assign-people-picker" [IsPickerForAssignment]=true
                              [roleShortName]="EQCRRoleName" [labelCtrlName]="EQCRlblCtrlName" [isLabelVisible]="false"
                              [InputCtrlIdIndex]="i" [PeoplePickerModel]="assignmentItem.newEQCRName"
                              [userEmail]="assignmentItem.newEQCREmailId" [IsValidateUserWithADGroup]="validateEQCR">
                            </app-people-picker>

                            <div class="input-group-append input-height">
                              <button type="button" id="PPIcon_{{i}}" data-toggle="modal"
                                data-target="#modal-AssignmentEQCR" data-keyboard="false" data-backdrop="static"
                                class="mw-100 people-picker-button text-center"
                                (click)="refreshAssignmentEQCRPopup(i,'txtPeoplePicker_')">
                                <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="Assignment EQCR" />
                              </button>

                            </div>
                          </div>
                        </div>

                        <div style="text-align: right;" class="w-75">
                          <span class="ml-4 cursor-pointer link-text-blue" (click)="cancelEQCR(i)">Cancel</span>

                          <span class="ml-4 font-weight-bold  link-text-blue"
                            (click)="updateEngagementEQCR(i, assignmentItem.assignmentCodeId)">Save</span>
                        </div>
                        <div class="form-control-feedback error-color" *ngIf="!assignmentItem.isvalidEQCR">
                          <p>Assignment EQCR should be valid</p>
                        </div>
                        <div id="businessMessageForEQCRDiv" *ngIf="!assignmentItem.isValidEQCRBusinessRule"
                          class="form-control-feedback error-color">
                          <p>EQCR cannot be EPPMD or CMS PPMD or AA</p>
                        </div>
                        <div id="businessMessageForEQCRDiv" *ngIf="assignmentItem.isEQCRExists"
                          class="form-control-feedback error-color">
                          <p>No change in EQCR to save</p>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>

            <div id="defaultAssignmentDiv"
              *ngIf="!isEngagementClosed && isVisibledefaultAssignmentDiv && engagementList.isAssignmentExec"
              class="mt-3 w-100">
              <div class="col-md-12 bg-white pl-2 pb-3">
                <div class="row m-0">
                  <div class="col-xl-3 col-md-6 pr-2 pt-3">
                    <label><span class="label">Assignment name </span> <span class="label mandatory">*</span></label>
                    <input id="txtAssignmentName" name="assignmentName" class="input-height form-control rounded-0 "
                      type="text" maxlength="40" placeholder="Assignment name"
                      [(ngModel)]="defaultEngAssignment.assignmentName"
                      (change)="invalidCharacterDetectionAssignmentName()"
                      (keyup)="invalidCharacterDetectionAssignmentName()" autocomplete="off" autofocus>
                    <div id="EngagementNameHint" class="span-hint mt-1 mb-0" *ngIf="!(!arrControlValidation[0]?.isvalid && arrControlValidation[0]?.apply) &&
                                      !(isNewAssignmentNameDuplicate  || isAssignmentNameExistForClient) &&
                                      validAssignmentName && !(invalidvaluesAN != '' && invalidvaluesAN != null)">
                      <p>Allowable: . - _ & ' , ( )</p>
                    </div>
                    <div class="form-control-feedback error-color"
                      *ngIf="invalidvaluesAN != '' && invalidvaluesAN != null ">
                      <p>'{{invalidvaluesAN}}' <span *ngIf="invalidvaluesAN.length === 1">is not allowed.</span><span
                          *ngIf="invalidvaluesAN.length > 1">are not allowed.</span></p>
                    </div>
                    <div class="form-control-feedback error-color"
                      *ngIf="!arrControlValidation[0]?.isvalid && arrControlValidation[0]?.apply">
                      <p>Enter assignment name</p>
                    </div>
                    <div class="error-color " *ngIf="isNewAssignmentNameDuplicate  || isAssignmentNameExistForClient">
                      <p>Duplicate assignment name </p>
                    </div>
                    <div class="form-control-feedback error-color" *ngIf="!validAssignmentName">
                      <p>Invalid Assignment Name </p>
                    </div>
                  </div>

                  <div class="col-xl-3 col-md-6 pr-2 pt-3">
                    <label><span class="label">Solution code </span> <span class="label mandatory">*</span></label>
                    <div class="selectdiv ">
                      <select id="drpsolutionCodeId" name="selectedsolutionCode" class="dropdown rounded-0"
                        [disabled]="(arrEngagementAssignment.length==0) " #solutionCodeId="ngModel"
                        [(ngModel)]="defaultEngAssignment.solutionCodeId" (change)="onSolutionCodeChange()">
                        <option value="null" disabled hidden>Select solution code</option>
                        <option *ngFor="let solutionCode of defaultEngAssignment.solutionCodeListData"
                          [value]=solutionCode?.childSolutionCodeId>
                          {{solutionCode.solutionCode}}-{{solutionCode.solutionCodeDescription}}</option>
                      </select>
                    </div>
                    <div class="form-control-feedback error-color"
                      *ngIf="!arrControlValidation[1]?.isvalid && arrControlValidation[1]?.apply">
                      <p>Select solution code</p>
                    </div>
                  </div>

                  <div class="col-xl-3 col-md-6 pr-2 pt-3">
                    <label><span class="label">CMS PPMD </span> <span class="label mandatory">*</span></label>
                    <div class="input-group mw-100">
                      <app-people-picker class="eng-assign-people-picker" [IsPickerForAssignment]=true
                        [roleShortName]="epRoleName" [labelCtrlName]="cmslblCtrlName" [isLabelVisible]="false"
                        [InputCtrlIdIndex]="peoplepickerIndex" [PeoplePickerModel]="cmsDisplayUserName"
                        [userEmail]="cmsDisplayUserEmail" [IsValidateUserWithADGroup]="validateCMSEP">
                      </app-people-picker>

                      <div class="input-group-append input-height">
                        <button type="button" id="PPIcon_{{peoplepickerIndex}}" data-toggle="modal"
                          data-target="#modal-EP" data-keyboard="false" data-backdrop="static"
                          class="mw-100 people-picker-button text-center"
                          (click)="refreshCMSEPPopup(i,'txtPeoplePicker_')">
                          <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="CMS PPMD" />
                        </button>
                      </div>
                    </div>
                    <div class="form-control-feedback error-color"
                      *ngIf="!arrControlValidation[6]?.isvalid && arrControlValidation[6]?.apply">
                      <p>Select CMS PPMD</p>
                    </div>
                    <div class="form-control-feedback error-color" *ngIf="!isvalidcmsEPPMDName">
                      <p>CMS PPMD should be valid</p>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6 pr-2 pt-3">
                    <label><span class="label">Assignment administrator </span> <span
                        class="label mandatory">*</span></label>
                    <div class="input-group mw-100">
                      <app-people-picker class="eng-assign-people-picker" [roleShortName]="assignAdminRoleName"
                        [labelCtrlName]="assignAdminlblCtrlName" [isLabelVisible]="false"
                        [InputCtrlIdIndex]="peoplepickerIndex" [IsPickerForAssignment]=true
                        [PeoplePickerModel]="adminDisplayUserName" [userEmail]="adminDisplayUserEmail"
                        [IsValidateUserWithADGroup]="validateCMSAA">
                      </app-people-picker>

                      <div class="input-group-append input-height">
                        <button type="button" id="PPIcon_{{peoplepickerIndex}}" data-toggle="modal"
                          data-target="#modal-AssignmentAdministrator" data-keyboard="false" data-backdrop="static"
                          class="mw-100 people-picker-button text-center"
                          (click)="refreshCMSAssignmentAdminPopup(i,'txtPeoplePicker_')">
                          <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="Assignment administrator" />
                        </button>
                      </div>
                    </div>
                    <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color"
                      *ngIf="!arrControlValidation[7]?.isvalid && arrControlValidation[7]?.apply">
                      <p>Select assignment administrator</p>
                    </div>

                    <div id="businessMessageForAdministratorsDiv" style="display: none;"
                      class="form-control-feedback error-color">
                      <p>CMS PPMD and AA cannot be the same.</p>
                    </div>
                    <div class="form-control-feedback error-color" *ngIf="!isvalidAassignmentAdministrator">
                      <p>Assignment administrator should be valid</p>
                    </div>
                  </div>
                </div>
                <div class="row m-0">
                  <div class="col-xl-3 col-md-6 pr-2 pt-3">
                    <label><span class="label">Engagement type </span> <span class="label mandatory">*</span>
                    </label>
                    <div class="selectdiv ">
                      <select id="drpengagementTypeId" name="selectedengagementType"
                        class="dropdown rounded-0 form-control" [(ngModel)]="defaultEngAssignment.engagementTypeId">
                        <option value="0" selected="selected">Select engagement type</option>
                        <option *ngFor="let EngTypeItem of defaultEngAssignment.engagementTypeData"
                          [value]=EngTypeItem.key>
                          {{EngTypeItem.value}}</option>
                      </select>
                    </div>
                    <div class="form-control-feedback error-color"
                      *ngIf="!arrControlValidation[2]?.isvalid && arrControlValidation[2]?.apply">
                      <p>Select engagement type</p>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6 pr-2 pt-3">
                    <label for="engPeriodDate">Estimated start date </label>
                    <span class="label mandatory"
                      *ngIf="defaultEngAssignment.selectedsolutionCode.isEngagementStartDateRequired">*</span>
                    <app-calendercontrol id="EstimatedStartDate" [txtDate]="StartDateCtrlId" class="eng-period-date"
                      [(ngModel)]="defaultEngAssignment.engagementStartDate">
                    </app-calendercontrol>
                    <div class="form-control-feedback error-color"
                      *ngIf="!arrControlValidation[13]?.isvalid && arrControlValidation[13]?.apply">
                      <p>Enter estimated start date</p>
                    </div>
                    <div id="errormessage" class="error-color" *ngIf="DateCompareResult > 0">
                      <p>Start date cannot be after the end date</p>
                    </div>
                    <div id="errormessage" class="form-control-feedback error-color" *ngIf="!isvalidESD">
                      <p>Start date should be valid</p>
                    </div>
                    <div id="errormessage" class="form-control-feedback error-color" *ngIf="startDateCompareResult > 0">
                      <p>Start cannot be in the past</p>
                    </div>
                  </div>

                  <div class="col-xl-3 col-md-6 pr-2 pt-3">
                    <label for="engPeriodDate">Estimated end date</label>
                    <span class="label mandatory"
                      *ngIf="defaultEngAssignment.selectedsolutionCode.isEngagementEndDateRequired">*</span>
                    <app-calendercontrol id="EstimatedEndDate" [txtDate]="EndDateCtrlId" class="eng-period-date"
                      [(ngModel)]="defaultEngAssignment.engagementEndDate">
                    </app-calendercontrol>
                    <div class="form-control-feedback error-color"
                      *ngIf="!arrControlValidation[14]?.isvalid && arrControlValidation[14]?.apply">
                      <p>Enter estimated end date</p>
                    </div>
                    <div id="errormessage" class="error-color" *ngIf="DateCompareResult == 0">
                      <p>Start date and end date can not be equal</p>
                    </div>
                    <div id="errormessage" class="form-control-feedback error-color" *ngIf="!isvalidEED">
                      <p>End date should be valid</p>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6 pr-2 pt-3">
                    <label><span class="label">Closest GT office </span> </label>
                    <div class="selectdiv">
                      <select id="ddClosestGTOffice" class="dropdown rounded-0 form-control"
                        [(ngModel)]="defaultEngAssignment.officeLocationCodeId">
                        <option value="0" disabled hidden>Select closest GT office</option>
                        <option *ngFor="let location of ClientLocations" [value]="location.id">
                          {{location.typeValue}}
                        </option>
                      </select>
                    </div>
                  </div>

                </div>
                <!-- new fieds -->
                <div class="row m-0">
                  <div class="col-xl-3 col-md-6 pr-2 pt-3">
                    <label><span class="label">Contingent fee </span> <span class="label mandatory">*</span></label>
                    <div class="selectdiv">
                      <select id="drpisContingent" name="isContingent" class="dropdown rounded-0 form-control"
                        [(ngModel)]="defaultEngAssignment.isContingent">
                        <option value="null" disabled hidden>Yes or No</option>
                        <option *ngFor="let item of defaultEngAssignment.iscontingentData" [value]=item.key>
                          {{item.value}}
                        </option>
                      </select>
                    </div>
                    <div class="form-control-feedback error-color"
                      *ngIf="!arrControlValidation[5]?.isvalid && arrControlValidation[5]?.apply">
                      <p>Select contingent</p>
                    </div>
                  </div>

                  <div class="col-xl-3 col-md-6 pr-2 pt-3">
                    <label><span class="label">Estimated net fees </span> <span class="label mandatory">*</span></label>
                    <input id="txtnetFees" name="netFees" class="input-height form-control rounded-0 " maxlength="9"
                      type="text" placeholder="$1,000,000" pattern="^[0-9]+(\.[0-9]{1,2})?$" autocomplete="off"
                      (keypress)="numberOnly($event)" [(ngModel)]="defaultEngAssignment.netFees">
                    <div class="form-control-feedback error-color"
                      *ngIf="!arrControlValidation[4]?.isvalid && arrControlValidation[4]?.apply">
                      <p>Enter estimated net fees</p>
                    </div>
                    <div id="errormessage" class="form-control-feedback error-color" *ngIf="!validNetfees">
                      <p>{{netFeesMessage}}</p>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6 pr-2 pt-3">
                    <label><span class="label">Realization % </span> <span
                        class="label mandatory input-height">*</span></label>
                    <input id="txtrealization" name="realization" class="input-height form-control rounded-0 "
                      type="text" placeholder="00%" maxlength="3" [(ngModel)]="defaultEngAssignment.realization"
                      autocomplete="off" (keypress)="numberOnly($event)">
                    <div class="form-control-feedback error-color"
                      *ngIf="!arrControlValidation[3]?.isvalid && arrControlValidation[3]?.apply">
                      <p>Enter realization</p>
                    </div>
                    <div id="errormessage" class="form-control-feedback error-color" *ngIf="!validRealization">
                      <p>{{RealizationMessage}}</p>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6 pr-2 pt-3">
                    <label for="EstimatedGM">Estimated gross margin % <span class="mandatory"
                        *ngIf="((defaultEngAssignment || {}).selectedsolutionCode || {}).isGrossMarginRequire==true">*</span></label>
                    <input id="txtGrossMargin" name="txtGrossMargin" class="input-height form-control rounded-0 "
                      type="text" placeholder="00%" maxlength="3" (keypress)="numberOnly($event)"
                      [(ngModel)]="defaultEngAssignment.estimatedGrossMargin" autocomplete="off">
                    <div class="form-control-feedback error-color"
                      *ngIf="!arrControlValidation[12]?.isvalid && arrControlValidation[12]?.apply">
                      <p>Enter Estimated gross margin</p>
                    </div>
                    <div id="errormessage" class="form-control-feedback error-color" *ngIf="!validEGrossMargin">
                      <p>{{grossMarginMessage}}</p>
                    </div>
                  </div>
                </div>
                <div class="row m-0">
                  <div class="col-xl-3 col-md-6 pr-2 pt-3">
                    <label><span class="label">Client billing contact name </span> <span
                        class="label mandatory">*</span></label>
                    <input id="txtClientBillingName" name="clientBillingName"
                      [(ngModel)]="defaultEngAssignment.clientBillingContactName" autocomplete="off"
                      class="input-height form-control rounded-0 " maxlength="50" type="text"
                      placeholder="(Default based on client)">
                    <div class="form-control-feedback error-color"
                      *ngIf="!arrControlValidation[8]?.isvalid && arrControlValidation[8]?.apply">
                      <p>Enter client billing name</p>
                    </div>
                  </div>

                  <div class="col-xl-3 col-md-6 pr-2 pt-3">
                    <label><span class="label">Client billing contact title </span> <span
                        class="label mandatory input-height">*</span></label>
                    <div class="selectdiv">
                      <select id="ddClientBillingTitle" name="clientBillingTitle"
                        class="dropdown rounded-0 form-control"
                        [(ngModel)]="defaultEngAssignment.clientBillingContactTitleId">
                        <option value="0" disabled hidden>(Default based on client)</option>
                        <option *ngFor="let contact of ClientContactTypes" [value]="contact.id">
                          {{contact.typeValue}}
                        </option>
                      </select>
                      <div class="form-control-feedback error-color"
                        *ngIf="!arrControlValidation[9]?.isvalid && arrControlValidation[09]?.apply">
                        <p>Select client billing contact title</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-3 col-md-6 pr-2 pt-3">
                    <label><span class="label">Client billing email </span>
                      <span class="label mandatory">*</span></label>
                    <input id="txtClientBillingEmail" name="clientBillingEmail"
                      [(ngModel)]="defaultEngAssignment.clientBillingContactEmail" autocomplete="off"
                      class="input-height form-control rounded-0 " maxlength="80" type="text"
                      placeholder="(Default based on client)" (blur)="checkSpaceChartBegin($event)">
                    <div class="form-control-feedback error-color"
                      *ngIf="!arrControlValidation[10]?.isvalid && arrControlValidation[10]?.apply">
                      <p>Enter client billing email</p>
                    </div>
                    <div class="form-control-feedback error-color" *ngIf="isInvalidClientBillingContactEmail">
                      <p>Enter valid client billing email</p>
                    </div>
                  </div>

                  <div class="col-xl-3 col-md-6 pr-2 pt-3">
                    <label><span class="label">Collaboration space </span>
                      <span class="label mandatory">*</span></label>
                    <div class="selectdiv">
                      <select id="ddCollaborationSpace" name="collaborationSpace"
                        class="dropdown rounded-0 form-control" [(ngModel)]="defaultEngAssignment.collaborationSiteId">

                        <option value="null" disabled hidden>Select collaboration space</option>
                        <option *ngFor="let collab of collaborationSiteList" [value]="collab.collaborationSiteId"
                          [hidden]="(collab.siteURL == null || collab.siteURL == '')
                          || (collab.siteRequestStatus != 'Completed' && collab.siteURL != null) ">
                          {{collab.collaborationSiteName}}
                        </option>
                      </select>
                      <div class="form-control-feedback error-color"
                        *ngIf="!arrControlValidation[11]?.isvalid && arrControlValidation[11]?.apply">
                        <p>Select collaboration space </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row m-0">
                  <!-- Start Assignment EQCR field ------>
                  <div class="col-xl-3 col-md-6 pr-2 pt-3">
                    <label><span class="label">Assignment EQCR
                      </span></label>
                    <div class="input-group mw-100">

                      <app-people-picker class="eng-assign-people-picker" [IsPickerForAssignment]=true
                        [roleShortName]="EQCRRoleName" [labelCtrlName]="EQCRlblCtrlName" [isLabelVisible]="false"
                        [InputCtrlIdIndex]="peoplepickerIndex" [PeoplePickerModel]="defaultEngAssignment.newEQCRName"
                        [userEmail]="defaultEngAssignment.newEQCREmailId" [IsValidateUserWithADGroup]="validateEQCR">
                      </app-people-picker>

                      <div class="input-group-append input-height">
                        <button type="button" id="PPIcon_{{peoplepickerIndex}}" data-toggle="modal"
                          data-target="#modal-AssignmentEQCR" data-keyboard="false" data-backdrop="static"
                          class="mw-100 people-picker-button text-center"
                          (click)="refreshAssignmentEQCRPopup(peoplepickerIndex,'txtPeoplePicker_')">
                          <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="Assignment EQCR" />
                        </button>

                      </div>

                    </div>

                    <div class="form-control-feedback error-color" *ngIf="!defaultEngAssignment.isvalidEQCR">
                      <p>Assignment EQCR should be valid</p>
                    </div>

                    <div id="businessMessageForEQCRDiv" *ngIf="!defaultEngAssignment.isValidEQCRBusinessRule"
                      class="form-control-feedback error-color">
                      <p>EQCR cannot be EPPMD or CMS PPMD or AA</p>
                    </div>
                  </div>
                  <!-- end Assignment EQCR field -->

                </div>
                <!-- end new fields -->
                <!-- button row start -->
                <div class="row mx-0 my-2">
                  <div class="col-xl-6 col-md-6 pr-2 pt-3">
                  </div>
                  <div class="col-xl-3 col-md-3 pr-2 pt-3">
                    <button id="btnCancelAssignment" *ngIf="arrEngagementAssignment.length > 0"
                      class="btn SecondaryButton w-100 text-center" type="button" (click)="cancelAssignment()">
                      Cancel
                    </button>
                  </div>
                  <div class="col-xl-3 col-md-3 pr-2 pt-3">
                    <button id="btnSaveAssignment" class="btn PrimaryButton rounded-0 w-100 input-height" type="button"
                      (click)="saveAssignment(defaultEngAssignment.assignmentName)">
                      <img src="../../../../assets/images/EDP/Engagement/save-24px.svg" alt="Save"
                        style="cursor: pointer; margin-right: 3px;">
                      Save assignment
                    </button>
                  </div>
                  <!-- button row End -->
                </div>
              </div>
            </div>

            <div id="addNewAssignmentDIV"
              *ngIf="(isEngagementClosed || isVisibleNewAssignmentDIV) && engagementList.isAssignmentExec"
              class="w-100">
              <div class="row mx-0 py-4 bg-white">
                <div class="col-md-12" *ngIf="isEngagementClosed">
                  <a id="addNewAssignment" class="link-text-blue nonClickableLink">
                    <img src="../../../../assets/images/EDP/Engagement/add_box.svg"
                      alt="Add a new assignment to the engagement">
                    <span class="d-inline-block pl-2">Add a new assignment to the engagement</span>
                  </a>
                </div>
                <div class="col-md-12" *ngIf="!isEngagementClosed && !isMemberFirm">
                  <a id="addNewAssignment" class="link-text-blue" (click)="openAssignmentModelWithOneViewOpportunity()">
                    <img src="../../../../assets/images/EDP/Engagement/add_box.svg"
                      alt="Add a new assignment to the engagement">
                    <span class="d-inline-block pl-2">Add a new assignment to the engagement</span>
                  </a>
                </div>
                <div class="col-md-12" *ngIf="isMemberFirm">
                  <a id="addNewAssignment" class="link-text-blue nonClickableLink">
                    <img src="../../../../assets/images/EDP/Engagement/add_box.svg"
                      alt="Add a new assignment to the engagement">
                    <span class="d-inline-block pl-2">Add a new assignment to the engagement</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <!-- End Engagement Assignment -->
        </div>

        <div role="tabpanel" class="tab-pane" [ngClass]="{'active' : isSelectedCollabImg}" id="collaborations">
          <!-- Start TeamMemberDiv -->
          <div id="TeamMemberDiv" class="row ml-0">
            <div class="col-md-12 px-0">
              <div class="row ml-0 my-2">
                <h5 class="col-md-7 mt-2 pl-1 edp-font-color font-weight-bold">Collaboration spaces</h5>
                <div class="col-md-5 text-right pr-2 mt-2" *ngIf="teamMemberList.length > 0 ">
                  <a class="link-text-blue" (click)="collapseAllAssignmentTeamDetails()">
                    <img src="assets/images/EDP/Engagement/keyboard_arrow_up.svg" alt="Expand"
                      style="cursor: pointer; padding-bottom: 2px;" />
                    <span class="d-inline-block edp-font-14">Collapse all</span>
                  </a>
                  <a class="ml-4 link-text-blue" (click)="expandAllAssignmentTeamDetails()">
                    <img src="assets/images/EDP/Engagement/keyboard_arrow_down.svg" alt="Expand"
                      style="cursor: pointer; padding-bottom: 2px;" />
                    <span class="d-inline-block edp-font-14">Expand all</span>
                  </a>
                </div>

                <div class="w-100 mt-2" id="collaborationSiteList" *ngIf="collaborationSiteViewModelList.length > 0 ">
                  <div class="row m-0 my-2 currentCollaboration" [ngClass]="{true: 'nonClickableDiv'}[collaboration.collaborationSite.siteRequestStatus != 'Completed' 
                  && collaboration.collaborationSite.siteURL != null]"
                    *ngFor="let collaboration of collaborationSiteViewModelList; let i=index">
                    <div class="col-xl-3 col-lg-3 col-md-12 px-0">
                      <span class="d-table edp-font-14 font-weight-bold">
                        <img src="../../../assets/images/EDP/Engagement/supervised_user_circle.svg" alt="Superwise user"
                          class="d-table-cell align-top" />
                        <span class="d-table-cell align-top pl-3 font-weight-bold text-break">
                          {{collaboration.collaborationSite.collaborationSiteName}}
                        </span>
                      </span>
                    </div>
                    <input type="hidden" name="hdnCollaborationId{{i}}" id="hdnCollaborationId{{i}}"
                      class="form-control" value="{{collaboration.collaborationSite.collaborationSiteId}}">
                    <div class="col-xl-6 col-lg-6 col-md-8 px-0">

                      <span class="mx-1" *ngIf="collaboration.collaborationSite.isPrimaryCollaboration">
                        <a class="link-text-blue edp-font-14" *ngIf="collaboration.collaborationSite.msTeamUrl != null"
                          href="{{collaboration.collaborationSite.msTeamUrl}}" target="_blank">
                          <img src="../../../assets/images/EDP/Engagement/teams-logo.svg" class="icon-logo"
                            alt="teams" />Microsoft Teams </a>

                        <a class="link-text-grey edp-font-14 nonClickableLink"
                          *ngIf="collaboration.collaborationSite.msTeamUrl == null">
                          <img src="../../../assets/images/EDP/Engagement/teams-logo.svg" class="icon-logo"
                            alt="teams" />Microsoft Teams </a>
                      </span>
                      <span *ngIf="!collaboration.collaborationSite.isPrimaryCollaboration">
                        <span class="mx-1"
                          *ngIf="!isEngagementClosed && !collaboration.collaborationSite.isPrimaryCollaboration">
                          <a class="link-text-blue edp-font-14"
                            (click)="setEditCollabSpaceModel(collaboration.collaborationSite.collaborationSiteId)"
                            data-toggle="modal" data-target="#modal-EditCollabSpace">
                            &nbsp;Edit </a>
                        </span>
                        <span class="mx-1">
                          <a class="link-text-blue edp-font-14" [ngClass]="{'sap-bar': !isEngagementClosed }"
                            *ngIf="collaboration.collaborationSite.msTeamUrl != null"
                            href="{{collaboration.collaborationSite.msTeamUrl}}" target="_blank">
                            <img src="../../../assets/images/EDP/Engagement/teams-logo.svg" class="icon-logo"
                              alt="teams" />Microsoft Teams </a>

                          <a class="link-text-grey edp-font-14 nonClickableLink"
                            [ngClass]="{'sap-bar': !isEngagementClosed }"
                            *ngIf="collaboration.collaborationSite.msTeamUrl == null">
                            <img src="../../../assets/images/EDP/Engagement/teams-logo.svg" class="icon-logo"
                              alt="teams" />Microsoft Teams </a>
                        </span>
                      </span>


                      <span class="mx-1" *ngIf="!isEngagementClosed && collaboration.collaborationSite.siteURL == null">
                        <a class="link-text-blue edp-font-14  sap-bar"
                          (click)="RemoveCollabSpace(collaboration.collaborationSite.collaborationSiteId)">Remove
                        </a></span>

                      <ng-container *ngIf="
                      collaboration.collaborationSite.siteRequestStatus === 'Completed' 
                      && collaboration.collaborationSite.siteURL != null
                      && !engagementList.isSPLinkUnavailableForAll
                      && (engagementList.status !== 'Closed'
                            || (engagementList.status === engagementStatus.EngClosed
                                && (!engagementList.isSPLinkAvailableOnlyForQualityAndAdmin
                                      || (engagementList.isSPLinkAvailableOnlyForQualityAndAdmin && (isQualityUserLoggedIn === 'yes' || isloggedInUserAdmin === 'yes')))));
                      then displaySharePointLink"></ng-container>
                      <ng-template #displaySharePointLink>
                        <span class="mx-1">
                          <a class="link-text-blue edp-font-14 sap-bar"
                            href="{{collaboration.collaborationSite.siteURL}}" target="_blank">
                            <img src="../../../assets/images/EDP/Engagement/sharepoint-logo.svg" class="icon-logo"
                              alt="sharepoint" />
                            SharePoint</a>
                        </span>
                      </ng-template>

                      <span class="mx-1" *ngIf="(collaboration.collaborationSite.siteRequestStatus != 'Completed'
                        && collaboration.collaborationSite.siteURL != null)">
                        <a class="link-text-blue edp-font-14 orange-color  sap-bar">&nbsp;Provisioning in progress</a>
                      </span>
                      <span class="mx-1" *ngIf="(collaboration.collaborationSite.siteRequestStatus === 'Completed'
                              && collaboration.collaborationSite.siteURL != null)">
                        <a for="input-id" class="link-text-blue edp-font-14 ml-1 sap-bar"
                          (click)="expandTeamDetails(i, true,collaboration.collaborationSite)" data-toggle="modal"
                          data-target=".bd-example-modal-lg">
                          Manage team</a>
                      </span>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-4 text-right pr-0">
                      <span class="label edp-font-14">GT Team:
                        {{collaboration.collaborationSite.siteMemberCount}}</span>
                      <span class="label edp-font-14 ml-xl-5 ml-md-3">Client Team:
                        {{collaboration.clientTeamMemberList.length}}</span>
                    </div>
                    <!-- assignment details -->
                    <div [ngClass]="{false: 'div-sortable w-100', true: 'w-100'}[isEngagementClosed]">
                      <div id="noAssignment{{i}}" class="row mx-1 pl-4 noAssignment" *ngIf="(collaboration.engagementAssignmentList == null || collaboration.engagementAssignmentList.length == 0)
                                  && !isEngagementClosed
                                  && !collaboration.collaborationSite.isLoggedInUserPartOfCollaboration
                                  && collaboration.collaborationSite.siteMemberCount == 0">
                        <span id="warningLabelAssignment{{i}}" class="label error-color px-2 edp-font-12">
                          Collaboration spaces must have at least one assignment.</span>
                      </div>
                      <div id="assignmentdetails_{{i}}" style="display:block;"
                        class="row mx-1 my-2 pl-4 assignmentsection"
                        *ngFor="let assignment of collaboration.engagementAssignmentList">
                        <div id="hdnValues{{i}}" class="hdnValues" style="display:none;">
                          <input type="hidden" name="hdnCollaborationId" id="hdnAssignCollaborationId"
                            class="form-control" value="{{collaboration.collaborationSite.collaborationSiteId}}">
                          <input type="hidden" name="hdnAssignmentCodeId" id="hdnAssignmentCodeId" class="form-control"
                            value="{{assignment.assignmentCodeId}}">
                          <input type="hidden" name="hdnAssignmentCode" id="hdnAssignmentCode" class="form-control"
                            value="{{assignment.assignmentCode}}">
                        </div>
                        <!-- when assignments count = 1 then restrict drag-drop -->
                        <div id="assignmentCollaboration_{{assignment.assignmentCodeId}}"
                          class="assignmentDetail col-md-12 px-2"
                          *ngIf="collaboration.engagementAssignmentList.length == 1">
                          <img style="display: none;" class="drag-click"
                            src="assets/images/EDP/Engagement/drag_handle-24px.svg" alt="drag">
                          <span class="d-inline-block ml-3 edp-font-14">{{assignment.assignmentName}}</span>
                          <span class="d-inline-block ml-3 edp-font-14"
                            *ngIf="assignment.assignmentCode">{{engagementList.clientCode}}.{{assignment.assignmentCode}}</span>
                        </div>
                        <!-- when assignments count > 1 then allow drag-drop -->
                        <div *ngIf="collaboration.engagementAssignmentList.length  > 1"
                          id="assignmentCollaboration_{{assignment.assignmentCodeId}}" class="assignmentDetail"
                          [ngClass]="{false: 'col-md-12 px-2 list-group-item', true: 'col-md-12 px-2'}
                            [ !isEngagementClosed
                            && collaboration.collaborationSite.isLoggedInUserPartOfCollaboration ] ">
                          <img *ngIf="collaboration.engagementAssignmentList.length > 1 &&
                                      assignment.assignmentCode != ''
                                      && assignment.assignmentCode != null
                                      && assignment.assignmentCode != undefined
                                      && collaboration.collaborationSite.isLoggedInUserPartOfCollaboration"
                            class="drag-click" src="assets/images/EDP/Engagement/drag_handle-24px.svg" alt="drag">
                          <span *ngIf="collaboration.engagementAssignmentList.length > 1 &&
                              (assignment.assignmentCode == ''
                              || assignment.assignmentCode == null
                              || assignment.assignmentCode == undefined
                              || !collaboration.collaborationSite.isLoggedInUserPartOfCollaboration)"
                            style="margin:12px;"></span>
                          <span class="d-inline-block ml-3 edp-font-14">{{assignment.assignmentName}}</span>
                          <span class="d-inline-block ml-3 edp-font-14"
                            *ngIf="assignment.assignmentCode">{{engagementList.clientCode}}.{{assignment.assignmentCode}}</span>
                        </div>
                      </div>

                      <!--collaborationSpacePriorYearViewModelList Start -->
                      <div id="divHideShowPriorYearViewModel" *ngIf="(engagementList.reacceptanceEngagementId != null 
                          && engagementList.reacceptanceEngagementId > 0) &&
                          (collaboration.collaborationSite.siteRequestStatus === 'Completed'
                                && collaboration.collaborationSite.siteURL != null)
                          " class="m-0 p-0">

                        <div *ngIf="collaboration.collaborationSpacePriorYearViewModelList.length  >= 1"
                          class="col-md-12 px-3 p-0 m-0" id="currentCollabPriorYearViewModelList_{{i}}">
                          <div class="row ml-1 my-2 p-2" style="display:block; background-color: white;">
                            <div id="relatedSpaceLinkRow_{{i}}" class="row ml-4 my-2">
                              <span style="color: #707070;">Related</span>&nbsp;&nbsp;&nbsp;
                              <span class="reviewer" id="relatedSpacesIcon_{{i}}" class="clickableLink"
                                data-toggle="modal" data-target="#modal-ManageRelatedSpaces"
                                (click)="ManageRelatedSpaces_PopUpOpen(collaboration.collaborationSite.collaborationSiteId)">
                                Manage related spaces</span>
                            </div>

                            <div id="PriorYearViewModel_{{x}}" class="row ml-4 my-2"
                              *ngFor="let PriorYearViewModel of collaboration.collaborationSpacePriorYearViewModelList; let x=index">

                              <div class="col-3 float-left p-0 m-0">
                                <span>
                                  <img class="drag-click"
                                    src="../../../assets/images/EDP/Engagement/supervised_user_circle.svg"
                                    alt="supervised_user">
                                </span>&nbsp;&nbsp;
                                <span
                                  class="ml-3 mr-3 font-weight-bold text-break align-middle">{{PriorYearViewModel.collaborationSiteName}}</span>
                              </div>

                              <div class="col-9 float-left p-0 m-0">
                                <span class="mx-1" *ngIf="PriorYearViewModel.siteURL != null">
                                  <a class="link-text-blue edp-font-14" href="{{PriorYearViewModel.siteURL}}"
                                    target="_blank">
                                    <img src="../../../assets/images/EDP/Engagement/sharepoint-logo.svg"
                                      class="icon-logo" alt="sharepoint" />
                                  </a>
                                </span>
                              </div>

                            </div>
                          </div>

                        </div>

                        <div *ngIf="collaboration.collaborationSpacePriorYearViewModelList.length == 0"
                          class="col-md-12 px-3 p-0 m-0" id="collaborationSpacePriorYearViewModelList_{{i}}">
                          <div class="row ml-1 my-2 p-2" style="display:block; background-color: white;">
                            <div id="relatedSpaceLinkRow" class="row ml-4 my-2">
                              <span style="color: #707070;">Related</span>&nbsp;&nbsp;&nbsp;
                              <span class="reviewer" id="relatedSpacesIcon_{{i}}" class="clickableLink"
                                data-toggle="modal" data-target="#modal-ManageRelatedSpaces"
                                (click)="ManageRelatedSpaces_PopUpOpen(collaboration.collaborationSite.collaborationSiteId)">Manage
                                related spaces</span>
                            </div>
                            <div id="PriorYearViewModel_0" class="row " class="row ml-4 my-2">
                              <span>No related sites</span>
                            </div>

                          </div>

                        </div>
                      </div>
                      <!--collaborationSpacePriorYearViewModelList End -->
                    </div>
                    <!-- end assignment details -->
                  </div> <!-- end for loop collaborationSiteList with index i-->
                </div>
                <!--end collaborationSiteList.length > 0-->
              </div>

              <!--start add Collaboration Space -->
              <div class="row mx-0 pt-4 mb-2 bg-white" *ngIf="isNewCollaborationSpace">
                <div class="col-md-9">
                  <input type="text" name="txtCollaborationSiteName" id="txtCollaborationSiteName"
                    class="form-control rounded-0 input-height" placeholder="Enter collaboration space"
                    [(ngModel)]="newCollaborationSiteName"
                    (change)="invalidCharacterDetectionCollaborationSiteName('Add')"
                    (keyup)="invalidCharacterDetectionCollaborationSiteName('Add')" maxlength="25">
                  <div class="row">
                    <div id="EngagementNameHint" class="ml-4 span-hint mt-1 mb-0" *ngIf="!isNewSiteNameRequired && !isNewSiteNameDuplicate && isNewSiteNameValid &&
                                        !(invalidvaluesCN != '' && invalidvaluesCN != null)">
                      <p>Allowable: . - _ & ' , ( )</p>
                    </div>
                    <div class="error-color ml-3" *ngIf="invalidvaluesCN != '' && invalidvaluesCN != null ">
                      <p>'{{invalidvaluesCN}}' <span *ngIf="invalidvaluesCN.length === 1">is not allowed.</span><span
                          *ngIf="invalidvaluesCN.length > 1">are not allowed.</span></p>
                    </div>
                    <div class="error-color ml-3" *ngIf="isNewSiteNameRequired">
                      <p>Collaboration space is a required field </p>
                    </div>
                    <div class="error-color ml-3" *ngIf="isNewSiteNameDuplicate">
                      <p>Collaboration space already exists. Please enter unique name </p>
                    </div>
                    <div class="error-color ml-3" *ngIf="!isNewSiteNameValid">
                      <p>Invalid collaboration space name</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 pr-0 text-left">
                  <button id="removeCollaborationSpace" type="button" class="btn SecondaryButton p-1"
                    data-dismiss="modal" aria-hidden="true" (click)="removeCollaborationSpace()"
                    style="width: 80px !important;">
                    <span id="manageTeam-close-cancel-cap" class="d-inline-block align-middle"> Cancel</span>
                  </button>&nbsp;&nbsp;
                  <button id="btnSaveCollaborationSite" type="button" class="btn PrimaryButton p-1"
                    (click)="saveCollaborationSite(newCollaborationSiteName)" style="width: 80px !important;">
                    Add</button>
                </div>
                <!--end addTeamMember link-->
              </div>
              <!-- end for loop collaborationSiteList with index i-->
              <!--end add Collaboration Space -->

              <!--start addCollaborationSite button-->
              <div class="row mx-0 py-3">
                <div class="col float-left" *ngIf="isEngagementClosed">
                  <span class="addCollabSpaceDiv nonClickableButton d-flex align-items-center">
                    <div class="d-flex align-items-center px-1 pl-lg-2 grayBand">
                      <img src="assets/images/EDP/Engagement/add_box-white.svg" alt="add">
                      <span class="d-inline-block pl-md-1 pl-lg-1 f-13-sm pr-lg-1">
                        Add new collaboration space </span>
                    </div>
                  </span>
                </div>
                <div class="col float-left" *ngIf="!isEngagementClosed">
                  <span *ngIf="isloggedInUserAdmin=='yes' && isAdminEngagementMember" class="d-flex align-items-center">
                    <div
                      [ngClass]="{false: 'gtBlueBand link-text-blue' , true: 'grayBand nonClickableButton'}[isDisableAddCollaborationSite]"
                      class="addCollabSpaceDiv d-flex align-items-center px-1 pl-lg-1" (click)="addCollaborationSite()">
                      <img *ngIf="isDisableAddCollaborationSite" src="assets/images/EDP/Engagement/add_box-white.svg"
                        alt="add">
                      <img *ngIf="!isDisableAddCollaborationSite" src="assets/images/EDP/Engagement/add_box-blue.svg"
                        alt="add">
                      <span class="d-inline-block pl-md-1 pl-lg-1 f-13-sm pr-lg-1">
                        Add new collaboration space </span>
                    </div>
                  </span>

                  <span *ngIf="isloggedInUserAdmin=='no' " class="d-flex align-items-center">
                    <div
                      [ngClass]="{false: 'gtBlueBand link-text-blue', true: 'grayBand nonClickableButton'}[isDisableAddCollaborationSite]"
                      class="d-flex align-items-center px-1 pl-lg-1" (click)="addCollaborationSite()">
                      <img *ngIf="isDisableAddCollaborationSite" src="assets/images/EDP/Engagement/add_box-white.svg"
                        alt="add">
                      <img *ngIf="!isDisableAddCollaborationSite" src="assets/images/EDP/Engagement/add_box-blue.svg"
                        alt="add">
                      <span class="d-inline-block pl-md-1 pr-md-1 pl-lg-1 f-13-sm pr-lg-1">
                        Add new collaboration space </span>
                    </div>
                  </span>
                  <span id="collbrationWarning" class="pr-2 edp-font-12" style="color: #707070;"
                    *ngIf="(engagementList.status === 'Active (pending CMS codes)' ||
                    engagementList.status === EngagementStatus.EngActivePendingSignature || engagementList.status === 'Active') && engagementAssignmentList.length === 1">
                    Multiple assignments required</span>
                </div>
                <!--start Update Assignment members button-->
                <div class="col text-right pl-lg-0 float-right" *ngIf="isEngagementClosed">
                  <button id="btnUpdateAssignment" type="button" class="btn w-50 nonClickableButton">
                    Update</button>
                </div>
                <div class="col text-right pl-lg-0" *ngIf="!isEngagementClosed ">
                  <button *ngIf="isloggedInUserAdmin=='yes' && isAdminEngagementMember" id="btnUpdateAssignment"
                    type="button" class=" btn PrimaryButton w-50 w-40-sm text-center"
                    (click)="updateAllCollaborationWithAssignment()">
                    Update</button>
                  <button *ngIf="isloggedInUserAdmin=='no' " id="btnUpdateAssignment" type="button"
                    class=" btn PrimaryButton w-75 w-20-sm text-center"
                    (click)="updateAllCollaborationWithAssignment()">
                    Update</button>
                </div>
                <!--end Update Assignment members button-->
              </div>
              <!--end addCollaborationSite button-->
            </div>
          </div>
          <!--end TeamMemberDiv-->

          <div class="row m-0 pt-4">
            <div class="col-md-12 px-0">
              <app-alerts></app-alerts>
            </div>
          </div>
        </div>

        <!-- Start engCloseoutCheckList Panel -->
        <div role="tabpanel" class="tab-pane" [ngClass]="{'active' : isSelectedEngCloseoutCheckListImg}"
          id="engCloseoutCheckList">

          <!-- Task required for closeout an enegagement start -->
          <div>
            <div class="d-flex justify-content-between">
              <div class="float-left">
                <h6 class="mt-4 edp-font-color font-weight-bold">Tasks required for closing an engagement</h6>
                <!-- <p class="mb-0">To closeout this engagement, you must complete the required tasks. </p> -->
                <p class="mb-0">The following tasks must be completed before this engagement can be closed. </p>
              </div>
              <div class="text-right"
                *ngIf="closeoutSectionStructureAndVisibility?.requiredForEngagementCloseout?.length > 0">
                <p class="mt-5 mb-0 clickableLink">
                  <a id="mainCloseoutChecklist" class="ml-4 mr-2 link-text-blue"
                    (click)="toggleEngagementClosureRequiredTabs();" style="text-decoration: underline;">
                    <!-- <img src="assets/images/EDP/Engagement/keyboard_arrow_down.svg" alt="Expand"
                      style="cursor: pointer; padding-bottom: 2px;" /> -->
                    <span class="edp-font-14">Expand all</span>
                  </a>
                </p>
              </div>
            </div>

            <div id="accordion" class="mt-2 mb-4">
              <div id="engagementQualityChecklist" class="row ml-0"
                *ngIf="checkIfSectionExists(closeoutSectionStructureAndVisibility?.requiredForEngagementCloseout, closeoutSections.QC)">
                <div class="col-md-12 px-0">
                  <app-engagement-closeout-quality-checklist [engagementId]="primaryCollaborationDetails.engagementId"
                    [siteUniqueID]="primaryCollaborationDetails.siteUniqueID"
                    [qualityHDTemplatePackageId]="engagementList.qualityHDTemplatePackageId"
                    [isEngagementClosed]="isEngagementClosed" [sectionConfiguration]="getSection(closeoutSections.QC)"
                    (collapseEvent)="collapseRequiredChecklistSection(closeoutSections.QC)"
                    [solutionCode]="engagementList.solutionCode"
                    [secondarySolutionCode]="engagementList.commaSeperatedSolutionCodes"
                    [descriptionPrimaryAssignmentCode]="engagementList.solutionCodeDescription"
                    [descriptionSecondaryAssignmentCode]="engagementList.commaSeparatedSolutionCodeDescription"
                    [periodEndDate]="engagementList.taxYear | date: 'M/d/yyyy'">

                  </app-engagement-closeout-quality-checklist>
                </div>
              </div>



              <div id="engagementCloseoutRetention" class="row ml-0"
                *ngIf="checkIfSectionExists(closeoutSectionStructureAndVisibility?.requiredForEngagementCloseout, closeoutSections.RM)">
                <div class="col-md-12 px-0">
                  <app-engagement-closeout-retention [engagementId]="primaryCollaborationDetails.engagementId"
                    [siteUniqueID]="primaryCollaborationDetails.siteUniqueID"
                    [closeoutHDTemplatePackageId]="engagementList.closeoutHDTemplatePackageId"
                    [sectionConfiguration]="getSection(closeoutSections.RM)"
                    (collapseEvent)="collapseRequiredChecklistSection(closeoutSections.RM)">
                  </app-engagement-closeout-retention>
                </div>
              </div>

              <div id="engagementCloseoutArchival" class="row ml-0"
                *ngIf="checkIfSectionExists(closeoutSectionStructureAndVisibility?.requiredForEngagementCloseout, closeoutSections.FAI)">
                <div class="col-md-12 px-0">
                  <app-engagement-closeout-archival [sectionConfiguration]="getSection(closeoutSections.FAI)"
                    [engagementId]="engagementId" [solutionCodeId]="engagementList.parentSolutionCodeId"
                    [isEngagementClosed]="isEngagementClosed" [isloggedInUserPSD]="isloggedInUserPSD"
                    [isloggedInUserAdmin]="isloggedInUserAdmin" [engagementPartner]="engagementList.engagementPartner"
                    [solutionCodeId]="engagementList.parentSolutionCodeId"
                    (collapseEvent)="collapseRequiredChecklistSection(closeoutSections.FAI)">
                  </app-engagement-closeout-archival>

                </div>
              </div>

              <div id="engagementCloseoutClientSurvey" class="row ml-0"
                *ngIf="checkIfSectionExists(closeoutSectionStructureAndVisibility?.requiredForEngagementCloseout, closeoutSections.CS)">
                <div class="col-md-12 px-0">
                  <app-engagement-closeout-client-voice-survey id="engCheckList" name="engCheckList"
                    [engagementId]="engagementId" [engagementName]="engagementList.engagementName"
                    [collaborationSiteMembersList]="collaborationSiteMembersList"
                    [clientCode]="engagementList.clientCode" [clientName]="engagementList.clientName"
                    [engagementPartner]="engagementList.engagementPartner"
                    [engagementPartnerName]="engagementList.engagementPartnerName"
                    [isEngagementClosed]="isEngagementClosed"
                    [isEngagementCloseoutDisable]="isEngagementCloseoutDisable" [engStatus]="engagementList.status"
                    [isSuccessorFirmEnabled]="engagementList.isSuccessorFirmEnabled"
                    (engCloseOut)="EngagementClosureValidation()"
                    (collapseEvent)="collapseRequiredChecklistSection(closeoutSections.CS)"
                    [sectionConfiguration]="getSection(closeoutSections.CS)"
                    [isRequiredToSendClientVoiceSurvey]="isRequiredToSendClientVoiceSurvey"
                    [lastSurveySentDateForClient]="lastSurveySentDateForClient"
                    [isloggedInUserAdmin]="isloggedInUserAdmin">
                  </app-engagement-closeout-client-voice-survey>

                </div>
              </div>

              <div id="engagementCloseoutCleanUpChecklist" class="row ml-0"
                *ngIf="checkIfSectionExists(closeoutSectionStructureAndVisibility?.requiredForEngagementCloseout, closeoutSections.FCC)">
                <div class="col-md-12 px-0">
                  <app-engagement-closeout-clean-up-checklist [engagementId]="primaryCollaborationDetails.engagementId"
                    [siteUniqueID]="primaryCollaborationDetails.siteUniqueID"
                    [closeoutHDTemplatePackageId]="engagementList.closeoutHDTemplatePackageId"
                    [isEngagementClosed]="isEngagementClosed" [sectionConfiguration]="getSection(closeoutSections.FCC)"
                    (collapseEvent)="collapseRequiredChecklistSection(closeoutSections.FCC)"
                    [solutionCode]="engagementList.solutionCode"
                    [secondarySolutionCode]="engagementList.commaSeperatedSolutionCodes">
                  </app-engagement-closeout-clean-up-checklist>
                </div>
              </div>

              <div id="engagementCloseoutAuditChecklist" class="row ml-0">
                <div class="col-md-12 px-0"
                  *ngIf="checkIfSectionExists(closeoutSectionStructureAndVisibility?.requiredForEngagementCloseout, closeoutSections.ALC)">
                  <app-engagement-closeout-audit-checklist id="engCheckListCloseout" name="engCheckListCloseout"
                    [engagementId]="engagementId" [engagementName]="engagementList.engagementName"
                    [isEngagementClosed]="isEngagementClosed"
                    [engagementCreatedDate]="engagementAssignmentList[0].createdDate"
                    [isEngagementCloseoutDisable]="isEngagementCloseoutDisable" [engStatus]="engagementList.status"
                    (engCloseOut)="EngagementClosureValidation()"
                    (collapseEvent)="collapseRequiredChecklistSection(closeoutSections.ALC)"
                    [sectionConfiguration]="getSection(closeoutSections.ALC)">
                  </app-engagement-closeout-audit-checklist>
                </div>
              </div>


              <div class="row mt-3 ml-0 mr-0" *ngIf="anyPendingEngagementCloseoutRequiredTask">
                <div class="row m-0 alert alert-danger">
                  <span id="review-notes-badge"
                    class="ml-0 badge badge-pill msg-icon review_note-count alert-pill-s-doc "><img
                      src="assets/images/EDP/Workspace/report_problem-24px.svg" alt="No site Created"
                      class="icons-s-doc"></span>
                  <span class="error-color mt-2 pt-1">Engagement cannot be closed until all required tasks are
                    complete.</span>
                </div>
              </div>
              <div class="card pl-2 mt-2 rounded-0 border-0"
                *ngIf="!anyEngagementCloseoutRequiredTaskExists && checkIfSectionExists(closeoutSectionStructureAndVisibility?.requiredForEngagementCloseout, closeoutSections.CS)">
                <div class="justify-content-center p-3 d-flex border-bottom-0">
                  <div class="card-heading font-weight-bold">
                    There are no required tasks.
                  </div>
                </div>
              </div>


              <div class="text-right mb-4">
                <button class="btn roundBtn mt-md-3 mt-lg-3" *ngIf="!isEngagementClosed"
                  [disabled]="isEngagementCloseoutDisable" (click)="EngagementClosureValidation()">
                  <span class="closeoutEngagementIcon"></span>
                  <span class="inline-block ml-2" data-toggle="modal" style="vertical-align: super;">Close
                    engagement</span>
                </button>
              </div>
            </div>
          </div>
          <!-- Task required for closeout an enegagement end -->

          <!-- Required task start -->
          <div class="ml-0 mr-0">
            <hr style="border: 1px solid #c7c7c7;">
          </div>
          <div class="d-flex justify-content-between ml-0 mr-0"
            *ngIf="closeoutSectionStructureAndVisibility?.requiredTasks">
            <div class="float-left">
              <p class="mt-1 mb-0 edp-font-color font-weight-bold">Required tasks</p>
              <p class="mb-0">The following tasks are required for this engagement.</p>
            </div>



            <div class="text-right">
              <p class="mt-4 mb-0 link-blue-text">
                <a id="requiredTasksDiv" class="ml-4 mr-2 link-text-blue clickableLink" (click)="toggleRequiredTabs();">
                </a>
              </p>
            </div>
          </div>
          <div class="card mr-0 pl-2 ml-0 mt-2 rounded-0 border-0"
            *ngIf="closeoutSectionStructureAndVisibility?.requiredTasks.length==0">
            <div class="justify-content-center p-3 d-flex border-bottom-0">
              <div class="card-heading font-weight-bold">
                There are no required tasks.
              </div>
            </div>
          </div>
          <div id="accordion" class="mt-2 ml-0 mr-0">
            <div id="engagementCloseoutClientVoiceSurvey" class="row ml-0">
              <div class="col-md-12 px-0"
                *ngIf="checkIfSectionExists(closeoutSectionStructureAndVisibility?.requiredTasks, closeoutSections.CS)">
                <app-engagement-closeout-client-voice-survey id="engCheckList" name="engCheckList"
                  [engagementId]="engagementId" [engagementName]="engagementList.engagementName"
                  [collaborationSiteMembersList]="collaborationSiteMembersList" [clientCode]="engagementList.clientCode"
                  [clientName]="engagementList.clientName" [engagementPartner]="engagementList.engagementPartner"
                  [engagementPartnerName]="engagementList.engagementPartnerName"
                  [isEngagementClosed]="isEngagementClosed" [isEngagementCloseoutDisable]="isEngagementCloseoutDisable"
                  [engStatus]="engagementList.status" [isSuccessorFirmEnabled]="engagementList.isSuccessorFirmEnabled"
                  (engCloseOut)="EngagementClosureValidation()"
                  (collapseEvent)="collapseRequiredChecklistSection(closeoutSections.CS)"
                  [sectionConfiguration]="getSection(closeoutSections.CS)"
                  [isRequiredToSendClientVoiceSurvey]="isRequiredToSendClientVoiceSurvey"
                  [lastSurveySentDateForClient]="lastSurveySentDateForClient"
                  [isloggedInUserAdmin]="isloggedInUserAdmin">
                </app-engagement-closeout-client-voice-survey>
              </div>
            </div>

            <div id="engagementCloseoutAuditChecklistRequired" class="row ml-0">
              <div class="col-md-12 px-0"
                *ngIf="checkIfSectionExists(closeoutSectionStructureAndVisibility?.requiredTasks, closeoutSections.ALC)">
                <app-engagement-closeout-audit-checklist id="engCheckListRequired" name="engCheckListRequired"
                  [engagementId]="engagementId" [engagementName]="engagementList.engagementName"
                  [isEngagementClosed]="isEngagementClosed" [isEngagementCloseoutDisable]="isEngagementCloseoutDisable"
                  [engStatus]="engagementList.status" (engCloseOut)="EngagementClosureValidation()"
                  [sectionConfiguration]="getSection(closeoutSections.ALC)">
                </app-engagement-closeout-audit-checklist>
              </div>
            </div>

          </div>


          <!-- Required task end -->

          <!-- Optional task start -->
          <div>
            <div id="accordion" class="mt-2 ml-0 mr-0 mb-2"
              *ngIf="closeoutSectionStructureAndVisibility?.optionalTasks">
              <div class="float-left">
                <p class="mt-1 mb-0 edp-font-color font-weight-bold">Optional tasks</p>
                <p class="mb-0">The following tasks are optional.</p>
              </div>

              <div id="engagementCloseoutClientVoiceSurvey" class="row ml-0">
                <div class="col-md-12 px-0"
                  *ngIf="checkIfSectionExists(closeoutSectionStructureAndVisibility?.optionalTasks, closeoutSections.CS)">
                  <app-engagement-closeout-client-voice-survey id="engCheckList" name="engCheckList"
                    [engagementId]="engagementId" [engagementName]="engagementList.engagementName"
                    [collaborationSiteMembersList]="collaborationSiteMembersList"
                    [clientCode]="engagementList.clientCode" [clientName]="engagementList.clientName"
                    [engagementPartner]="engagementList.engagementPartner"
                    [engagementPartnerName]="engagementList.engagementPartnerName"
                    [isEngagementClosed]="isEngagementClosed"
                    [isEngagementCloseoutDisable]="isEngagementCloseoutDisable" [engStatus]="engagementList.status"
                    [isSuccessorFirmEnabled]="engagementList.isSuccessorFirmEnabled"
                    (engCloseOut)="EngagementClosureValidation()"
                    (expandEvent)="collapseRequiredChecklistSection(closeoutSections.CS)"
                    [sectionConfiguration]="getSection(closeoutSections.CS)" [isloggedInUserAdmin]="isloggedInUserAdmin"
                    [lastSurveySentDateForClient]="lastSurveySentDateForClient">
                  </app-engagement-closeout-client-voice-survey>
                </div>
                <div class="col-md-12 px-0"
                  *ngIf="checkIfSectionExists(closeoutSectionStructureAndVisibility?.optionalTasks, closeoutSections.IM)">
                  <app-engagement-closeout-insight-metrix [sectionConfiguration]="getSection(closeoutSections.IM)">
                  </app-engagement-closeout-insight-metrix>
                </div>
              </div>
            </div>
            <div class="card mr-0 pl-2 ml-0 mt-2 rounded-0 border-0 mb-5"
              *ngIf="closeoutSectionStructureAndVisibility?.optionalTasks.length==0">
              <div class="justify-content-center p-3 d-flex border-bottom-0">
                <div class="card-heading font-weight-bold">
                  There are no optional tasks.
                </div>
              </div>
            </div>
          </div>
          <!-- Optional task end -->
        </div>

        <!-- end engCloseoutCheckList panel -->
      </div>
      <!-- end collaboration panel -->

    </div>

    <app-contact-popup [contactEmailId]="selectContactEmailId"></app-contact-popup>
    <!-- start popup section -->
    <div id="modal-EP" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <span class="modal-title">Select CMS PPMD</span>
            <button type="button" class=" close" data-dismiss="modal" aria-hidden="true" (click)="deselectEP();">
              <span class="close-text mr-1">Close </span>
              <span class="modal-close-btn">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <span id="EPErrorMessage" class="error-color"></span>

            <div class="input-group nav-searchbox-length pb-2">
              <input type="text" id="searchEPName" (keydown.enter)="searchUsers('searchEPName','tblEPAD');"
                class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for CMS PPMD" autocomplete="off">
              <span class="input-group-append nav-seachBox">
                <div class="input-group-text" (click)="searchUsers('searchEPName','tblEPAD');">
                  <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
                </div>
              </span>
            </div>
            <span class="span-hint mt-1">Hint: Last name, first name, or email.</span>
            <div class="tableScroll mt-2">
              <table id="tblEPAD" class="table  table-hover" aria-describedby="EP">
                <thead>
                  <tr>
                    <th scope="col"> Name</th>
                    <th scope="col"> Email </th>
                    <th scope="col"> Job Title</th>
                    <th scope="col"> Phone</th>
                    <th scope="col"> Line of Business</th>
                    <th scope="col"> Service Line</th>
                    <th scope="col"> Location</th>
                  </tr>
                </thead>
                <tbody>
                  <tr (click)='selectEP(i,$event)' id="trEPs" name="trEPs"
                    *ngFor="let member of membersListArrayForEP let i=index">
                    <td>
                      <span id='spEPdisplayName{{i}}'>{{member.displayName}}</span>
                    </td>
                    <td> <span id='spEPmail{{i}}'>{{member.mail}}</span>
                    </td>
                    <td>
                      <span id='spjobtitle{{i}}'>{{member.jobTitle}}</span>
                    </td>
                    <td>
                      <span id='spEPbusinessPhones{{i}}'>{{member.businessPhones}}</span>
                    </td>
                    <td> <span id='splineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                    </td>
                    <td> <span id='spserviceLine{{i}}'>{{member.serviceLine}}</span>
                    </td>
                    <td> <span id='splocation{{i}}'>{{member.location}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="SecondaryButton mr-2" data-dismiss="modal"
              style="width: 150px; text-align: center;" (click)="deselectEP();">
              <span class="d-inline-block mr-1 align-top">
                <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
              </span>
              <span class="d-inline-block align-middle">Cancel</span>
            </button>
            <button type="button" class="PrimaryButton" data-dismiss="modal" style="width: 150px; text-align: center; "
              (click)="setEP();">
              <span class="d-inline-block mr-1 align-top">
                <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
              </span>
              <span class="d-inline-block align-middle">OK</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Start Manage Related Spaces -->
    <div id="modal-ManageRelatedSpaces" class="modal fade modal-window modal-window-mrs" tabindex="-1" role="dialog"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <span class="modal-title">Manage related spaces</span>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
              <span class="close-text mr-1">Close </span>
              <img src="../../../assets/images/EDP/Engagement/close-24px.svg" class="mt-1" alt="ManageRelatedSpaces"
                style="height: 20px; width: 20px;" />
            </button>
          </div>
          <div class="modal-body">
            <div class="tableScroll mt-0">
              <table id="tbltrcollabPYMappings" class="table  table-hover" aria-describedby="EP">
                <thead>
                  <tr>
                    <th scope="col1" class="t-sm-col"> Site</th>
                    <th scope="col2" class="t-lr-col"></th>
                    <th scope="col3" class="t-lr-col"></th>
                    <th scope="col4" class="t-md-col">Period end</th>

                  </tr>
                </thead>
                <tbody>
                  <tr id="trcollabPYMappings" name="trcollabPYMappings"
                    *ngFor="let mappingItem of collaborationSpacePriorYearMappingData let j=index">
                    <td class="t-sm-col">
                      <label class="custom-checkbox chkBox">
                        <input type="checkbox" class="" name="collaborationSiteId_{{mappingItem.collaborationSiteId}}"
                          id="collaborationSiteId_{{mappingItem.collaborationSiteId}}"
                          [(ngModel)]="mappingItem.mappingExists" value="{{mappingItem.mappingExists}}">
                        <span class="geekmark"></span>
                      </label>
                      <input type="hidden" name="PYengagementId" id="PYengagementId" class="form-control"
                        value="{{mappingItem.engagementId}}">
                      <input type="hidden" name="PYcollaborationSiteId" id="PYcollaborationSiteId" class="form-control"
                        value="{{mappingItem.collaborationSiteId}}">
                    </td>

                    <td class="t-lr-col text-left">
                      <span id='PYcollaborationSiteName{{j}}'>{{mappingItem.collaborationSiteName}}</span>
                    </td>

                    <td class="t-lr-col">
                      <span id='PYsiteURL{{j}}' class="mx-1" *ngIf="mappingItem.siteURL != null">
                        <a class="link-text-blue edp-font-14" href="{{mappingItem.siteURL}}" target="_blank">
                          <img src="../../../assets/images/EDP/Engagement/sharepoint-logo.svg" class="icon-logo"
                            alt="sharepoint" />
                        </a>
                      </span>
                    </td>

                    <td class="t-md-col">
                      <span id='PYtaxYear{{j}}'>{{mappingItem.taxYear | date: "MM/dd/yyyy"}}</span>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer mt-2">
            <button type="button" class="SecondaryButton mr-2" data-dismiss="modal"
              style="width: 150px; text-align: center;" (click)="cancelManageRelatedSpacesModel();">
              <span class="d-inline-block mr-1 align-top">
                <img alt="Cancel" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
              </span>
              <span class="d-inline-block align-middle">Cancel</span>
            </button>
            <button type="button" class="PrimaryButton"
              [disabled]="collaborationSpacePriorYearMappingData.length == 0 || isEngagementClosed"
              style="width: 150px; text-align: center; " (click)="SaveManageRelatedSpaces();">
              <span class="d-inline-block mr-1 align-top">
                <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
              </span>
              <span class="d-inline-block align-middle">OK</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- End Manage Related Spaces -->

    <div id="modal-EPPMD" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <span class="modal-title">Select engagement PPMD</span>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="deselectEPPMD();">
              <span class="close-text mr-1">Close </span>
              <span class="modal-close-btn">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <span id="EPErrorMessage" class="error-color"></span>

            <div class="input-group nav-searchbox-length pb-2">
              <input type="text" id="searchEPPMDName" (keydown.enter)="searchUsers('searchEPPMDName','tblEPAD');"
                class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for engagement PPMD"
                autocomplete="off">
              <span class="input-group-append nav-seachBox">
                <div class="input-group-text" (click)="searchUsers('searchEPPMDName','tblEPAD');">

                  <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
                </div>
              </span>
            </div>
            <span class="span-hint mt-1">Hint: Last name, first name, or email.</span>
            <div class="tableScroll mt-2">
              <table id="tblEPPMD" class="table  table-hover" aria-describedby="EP">
                <thead>
                  <tr>
                    <th scope="col1"> Name</th>
                    <th scope="col2"> Email </th>
                    <th scope="col3"> Job Title</th>
                    <th scope="col4"> Phone</th>
                    <th scope="col5"> Line of Business</th>
                    <th scope="col6"> Service Line</th>
                    <th scope="col7"> Location</th>
                  </tr>
                </thead>
                <tbody>
                  <tr (click)='selectEPPMD(i,$event)' id="trEPs" name="trEPs"
                    *ngFor="let member of membersListArrayForEP let i=index">
                    <td>
                      <span id='spEPdisplayName{{i}}'>{{member.displayName}}</span>
                    </td>
                    <td> <span id='spEPmail{{i}}'>{{member.mail}}</span>
                    </td>
                    <td>
                      <span id='spjobtitle{{i}}'>{{member.jobTitle}}</span>
                    </td>
                    <td>
                      <span id='spEPbusinessPhones{{i}}'>{{member.businessPhones}}</span>
                    </td>
                    <td> <span id='splineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                    </td>
                    <td> <span id='spserviceLine{{i}}'>{{member.serviceLine}}</span>
                    </td>
                    <td> <span id='splocation{{i}}'>{{member.location}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="SecondaryButton mr-2" data-dismiss="modal"
              style="width: 150px; text-align: center;" (click)="deselectEPPMD();">
              <span class="d-inline-block mr-1 align-top">
                <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
              </span>
              <span class="d-inline-block align-middle">Cancel</span>
            </button>
            <button type="button" class="PrimaryButton" data-dismiss="modal" style="width: 150px; text-align: center; "
              (click)="setEPPMD();">
              <span class="d-inline-block mr-1 align-top">
                <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
              </span>
              <span class="d-inline-block align-middle">OK</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="modal-AssignmentAdministrator" class="modal fade modal-window" tabindex="-1" role="dialog"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <span class="modal-title">Select assignment administrator </span>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="deselectAdmin();">
              <span class="close-text mr-1">Close </span>
              <span class="modal-close-btn">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <span id="AssignmentAdminErrorMessage" class="error-color"></span>

            <div class="input-group nav-searchbox-length pb-2">
              <input type="text" id="searchAssAdminName"
                (keydown.enter)="searchUsers('searchAssAdminName','tblAssAdminAD');"
                class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for assignment administrator"
                autocomplete="off">
              <span class="input-group-append nav-seachBox">
                <div class="input-group-text" (click)="searchUsers('searchAssAdminName','tblAssAdminAD');">

                  <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
                </div>
              </span>
            </div>
            <span class="span-hint mt-1">Hint: Last name, first name, or email.</span>

            <div class="tableScroll mt-2">
              <table id="tblAssAdminAD" aria-describedby="Assignment Admin" class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col1"> Name</th>
                    <th scope="col2"> Email </th>
                    <th scope="col3"> Job Title</th>
                    <th scope="col4"> Phone</th>
                    <th scope="col5"> Line of Business</th>
                    <th scope="col6"> Service Line</th>
                    <th scope="col7"> Location</th>
                  </tr>
                </thead>
                <tbody>
                  <tr (click)='selectAdmin(i,$event)' id="trAssAdmins" name="trAssAdmins" style="cursor: pointer;"
                    *ngFor="let member of membersListArray let i=index">
                    <td> <span id='spAdmindisplayName{{i}}'>{{member.displayName}}</span>
                    </td>
                    <td> <span id='spAdminmail{{i}}'>{{member.mail}}</span>
                    </td>
                    <td>
                      <span id='spjobtitle{{i}}'>{{member.jobTitle}}</span>
                    </td>
                    <td>
                      <span id='spAdminbusinessPhones{{i}}'>{{member.businessPhones}}</span>
                    </td>
                    <td> <span id='splineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                    </td>
                    <td> <span id='spserviceLine{{i}}'>{{member.serviceLine}}</span>
                    </td>
                    <td> <span id='splocation{{i}}'>{{member.location}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="SecondaryButton" style="width: 150px; text-align: center;" data-dismiss="modal"
              (click)="deselectAdmin();">
              <span class="d-inline-block mr-1 align-top">
                <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
              </span>
              <span class="d-inline-block align-middle">Cancel</span>
            </button>
            <button type="button" class="PrimaryButton" style="width: 150px; text-align: center;" data-dismiss="modal"
              (click)="setAdmin();">
              <span class="d-inline-block mr-1 align-top">
                <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
              </span>
              <span class="d-inline-block align-middle">OK</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="modal-TeamMember" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <span class="modal-title">Select team member</span>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="deselectTeamMember();">
              <span class="close-text mr-1">Close </span>
              <span class="modal-close-btn">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <span id="TeamMemberErrorMessage" class="error-color"></span>
            <div class="input-group nav-searchbox-length pb-2">
              <input type="text" id="searchTeamMemberName"
                (keydown.enter)="searchUsers('searchTeamMemberName','tblMemberAD');"
                class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for team member">
              <span class="input-group-append nav-seachBox">
                <div class="input-group-text" (click)="searchUsers('searchTeamMemberName','tblMemberAD');">

                  <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
                </div>
              </span>
            </div>
            <span class="span-hint mt-1">Hint: Last name, first name, or email.</span>
            <div class="tableScroll mt-2">
              <table id="tblMemberAD" class="table  table-hover" aria-describedby="Members">
                <thead>
                  <tr>
                    <th scope="col"> Name</th>
                    <th scope="col"> Email </th>
                    <th scope="col"> Job Title</th>
                    <th scope="col"> Phone</th>
                    <th scope="col"> Line of Business</th>
                    <th scope="col"> Service Line</th>
                    <th scope="col"> Location</th>
                  </tr>
                </thead>
                <tbody>
                  <tr id="trTeamMembers" name="trTeamMembers"
                    *ngFor="let member of membersListArrayForTeamMembers let i=index"
                    (click)='selectTeamMember(i,member)'>
                    <td id="TeamMemberList{{i}}">
                      <span id='spMemberdisplayName{{i}}'>{{member.displayName}}</span>
                    </td>
                    <td id="TeamMemberList{{i}}"> <span id='spMembermail{{i}}'>{{member.mail}}</span>
                    </td>
                    <td id="TeamMemberList{{i}}">
                      <span id='spjobtitle{{i}}'>{{member.jobTitle}}</span>
                    </td>
                    <td id="TeamMemberList{{i}}">
                      <span id='spMemberbusinessPhones{{i}}'>{{member.businessPhones}}</span>
                    </td>
                    <td id="TeamMemberList{{i}}"> <span id='splineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                    </td>
                    <td id="TeamMemberList{{i}}"> <span id='spserviceLine{{i}}'>{{member.serviceLine}}</span>
                    </td>
                    <td id="TeamMemberList{{i}}"> <span id='splocation{{i}}'>{{member.location}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="SecondaryButton" style="width: 150px; text-align: center;" data-dismiss="modal"
              (click)="deselectTeamMember();">
              Cancel
            </button>
            <button type="button" class="PrimaryButton" style="width: 150px; text-align: center;"
              (click)="setTeamMember();">OK</button>
          </div>
        </div>
      </div>
    </div>

    <div id="modal-ESignatureHandler" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <span class="modal-title">Add Signature Handler</span>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
              (click)="DeselectSignatureHandler();">
              <span class="close-text mr-1">Close </span>
              <span class="modal-close-btn">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <span id="SignatureHandlerErrorMessage" class="error-color"></span>
            <div class="input-group nav-searchbox-length pb-2">
              <input type="text" id="searchSignatureHandlerName"
                (keydown.enter)="searchUsers('searchSignatureHandlerName','tblMemberAD');"
                class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for signature handler"
                autocomplete="off">
              <span class="input-group-append nav-seachBox">
                <div class="input-group-text" (click)="searchUsers('searchSignatureHandlerName','tblMemberAD');">

                  <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
                </div>
              </span>
            </div>
            <span class="span-hint mt-1">Hint: Last name, first name, or email.</span>
            <div class="tableScroll mt-2">
              <table id="tblSignatureHanderMemberAD" class="table  table-hover" aria-describedby="Members">
                <thead>
                  <tr>
                    <th scope="col"> Name</th>
                    <th scope="col"> Email </th>
                    <th scope="col"> Job Title</th>
                    <th scope="col"> Phone</th>
                    <th scope="col"> Line of Business</th>
                    <th scope="col"> Service Line</th>
                    <th scope="col"> Location</th>
                  </tr>
                </thead>
                <tbody>
                  <tr (click)='SelectSignatureHandler(i,$event)' id="trSignatureHandlers" name="trSignatureHandlers"
                    *ngFor="let member of signatureHandlers let i=index">
                    <td>
                      <span id='spSignatureHandlerDisplayName{{i}}'>{{member.displayName}}</span>
                    </td>
                    <td> <span id='spSignatureHandlerMail{{i}}'>{{member.mail}}</span>
                    </td>
                    <td>
                      <span id='spSignatureHandlerJobtitle{{i}}'>{{member.jobTitle}}</span>
                    </td>
                    <td>
                      <span id='spSignatureHandlerBusinessPhones{{i}}'>{{member.businessPhones}}</span>
                    </td>
                    <td> <span id='spSignatureHandlerLineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                    </td>
                    <td> <span id='spSignatureHandlerServiceLine{{i}}'>{{member.serviceLine}}</span>
                    </td>
                    <td> <span id='spSignatureHandlerLocation{{i}}'>{{member.location}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="SecondaryButton mr-2" data-dismiss="modal"
              style="width: 150px; text-align: center;" (click)="DeselectSignatureHandler();">
              <span class="d-inline-block mr-1 align-top">
                <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
              </span>
              <span class="d-inline-block align-middle">Cancel</span>
            </button>
            <button type="button" class="PrimaryButton" data-dismiss="modal" style="width: 150px; text-align: center; "
              (click)="SetSignatureHandler();">
              <span class="d-inline-block mr-1 align-top">
                <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
              </span>
              <span class="d-inline-block align-middle">OK</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--Start EQCR POPUP-->
    <div id="modal-AssignmentEQCR" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <span class="modal-title">Select assignment EQCR </span>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="deselectEQCR();">
              <span class="close-text mr-1">Close </span>
              <span class="modal-close-btn">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <span id="EQCRErrorMessage" class="ErrorFont"></span>

            <div class="input-group nav-searchbox-length pb-2">
              <input type="text" id="searchEQCRName" (keydown.enter)="searchUsers('searchEQCRName','tblEQCR');"
                class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for Assignment EQCR"
                autocomplete="off">
              <span class="input-group-append nav-seachBox">
                <div class="input-group-text" (click)="searchUsers('searchEQCRName','tblEQCR');">

                  <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
                </div>
              </span>
            </div>
            <span class="span-hint mt-1">Hint: Last name, first name, or email.</span>
            <div class="tableScroll mt-2">
              <table id="tblEQCR" class="table  table-hover" aria-describedby="EQCR">
                <thead>
                  <tr>
                    <th scope="col1"> Name</th>
                    <th scope="col1"> Email </th>
                    <th scope="col1">Job Title</th>
                    <th scope="col1">Phone</th>
                    <th scope="col1">Line of Business</th>
                    <th scope="col1">Service Line</th>
                    <th scope="col1">Location</th>
                  </tr>
                </thead>
                <tbody>
                  <tr (click)='selectEQCR(i,$event)' id="trEQCR" name="trEQCR"
                    *ngFor="let member of membersListArrayForEP let i=index">
                    <td>
                      <span id='spEQCRdisplayName{{i}}'>{{member.displayName}}</span>
                    </td>
                    <td> <span id='spEQCRmail{{i}}'>{{member.mail}}</span>
                    </td>
                    <td>
                      <span id='spjobtitle{{i}}'>{{member.jobTitle}}</span>
                    </td>
                    <td> <span id='spEQCRbusinessPhones{{i}}'>{{member.businessPhones}}</span>
                    </td>
                    <td> <span id='splineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                    </td>
                    <td> <span id='spserviceLine{{i}}'>{{member.serviceLine}}</span>
                    </td>
                    <td> <span id='splocation{{i}}'>{{member.location}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="SecondaryButton mr-2" data-dismiss="modal"
              style="width: 150px; text-align: center;" (click)="deselectEQCR();">
              <span class="d-inline-block mr-1 align-top">
                <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
              </span>
              <span class="d-inline-block align-middle">Cancel</span>
            </button>
            <button type="button" class="PrimaryButton" data-dismiss="modal" style="width: 150px; text-align: center; "
              (click)="setEQCR();">
              <span class="d-inline-block mr-1 align-top">
                <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
              </span>
              <span class="d-inline-block align-middle">OK</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--End EQCR POPUP-->

    <!--Strat edit collaboration space POPUP-->
    <div id="modal-EditCollabSpace" class="modal fade modal-EditCollabSpace modal-window " data-backdrop="static"
      tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content model-content-editCollabSpace shadow bg-white rounded">
          <div class="modal-body mr-0">
            <div class="row header-first-row pl-1">
              <span class="form-sub-heading-text col-md-10">Edit collaboration space</span>
              <div class="editCollabSpace-close" data-dismiss="modal" aria-hidden="true" aria-label="Close"
                (click)="CancelEditCollabSpaceName()">
                <span style="cursor: pointer;">Close <img src="../../../../assets/images/EDP/Workspace/close-24px.svg"
                    alt="Close" />
                </span>
              </div>
            </div>
            <div class="row header-second-row mt-1 pl-1">
              <span class="col-md-10 edp-font-14">{{engagementList.engagementName}} |
                {{engagementList.clientName}}</span>
            </div>
            <label class="mt-3 pl-1">Name</label>
            <div class="row pl-3 pr-0">
              <input type="text" name="txtEditCollabSpaceName" id="txtEditCollabSpaceName" class="form-control mw-100 "
                required="required" [(ngModel)]="UpdatedCollabSpaceName" maxlength="25"
                (change)="invalidCharacterDetectionCollaborationSiteName('Edit')"
                (keyup)="invalidCharacterDetectionCollaborationSiteName('Edit')">
            </div>
            <div id="CollabSpaceNameHint" class="span-hint mt-1 mb-0 pl-1" *ngIf="!EditCollabSpaceNameRequired && !checkEditCollabSpaceNameExists && validEditColabSpaceName &&
                          !(invalidvaluesCN != '' && invalidvaluesCN != null)">
              <p>Allowable: . - _ & ' , ( )</p>
            </div>
            <div class="error-color mt-1 pl-1" *ngIf="invalidvaluesCN != '' && invalidvaluesCN != null ">
              <p>'{{invalidvaluesCN}}' <span *ngIf="invalidvaluesCN.length === 1">is not allowed.</span><span
                  *ngIf="invalidvaluesCN.length > 1">are not allowed.</span></p>
            </div>
            <div class="error-color mt-1 pl-1" *ngIf="EditCollabSpaceNameRequired">
              <p>Collaboration space is a required field </p>
            </div>
            <div class="error-color mt-1 pl-1" *ngIf="checkEditCollabSpaceNameExists">
              <p>Collaboration space already exists. Please enter unique name </p>
            </div>
            <div class="error-color mt-1 pl-1" *ngIf="!validEditColabSpaceName">
              <p>Invalid collaboration space name</p>
            </div>
            <div class=" row modal-footer-editCollabSpace">
              <button type="button" class="SecondaryButton mr-1 cancle-button" style="text-align: center;"
                data-dismiss="modal" (click)="CancelEditCollabSpaceName()">
                <span class="d-inline-block mr-1 align-top">
                  <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
                </span>
                <span class="d-inline-block align-middle">Cancel</span>
              </button>
              <button type="button" class="PrimaryButton ml-1 save-button" style="text-align: center;"
                (click)="UpdateCollabSiteName();">
                <span class="d-inline-block mr-1 align-top">
                  <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
                </span>
                <span class="d-inline-block align-middle">Save</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End edit collaboration space POPUP-->
    <app-loading-screen></app-loading-screen>

    <div class="manage-team-modal modal fade modal-window bd-example-modal-lg z-i-c" data-keyboard="false"
      data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">

      <div class="modal-dialog modal-lg">
        <div class="modal-content">

          <div class="modal-body py-0 scroll-modal" style="overflow-x: hidden;">
            <div class="row my-1 mx-0">
              <div class="col-xl-8 px-0">
                <span class=" font-weight-bold ">Manage team members</span>
                <div class="Cilent-details--collb-space">
                  <span>{{currentCollabModel.currentCollaborationSiteViewModel.engagement.clientName}}</span>
                  <span class="eng-name mx-3">
                    {{currentCollabModel.currentCollaborationSiteViewModel.engagement.engagementName}} </span>
                  <span class="coll-name" *ngIf="currentCollabModel.currentCollaborationSiteViewModel.engagement.engagementName!=
                    currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.collaborationSiteName">
                    {{currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.collaborationSiteName}}</span>
                </div>
              </div>

              <div class="col-xl-4 text-right px-0">
                <div class="col-md-12 px-0 text-right"
                  [ngClass]="{'nonClickableLink': isEngagementClosed && isEngDetailINDUSStaffingManagersHaveAccess }">

                  <span class="font-weight-bold d-inline-block mr-2">INDUS approval:</span>

                  <span class="px-0 edp-font-12 my-1 d-inline-block">
                    <label class="custom-radio mr-2">
                      <span class="mb-0 pos-top ">Yes</span>
                      <input type="radio" name="indusApprovalOptions" [value]="true"
                        [(ngModel)]="currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus"
                        (change)="setTeamMemberValue()">
                      <span class="checkmark"></span>
                    </label>

                    <label class="custom-radio mr-2">
                      <span class="mb-0 pos-top ">No</span>
                      <input type="radio" name="indusApprovalOptions" [value]="false"
                        [(ngModel)]="currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus"
                        (change)="setTeamMemberValue()">
                      <span class="checkmark"></span>
                    </label>

                  </span>
                </div>
              </div>
            </div>

            <!-- Sub section start -->
            <div *ngIf="isAdMemberchecked ; else InProgressMemberInfo">
              <!-- start GT each team details -->
              <div class="w-100 teamdetailsAll " id="teamdetails_{{i}}">
                <div class=" font-weight-bold  edp-font-14">Grant Thornton team management</div>
                <table class="table table-borderless assignmentTeamDetails engSummaryTable mb-0 table-popup">
                  <thead class="border-bottom-0 tb-col-sp">
                    <th scope="col1" class="border-bottom-0 w-td-1"><label for="Role">Name</label></th>
                    <th scope="col2" class="border-bottom-0  w-td-2" *ngIf="!isAudit"><label for="Role">Title</label>
                    </th>
                    <th scope="col3" class="border-bottom-0  w-td-3" *ngIf="!isAudit">
                      <label for="Role">Service line</label>
                    </th>
                    <th scope="col3" class="border-bottom-0  w-td-2 pr-3" *ngIf="isAudit" colspan="2">
                      <label for="Role" style="float: right;">Key</label>
                    </th>
                    <th scope="col4" class="border-bottom-0  w-td-4 ">
                      <label for="Role">Role<span class="mandatory">*</span></label>
                    </th>
                    <th scope="col5" class="border-bottom-0  w-td-5">
                      <label for="Permissions">Permissions<span class="mandatory">*</span></label>
                    </th>
                    <th scope="col6" class="txt-last border-bottom-0  w-td-6">
                    </th>
                  </thead>
                  <tbody class="bg-edp-body">
                    <div style="display: contents;"
                      *ngFor="let member of currentCollabModel.currentCollaborationSiteViewModel.adGroupUsersSyncCollabMembers; let j =index">


                      <tr>
                        <td class="edp-font-13 pl-2">
                          <div class="hdnteams" style="display: none;">
                            <input type="hidden" name="hdnTeamCollaborationId" id="hdnTeamCollaborationId"
                              class="form-control" value="{{member.userName}}">
                            <input type="hidden" name="userName" id="userName" class="form-control"
                              value="{{member.userName}}">
                            <input type="hidden" name="memberId" id="memberId" class="form-control"
                              value="{{member.collaborationSiteMembersId}}">
                          </div>
                          <span class="link-text-blue edp-font-13 ">{{member.userName}}</span>
                        </td>

                        <td class="edp-font-13 td-sep" *ngIf="!isAudit">
                          <span class="label">{{member.jobtitle}}</span>
                        </td>
                        <td class="edp-font-13 td-sep" *ngIf="!isAudit">
                          <span class="label ">{{member.serviceLine}}</span>
                        </td>
                        <td class="edp-font-13 pr-3" [ngClass]="{false: 'td-sep'}[isAudit]" *ngIf="isAudit" colspan="2">
                          <a *ngIf="member.isKeyTeamMember" style="float: right;">
                            <img class="" src="assets/images/EDP/Engagement/key-button.svg" alt="Key team member"
                              class="middle-align" style="cursor: auto !important; height: 30px;">
                          </a>

                          <a *ngIf="!member.isKeyTeamMember" style="float: right;">
                            <img class="" src="assets/images/EDP/Engagement/key-locked.svg" alt="Non key team member"
                              class="middle-align" style="cursor: auto !important; height: 30px;">
                          </a>
                        </td>
                        <td [ngClass]="{false: 'td-sep2'}[isAudit]">
                          <div class="form-group mb-0 h-28">
                            <div class="selectdiv edp-font-12 w-97 ">
                              <!-- Below drop down is only for Audit service line -->
                              <select *ngIf="isAudit" class="dropdown rounded-0 form-control h-30" name="KeyMemberRole"
                                [disabled]="((isEngagementClosed && !isEngDetailINDUSStaffingManagersHaveAccess )||
                            (isEngagementClosed && isEngDetailINDUSStaffingManagersHaveAccess && !currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus) ) || 
                            member.keyMemberRoleId == getTeamMemberRoleIdByCode('EP') || member.keyMemberRoleId == getTeamMemberRoleIdByCode('PR')"
                                id="KeyMemberRole_Row_{{j}}" [(ngModel)]="member.keyMemberRoleId">
                                <option value="null" disabled hidden>Select team member role</option>
                                <option value="{{member.keyMemberRoleId}}"
                                  *ngIf="member.keyMemberRoleId == getTeamMemberRoleIdByCode('EP')" selected>Engagement
                                  PPMD</option>
                                <option value="{{member.keyMemberRoleId}}"
                                  *ngIf="member.keyMemberRoleId == getTeamMemberRoleIdByCode('PR')" selected>Eng qual
                                  control reviewer (EQCR)</option>
                                <option *ngFor="let role of keyApplicationmasterList"
                                  [value]="role.applicationMasterId">
                                  <span *ngIf="role.code != 'EP'"> {{role.value}}</span>
                                </option>
                              </select>

                              <select *ngIf="!isAudit" class="dropdown rounded-0 form-control h-30" name="MemberRole"
                                [disabled]="((isEngagementClosed && !isEngDetailINDUSStaffingManagersHaveAccess )||
                            (isEngagementClosed && isEngDetailINDUSStaffingManagersHaveAccess && !currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus)) || 
                            member.memberRoleId == getTeamMemberRoleIdByCode('EP') || member.memberRoleId == getTeamMemberRoleIdByCode('PR')"
                                id="MemberRole_Row_{{j}}" [(ngModel)]="member.memberRoleId"
                                (change)="OnRoleChangeForExistingMember(member.memberRoleId,j)">
                                <option value="null" disabled hidden>Select team member role</option>
                                <option *ngFor="let role of applicationmasterList" [value]="role.applicationMasterId"
                                  [disabled]="role.code=='EP' || role.code=='PR' || role.code=='NOP'"
                                  [hidden]=" role.code=='EP' || role.code=='C' || role.code=='A' || role.code=='CMSEP' || role.code=='PR' ">
                                  <span *ngIf="role.code == 'EP'">Engagement PPMD</span>
                                  <span *ngIf="role.code != 'EP'"> {{role.value}}</span>
                                </option>
                              </select>
                            </div>
                          </div>
                        </td>

                        <td [ngClass]="{false: 'td-sep2'}[isAudit]">
                          <div class="form-group mb-0 h-28">
                            <div class="selectdiv edp-font-12 w-99 ">
                              <select class="dropdown rounded-0 form-control h-30" name="teamMemberPermissions"
                                id="teamMemberPermissions_Row_{{j}}" [(ngModel)]="member.memberPermissionId"
                                [disabled]="((isEngagementClosed && !isEngDetailINDUSStaffingManagersHaveAccess )||(isEngagementClosed && isEngDetailINDUSStaffingManagersHaveAccess && 
                              !currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus) )|| currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.msTeamUrl != null">
                                <option value="null" selected disabled hidden>Select permission</option>
                                <option *ngFor="let permission of teamMemberPermissions" [value]="permission.id">
                                  {{permission.typeValue}}
                                </option>
                              </select>
                            </div>
                          </div>
                        </td>

                        <td class="txt-last">
                          <a *ngIf="isEngagementClosed && !isEngDetailINDUSStaffingManagersHaveAccess"
                            id="removeTeamMemberLink_{{j}}" class="nonClickableLink">
                            <img class="" src="assets/images/EDP/Engagement/remove_circle_outline.svg" alt="remove"
                              class="middle-align">
                          </a>

                          <a *ngIf="(!isEngagementClosed && !(member.memberRoleId == getTeamMemberRoleIdByCode('EP') || member.memberRoleId == getTeamMemberRoleIdByCode('PR'))) ||
                              (isEngagementClosed && isEngDetailINDUSStaffingManagersHaveAccess && 
                              currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus &&
                              !(member.memberRoleId == getTeamMemberRoleIdByCode('EP') || member.memberRoleId == getTeamMemberRoleIdByCode('PR')))"
                            id="removeTeamMemberLink_{{j}}"
                            (click)="removeCollabTeamMember(currentCollabModel.index, j)">
                            <img class="" src="assets/images/EDP/Engagement/remove_circle_outline.svg" alt="remove"
                              class="middle-align">
                          </a>

                        </td>

                      </tr>
                      <tr
                        *ngIf="isAudit && (member.description !='' || (member.keyMemberRoleId == getKeyTeamMemberRoleIdByCode('OTH') && member.isKeyTeamMember == true))">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colspan="2">
                          <div
                            *ngIf="member.keyMemberRoleId != getKeyTeamMemberRoleIdByCode('OTH') && member.description !=''">
                            <span class="label ">{{member.description}}</span>
                          </div>
                          <div
                            *ngIf="member.keyMemberRoleId == getKeyTeamMemberRoleIdByCode('OTH') && member.isKeyTeamMember == true">
                            <textarea name="txtKeyTeamMemberDescription_{{j}}" id="txtKeyTeamMemberDescription_{{j}}"
                              [(ngModel)]="member.description"
                              [disabled]="((isEngagementClosed && !isEngDetailINDUSStaffingManagersHaveAccess )||
                          (isEngagementClosed && isEngDetailINDUSStaffingManagersHaveAccess && !currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus)) || 
                          member.memberRoleId == getTeamMemberRoleIdByCode('EP') || member.memberRoleId == getTeamMemberRoleIdByCode('PR')"
                              class="form-control rounded-0 edp-font-14" maxlength="100"
                              style="resize: none;"></textarea>

                          </div>
                        </td>
                        <td></td>
                      </tr>
                    </div>
                    <!--end each team teamdetails_-->
                    <!-- end each assignment team details -->

                    <!-- start divAddNewTeamMember -->

                    <hr id="divAddNewTeamMember_{{currentCollabModel.index}}" *ngIf="currentCollabModel.currentCollaborationSiteViewModel.newTeamMemberList!=null
                    && currentCollabModel.currentCollaborationSiteViewModel.newTeamMemberList.length > 0"
                      class=" ml-0 AddNewTeamMemberSection d-none">
                    <tr *ngFor="let teamMember of newTeamMemberList;let t = index" class="teammemberDataRow">
                      <td colspan="3">
                        <div class="w-custom pr-0 pl-2">
                          <div class="input-group w-99"
                            *ngIf="!currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus">
                            <app-people-picker class="eng-people-picker" [roleShortName]="teamMemberRoleName"
                              id="collab-manageteam" [labelCtrlName]="teamMemberlblCtrlName" [isLabelVisible]="false"
                              [InputCtrlIdIndex]="t" [PeoplePickerModel]="newTeamMemberList[t].userName"
                              [userEmail]="newTeamMemberList[t].memberEmailId"
                              [IsValidateUserWithADGroup]="newTeamMemberList[t].validateUser"
                              [memberFirmDetails]="memberFirmData">
                            </app-people-picker>

                            <div class="input-group-append input-height h-30 ">
                              <button type="button" id="PPIcon_{{peoplepickerIndex}}" data-toggle="modal"
                                data-target="#modal-TeamMember" data-keyboard="false" data-backdrop="static"
                                class="mw-100 people-picker-button text-center"
                                (click)="refreshTeamMemberPopup(t,'txtPeoplePicker_')">
                                <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="People Picker" />
                              </button>
                            </div>
                          </div>

                          <div class="input-group w-99"
                            *ngIf="currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus">
                            <app-people-picker class="eng-people-picker" [roleShortName]="INDUSRoleName"
                              [labelCtrlName]="teamMemberlblCtrlName" [isLabelVisible]="false" [InputCtrlIdIndex]="t"
                              [PeoplePickerModel]="newTeamMemberList[t].userName"
                              [userEmail]="newTeamMemberList[t].memberEmailId"
                              [IsValidateUserWithADGroup]="newTeamMemberList[t].validateUser"
                              [memberFirmDetails]="memberFirmData">
                            </app-people-picker>

                            <div class="input-group-append input-height h-30 ">
                              <button type="button" id="PPIcon_{{peoplepickerIndex}}" data-toggle="modal"
                                data-target="#modal-TeamMember" data-keyboard="false" data-backdrop="static"
                                class="mw-100 people-picker-button text-center"
                                (click)="refreshTeamMemberPopup(t,'txtPeoplePicker_')">
                                <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="People Picker" />
                              </button>
                            </div>
                          </div>

                          <div>
                            <span id="errorLabelMemberName{{t}}" class="label error-color "
                              *ngIf="arrValidationForNewTeamMember[t].userName==null || arrValidationForNewTeamMember[t].userName==''"
                              style="display:none;">Select Member</span>
                            <span id="userExist{{t}}" class="label error-color"
                              *ngIf="arrValidationForNewTeamMember[t].isValidInput && arrValidationForNewTeamMember[t].cmsDataMatch"
                              [style.display]="arrValidationForNewTeamMember[t].isValidInput && arrValidationForNewTeamMember[t].cmsDataMatch ? 'block' : 'none'">Member
                              already exists!</span>
                            <span class="label error-color"
                              *ngIf="!arrValidationForNewTeamMember[t].ishdnValidInputName" style="display:none;">Team
                              member
                              should
                              be
                              valid</span>
                          </div>
                        </div>

                        <span class="devider px-0 text-center hide-me"></span>
                      </td>
                      <td [ngClass]="{false: 'td-sep2'}[isAudit]">
                        <div class=" h-28">
                          <div class="selectdiv edp-font-12 w-97">
                            <!-- Below drop down is only for Audit service line -->
                            <select *ngIf="isAudit" id="drpKeyTeamMemberRole_{{t}}" required
                              name="KeyTeamMemberRole_{{t}}" class="form-control dropdown rounded-0 h-30" [disabled]="(isEngagementClosed && !isEngDetailINDUSStaffingManagersHaveAccess) ||
                              (isEngagementClosed && isEngDetailINDUSStaffingManagersHaveAccess && 
                              !currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus)"
                              [(ngModel)]="teamMember.keyMemberRoleId">
                              <option value="null" disabled hidden>Select role</option>
                              <option id="drpKeyTeamMembersOptions_{{t}}" *ngFor="let role of keyApplicationmasterList"
                                [value]=role.applicationMasterId>
                                {{role.value}}
                              </option>
                            </select>

                            <select *ngIf="!isAudit" id="drpTeamMemberRole_{{t}}" required name="TeamMemberRole_{{t}}"
                              class="form-control dropdown rounded-0 h-30" [disabled]="(isEngagementClosed && !isEngDetailINDUSStaffingManagersHaveAccess) ||
                              (isEngagementClosed && isEngDetailINDUSStaffingManagersHaveAccess && 
                              !currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus)"
                              [(ngModel)]="teamMember.memberRoleId" (change)="OnRoleChange(teamMember.memberRoleId,t)">
                              <option value="null" disabled hidden>Select role</option>
                              <option id="drpTeamMembersOptions_{{t}}" *ngFor="let role of applicationmasterList"
                                [value]=role.applicationMasterId
                                [hidden]=" role.code=='EP' || role.code=='C' || role.code=='A' || role.code=='CMSEP' || role.code=='PR' || role.code=='PR'"
                                selected>
                                {{role.value}}
                              </option>
                            </select>
                          </div>
                          <div>
                            <span id="errorLabelMemberRole{{t}}" style="display:none;" class="label error-color"
                              *ngIf="arrValidationForNewTeamMember[t].memberRoleId==null">Select team
                              member role</span>
                          </div>
                        </div>

                        <span class="devider px-0 text-center hide-me"></span>
                      </td>
                      <td [ngClass]="{false: 'td-sep2'}[isAudit]">
                        <div class=" h-28">
                          <div class="selectdiv edp-font-12  w-99 ">
                            <select id="drpTeamMemberPermissions_{{t}}" name="TeamMemberPermission_{{t}}"
                              class="form-control dropdown rounded-0 h-30" [disabled]="(isEngagementClosed && !isEngDetailINDUSStaffingManagersHaveAccess) || 
                              currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.msTeamUrl != null ||
                              (isEngagementClosed && isEngDetailINDUSStaffingManagersHaveAccess && 
                              !currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus)"
                              [(ngModel)]="teamMember.memberPermissionId">
                              <option value="null" disabled hidden>Select permissions</option>
                              <option id="drpTeamMemberPermissionsOptions_{{t}}"
                                *ngFor="let permission of teamMemberPermissions" [value]=permission.id>
                                {{permission.typeValue}}
                              </option>
                            </select>
                          </div>
                          <div>
                            <span id="errorLabelMemberPermission{{t}}" style="display:none;" class="label error-color"
                              *ngIf="arrValidationForNewTeamMember[t].memberPermissionId==null">Select
                              team
                              member
                              permission</span>
                          </div>
                        </div>
                      </td>
                      <td class="txt-last">
                        <div *ngIf="currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus">

                          <a id="removeLink_{{t}}" (click)="removeNewTeamMember(t,INDUSRoleName)">
                            <img src="assets/images/EDP/Engagement/remove_circle_outline.svg" alt="remove"
                              class="middle-align">
                          </a>
                        </div>
                        <div *ngIf="!currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus">
                          <a id="removeLink_{{t}}" (click)="removeNewTeamMember(t,teamMemberRoleName)">
                            <img src="assets/images/EDP/Engagement/remove_circle_outline.svg" alt="remove"
                              class="middle-align">
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- end div GT TeamMember -->

              <!--start addTeamMember link-->
              <div id="divAddTeamMember_{{i}}"
                class="row mx-0 py-2 my-3 bg-edp-body align-items-center addTeamMemberbuttons">
                <div class="col-xl-8 col-md-6"
                  *ngIf="isEngagementClosed && !isEngDetailINDUSStaffingManagersHaveAccess">
                  <a class="link-text-blue nonClickableLink d-flex align-items-center" id="btnAddTeamMember_{{i}}">
                    <img src="assets/images/EDP/Engagement/add_box-blue.svg" alt="add">
                    <span class="d-inline-block pl-2"> Add a new team member </span>
                  </a>
                </div>
                <div class="col-xl-8 col-md-6" *ngIf="isEngagementClosed && isEngDetailINDUSStaffingManagersHaveAccess &&
                !currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus">
                  <a class="link-text-blue nonClickableLink d-flex align-items-center" id="btnAddTeamMember_{{i}}">
                    <img src="assets/images/EDP/Engagement/add_box-blue.svg" alt="add">
                    <span class="d-inline-block pl-2"> Add a new team member </span>
                  </a>
                </div>
                <div class="col-xl-8 col-md-6" *ngIf="!isEngagementClosed || (isEngagementClosed && isEngDetailINDUSStaffingManagersHaveAccess && 
                currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus)">
                  <a class="link-text-blue d-flex align-items-center" id="btnAddTeamMember_{{currentCollabModel.index}}"
                    (click)="addTeamMember(currentCollabModel.index,currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.collaborationSiteId)">
                    <img src="assets/images/EDP/Engagement/add_box-blue.svg" alt="add">
                    <span class="d-inline-block pl-2"> Add a new team member </span>
                  </a>
                </div>
              </div>
              <!--end addTeamMember link-->

              <!-- start each client team details -->
              <div class="w-100 teamdetailsAll my-3" id="clientTeamdetails_{{currentCollabModel.index}}">
                <div class="mt-3 font-weight-bold">Client users</div>
                <table class="table table-borderless clientTeamDetails engSummaryTable table-popup">
                  <thead class="border-bottom-0">
                    <th scope="col1" class="border-bottom-0 w-td-1">First name <span class="mandatory">*</span></th>
                    <th scope="col2" class="border-bottom-0 w-td-2">Last name <span class="mandatory">*</span></th>
                    <th scope="col3" class="border-bottom-0 w-td-3">Email <span class="mandatory">*</span></th>
                    <th scope="col4" class="border-bottom-0 w-td-4">Title</th>
                    <th scope="col5" class="border-bottom-0 w-td-5">Site permissions<span class="mandatory">*</span>
                    </th>
                    <th scope="col6" class="txt-last border-bottom-0 w-td-6">
                    </th>
                  </thead>
                  <tbody class="bg-edp-body edp-font-13">

                    <tr
                      *ngFor="let member of currentCollabModel.currentCollaborationSiteViewModel.adClientSiteUsersSyncEngageClientMmembers; let j=index">
                      <td class="pl-2">
                        <div class="hdnteams" style="display: none;">
                          <input type="hidden" name="hdnTeamCollaborationId" id="hdnTeamCollaborationId"
                            class="form-control" value="{{member.collaborationSiteId}}">
                          <input type="hidden" name="memberName" id="memberName" class="form-control"
                            value="{{member.firstName}}">
                          <input type="hidden" name="clientMemberId" id="clientMemberId" class="form-control"
                            value="{{member.EmailId}}">
                        </div>
                        <span class="label alert-pre">{{member.firstName}}</span>
                      </td>
                      <td class="td-sep"> <span class="label alert-pre">{{member.lastName}}</span></td>

                      <td class="td-sep"> <span class="label alert-pre link-text-blue">{{member.emailId}}</span>
                      </td>

                      <td class="td-sep2"
                        *ngIf="member.status !='Added' && member.status !='Error' && member.status !='Removed' && member.status !='PermissionUpdated'">
                        <input type="text" name="txtUpdateClientTitle" id="txtUpdateClientTitle{{j}}"
                          [attr.disabled]="isEngagementClosed ? '' : null"
                          class="form-control rounded-0 edp-font-12 h-30 w-97" value="{{member.title}}" maxlength="50"
                          [(ngModel)]="member.title" (blur)="checkSpaceChartBegin($event)">
                        <div>
                          <span id="errorUpdateTitle{{j}}" style="display:none;" class="label error-color">
                            Client title is required </span>
                        </div>
                      </td>
                      <td class="td-sep2"
                        *ngIf="member.status !='Added' && member.status !='Error' && member.status !='Removed' && member.status !='PermissionUpdated'">
                        <div class="form-group mb-0 h-28">
                          <div class="selectdiv  edp-font-12">
                            <select class="dropdown rounded-0 form-control edp-font-12 h-30"
                              name="teamMemberPermissions" [disabled]="isEngagementClosed"
                              id="clientTeamMemberPermissions_Row_{{j}}" [(ngModel)]="member.permissionId">
                              <option value="null" selected disabled hidden>Select permission</option>
                              <option *ngFor="let permission of clientMemberPermissionModelList"
                                [value]="permission.id">
                                {{permission.typeValue}}
                              </option>
                            </select>
                          </div>
                        </div>
                      </td>
                      <td colspan="2" class="td-sep2"
                        *ngIf="member.status =='Added' || member.status =='Error' || member.status =='Removed' || member.status =='PermissionUpdated'">
                        <span class="error-color" *ngIf="member.status =='Error'">&nbsp;&nbsp;(Error)</span>
                        <span class="success-color"
                          *ngIf="member.status =='Added' || member.status =='Removed' || member.status =='PermissionUpdated'">&nbsp;&nbsp;(In
                          progress)</span>
                      </td>
                      <td class="txt-last">
                        <a id="removeClientTeamMemberLink_{{j}}" *ngIf="isEngagementClosed" class="nonClickableLink">
                          <img class="" src="assets/images/EDP/Engagement/remove_circle_outline.svg" alt="remove"
                            class="middle-align">
                        </a>
                        <a id="removeClientTeamMemberLink_{{j}}"
                          *ngIf="!isEngagementClosed && member.status !='Added' && member.status !='Removed' && member.status != 'PermissionUpdated'"
                          (click)="removeCollabClientTeamMember(currentCollabModel.index, j)">
                          <img class="" src="assets/images/EDP/Engagement/remove_circle_outline.svg" alt="remove"
                            class="middle-align">
                        </a>
                      </td>

                    </tr>
                    <hr class="d-none" id="divNewClientTeamMember_{{currentCollabModel.index}}" *ngIf="currentCollabModel.currentCollaborationSiteViewModel.newClientTeamMemberList!=null
                    && currentCollabModel.currentCollaborationSiteViewModel.newClientTeamMemberList.length > 0">
                    <tr *ngFor="let clientTeamMember of newClientTeamMemberList;let t = index">
                      <td>
                        <div class=" pl-2">
                          <input type="text" name="txtFirstName" id="txtFirstName_{{t}}" placeholder="First name"
                            class="form-control rounded-0 h-30 edp-font-12 w-97"
                            [(ngModel)]="clientTeamMember.firstName" autofocus>
                          <div>
                            <span id="errorLabelFirstName{{t}}" class="label error-color " style="display:none;">First
                              name is required</span>
                          </div>
                        </div>
                      </td>
                      <td class="td-sep2">
                        <div class="">
                          <input type="text" name="txtLastName" id="txtLastName_{{t}}" placeholder="Last name"
                            class="form-control rounded-0 h-30 edp-font-12  w-97"
                            [(ngModel)]="clientTeamMember.lastName">
                          <div>
                            <span id="errorLabelLastName{{t}}" class="label error-color " style="display:none;">Last
                              name is required</span>
                          </div>

                        </div>
                      </td>
                      <td class="td-sep2">
                        <div class="">
                          <input type="text" autocomplete="no-fill" formControlName="confirmpass"
                            (cut)="$event.preventDefault()" (copy)="$event.preventDefault()"
                            (paste)="$event.preventDefault()" name="txtClientEmail" id="txtClientEmail_{{t}}"
                            placeholder="Email" class="form-control rounded-0 h-30 edp-font-12  w-97"
                            [(ngModel)]="clientTeamMember.emailId" maxlength="50"
                            (blur)="checkSpaceChartBeginInTableRow($event,t,'txtClientEmail_');ValidateClientEmailField(t, currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.collaborationSiteId);">
                          <div>
                            <span id="errorLabelEmail{{t}}" style="display:none;" class="label error-color">
                              Client email is required </span>
                          </div>
                          <div class="label error-color"
                            *ngIf="!(arrValidationForNewClientTeamMember[t].emailId == null || arrValidationForNewClientTeamMember[t].emailId=='') && arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberEmail">
                            Client email should be in valid format.
                          </div>
                          <div class="label error-color"
                            *ngIf="!(arrValidationForNewClientTeamMember[t].emailId == null || arrValidationForNewClientTeamMember[t].emailId=='') && arrValidationForNewClientTeamMember[t].isNewUserDuplicate">
                            <p>Email already exists! Please enter unique email </p>
                          </div>
                          <span id="userExist{{t}}" class="label error-color"
                            *ngIf="arrValidationForNewClientTeamMember[t].cmsDataMatchClient ">User
                            already exists!</span>

                          <br>
                          <input type="email" autocomplete="no-fill" formControlName="confirmpass"
                            (cut)="$event.preventDefault()" (copy)="$event.preventDefault()"
                            (paste)="$event.preventDefault()" name="txtconfirmClientEmail_{{t}}"
                            id="txtconfirmClientEmail_{{t}}" placeholder="Confirm Email"
                            class="form-control rounded-0 h-30 edp-font-12  w-97"
                            [(ngModel)]="clientTeamMember.clientEmailId" maxlength="50"
                            (blur)="ValidateConfirmClientEmailField(t, currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.collaborationSiteId)">

                          <div>
                            <span id="errorLabelConfirmEmail{{t}}" style="display:none;"
                              class="label error-color edp-font-12">
                              <p>Confirm client email is required</p>
                            </span>
                          </div>
                          <div class="label error-color edp-font-12"
                            *ngIf="!(arrValidationForNewClientTeamMember[t].clientEmailId == null || arrValidationForNewClientTeamMember[t].clientEmailId=='') && arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberConfirmEmail">
                            Confirm client email should be in valid format.
                          </div>
                          <div>
                            <span id="errorLabelConfirmEmailMatch{{t}}" style="display:none;"
                              class="label error-color edp-font-12">
                              <p>Emails do not match.</p>
                            </span>
                          </div>
                        </div>
                      </td>
                      <td class="td-sep2">
                        <div class="">
                          <input type="text" name="txtClientTitle" id="txtClientTitle_{{t}}" placeholder="Title"
                            class="form-control rounded-0 h-30 edp-font-12 w-97" [(ngModel)]="clientTeamMember.title"
                            maxlength="50" (blur)="checkSpaceChartBegin($event)">
                        </div>
                      </td>
                      <td class="td-sep2">
                        <div class="h-28">
                          <div class="selectdiv  ">
                            <select id="drpClientTeamMemberPermissions_{{t}}" name="ClientTeamMemberPermission_{{t}}"
                              class="form-control dropdown rounded-0 edp-font-12 h-30"
                              [(ngModel)]="clientTeamMember.permissionId">
                              <option value="null" disabled hidden>Select permissions</option>
                              <option id="drpTeamMemberPermissionsOptions_{{t}}"
                                *ngFor="let permission of clientMemberPermissionModelList" [value]=permission.id>
                                {{permission.typeValue}}
                              </option>
                            </select>
                          </div>
                          <div>
                            <span id="errorLabelClientTeamMemberPermission{{t}}" style="display:none;"
                              class="label error-color edp-font-12">
                              Select permission</span>
                          </div>
                        </div>
                      </td>
                      <td class="txt-last">
                        <div class="">
                          <a id="removeLink_{{t}}" (click)="removeClientNewTeamMember(t)">
                            <img src="assets/images/EDP/Engagement/remove_circle_outline.svg" alt="remove"
                              class="middle-align">
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--end each client team teamdetails_-->

              <!-- start divNewClientTeamMember -->

              <div id="divAddClientTeamMember_{{currentCollabModel.index}}"
                class="row mx-0 mb-3 py-2 bg-edp-body align-items-center addTeamMemberbuttons">
                <div class="col-xl-9 col-md-8" *ngIf="isEngagementClosed" [class.nonClickableLink]="isMemberFirm">
                  <a class="link-text-blue nonClickableLink d-flex align-items-center"
                    id="btnAddClientTeamMember_{{currentCollabModel.index}}">
                    <img src="assets/images/EDP/Engagement/add_box-blue.svg" alt="add">
                    <span class="d-inline-block pl-2"> Add a new client user </span>
                  </a>
                </div>

                <div class="col-xl-9 col-md-8" *ngIf="!isEngagementClosed &&
                      (currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isLoggedInUserPartOfCollaboration 
                      || isloggedInUserAdmin=='yes')">
                  <span
                    *ngIf="(currentCollabModel.currentCollaborationSiteViewModel.collaborationClientSite.siteRequestStatus === 'Completed'
                        && currentCollabModel.currentCollaborationSiteViewModel.collaborationClientSite.siteURL != null)">
                    <a class="link-text-blue d-flex align-items-center" [class.nonClickableLink]="isMemberFirm"
                      id="btnAddClientTeamMember_{{currentCollabModel.index}}"
                      (click)="addClientTeamMember(currentCollabModel.index, currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.collaborationSiteId)">
                      <img src="assets/images/EDP/Engagement/add_box-blue.svg" alt="add">
                      <span class="d-inline-block pl-2"> Add a new client user </span>
                    </a>
                  </span>

                  <span
                    *ngIf="(currentCollabModel.currentCollaborationSiteViewModel.collaborationClientSite.siteURL === '')">
                    <span class="link-text-blue edp-font-12 orange-color">Client site provisioning in
                      progress</span>
                  </span>

                  <span *ngIf="(currentCollabModel.currentCollaborationSiteViewModel.collaborationClientSite.siteRequestStatus != 'Completed'
                      && currentCollabModel.currentCollaborationSiteViewModel.collaborationClientSite.siteURL != '')">
                    <span class="link-text-blue edp-font-12 orange-color">Client site provisioning in
                      progress</span>
                  </span>

                </div>

                <div class="col-xl-9 col-md-8" *ngIf="(!currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isLoggedInUserPartOfCollaboration
                  && isloggedInUserAdmin =='no' && isQualityUserLoggedIn == 'no' )">
                  <span class="link-text-blue edp-font-14 orange-color">You do not have access to view
                    details!</span>
                </div>

              </div>
              <!-- End divNewClientTeamMember -->

              <!-- start OK and Cancel Buttons Div-->
              <div class="row m-0 text-right mb-2" id="divOKCancelSection_{{currentCollabModel.index}}">
                <div class="col-md-12 text-right pr-0">
                  <div id="success-validation-section mb-1" class="text-right">
                    <span id="member-save-validation" class="text-right pt-1 d-none error-color">
                      Two site owners are mandatory</span>
                    <span id="member-save-success" class="text-right pt-1 d-none error-color">Team member
                      added/updated successfully</span>
                  </div>
                  <button type="button" class="btn SecondaryButton w-17 w-20-sm text-center" data-dismiss="modal"
                    aria-hidden="true" (click)="CloseManageTeam()">
                    <span>
                      <img class="d-inline-block mr-1 align-top"
                        src="../../../assets/images/EDP/Engagement/cancel-24px.svg" alt="Cancel" />
                    </span>
                    <span id="manageTeam-close-cancel-cap" class="d-inline-block align-middle"> Cancel</span>
                  </button>
                  <button *ngIf="currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isLoggedInUserPartOfCollaboration 
                    || isloggedInUserAdmin=='yes' || (isEngDetailINDUSStaffingManagersHaveAccess &&
                    currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.isIndus)"
                    [disabled]="(isEngagementClosed && !isEngDetailINDUSStaffingManagersHaveAccess)"
                    class="btn PrimaryButton w-17 w-20-sm text-center ml-3" type="button"
                    (click)="UpdateCollaborationSiteModel(currentCollabModel.index, currentCollabModel.currentCollaborationSiteViewModel.collaborationSite.collaborationSiteId)">
                    <span>
                      <img class="d-inline-block mr-1  align-top"
                        src="../../../assets/images/EDP/Engagement/ok-24px.svg" alt="ok" />
                    </span>
                    <span class="d-inline-block align-middle"> Save </span>
                  </button>

                </div>

              </div>

            </div>
          </div>
          <!-- End OK and Cancel Buttons Div-->

          <!-- Sub section end -->
        </div>
      </div>

      <ng-template #InProgressMemberInfo>
        <div class="d-flex justify-content-center">
          <div class="spinner-border spinner-border-lr text-info"
            style="width: 6rem; height: 6rem; position:absolute;top:40%" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

      </ng-template>
    </div>

    <!--Start client Opportunity POPUP-->
    <div id="modal-Opportunity-featured" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true"
      data-backdrop="static" data-keyboard="false">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body" style="padding:30px 30px 10px 30px;border-radius: 10px;">
            <div class="row m-0">
              <div class="col-md-11 pl-3">
                <div class="row pop-up-pageTitle"> Select opportunity services</div>
                <div class="row d-flex">
                  <div class="col-md-4 pl-0">
                    <span style="font-size: 15px; margin-top: 5px;">{{selectedclientName}}
                      <span>{{ selectedclientCode ? '(' + selectedclientCode + ')' : '' }}</span>
                    </span>
                  </div>
                  <div class="col-md-4">
                    <span style="font-size: 15px; margin-top: 5px;">{{opportunityName}}</span>
                  </div>
                  <div class="col-md-4">
                    <span style="font-size: 15px; margin-top: 5px;">{{selectedsolutionCodeWithDesc}}</span>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-md-12 p-0 m-0">
                    <span>Select the opportunity service(s) to associate with this engagement. If the opportunity
                      service is not listed, a new one will be created in oneView.
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-1 p-0">
                <button type="button" class="close edp-font-14" data-dismiss="modal" aria-hidden="true"
                  (click)="CancelOpportunityPopUp();">
                  <span class="close-text mr-1">Close </span>
                  <span class="modal-close-btn">&times;</span>
                </button>
              </div>
            </div>


            <div class="row m-0 p-0 col-md-12">
              <div class="col-md-3 r-n-res-checkboxNew px-0 ">
                <img src="assets/images/EDP/Workspace/blue_lagend.png" alt="chk_UnrelatedService" width="20" height="20"
                  class="img-responsive"
                  style="vertical-align: middle; float: left; margin-right: 5px; margin-top: 4px;">
                <label class="custom-check-no-pointer edp-font-12" for="chk_RelatedService">Related service
                  offering</label>
              </div>

              <!-- <div class="col-md-2 r-n-res-checkbox px-0 ">
                <img src="assets/images/EDP/Workspace/grey1_lagend.png" alt="chk_UnrelatedService" width="20"
                  height="20" class="img-responsive"
                  style="vertical-align: middle; float: left; margin-right: 5px; margin-top: 4px;">
                <label class="custom-check-no-pointer edp-font-12" for="chk_UnrelatedService">Unrelated service</label>
              </div> -->

              <div class="col-md-3 r-n-res-checkboxNew px-0 ">
                <img src="assets/images/EDP/Workspace/icon-unrelated-service.svg" alt="chk_UnrelatedService" width="20"
                  height="20" class="img-responsive"
                  style="vertical-align: middle; float: left; margin-right: 5px; margin-top: 4px;">
                <label class="custom-check-no-pointer edp-font-12" for="chk_UnrelatedService">Ineligible service
                  offering</label>
              </div>

              <div class="col-md-3 r-n-res-checkboxNew px-0 ">
                <img src="assets/images/EDP/Workspace/report_problem_warning.svg" alt="Warning" width="20" height="20"
                  class="img-responsive"
                  style="vertical-align: middle; float: left; margin-right: 5px; margin-top: 4px;">
                <label class="custom-check-no-pointer edp-font-12" for="chk_CurrentlyAssigned">Linked to another
                  assignment</label>
              </div>

              <div class="col-md-2 r-n-res-checkboxNew px-0 ">
                <img _ngcontent-lfg-c58="" alt="add" src="assets/images/EDP/Dashboard/redo-24px-cont.svg" width="20"
                  height="20" class="img-responsive"
                  style="vertical-align: middle; float: left; margin-right: 5px; margin-top: 4px;">
                <label class="custom-check-no-pointer edp-font-12" for="chk_Continuance">Continuance</label>
              </div>
            </div>

            <div class="tableScroll m-0 row mt-1">
              <table id="tblEPAD" class="table mb-0" aria-describedby="EP">
                <tbody>
                  <tr *ngIf="noOpportunityMsg.length > 0 && 
                              oneViewClientOpportunityViewModelWrapper.isOpportunityServiceMatchWithPrimaryNSecondary"
                    style="text-align: center; background: #FEF0E9;cursor: default;">
                    <td colspan="6">
                      <span
                        [ngClass]="{'no-opportunity-msg-div-default': (noOpportunityMsg.length == 0),
                                                                'no-opportunity-msg-div': (noOpportunityMsg.length > 0)}"
                        class="font-weight-bold">
                        {{noOpportunityMsg}}</span>
                    </td>
                  </tr>
                  <tr *ngIf="!oneViewClientOpportunityViewModelWrapper.isOpportunityServiceMatchWithPrimaryNSecondary"
                    style="text-align: center;">
                    <td colspan="6" style="padding-top: 60px">
                      <span class="font-weight-bold">
                        {{noOpportunityMsg}}</span>
                    </td>
                  </tr>
                  <tr id="clientOpportunity" name="clientOpportunity"
                    *ngFor="let opportunity of clientOpportunityList let i=index">
                    <td colspan="6" style="padding:10px 0px 0px 0px;">
                      <!-- <div class="row col-md-12">
                        <img *ngIf="opportunity.isAnnuityOpportunity" _ngcontent-lfg-c58="" alt="Annuity"
                          src="assets/images/EDP/Dashboard/redo-24px-cont.svg" width="20" height="20"
                          class="img-responsive" style="vertical-align: middle; float: left; margin-right: 5px;">
                        <span id='opportunityName{{i}}'
                          style="font-size: 15px;cursor:none;"><strong>{{opportunity.opportunityName}}</strong></span>
                      </div> -->

                      <!-- <div class="row pb-3 mb-2 col-md-12 pl-2">
                        <span id='totalEstimatedFees{{i}}' class="filter-partition"
                          style="width: 18.8%;word-break:break-all;">
                          Total estimated fees:&nbsp;{{opportunity.opportunityEstimatedvalue}}</span>
                        <span id='expectedStartDate{{i}}' class="pl-2 filter-partition" style="width: 20.4%;">Expected
                          start
                          date:
                          {{datePipe.transform(opportunity.expectedStartDate,'MM/dd/yyyy')}}</span>
                        <span id='keyDicisionMaker{{i}}' class="pl-2 filter-partition" style="width: 28%;">oneView
                          key
                          decision maker:
                          {{opportunity.keyDicisionMaker}}<img alt="add"
                            src="../../../../assets/images/Client/tooltip.svg"
                            style="height: 16px; width: 16px;margin-left:6px;" data-toggle="tooltip"
                            data-custom-class="tooltipIndicators" data-html="true"
                            data-title="The key decision maker is maintained in oneView and does not impact engagement creation." />
                        </span>
                        <span id='pursuitTeam{{i}}' class="pl-2" style="width: 32%;">Pursuit team:
                          {{opportunity.pursuitTeam}}</span>
                      </div> -->

                      <div class="row col-md-12 m-0 p-0">
                        <table id="tblOpportunity" class="table mb-0 table-border" aria-describedby="opportunity">
                          <thead>
                            <tr>
                              <th>Sol. code</th>
                              <th>Description</th>
                              <th>oneView estimated net fees
                                <img alt="add" src="../../../../assets/images/Client/tooltip.svg"
                                  style="height: 16px; width: 16px;margin-left:6px;" data-toggle="tooltip"
                                  data-custom-class="tooltipIndicators" data-html="true"
                                  data-title="This value for estimated net fees from oneView can be updated in the assignment setup in Engage." />
                              </th>
                            </tr>

                          </thead>
                          <tbody>
                            <tr id="service.opportunityServiceId" name="clientOpportunityService"
                              *ngFor="let service of opportunity.opportunityServiceList let j=index">
                              <!-- <div > -->
                              <td id="{{service.opportunityServiceId}}"
                                [ngClass]="{'PartOfSecondary-background': service.isPartOfSecondary, 'service-bold': service.isPrimary}">
                                <img *ngIf="!service.isEnabled"
                                  src="assets/images/EDP/Workspace/icon-unrelated-service.svg"
                                  alt="chk_UnrelatedService" width="20" height="20" class="img-responsive"
                                  style="vertical-align: middle; float: left; margin-right: 5px;">
                                <label
                                  [ngClass]="{'custom-radio': service.isEnabled,'cursor-default-important':service.isAssigned==true}">
                                  <!-- <span class="mb-0 pos-top" style="padding-left: 25px"
                                      *ngIf="!service.isEnabled"></span> -->
                                  <span class="mb-0 pos-top">{{service.solutionCode}}</span>
                                  <input *ngIf="service.isEnabled" type="radio" name="rdoOpportunityService"
                                    [disabled]="service.isAssigned==true" ng-control="rdoOpportunityService"
                                    [checked]="service.isChecked"
                                    (change)="OpportunityServiceClick(opportunity.opportunityId, service.opportunityServiceId, service.isChecked)">
                                  <span class="checkmark"></span>
                                </label>

                              </td>
                              <td id="{{service.opportunityServiceId}}"
                                [ngClass]="{'PartOfSecondary-background': service.isPartOfSecondary, 'service-bold': service.isPrimary}">
                                <img *ngIf="service.isAssigned"
                                  src="assets/images/EDP/Workspace/report_problem_warning.svg" alt="Warning" width="20"
                                  height="20" class="img-responsive"
                                  style="vertical-align: middle; float: left; margin-right: 5px; margin-top: 4px;">
                                <!-- <img *ngIf="service.isContinuanceCarryFrwdAssignment"
                                  src="assets/images/EDP/Dashboard/redo-24px-cont.svg" alt="Continuance" width="20"
                                  height="20" class="img-responsive"
                                  style="vertical-align: middle; float: left; margin-right: 5px; margin-top: 4px;"> -->
                                <span id='solutionCodeDesc{{i}}{{j}}'
                                  style="vertical-align: sub;">{{service.solutionDescription}}</span>
                              </td>
                              <td id="{{service.opportunityServiceId}}"
                                [ngClass]="{'PartOfSecondary-background': service.isPartOfSecondary, 'service-bold': service.isPrimary}">
                                <span id='estimatedNetFees{{i}}{{j}}'
                                  style="vertical-align: sub;">{{service.priceAmtFormattedValue}}</span>
                              </td>
                              <!-- </div> -->

                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="continuanceEngagementCount > 0" class="mt-2 py-1 font-weight-bold"
              style="text-align: center; background: #FEF0E9;cursor: default; color: #F26315">
              The selected solution code is currently associated with a continuance engagement.
              Please check your&nbsp;
              <a style="text-decoration: underline; cursor: pointer;" routerLink="{{continuanceDashBoardUrl}}"
                target="_self"> continuance dashboard </a>&nbsp;before proceeding.
            </div>
          </div>
          <div class="modal-footer ml-3 mr-3">
            <div class="row m-0 p-0">
              <label class="custom-check edp-font-12" for="chkServiceNotlisted">The opportunity service is not listed or
    the solution code is already selected but I want to add another.
                <input type='checkbox' name='chkServiceNotlisted' id='chkServiceNotlisted'
                  value='{{oneViewClientOpportunityViewModelWrapper.isOpportunityServicelisted}}'
                  [(ngModel)]='oneViewClientOpportunityViewModelWrapper.isOpportunityServicelisted'
                  (click)='OpportunityServiceNotListed(oneViewClientOpportunityViewModelWrapper.isOpportunityServicelisted);'>
                <span class="checkmark-custom"></span>
              </label>
            </div>
            <div class="row m-0 p-0 label error-color edp-font-12" *ngIf="validateOpportunityService && 
                !oneViewClientOpportunityViewModelWrapper.isOpportunitySelected &&
                !oneViewClientOpportunityViewModelWrapper.isOpportunityServicelisted">
              <p *ngIf="clientOpportunityList.length > 0" style="font-size: 14px;">Please select opportunity service
                or
                click on check box <b>The opportunity service is not listed.</b></p>
              <p *ngIf="clientOpportunityList.length == 0" style="font-size: 14px;">Click on check box <b>The
                  opportunity
                  service is not listed.</b></p>
            </div>
            <div class="m-0 p-0 float-right">
              <button type="button" class="SecondaryButton mr-2" data-dismiss="modal" id="btnCancelOpportunity"
                style="width: 150px; text-align: center;" (click)="CancelOpportunityPopUp();">
                <span class="d-inline-block mr-1 align-top">
                  <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
                </span>
                <span class="d-inline-block align-middle">Cancel</span>
              </button>
              <button type="button" class="PrimaryButton" style="width: 150px; text-align: center; "
                id="btnOkOpportunity" (click)="OkOpportunityPopUp();">
                <span class="d-inline-block mr-1 align-top">
                  <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
                </span>
                <span class="d-inline-block align-middle">OK</span>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!--Start client Opportunity POPUP-->

    <!--Start Sharepoint File Label POPUP-->
    <app-sharepoint-file-label [sharePointSelectedFileLabel]="sharePointSelectedFileLabel"
      (close)="cancelSharepointFileLabel()" (ok)="addSharepointFileLabel($event)"></app-sharepoint-file-label>
    <!--End Sharepoint File Label POPUP-->

    <!--Export Document Completed POPUP-->
    <app-export-document-completed-popup [exportedDocSharepointURL]="spExceutionDocExportURL">
    </app-export-document-completed-popup>
    <!--Export document Completed POPUP-->

    <!--Sharepoint File Picker POPUP-->
    <app-sharepoint-file-picker #sharepointFilePickerEsc id="sharepoint-file-picker-esc"
      [siteUrl]="engagementList.siteUrl" [engagementName]="engagementList.engagementName"
      [clientName]="engagementList.clientName" (close)="cancelSharepointFilePicker()"
      (ok)="okSharepointFilePicker($event)"></app-sharepoint-file-picker>
    <!--Sharepoint File Picker POPUP-->

  </div>

  <!-- end popup section -->

  <!-- end engagement summary div -->
  <div class="row m-0 pt-4" *ngIf="engagementList==null || isTnCInProgress">
    <div class="col-md-12 px-0">
      <app-alerts></app-alerts>
    </div>
  </div>

</div>



<!-- containerDiv -->


<script type="text/javascript">
  $('.image').on('click', function (e) {
    e.stopPropagation();
  });

  $(".nav-tabs a[data-toggle=tab]").on("click", function (e) {
    if ($(this).hasClass("disabled")) {
      e.preventDefault();
      return false;
    }
  });
</script>