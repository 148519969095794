import { TemplateMaintenanceComponent } from './pages/configuration/template-maintenance/template-maintenance.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EngagementHomeComponent } from './pages/engagement/engagement-home/engagement-home.component';
import { ClientHomeComponent } from './pages/client/client-home/client-home.component';
import { DocumentHomeComponent } from './pages/document/document-home/document-home.component';
import { ClientsummaryComponent } from './pages/clientsummary/clientsummary.component';
import { EngagementSummaryComponent } from './pages/engagement-summary/engagement-summary.component';
import { WorkspaceComponent } from './pages/review/workspace/workspace.component';
import { RevenueHomeComponent } from './pages/revenue/revenue-home/revenue-home.component';
import { FavoriteHomeComponent } from './pages/favorite/favorite-home/favorite-home.component';
import { PageNotFoundComponent } from './common/page/page-not-found/page-not-found.component';
import { AdminConfigurationComponent } from './pages/admin-configuration/admin-configuration.component';
import { MyreacceptanceengagementsComponent } from './pages/myreacceptanceengagements/myreacceptanceengagements.component';
import { NoaccessComponent } from './common/page/noaccess/noaccess.component';
import { InitiateEngagementComponent } from './pages/initiate-engagement/initiate-engagement.component';
import { CreateClientComponent } from './pages/client/create-client/create-client.component';
import { SolutioncodeConfigurationComponent } from './pages/solutioncode-configuration/solutioncode-configuration.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { InterviewsComponent } from './pages/interviews/interviews.component';
import { HomeComponent } from '../app/common/home/home.component';
import { MyReviewsListComponent } from './common/page/my-reviews-list/my-reviews-list.component';
import { BookmarksListViewComponent } from './pages/bookmarks/bookmarks-list-view/bookmarks-list-view.component';
import { AuthGuard } from './helper/AuthGaurd';
import { AssignmentListViewComponent } from './pages/assignments/assignment-list-view/assignment-list-view.component';
// import { SearchListViewComponent } from './pages/search/search-list-view/search-list-view.component';
import { LoginComponent } from './pages/login/login.component';
import { AllclientsComponent } from './pages/allclients/allclients.component';
import { ContinuancehomeComponent } from './pages/continuancehome/continuancehome.component';
import { EditClientComponent } from './pages/client/edit-client/edit-client.component';
import { ProcessCmsErrorAssignmentComponent } from './process-cms-error-assignment/process-cms-error-assignment.component';
import { ProcessAuditEngagementComponent } from './process-audit-engagement/process-audit-engagement.component';
import { ProcessAddParaprofessionalsEngagementComponent } from './process-add-paraprofessionals-engagement/process-add-paraprofessionals-engagement/process-add-paraprofessionals-engagement.component';
import { NewDocumentInterviewsComponent } from './pages/new-document-interviews/new-document-interviews.component';
import { InitiateChangeOrderComponent } from './pages/initiate-change-order/initiate-change-order.component';
import { ChangeOrderInterviewComponent } from './pages/change-order-interview/change-order-interview.component';

const routes: Routes = [
  {
    path: 'Home',
    component: HomeComponent
  },
  {
    path: '',
    redirectTo: '/Home',
    pathMatch: 'full'
  },
  {
    path: 'MyClients',
    component: ClientHomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'NewClient',
    component: CreateClientComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'EditClient/:clientId',
    component: EditClientComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'ClientDetails/:clientId',
    component: ClientsummaryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'MyEngagements',
    component: EngagementHomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'EngagementDetail/:engagementId',
    component: EngagementSummaryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'EngagementDetail/:engagementId/:tabName',
    component: EngagementSummaryComponent,
    canActivate: [AuthGuard]
  },
  /*
  {
    path: 'Document',
    component: DocumentHomeComponent,
    canActivate: [AuthGuard]
  },
  */
  {
    path: 'Review/:workspaceId',
    component: WorkspaceComponent,
    canActivate: [AuthGuard]
  },
  /*
  {
    path: 'Revenue',
    component: RevenueHomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'Favorites',
    component: FavoriteHomeComponent,
    canActivate: [AuthGuard]
  },
  */
  {
    path: 'Configuration',
    component: AdminConfigurationComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: TemplateMaintenanceComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'solution-code',
        component: SolutioncodeConfigurationComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'template-maintenance',
        component: TemplateMaintenanceComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  /*
  {
    path: 'AllClients',
    component: AllclientsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'SolutionCodeConfiguration',
    component: SolutioncodeConfigurationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'ContinuanceEngagements',
    component: MyreacceptanceengagementsComponent,
    canActivate: [AuthGuard]
  },
  */
  {
    path: 'NewEngagement',
    component: InitiateEngagementComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard]
  },
  {
    path: 'EditEngagement/:engagementId',
    component: InitiateEngagementComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard]
  },
  {
    path: 'NewChangeOrder',
    component: InitiateChangeOrderComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard]
  },
  {
    path: 'ChangeOrderInterviews/:workspaceId',
    component: ChangeOrderInterviewComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard]
  },
  {
    path: 'EditChangeOrder/:changeOrderId',
    component: InitiateChangeOrderComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard]
  },
  {
    path: 'UserProfile',
    component: UserProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'NoAccess',
    component: NoaccessComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'NoAccess/:signOut',
    component: NoaccessComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'Interviews/:workspaceId',
    component: InterviewsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard]
  },
  {
    path: 'NewDocumentInterviews/:workspaceId',
    component: NewDocumentInterviewsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard]
  },
  {
    path: 'MyApproval',
    component: MyReviewsListComponent,
    canActivate: [AuthGuard]
  },
  /*
  {
    path: 'MyBookmarks',
    component: BookmarksListViewComponent,
    canActivate: [AuthGuard]
  },*/
  {
    path: 'Continuance',
    component: ContinuancehomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'Assignments',
    component: AssignmentListViewComponent,
    canActivate: [AuthGuard]
  },
  {
    // path: 'Search',
    // component: SearchListViewComponent,
    path: 'Search', // Path to access the feature
    loadChildren: () => import('../app/globalSearch/global-search.module').then(m => m.GlobalSearchModule) ,
    canActivate: [AuthGuard]
  },
  {
    path: 'Login',
    component: LoginComponent
  },
  {
    path: 'Process-CMS-Error-Request',
    component: ProcessCmsErrorAssignmentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'process-audit-engagement',
    component: ProcessAuditEngagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'process-add-paraprofessionals-engagement',
    component: ProcessAddParaprofessionalsEngagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
