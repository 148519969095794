<section class="filter-wrapper">
  <div class="row">
    <div class="col-12 col-xs-12 col-md-12 col-lg-12 pr-0 pl-0">
      <label>Search all accounts in oneView <span class="mandatory">*</span> </label>
      <span class="d-flex">
        <input #autoSearch type="text" id="txtValue{{MappedSource}}{{Sequence}}"
          name="txtValue{{MappedSource}}{{Sequence}}" autocomplete="off" value="" [name]="name" [(ngModel)]="value"
          class="form-control rounded-0 textBoxDesign input-height"
          (keydown.enter)="onSearch(autoSearch.value, $event);$event.preventDefault();" maxlength="40"
          placeholder="{{clientNamePlaceholderText}}" (focus)="this.clientNamePlaceholderText=''"
          (blur)="this.clientNamePlaceholderText = 'Enter a client name, oneView account name, aka/dba name, or oneView account ID'"
          (blur)="onSearch(autoSearch.value, $event);$event.preventDefault();" />
        <span class="icon-dupe-check-search" id="DuplicateCheckOneviewAccountSearch"
          (click)="onSearch(autoSearch.value, $event);$event.preventDefault();"></span>
      </span>

      <div class="icon-container">
        <em id='inlineLoader' class=""></em>
      </div>
      <input type="hidden" id="hdnKeyCode{{MappedSource}}{{Sequence}}" />
      <input type="hidden" id="hdnKey{{MappedSource}}{{Sequence}}" />
      <input type="hidden" id="txtValue{{MappedSource}}{{Sequence}}" />
      <input type="hidden" id="hdnKeyClientOneViewAccountId{{MappedSource}}{{Sequence}}" />
    </div>
  </div>
  <div class="row filter-list">
    <div class="col-12 col-xs-12 col-md-12 col-lg-12 mb-1">
      <div
        [ngClass]="listViewNoOfrecordsPerPage > 5 ? 'filter-select pl-3 pr-3 mt-3' : 'filter-select-OneView pl-3 pr-3 mt-3'"
        style="border-top:1px solid #c7c7c7;">

        <div *ngIf="clientList.length > 0" class="mt-3">
          <div class="row" *ngIf="!isSingleSearch">
            <div class="col-md-8 pl-0">
              <h6 style="color:#707070;">Search results: <strong>{{ this.totalNoOfRecords }}</strong></h6>
            </div>
            <div class="col-md-4">
              <a (click)="closeCard()" style="text-align: right;text-decoration: underline;color:#00a7b5;"
                class="float-right" aria-label="Close">Cancel</a>
            </div>
          </div>
          <div *ngIf="!isSingleSearch">
            <h6 style="color:#707070;" class="font-weight-bold">Select account</h6>
          </div>
          <div *ngIf="isSingleSearch">
            <h6 style="color:#707070;">Selected account</h6>
          </div>
          <p *ngIf="!isSingleSearch">The following accounts are captured in oneView and have attributes that match the
            search term above. Please review the list and select the one to associate with this engagement.</p>
          <div class="row w-100" *ngFor="let client of clientList"
            (mousedown)="!isSingleSearch && selectedClientValue($event, client)">
            <div class="col-12">
              <div class="card mb-3" style="border-radius: 0px;">
                <div class="card-body p-0">

                  <div class="row p-3">

                    <div class="col-md-6 p-0">

                      <h6 style="color:#707070;" class="card-title mb-0">oneView account name: <span
                          innerHTML="{{ client.name |
                        highlight : autoSearch.value}} {{'(' + client.hs_accountid + ')' | highlight : autoSearch.value}}"></span>
                      </h6>
                      <p class="card-text mb-0">AKA/DBA: <span
                          innerHTML="{{ client.hs_akadba | highlight : autoSearch.value}}"></span></p>
                      <p class="card-text mb-0">CMS client name: <span innerHTML="{{ client.gt_cmsaccountname | highlight : autoSearch.value}} {{ client.accountnumber
                        == null ? ''
                        :
                        '(' + client.accountnumber + ')' | highlight : autoSearch.value}}"></span> </p>
                      <p class="card-text mb-0">Opportunities: {{client.OppCount}}</p>
                    </div>

                    <div class="col-md-3">
                      <p class="card-text"></p>
                      <p class="card-text mb-0">Headquarters: {{ client.customeHeadquarter }}</p>
                      <p class="card-text mb-0">Web site: {{ client.websiteurl }}</p>
                    </div>

                    <div class="col-md-3">
                      <p class="card-text">Client status: {{(client.hs_clientstatus_derivedstatus ==null||
                        client.hs_clientstatus_derivedstatus == ''|| client.hs_clientstatus_derivedstatus ==
                        undefined)?client.hs_clientstatus:client.hs_clientstatus_derivedstatus}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ListView Pagination Start -->
        <div class="justify-content-evenly text-center pagination-section mt-2" *ngIf="clientList?.length > 0"
          [ngClass]="{ 'd-none': isSingleSearch, 'd-flex': !isSingleSearch }">
          <ul *ngIf="pager.pages && pager.pages.length" class="pagination mx-2">
            <li>
              <a id="first" [ngClass]="{isDisabled:pager.currentPage === 1}"
                (click)="getClientList(clientSearchText, 3, this.listViewNoOfrecordsPerPage, 1)">
                <img alt="first row" src="../../../../assets/images/EDP/Engagement/first_arrow.png">
              </a>
            </li>
            <li>
              <a id="previous" [ngClass]="{isDisabled:pager.currentPage === 1}"
                (click)="getClientList(clientSearchText, 3, this.listViewNoOfrecordsPerPage, pager.currentPage - 1)">
                <img alt="previous row" alt="Previous row"
                  src="../../../../assets/images/EDP/Engagement/previous_arrow.png">
              </a>

            </li>
            <li [ngClass]="{active:pager.currentPage === page}">
              <span class="pagetext">{{pager.currentPage}} of {{pager.totalPages}}</span>
            </li>

            <li>
              <a [ngClass]="{isDisabled:pager.currentPage === pager.totalPages}"
                (click)="getClientList(clientSearchText, 3, this.listViewNoOfrecordsPerPage, pager.currentPage + 1)">
                <img alt="Next row" src="../../../../assets/images/EDP/Engagement/next_arrow.png">
              </a>
            </li>
            <li>
              <a [ngClass]="{isDisabled:pager.currentPage === pager.totalPages}"
                (click)="getClientList(clientSearchText, 3, this.listViewNoOfrecordsPerPage, pager.totalPages)">
                <img alt="Last row" src="../../../../assets/images/EDP/Engagement/last_arrow.png">
              </a>
            </li>
          </ul>
          <div>
            <span>Page</span>
            <input id="txtPageNo" type="text" class="d-inline text-center ml-3 mr-3 form-control rounded-0 page-number"
              value="{{pager.currentPage}}" pattern="^[0-9]+(\.[0-9]{1,2})?$" autocomplete="off"
              (keyup)="numberOnly($event);$event.preventDefault();">
            <a style="text-decoration: underline;color: #00a7b5;" (click)="onGoClick()">Go</a>
          </div>
          <div>
            <span>Rows</span>
            <select id="drprecordsPerPage" [(ngModel)]="listViewNoOfrecordsPerPage" name="drprecordsPerPage"
              (change)="getClientList(clientSearchText, 3, this.listViewNoOfrecordsPerPage, 1)" class="page-counts">
              <option *ngFor="let listViewNoOfrecordsPerPage of noOfrecordsPerPageList"
                value="{{listViewNoOfrecordsPerPage}}">{{listViewNoOfrecordsPerPage}}</option>
            </select>
          </div>
        </div>
        <!-- ListView Pagination End -->
        <div class="row w-100 oneViewNoResult p-3" [ngStyle]="{ 'display': CantfindMsg ? 'flex' : 'none'}"
          style="margin : 20px 0px !important">
          <div class="col-md-12 p-3" style="background-color: #f5f5f5;">Can't find what you are looking for? The account might not have been created in oneView yet. Contact your engagement PPMD, Senior Manager or Director for next steps.</div>
        </div>
        <div class="row" [ngStyle]="{ 'display': noResultFoundMsg ? 'flex' : 'none'}">
          <div class="col-md-12 pl-0" style="margin : 10px 0px !important">
            <h6 style="color:#707070;">Search results: <strong>{{ this.totalNoOfRecords }}</strong></h6>
          </div>
          <div class="col-md-12 text-left p-4" style="background-color: #f5f5f5;margin : 10px 0px !important">No results were found. The account might not have been created in oneView yet. Contact your engagement PPMD, Senior Manager or Director for next steps.
          </div>
        </div>
      </div>

    </div>
  </div>
</section>