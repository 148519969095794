import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavbarService } from 'src/app/services/navbarServices';
import { AlertsService } from 'src/app/services/alerts.service';
import { IWantToList } from 'src/app/model/IWantToList';
import { Alert } from 'src/app/model/Alert';
import { cardService } from 'src/app/services/cardServices';
import { CardListModel } from 'src/app/model/cardlistmodel';
import { Router, Event, NavigationEnd } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { EngagementDialogTitles } from 'src/app/model/Engagement/Engagements';
import { Subscription } from 'rxjs';
import { WorkspaceService } from 'src/app/services/workspace.service';

declare var $;
export enum navigationIds {
  Home = 'Home',
  MyClients = 'MyClients',
  MyEngagements = 'MyEngagements',
  MyApproval = 'MyApproval',
  MyBookmarks = 'MyBookmarks',
  Assignments = 'Assignments',
  Search = 'Search',
  Continuance = 'Continuance'
}
export enum NavigationIdWithPurpleImageNames {
  Home = 'home-purple',
  MyClients = 'business-purple',
  MyEngagements = 'description-purple',
  MyApproval = 'library_add_check-purple',
  MyBookmarks = 'bookmarks-purple',
  Assignments = 'assignment-purple',
  Search = 'search-purple',
  Continuance = 'continuance-purple'
}
export enum NavigationIdWithGreyImageNames {
  Home = 'home-grey',
  MyClients = 'business-grey',
  MyEngagements = 'description-grey',
  MyApproval = 'library_add_check-grey',
  MyBookmarks = 'bookmarks-grey',
  Assignments = 'assignment-grey',
  Search = 'search-grey',
  Continuance = 'continuance-grey'
}

@Component({
  selector: 'app-leftnav',
  templateUrl: './leftnav.component.html',
  styleUrls: ['./leftnav.component.css'],
  providers: [cardService]
})


export class LeftnavComponent implements OnInit {

  iWantToActionList: IWantToList[];
  alertClient: Alert;
  cardlistmodel: CardListModel[] = [];
  screenWidth: number;
  screenHeight: number;
  //isSmallDevice = false;
  replacedImageBasePath = '/assets/images/EDP/LeftSidebar/purple/';
  originalImageBasePath = '/assets/images/EDP/LeftSidebar/grey/';
  imageType = '.svg';
  originalImagePath = '';
  newImagePath = '';
  originalImgClass = 'imgchange';
  newImgClass = 'imgReplace';
  currentYear: number;
  selectedValue: any;
  currentClientId: number = -1;
  currentEngagementId: number = -1;
  tileId: number;
  currentRoute: string;
  subCollapsibleLeftNav: Subscription;
  isCollapsibleLeftNavVisible: boolean;
  changeOrderFeatureFlag:boolean;
  private modalNewEngDialogRef: Subscription = null;
  modeldata = {
    fromComponent: '',
    isUnsavedChangesExist: false
  };
  iWantToSelectedItem: string;

  @HostListener('document:click', ['$event'])
  clickoutSideLeftNav(event) {
    if (!($(event.target).is('.imgIwantTo') || $(event.target).is('.divIwantto') || $(event.target).is('#iwantto.in')))
      $("#iwantto").removeClass('in');
  }



  constructor(private workspaceService: WorkspaceService,private navbarService: NavbarService, private alertService: AlertsService
    , private _cardService: cardService, private route: Router, private data: DataService) {
    this.currentYear = (new Date()).getFullYear();

    this.currentRoute = "";
    this.route.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        var pageName = this.currentRoute.split('/', 2);
        this.resetPrepopulation(pageName[1]);
        console.log("Current page: " + pageName);
      }
    });
  }

  ngOnInit() {
    this.getEDPSideMenuList();
    this.getChangeOrderFeatureFlag();
    setTimeout(() => {
      this.getIWantToList();
    }, 50);

    this.modalNewEngDialogRef = this.data.getModalDetails$.subscribe(
      dialogData => {
        if (dialogData) {
          let data = JSON.parse(dialogData)
          let dailogTitle = data.dialogTitle;
          let btnName = data.dialogButtonName;
          if (dailogTitle === EngagementDialogTitles.UnsavedChangesConfirmationIWantTo) {
            if (btnName === 'ok') {
              if (this.iWantToSelectedItem === 'Download template(s)') {
                this.documentTemplatePopUpDisplay();
              }
              else {
                this.newDocumentPopUpDisplay();
              }


            }
            else if (btnName === 'cancel' || btnName === 'close') {

            }
          }
        }
      });

    this.subCollapsibleLeftNav =
      this.data.isCollapsibleLeftNavVisible$.subscribe(value => {
        this.isCollapsibleLeftNavVisible = value
      })
  }

  getChangeOrderFeatureFlag() {
    this.workspaceService.getChangeOrderFeatureFlag().subscribe({
      next: result => {
        this.changeOrderFeatureFlag = result;
     
      }
      , error: er => {
        this.changeOrderFeatureFlag = false;
        //console.log("failed to load changeOrderFeatureFlag");
      }
    })
  }
  setDefaultMenuStyle() {
    let defaultRouteName = this.route.url;

    var menuitem = $('.nav-tabs> #sideMenuItem.active');
    var anchorChild = $(menuitem).children();
    $(anchorChild).addClass('active');
    var anchoredImg = $(anchorChild).find('img');
    if (defaultRouteName.toLowerCase().includes(navigationIds.Home.toLowerCase())) {
      this.newImagePath = this.replacedImageBasePath + NavigationIdWithPurpleImageNames.Home + this.imageType;
      $(anchoredImg).attr('src', this.newImagePath);
      $(anchoredImg).addClass(this.newImgClass);
    } else if (defaultRouteName.toLowerCase().includes(navigationIds.MyClients.toLowerCase())) {
      this.newImagePath = this.replacedImageBasePath + NavigationIdWithPurpleImageNames.MyClients + this.imageType;
      $(anchoredImg).attr('src', this.newImagePath);
      $(anchoredImg).addClass(this.newImgClass);
    } else if (defaultRouteName.toLowerCase().includes(navigationIds.MyEngagements.toLowerCase())) {
      this.newImagePath = this.replacedImageBasePath + NavigationIdWithPurpleImageNames.MyEngagements + this.imageType;
      $(anchoredImg).attr('src', this.newImagePath);
      $(anchoredImg).addClass(this.newImgClass);
    } else if (defaultRouteName.toLowerCase().includes(navigationIds.MyApproval.toLowerCase())) {
      this.newImagePath = this.replacedImageBasePath + NavigationIdWithPurpleImageNames.MyApproval + this.imageType;
      $(anchoredImg).attr('src', this.newImagePath);
      $(anchoredImg).addClass(this.newImgClass);
    } else if (defaultRouteName.toLowerCase().includes(navigationIds.Assignments.toLowerCase())) {
      this.newImagePath = this.replacedImageBasePath + NavigationIdWithPurpleImageNames.Assignments + this.imageType;
      $(anchoredImg).attr('src', this.newImagePath);
      $(anchoredImg).addClass(this.newImgClass);
    } else if (defaultRouteName.toLowerCase().includes(navigationIds.MyBookmarks.toLowerCase())) {
      this.newImagePath = this.replacedImageBasePath + NavigationIdWithPurpleImageNames.MyBookmarks + this.imageType;
      $(anchoredImg).attr('src', this.newImagePath);
      $(anchoredImg).addClass(this.newImgClass);
    } else if (defaultRouteName.toLowerCase().includes(navigationIds.Search.toLowerCase())) {
      this.newImagePath = this.replacedImageBasePath + NavigationIdWithPurpleImageNames.Search + this.imageType;
      $(anchoredImg).attr('src', this.newImagePath);
      $(anchoredImg).addClass(this.newImgClass);
    } else if (defaultRouteName.toLowerCase().includes(navigationIds.Continuance.toLowerCase())) {
      this.newImagePath = this.replacedImageBasePath + NavigationIdWithPurpleImageNames.Continuance + this.imageType;
      $(anchoredImg).attr('src', this.newImagePath);
      $(anchoredImg).addClass(this.newImgClass);
    }
  }
  adjustImagePath(event, index, navigationId: string) {
    if (navigationId === navigationIds.Home) {
      this.removeActiveMenuStyle(this.newImgClass);
      this.newImagePath = this.replacedImageBasePath + NavigationIdWithPurpleImageNames.Home + this.imageType;
      $('.' + this.originalImgClass + index).attr('src', this.newImagePath);
      $('.' + this.originalImgClass + index).addClass(this.newImgClass);

    } else if (navigationId === navigationIds.MyClients) {
      this.removeActiveMenuStyle(this.newImgClass);
      this.newImagePath = this.replacedImageBasePath + NavigationIdWithPurpleImageNames.MyClients + this.imageType;
      $('.' + this.originalImgClass + index).attr('src', this.newImagePath);
      $('.' + this.originalImgClass + index).addClass(this.newImgClass);

    } else if (navigationId === navigationIds.MyEngagements) {
      this.removeActiveMenuStyle(this.newImgClass);
      this.newImagePath = this.replacedImageBasePath + NavigationIdWithPurpleImageNames.MyEngagements + this.imageType;
      $('.' + this.originalImgClass + index).attr('src', this.newImagePath);
      $('.' + this.originalImgClass + index).addClass(this.newImgClass);

    } else if (navigationId === navigationIds.MyApproval) {
      this.removeActiveMenuStyle(this.newImgClass);
      this.newImagePath = this.replacedImageBasePath + NavigationIdWithPurpleImageNames.MyApproval + this.imageType;
      $('.' + this.originalImgClass + index).attr('src', this.newImagePath);
      $('.' + this.originalImgClass + index).addClass(this.newImgClass);

    } else if (navigationId === navigationIds.Assignments) {
      this.removeActiveMenuStyle(this.newImgClass);
      this.newImagePath = this.replacedImageBasePath + NavigationIdWithPurpleImageNames.Assignments + this.imageType;
      $('.' + this.originalImgClass + index).attr('src', this.newImagePath);
      $('.' + this.originalImgClass + index).addClass(this.newImgClass);

    } else if (navigationId === navigationIds.MyBookmarks) {
      this.removeActiveMenuStyle(this.newImgClass);
      this.newImagePath = this.replacedImageBasePath + NavigationIdWithPurpleImageNames.MyBookmarks + this.imageType;
      $('.' + this.originalImgClass + index).attr('src', this.newImagePath);
      $('.' + this.originalImgClass + index).addClass(this.newImgClass);

    } else if (navigationId === navigationIds.Search) {
      this.removeActiveMenuStyle(this.newImgClass);
      this.newImagePath = this.replacedImageBasePath + NavigationIdWithPurpleImageNames.Search + this.imageType;
      $('.' + this.originalImgClass + index).attr('src', this.newImagePath);
      $('.' + this.originalImgClass + index).addClass(this.newImgClass);

    } else if (navigationId === navigationIds.Continuance) {
      this.removeActiveMenuStyle(this.newImgClass);
      this.newImagePath = this.replacedImageBasePath + NavigationIdWithPurpleImageNames.Continuance + this.imageType;
      $('.' + this.originalImgClass + index).attr('src', this.newImagePath);
      $('.' + this.originalImgClass + index).addClass(this.newImgClass);
    }
    if (this.isCollapsibleLeftNavVisible)
      this.data.setCloseReviewNotes(true);
    $('.margin-all').removeClass('compressDiv');
    $('.modal-open').removeClass('modal-open');
  }

  modifyIwantToUIFortablet() {
    //this.screenWidth = window.screen.width;
    //this.screenHeight = window.screen.height;
    console.log('width: ' + window.screen.width + 'height: ' + window.screen.height);
    // if (this.screenWidth >= 767 && this.screenWidth <= 1280){
    //this.isSmallDevice = true;

    // console.log('smalldevice: ' + this.isSmallDevice);
    setTimeout(() => {
      $("[data-toggle='toggle']").click(function () {
        var selector = $(this).data("target");
        $(selector).toggleClass('in');
      });
      $('.btnIwanttoHeading').click(function () {
        var selector = $("[data-toggle='toggle']").data("target");
        $(selector).toggleClass('in');
      });
    }, 50);
    // }
  }
  getIWantToList() {
    this.navbarService.getIWantToList().subscribe(result => {
      this.iWantToActionList = result;
    if(!this.changeOrderFeatureFlag){
      this.iWantToActionList = result.filter(item =>item.text !=="Create change order");
      }
      let tile = this.iWantToActionList.filter(tid => tid.text == 'Create new engagement')
    if (tile.length > 0)
        this.selectedValue = tile[0].tileId;
      this.tileId = this.selectedValue;
    setTimeout(() => {
        $('.btnIwanttoHeading').click(function () {
          var menuitems = $('.nav-tabs> #sideMenuItem');
          var childMenu = $(menuitems).children();
          $(childMenu).each(function (i, obj) {
            if ($(obj).hasClass('active')) {
              $(obj).removeClass('active');
            }
          });
        });
        this.setDefaultMenuStyle();
        this.modifyIwantToUIFortablet();
      }, 50);
    },
      error => {
        this.alertService.remove(this.alertClient);
        this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      });
  }
  getEDPSideMenuList() {
    this._cardService.getEDPSideMenu().subscribe(
      result => {
        this.cardlistmodel = result;
        console.log(this.cardlistmodel);
      },
      err => {
        this.cardlistmodel = null;
      });
  }

  removeActiveMenuStyle(replacedImgClass: string) {
    var _originalImagePath = this.originalImagePath;
    var _originalImageBasePath = this.originalImageBasePath;
    var _imageType = this.imageType;
    var menuitems = $('.nav-tabs> #sideMenuItem');
    var childMenu = $(menuitems).children();
    $(childMenu).each(function (i, obj) {

      if ($(obj).hasClass('active')) {
        $(obj).removeClass('active');
      }
      var anchoredImg = $(obj).find('img');
      if ($(anchoredImg).hasClass(replacedImgClass)) {
        $(anchoredImg).removeClass(replacedImgClass);
        // replace it with original image.
        var prevMenuItemHref = $(obj).attr('href');

        if (prevMenuItemHref.toLowerCase().includes(navigationIds.Home.toLowerCase())) {
          _originalImagePath = _originalImageBasePath + NavigationIdWithGreyImageNames.Home + _imageType;
          $(anchoredImg).attr('src', _originalImagePath);
        } else if (prevMenuItemHref.toLowerCase().includes(navigationIds.MyClients.toLowerCase())) {
          _originalImagePath = _originalImageBasePath + NavigationIdWithGreyImageNames.MyClients + _imageType;
          $(anchoredImg).attr('src', _originalImagePath);
        } else if (prevMenuItemHref.toLowerCase().includes(navigationIds.MyEngagements.toLowerCase())) {
          _originalImagePath = _originalImageBasePath + NavigationIdWithGreyImageNames.MyEngagements + _imageType;
          $(anchoredImg).attr('src', _originalImagePath);
        } else if (prevMenuItemHref.toLowerCase().includes(navigationIds.MyApproval.toLowerCase())) {
          _originalImagePath = _originalImageBasePath + NavigationIdWithGreyImageNames.MyApproval + _imageType;
          $(anchoredImg).attr('src', _originalImagePath);
        } else if (prevMenuItemHref.toLowerCase().includes(navigationIds.Assignments.toLowerCase())) {
          _originalImagePath = _originalImageBasePath + NavigationIdWithGreyImageNames.Assignments + _imageType;
          $(anchoredImg).attr('src', _originalImagePath);
        } else if (prevMenuItemHref.toLowerCase().includes(navigationIds.MyBookmarks.toLowerCase())) {
          _originalImagePath = _originalImageBasePath + NavigationIdWithGreyImageNames.MyBookmarks + _imageType;
          $(anchoredImg).attr('src', _originalImagePath);
        } else if (prevMenuItemHref.toLowerCase().includes(navigationIds.Search.toLowerCase())) {
          _originalImagePath = _originalImageBasePath + NavigationIdWithGreyImageNames.Search + _imageType;
          $(anchoredImg).attr('src', _originalImagePath);
        } else if (prevMenuItemHref.toLowerCase().includes(navigationIds.Continuance.toLowerCase())) {
          _originalImagePath = _originalImageBasePath + NavigationIdWithGreyImageNames.Continuance + _imageType;
          $(anchoredImg).attr('src', _originalImagePath);
        }
      }
    });
  }

  selected(item) {
    this.iWantToSelectedItem = item.text;
    if (this.isCollapsibleLeftNavVisible)
      this.data.setCloseReviewNotes(true);
    console.log('test tileid : ' + item.tileId);
    $("#iwantto").removeClass('in');
    if (item.tileId === this.tileId) {
      this.route.navigateByUrl('/NewEngagement')
    } else if (item.text === 'Create new document') {
      this.displayAddNewDocument();
      //this.getIWantToList();
    } else if (item.text === 'Download template(s)') {
      this.displayAddDocumentTemplate();
    } 
    else if (this.changeOrderFeatureFlag){
      if(item.text === 'Create change order') {
        this.route.navigateByUrl('/NewChangeOrder')
      }
    }
  
  }

  displayAddNewDocument() {
    this.currentClientId = 0;
    this.currentEngagementId = 0;
    this.getParametersForTemplate();
    if ((this.modeldata.fromComponent == 'engagement' || this.modeldata.fromComponent == 'interviews') && this.modeldata.isUnsavedChangesExist) {
      this.setDialogContent("information", EngagementDialogTitles.UnsavedChangesConfirmationIWantTo);
    }
    else {
      this.newDocumentPopUpDisplay();
    }
  }
  displayAddDocumentTemplate() {

    this.getParametersForTemplate();
    if ((this.modeldata.fromComponent == 'engagement' || this.modeldata.fromComponent == 'interviews') && this.modeldata.isUnsavedChangesExist) {
      this.setDialogContent("information", EngagementDialogTitles.UnsavedChangesConfirmationIWantTo);
    }
    else {
      this.documentTemplatePopUpDisplay();
    }
  }
  getParametersForTemplate() {

    this.data.checkForUnsavedChanges.subscribe(data => {
      if (data) {
        const modal = JSON.parse(data);
        this.modeldata.fromComponent = modal.fromComponent;
        this.modeldata.isUnsavedChangesExist = modal.isUnsavedChangesExist;
      }
    }).unsubscribe();
    this.data.currentClientIdForPrePopulate.subscribe(clientId => this.currentClientId = clientId > 0 ? clientId : -1).unsubscribe();
    this.data.currentEngagementIdForPrePopulate.subscribe(engagementId => this.currentEngagementId = engagementId > 0 ? engagementId : -1).unsubscribe();
  }

  displayDialog(type: string, title: string, description: string, button: number, bigTitle: boolean, isContinuanceEngExists: boolean = false) {
    var modalObj = {
      dialogType: type,
      dialogTitle: title,
      dialogDescription: description,
      dialogRedirectUrl: '',
      dialogButton: button,
      modalDisplay: true,
      dialogBigTitle: bigTitle,
      dialogisContinuanceEngagementExistsModel: isContinuanceEngExists,
      dialogcontinuanceDashBoardUrl: isContinuanceEngExists ? '/Continuance' : ''
    };
    var modalJSON = JSON.stringify(modalObj);
    console.log(' showDialog func called information');
    this.data.displayDialogNotification(modalJSON);
  }

  setDialogContent(type: string, title: string) {
    let description: string;
    let button: number = 2;
    let bigTitle = false;
    if (this.modeldata.fromComponent == 'engagement') {
      description = 'You have changes that have not been saved. Click "Cancel" to return to the page to update or save your changes, or click "OK" to continue to leave the page without saving your changes.';
    }
    if (this.modeldata.fromComponent == 'interviews') {
      description = 'While the questionnaire responses are autosaved, leaving now may result in recent changes not being saved. Click “Cancel” to go back and manually save your updates. Click “OK” to proceed without manually saving them.';
    }
    button = 2;
    bigTitle = true;
    this.displayDialog(type, title, description, button, bigTitle);
  }

  newDocumentPopUpDisplay() {
    const dialogData = {
      //dialogTitle: 'Display Delegate',
      modalDisplay: true,
      prePopulatedClientId: this.currentClientId,
      prePopulatedEngagementId: this.currentEngagementId
    };
    const dialogJSON = JSON.stringify(dialogData);
    this.data.setNewDocumentDialogDetails(dialogJSON);

    // this.data.setClientIdForPrePopulate(-1);
    // this.data.setEngagementIdForPrePopulate(-1);
    // const dialogData1 = {
    //   fromComponent: '',
    //   isUnsavedChangesExist: false
    // };
    // const dialogJSON1 = JSON.stringify(dialogData1);
    // this.data.setUnsavedChangesModel(dialogJSON1);


  }

  documentTemplatePopUpDisplay() {
    const dialogData = {
      modalDisplay: true,
      prePopulatedClientId: this.currentClientId,
      prePopulatedEngagementId: this.currentEngagementId
    };
    const dialogJSON = JSON.stringify(dialogData);
    this.data.setDocumentTemplateDialogDetails(dialogJSON);
  }

  resetPrepopulation(Url: string) {
    switch (Url) {
      case 'Home':
        this.resetClientIdandEngagementId();
        break;

      case 'MyClients':
        this.resetClientIdandEngagementId();
        break;

      case 'MyEngagements':
        this.resetClientIdandEngagementId();
        break;

      case 'Assignments':
        this.resetClientIdandEngagementId();
        break;

      case 'Continuance':
        this.resetClientIdandEngagementId();
        break;

      case 'EngagementDetail':
        this.resetUnsavedChangesPopup();
        break;

      case 'ClientDetails':
        this.resetUnsavedChangesPopup();
        break;

      case 'EditClient':
        this.resetUnsavedChangesPopup();
        break;

      case 'Interviews':
        this.resetUnsavedChangesPopup();
        break;

    }
  }

  resetClientIdandEngagementId() {
    this.data.setClientIdForPrePopulate(-1);
    this.data.setEngagementIdForPrePopulate(-1);
  }

  resetUnsavedChangesPopup() {
    const dialogData1 = {
      fromComponent: '',
      isUnsavedChangesExist: false
    };
    const dialogJSON1 = JSON.stringify(dialogData1);
    this.data.setUnsavedChangesModel(dialogJSON1);
  }

  ngOnDestroy() {
    this.modalNewEngDialogRef.unsubscribe();
    this.subCollapsibleLeftNav.unsubscribe();
  }
}
