import { ClientTypesEnum } from './../../../model/Client/client-types.enum';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

// import model classes
import { NewClient, ClientStatus, CMSClient, ClientDialogTitles } from 'src/app/model/Client/NewClient';
import { ClientAdministrators } from 'src/app/model/Client/ClientAdministrators';
import { FirmServiceLines } from 'src/app/model/Client/FirmServiceLines';
import { ClientTypes } from 'src/app/model/Client/ClientTypes';
import { IndustryType } from 'src/app/model/Client/IndustryType';
import { StockExchange } from 'src/app/model/Client/StockExchange';
import { ClientService } from 'src/app/services/clientservice';
import { KeyRelationshipPartners } from 'src/app/model/Client/KeyRelationshipPartners';
import { CountryMaster } from 'src/app/model/CountryMaster';
import { StateMaster } from 'src/app/model/StateMaster';
import { CountryService } from 'src/app/services/country.service';
import { StateService } from 'src/app/services/state.service';
import { ReacceptanceClientsService } from 'src/app/services/reacceptanceClientsService';
import { AzureADUsersArrayList, SearchGroupUserViewModel } from 'src/app/model/AzureADUsersArrayList';
import { CreateClientViewModel, ClientViewModel, AddressLine1Model, TickerSymbolModel, Telephone1Model, SearchClientbyFieldRequestModel, OneViewSearchFieldClientModel, ClientValidationFields, WebSiteModel, BusinessContactEmailModel, BillingContactEmailModel, ClientNameModel, ClientExistDialogTitles, MonthModel, ClientOneViewAccountDetails } from 'src/app/model/Client/CreateClientViewModel';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { AdUsersService } from 'src/app/services/adusers.service';
import { RoleNames } from 'src/app/model/Role';
import { Alert } from 'src/app/model/Alert';
import { AlertsService } from 'src/app/services/alerts.service';
import { DataService } from 'src/app/services/data.service';
import { Applicationmaster, AppMasterCodes, CalendarDays } from 'src/app/model/ApplicationMaster';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/model/User';
import { StructEntity, StructEntityEnum } from 'src/app/model/Client/StructEntity';
import { FieldValidator } from 'src/app/model/Engagement/FieldValidator';
import { MembersList } from 'src/app/model/MembersList';
import { ApplicationMasterService } from 'src/app/services/application-master.service';
import { LogService } from 'src/app/services/log.service';
import { OneViewClientDetailModel } from 'src/app/model/Client/OneViewClientDetail';
//import { OneviewClientSmartSearchComponent } from 'src/app/common/component/oneview-client-smart-search/oneview-client-smart-search.component';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { KeyValue } from 'src/app/model/KeyValue';
import { ClientSearch } from 'src/app/model/Client/ClientSearch';
import { event } from 'jquery';
declare var $;

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.css']
})
export class CreateClientComponent implements OnInit, OnDestroy {
  baseUrl: string;
  // members list
  defaultClient: NewClient = new NewClient();
  client: NewClient = new NewClient();
  cmsClientList: CMSClient[] = [];
  cmsClient: CMSClient = new CMSClient();
  clientStatusList: ClientStatus[] = [];
  clientAdministrator: ClientAdministrators = new ClientAdministrators();
  clientAdministratorsList: ClientAdministrators[] = [];
  firmServiceLines: FirmServiceLines[];
  clientTypes: ClientTypes[] = [];
  clientProgramTypes: Applicationmaster[] = [];
  industryTypes: IndustryType[] = [];
  stockExchangeValues: StockExchange[] = [];
  stockExchangeObjList: StockExchange[] = [];
  structEntities: StructEntity[] = [];
  structEntitiesBind: StructEntity[] = [];
  keyRelationshipPartner: KeyRelationshipPartners = new KeyRelationshipPartners();
  keyRelationshipPartnersList: KeyRelationshipPartners[] = [];
  countries: CountryMaster[];
  states: StateMaster[] = [];
  selectedCountryId: number;
  partnerADUsersList: AzureADUsersArrayList;
  CMSAdminList: AzureADUsersArrayList;
  CMSAdminmembersListArray: MembersList[];
  KRPmembersListArray: MembersList[];
  createClientViewModel: CreateClientViewModel = new CreateClientViewModel();
  clientOneViewAccountIDetails: ClientOneViewAccountDetails = new ClientOneViewAccountDetails();
  alertClient: Alert;
  contactTypes: Applicationmaster[] = [];
  billingContactTypes: Applicationmaster[] = [];


  SICCodeMappedValue: string;
  ReferralSourceMappedValue: string;
  thirtyOneDaysMonths = [1, 3, 5, 7, 8, 10, 12];
  thirtyDaysMonths = [4, 6, 9, 11];
  fyeDays: any[] = [];
  ApplicationMasterData: Applicationmaster = new Applicationmaster();
  user: User;
  IsMSA: boolean = false;
  clientShortNameCount: number = 0;
  // MSAOrELOptions: any = [{ id: true, value: 'MSA' }, { id: false, value: 'Engagement Letter' }];
  MSAList: any = [{ id: 'N/A', value: 'N/A' }, { id: 'GT MSA', value: 'GT' }, { id: 'Custom MSA', value: 'Custom' }];
  // ELetterList: any = [{ id: 'MultiYear EL', value: 'Multi Year' }, { id: 'SingleYear EL', value: 'Single Year' }];
  AgreementTypeList: any = [{ id: false, value: 'No' }, { id: true, value: 'Yes' }];
  arrControlValidation: FieldValidator[] = [];
  objectArray: [string, any][];
  clientIdForEngagementRedirection: string;
  isInvalidClientContactEmail: boolean = false;
  isInvalidClientBillingEmail: boolean = false;
  phoneInvalid: boolean = false;
  isValidStockExchange: boolean = false;
  isValidTickerSymbol: boolean = false
  selectedCMSCAIndex: number;
  selectedKRPIndex: number;
  validateCMSCA: boolean;
  validateKRP: boolean
  isValidWebsiteAddress: boolean = true;
  IsPreviousClientSearchOnEnter: boolean = false;
  businessContactTitleControlIndex: number;
  billingContactTitleControlIndex: number;

  isValidClient: boolean = true;
 // @ViewChild(OneviewClientSmartSearchComponent, { static: false })
  //private clientSearch: OneviewClientSmartSearchComponent;
  MappedValue: string;
  oneViewClientDetailModel: OneViewClientDetailModel = new OneViewClientDetailModel();
  createOneViewClientDetailModel: OneViewClientDetailModel = new OneViewClientDetailModel();
  clientViewModel: ClientViewModel = new ClientViewModel();
  matchingClientList: OneViewSearchFieldClientModel[] = [];
  searchClientbyFieldRequestModel = new SearchClientbyFieldRequestModel('', '', '');
  userObject: any;
  mappedSourceSicValue: string = '';
  mappedSourceReferralValue = '';
  mappedSourceClientNameValue: string = ''
  fiscalYearMonthList: MonthModel[] = [];
  searchOneViewAccountId:string='';
  isStockExchangeData: boolean = true;
  isStockDataRequired: boolean = false;
  isSicCodeData: boolean = true;
  constructor(private auth: AuthService, private http: HttpClient, private route: ActivatedRoute, private loadingScreenService: LoadingScreenService,
    private clientService: ClientService, private reacceptanceClientsService: ReacceptanceClientsService,
    private countryService: CountryService, private stateService: StateService
    , private adUsersService: AdUsersService, private _alertService: AlertsService
    , private dataService: DataService
    , private router: Router
    , private applicationMasterService: ApplicationMasterService
    , private logService: LogService) {
    this.user = auth.getUserInfo();
    this.IsLoggedInUserSystemAdmin();
    this.SICCodeMappedValue = 'CreateClientSICCode';
    this.ReferralSourceMappedValue = 'CreateClientReferralSource';
    this.MappedValue = 'OneViewClientDetail';

    this.dataService.getNewClientName.subscribe(clientName => {
      this.client.clientName = clientName;
    });
    
  this.dataService.getOneViewAccountId.subscribe(oneViewAccountId => {
  this.searchOneViewAccountId = oneViewAccountId;
});
  }


  private modalConfirmationDialogRef: Subscription = null;
  errorMessage: string;
  successMessage = '';
  isSuccessContact = false;
  isSuccessPartners = false;
  showMsg = false;

  partnerNameCtrl: string;
  partnerPhoneCtrl: string;
  partnerEmailCtrl: string;
  requestedClientId: number = 0;
  EditMode: boolean = false;
  currentPartnerListRowIndex: number;
  currentIwantToHeading: string;
  returnUrl: string;
  isCodeMatched = false;
  isSystemAdmin = false;
  KRPCtrl: string;
  CMSAdminCtrl: string;
  adminRoleName: string;
  KRPRoleName: string;
  KRPlblCtrlName: string;
  AdminlblCtrlName: string;
  peoplepickerIndex: number = 0;
  isPersonalClientType: boolean = false;
  private modalNewClientDialogRef: Subscription = null;
  invalidvalues: string;
  clientSearchFeatureFlagEnabled:boolean = false;

  ngOnInit() {
   
    this.getClientSearchFeatureFlag();
  
    this.userObject = JSON.parse(localStorage.getItem('currentUser'));
    this.initiateDefaultValues();
    this.initiateClientViewModel();
   // this.initinateConfirmationPopUp();
    this.client.industryTypeId = 0;
    this.KRPRoleName = RoleNames.KeyRelationshipPartner;
    // purposely keep krp role as we don't have mapping in ad for CMS Client Administrator
    this.adminRoleName = RoleNames.ClientAdministrator;
    this.KRPlblCtrlName = 'key relationship leader';
    this.AdminlblCtrlName = 'CMS client administrator';

    this._alertService.remove(this.alertClient);
    this.baseUrl = this.clientService.getAppEndPoint();
    this.route.paramMap.subscribe(params => {
      this.requestedClientId = +params.get('clientId');
    });
    // this.phoneControl = new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(0)]);
    if (this.requestedClientId !== 0) {
      this.loadingScreenService.startLoading();
      // edit mode true
      this.EditMode = true;
      // fetch given client details and respective reference data(contacts and partners);
      this.GetClientDetailsById(this.requestedClientId);
      this.GetClientAdministratorsByClientId(this.requestedClientId);
      //this.GetKeyRelationshipPartnersByClientId(this.requestedClientId);
      this.GetClientStatusList();

    }

    this.GetCountries();
 
    // this.getSICCodes();

    this.GetClientTypes();
    this.GetStructEntities();
    this.GetClientProgramTypes();
    this.GetIndustryTypes();
    this.GetStockExchangeValues();
    this.GetClientContactTypes();


    // this.GetReferralSources();

    // material autocomplete for dropdowns

    // this.getFilteredDropdowns();

    // this.objectInitialization();

    $('.datepicker').datepicker({
      format: 'DD/MM',
      autoclose: true
    });

    //  $("select").chosen({ allow_single_deselect: true });

    $('#ulPartnerlist li').click(function () {
      $(this).addClass('active');
      alert($(this).html()); // gets innerHTML of clicked li
      alert($(this).text()); // gets text contents of clicked li
      $(this).text();
    });
    if (this.EditMode === false) {
      this.dataService.currentIwantToHeading.subscribe(heading => this.currentIwantToHeading = heading);

      this.dataService.lastPageUrl.subscribe(pageUrl => this.returnUrl = pageUrl);
      //#region Add Client Mode.
      // this.newDynamicContacts = { contactName: '', phoneNumber: '', email: '' };

      // this.dynamicClientContacts.push(this.newDynamicContacts);
      // this.newDynamicPartners = { relationshipPartnerName: '', phoneNumber: '', email: '', partnerRoles: ['Advisory', 'Audit', 'KRP', 'TAX'] };
      // this.dynamicRelationshipPartners.push(this.newDynamicPartners);
      //#endregion
    }
    this.errorMessage = '';

  this.ProcessDialogAction();  


  setTimeout(() => {
    if (this.requestedClientId == 0 && this.getClientSearchFeatureFlag) {
    this.fetchOneviewClientDetailsAndBind(this.searchOneViewAccountId);
    }
  }, 3000);
 

          
    
  } //end of oninit


  initiateDefaultValues() {
    this.client.fyeMonth = 0;
    this.client.stockExchangeId = <any>('undefined');
    this.client.structEntityId = 0;
    this.fiscalYearMonthList = [];
    this.fiscalYearMonthList.push(new MonthModel(1, 'January'));
    this.fiscalYearMonthList.push(new MonthModel(2, 'February'));
    this.fiscalYearMonthList.push(new MonthModel(3, 'March'));
    this.fiscalYearMonthList.push(new MonthModel(4, 'April'));
    this.fiscalYearMonthList.push(new MonthModel(5, 'May'));
    this.fiscalYearMonthList.push(new MonthModel(6, 'June'));
    this.fiscalYearMonthList.push(new MonthModel(7, 'July'));
    this.fiscalYearMonthList.push(new MonthModel(8, 'August'));
    this.fiscalYearMonthList.push(new MonthModel(9, 'September'));
    this.fiscalYearMonthList.push(new MonthModel(10, 'October'));
    this.fiscalYearMonthList.push(new MonthModel(11, 'November'));
    this.fiscalYearMonthList.push(new MonthModel(12, 'December'));
  }
  getClientSearchFeatureFlag() {
    this.clientService.GetClientSearchFeatureFlag().subscribe({
      next: result => {
        this.clientSearchFeatureFlagEnabled = result;
        if(this.clientSearchFeatureFlagEnabled){
          $('.clientCreationDiv').removeClass('hide');
        }
        else{
          $('.oneviewAccountSearchDivMain').removeClass('hide');
        }
      }
      , error: er => {
        this.clientSearchFeatureFlagEnabled = false;
        console.log("failed to load changeOrderFeatureFlag");
      }
    })
  }

  ProcessDialogAction() {
    this.modalNewClientDialogRef = this.dataService.getModalDetails$.subscribe(
      dialogData => {
        if (dialogData) {
          let data = JSON.parse(dialogData)
          let dailogTitle = data.dialogTitle;
          let btnName = data.dialogButtonName;
          if (dailogTitle === ClientDialogTitles.createClient && btnName === 'ok') {
            this.RedirectUser();
          }
        }
      });

  }
  ShowDocDatesinEditMode() {
    $('#dtMSADocDate').datepicker({
      format: 'mm/dd/yyyy',
      autoclose: true,
      startDate: new Date(),
      onSelect: function () {
        this.changeDocDate();
      }
    });

    $('#dtMSAExpiryDate').datepicker({
      format: 'mm/dd/yyyy',
      autoclose: true,
      startDate: new Date(),
      onSelect: function () {
        this.changeExpDate();
      }
    });

  }
  initiateDatePicker() {
    setTimeout(() => {
      this.ShowDocDatesinEditMode();
    }, 1000);
  }

  changeDocDate() {
    setTimeout(() => {
      this.client.msaDocDate = $('#dtMSADocDate').val();
      let docDate = new Date($('#dtMSADocDate').val());
      let ExpiryDate = new Date($('#dtMSAExpiryDate').val());

      if (docDate != null && ExpiryDate != null) {
        if (docDate > ExpiryDate) {
          this._alertService.remove(this.alertClient);
          this._alertService.add('doc date should be less than expiry date', 'danger', null);
        }
      }

    }, 1000);

  }
  changeExpDate() {
    setTimeout(() => {
      let expDate = new Date($('#dtMSAExpiryDate').val());
      let docDate = new Date($('#dtMSADocDate').val());
      this.client.msaExpDate = $('#dtMSAExpiryDate').val();
      if (docDate != null && expDate != null) {
        if (expDate < docDate) {
          this._alertService.remove(this.alertClient);
          this._alertService.add('expiry date should be greater than doc date', 'danger', null);
        }
      }

    }, 1000);

  }

  restrictNumber(): boolean {
    const txtPhone = $('#txtPhone').val();
    let retvalue = false;
    if (txtPhone.length > 0 && txtPhone.length >= 10 && txtPhone.length < 15) {
      const strPhone = txtPhone;
      for (let i = 0; i < strPhone.length; i++) {
        const n = strPhone.charCodeAt(i);
        if (!((n >= 48) && (n <= 57) || (n === 40) || (n === 41) || (n === 45) || (n === 32))) {
          retvalue = true;
          break;
        }
      }
    }

    var invalidPhoneList: any[] = [];

    $('#contactRow').each(function (i, row) {
      var inputVal = $(this).find('td #txtPhone' + i).val();
      if (inputVal.length >= 10 && inputVal.length < 15) {
        for (; i < inputVal.length; i++) {
          let n = inputVal.charCodeAt(i);
          if (!((n >= 48) && (n <= 57) || (n === 40) || (n === 41) || (n === 45))) {
            invalidPhoneList.push(inputVal);
          }
        }
      }
    });

    $('#partnerRow').each(function (i, row) {
      var inputVal = $(this).find('td #txtPartnerPhone' + i).val();
      if (inputVal.length >= 10 && inputVal.length < 15) {
        for (; i < inputVal.length; i++) {
          let n = inputVal.charCodeAt(i);
          if (!((n >= 48) && (n <= 57) || (n === 40) || (n === 41) || (n === 45))) {
            invalidPhoneList.push(inputVal);
          }
        }
      }
    });

    if (invalidPhoneList.length > 0 || retvalue === true) {
      return true;
    } else {
      return false;
    }

  }
  // multiple contacts and partners has been depricated in wave1 new UI.
  /*
    // create dynamic rows for client contacts table
    addContactRow() {
      this._alertService.remove(this.alertClient);
      if (this.dynamicClientContacts.length > 10) {
        this.errorMessage = 'Cannot add more than 10 records';
        this._alertService.remove(this.alertClient);
        this._alertService.add(this.errorMessage, 'danger', null);
        document.documentElement.scrollTop = 0;
        this.isSuccessContact = false;
        this.showMsg = true;
        return false;
      }
      this.newDynamicContacts = { contactName: '', phoneNumber: '', email: '' };
      this.dynamicClientContacts.push(this.newDynamicContacts);
      // this.successMessage = 'New row added successfully';
      console.log(this.dynamicClientContacts);
      this.isSuccessContact = true;
      return true;
    }
    addPartnerRow() {
      this._alertService.remove(this.alertClient);
      if (this.dynamicRelationshipPartners.length > 10) {
        this.errorMessage = 'Cannot add more than 10 records';
        this._alertService.remove(this.alertClient);
        this._alertService.add(this.errorMessage, 'danger', null);
        document.documentElement.scrollTop = 0;
        this.isSuccessPartners = false;
        this.showMsg = true;
        return false;
      }
      this.newDynamicPartners = { relationshipPartnerName: '', email: '', phoneNumber: '', partnerRoles: ['Advisory', 'Audit', 'KRP', 'TAX'] };
      this.dynamicRelationshipPartners.push(this.newDynamicPartners);
      // this.successMessage = 'New row added successfully';
      console.log(this.dynamicRelationshipPartners);
      this.isSuccessPartners = true;
      return true;
    }
    deleteContactRow(index) {
      if (this.dynamicClientContacts.length === 1) {
        this.CloseAlert();
        this.ShowAlert();
        this.errorMessage = 'Cannot delete the row when there is only one row';
        this.isSuccessContact = false;
        this.showMsg = true;
        this._alertService.remove(this.alertClient);
        this._alertService.add(this.errorMessage, 'danger', null);
        document.documentElement.scrollTop = 0;
        return false;
      } else {
        this.dynamicClientContacts.splice(index, 1);
        // this.successMessage = 'Row(s) deleted successfully';
        this.isSuccessContact = true;
        this.showMsg = true;
        return true;
      }
    }
    deletePartnerRow(index) {
      if (this.dynamicRelationshipPartners.length === 1) {
        this.CloseAlert();
        this.ShowAlert();
        this.errorMessage = 'Cannot delete the row when there is only one row';
        this.isSuccessPartners = false;
        this.showMsg = true;
        this._alertService.remove(this.alertClient);
        this._alertService.add(this.errorMessage, 'danger', null);
        document.documentElement.scrollTop = 0;
        return false;
      } else {
        this.dynamicRelationshipPartners.splice(index, 1);
        // this.successMessage = 'Row(s) deleted successfully';
        this.isSuccessPartners = true;
        return true;
      }
    }
    */

  refreshCMSAdminPopup(index, textboxName: string) {
    this.removeRowSelection('tblAssAdminAD', 'searchCSMClientAdminName', 'AssignmentAdminErrorMessage');
    this.CMSAdminCtrl = textboxName + this.adminRoleName + '_' + this.peoplepickerIndex;
    this.validateCMSCA = false;
    this.CMSAdminmembersListArray = null;
  }

  GetCMSClientAdmins() {
    this.loadingScreenService.startLoading();
    // this.adUsersService.getAssigmentAdminList()
    this.adUsersService.GetAzureADGroupsByRoleShortName(RoleNames.ClientAdministrator)
      .subscribe(
        res => {
          this.CMSAdminList = res;
          this.loadingScreenService.stopLoading();
          this.adUsersService.getAzureAdUsersByGroups(this.CMSAdminList, RoleNames.ClientAdministrator)
            .subscribe(
              userList => {
                this.loadingScreenService.stopLoading();
                //this.CMSAdminmembersListArray = userList;
              });
        },
        () => {
          this.CMSAdminList = null;
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        }
      );

  }

  searchUsers(searchControl, tableId: string) {
    const searchText = $('#' + searchControl).val();
    this.GetRelationshipPartnersFromAD(searchText);
    /*
    $('#' + event.currentTarget.id).keyup(function () {
      const searchText = this.value.toLowerCase().trim();

      $('#' + tableId + ' tr').each(function (index) {
        if (!index) return;
        $(this).find('td').each(function () {
          var id = $(this).text().toLowerCase().trim();
          var not_found = (id.indexOf(searchText) === -1);
          $(this).closest('tr').toggle(!not_found);
          return not_found;
        });
      });
    });
    */
  }

  setCMSACAdmin() {
    let index = this.selectedCMSCAIndex;
    if (Number(index) >= 0) {

      const selectedAdminName = $('#spAdmindisplayName' + index).text();
      const selectedAdminEmail = $('#spAdminmail' + index).text();
      const selectedAdminPhone = $('#spAdminbusinessPhones' + index).text();
      const selectedAdminJobTitle = $('#spAdminjobtitle' + index).text();
      // const selectedAdminLineOfBusiness = $('#spAdminlineOfBusiness' + index).text();
      // const selectedAdminServiceLine = $('#spAdminserviceLine' + index).text();
      // const selectedspAdminLocation = $('#spAdminlocation' + index).text();
      this.clientAdministrator.contactName = selectedAdminName;
      this.clientAdministrator.email = selectedAdminEmail;
      this.clientViewModel.cmsClientAdministratorEmail = selectedAdminEmail;
      this.clientViewModel.cmsClientAdministratorFullname = selectedAdminName;
      this.clientAdministrator.phoneNumber = selectedAdminPhone;
      this.clientAdministrator.jobTitle = selectedAdminJobTitle;
      this.clientAdministratorsList.push(this.clientAdministrator);

      $('#' + this.CMSAdminCtrl).val(selectedAdminName);
      $('#UserHiddenProps_CLA_0 #hdnUserName').val(selectedAdminName);
      $('#UserHiddenProps_CLA_0 #hdnUserEmail').val(selectedAdminEmail);
      $('#UserHiddenProps_CLA_0 #hdnUserPhone').val(selectedAdminPhone);
      $('#UserHiddenProps_CLA_0 #hdnJobTitle').val(selectedAdminJobTitle);
      if ($('#hdnValidInput_CLA_0').val()) {
        $('#AdminValid').removeClass('d-block');
        $('#AdminValid').addClass('d-none');
      }

      this.validateCMSCA = true;
    }
    this.CMSAdminmembersListArray = null;
    this.KRPmembersListArray = null;

  }

  selectAdmin(index) {
    $('#tblAssAdminAD').on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    this.selectedCMSCAIndex = index;
  }

  refreshModalPartnersList(index: number, textboxName: string) {
    this.removeRowSelection('tblKRPAD', 'searchPartnerName', 'PartnerNameErrorMessage');
    index = this.peoplepickerIndex;
    console.log('calling refresh function to get Partners data');

    this.KRPCtrl = textboxName + this.KRPRoleName + '_' + this.peoplepickerIndex;
    this.currentPartnerListRowIndex = index;
    this.validateKRP = false;
    this.KRPmembersListArray = null;
  }

  setPartner() {
    let index = this.selectedKRPIndex;
    if (Number(index) >= 0) {
      const selectedPartnerName = $('#spdisplayName' + index).text();
      const selectedPartnerEmail = $('#spmail' + index).text();
      const selectedPartnerPhone = $('#spbusinessPhones' + index).text();
      const selectedJobTitle = $('#spjobtitle' + index).text();

      this.keyRelationshipPartner.relationshipPartnerName = selectedPartnerName;
      this.keyRelationshipPartner.email = selectedPartnerEmail;
      this.keyRelationshipPartner.phoneNumber = selectedPartnerPhone;
      this.keyRelationshipPartner.jobTitle = selectedJobTitle;
      this.keyRelationshipPartnersList.push(this.keyRelationshipPartner);
      $('#' + this.KRPCtrl).val(selectedPartnerName);
      $('#UserHiddenProps_KRP_0 #hdnUserName').val(selectedPartnerName);
      $('#UserHiddenProps_KRP_0 #hdnUserEmail').val(selectedPartnerEmail);
      $('#UserHiddenProps_KRP_0 #hdnUserPhone').val(selectedPartnerPhone);
      $('#UserHiddenProps_CLA_0 #hdnJobTitle').val(selectedJobTitle);
      //$('#hdnValidInput_KRP' + '_' + this.peoplepickerIndex).val(true);
      this.validateKRP = true;
    }
    this.keyRelationshipPartnersList = null;
  }

  selectPartner(index, event) {
    $('#tblKRPAD').on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    this.selectedKRPIndex = index;
  }

  deselectPartner() {
    // $('#' + this.partnerNameCtrl).val('');
    this.keyRelationshipPartnersList = null;
    this.selectedKRPIndex = null;
  }

  deselectCMSAdmin() {
    this.CMSAdminmembersListArray = null;
    this.selectedCMSCAIndex = null;
  }

  GetClientDetailsById(requestedClientId: number) {
    this.clientService.GetClientDetailsById(requestedClientId).subscribe(result => {
      this.client = result;
      this.loadingScreenService.stopLoading();
      console.log('recieved client info =>', this.client);
      if (this.EditMode === true) {
        this.GetStateMasters();
        $('#txtFYE').val(this.client.fye);
        $("#txtFax").val(this.client.fax);
        $('#dtMSADocDate').val(this.client.msaDocDate);
        $('#dtMSAExpiryDate').val(this.client.msaExpDate);
        if (this.client.fyeMonth > 0) {
          this.SelectDay(this.client.fyeMonth);
        }
        if (this.client.clientTypeId > 0) {
          this.StructEntityChange(this.client.structEntityId, true);
        }
        $('#txtValue' + this.SICCodeMappedValue + 0).val(this.client.sicCode);
        $('#hdnKey' + this.SICCodeMappedValue + 0).val(this.client.sicCodeId);
        $('#txtValue' + this.ReferralSourceMappedValue + 0).val(this.client.referralSource);
        $('#hdnKey' + this.ReferralSourceMappedValue + 0).val(this.client.referralSourceId);
        if (this.isSystemAdmin === true) {
          if (this.client.msaType === null) {
            this.client.msaType = 'N/A';
          }
        }

        // this.restrictNumber();
      }
    },
      error => {
        this.client = null;
      });
  }

  GetClientAdministratorsByClientId(requestedClientId: number) {
    this.clientService.GetClientAdministratorsByClientId(requestedClientId).subscribe(result => {
      this.clientAdministratorsList = result;
      console.log('received contactsList info =>', this.clientAdministratorsList);
      // call method to create new DynamicContacts objects and
      // push it into dynamicClientContacts array.
      //this.CreateControlsForContacts();
    },
      error => {
        this.clientAdministratorsList = null;
      });
  }
  GetKeyRelationshipPartnersByClientId(requestedClientId: number) {
    this.clientService.GetKeyRelationshipPartnersByClientId(requestedClientId).subscribe(result => {
      this.keyRelationshipPartnersList = result;
      console.log('received partnerList info =>', this.keyRelationshipPartnersList);
      // call method to create new DynamicPartners objects and
      // push it into dynamicRelationshipPartners array
      // this.CreateControlsForPartners();
    },
      error => {
        this.keyRelationshipPartnersList = null;
      });
  }
  /* Below Code multiple contacts and partners has been depricated due to wave1 new UI
  // methods to create dynamic arrays(contacts, partners) to make editable html controls on the fly.
  CreateControlsForContacts() {
    if (this.EditMode === true) {
      //#region Edit Client Mode.
      // iterate over fetched clientContactList and create new DynamicContacts objects and
      // push it into dynamicClientContacts array.
      for (let i = 0; i < this.clientContactList.length; i++) {
        this.newDynamicContacts = {
          contactName: this.clientContactList[i].contactName,
          phoneNumber: this.clientContactList[i].phoneNumber,
          email: this.clientContactList[i].email
        };
        this.dynamicClientContacts.push(this.newDynamicContacts);
      }
      //#endregion
    }
  }

  CreateControlsForPartners() {
    if (this.EditMode === true) {
      //#region Edit Client Mode.
      // iterate over fetched keyRelationshipPartnersList and create new DynamicPartners objects and
      // push it into dynamicRelationshipPartners array
      for (let i = 0; i < this.keyRelationshipPartnersList.length; i++) {
        this.newDynamicPartners = {
          relationshipPartnerName: this.keyRelationshipPartnersList[i].relationshipPartnerName,
          relationshipPartnerRole: this.keyRelationshipPartnersList[i].relationshipPartnerRole,
          email: this.keyRelationshipPartnersList[i].email,
          phoneNumber: this.keyRelationshipPartnersList[i].phoneNumber,
          partnerRoles: ['Advisory', 'Audit', 'KRP', 'TAX']
        };
        this.dynamicRelationshipPartners.push(this.newDynamicPartners);
      }
      //#endregion
    }
    setTimeout(() => {
      this.ShowDocDatesinEditMode();
    }, 500);

  }
  */

  GetRelationshipPartnersFromAD(searchTerm: string) {
    this.loadingScreenService.startLoading();
    let searchModel = new SearchGroupUserViewModel();
    searchModel.user = new MembersList();
    searchModel.user.displayName = searchTerm;
    this.adUsersService.GetUserInformationByDisplayName(searchModel)
      .subscribe(
        userList => {
          this.loadingScreenService.stopLoading();
          this.KRPmembersListArray = userList;
          this.CMSAdminmembersListArray = userList;
        },
        (error) => {

          this.KRPmembersListArray = null;
          this.CMSAdminmembersListArray = null;
          this.loadingScreenService.stopLoading();
          console.log('Error in getting response GetRelationshipPartners func ', error);
        });
    /*
    this.adUsersService
      .GetRelationshipPartners()
      .subscribe(
        partnerList => {
          this.partnerADUsersList = partnerList;
          this.adUsersService.getAzureAdUsersByGroups(this.partnerADUsersList, RoleNames.KeyRelationshipPartner)
            .subscribe(
              userList => {
                this.KRPmembersListArray = userList;
                this.CMSAdminmembersListArray = userList; // temporary adjustment as we don't have mapping in AD
                this.loadingScreenService.stopLoading();
              });

        },
        error => {
          this.partnerADUsersList = null;
          console.log('Error in getting response GetRelationshipPartners func ', error);
        }
      );
      */

  }
  getCMSClientByName(clientName: string) {
    // check given client name in CMS

    this.clientService.GetCMSClientDetailsByName(clientName).subscribe(result => {
      this.cmsClientList = result;
    });

  }
  GetEDPClientDetailsByName(clientName: string): number {
    let clientId = 0;
    let clientSearchModel = new ClientSearch();
    clientSearchModel.clientName = clientName;

    this.clientService.GetEDPClientDetailsByName(clientSearchModel).subscribe(result => {
      if (result.clientId > 0) {
        clientId = result.clientId;
      }
    });
    return clientId;
  }

  // add client data along with dependent info.
  AddNewClient() {
if( this.clientSearchFeatureFlagEnabled &&(this.client.oneViewAccountId== null || this.client.oneViewAccountId==undefined|| this.client.oneViewAccountId==''))
    {/*this.errorMessage = 'OneView account id is not linked ';
    this._alertService.remove(this.alertClient);
    this._alertService.add(this.errorMessage, 'danger', null);*/
    this.displayDialog('warning', ClientDialogTitles.OneViewAccountLinkage, ClientDialogTitles.OneViewAcctLinkageDesc, 1,false);
    }
    else{
    this.mandatoryFieldInitialization();
    let clientExists = false;
    let isEmptyCMSAdmin: boolean = false;
    let isInvalidCMSAdmin: boolean = false;
    //this.setClientData();
    let clientMiddleNameLength: number = 0;
    let isEmptyStockEx: boolean = false;
    let CMSAdminValue = $('#txtPeoplePicker_' + this.adminRoleName + '_' + this.peoplepickerIndex).val();
    if (CMSAdminValue === '' || CMSAdminValue === undefined) {
      $('#AdminRequired').removeClass('d-none');
      $('#AdminRequired').addClass('d-block');
      $('#AdminValid').addClass('d-none');
      isEmptyCMSAdmin = true;
    } else {
      $('#AdminRequired').removeClass('d-block');
      $('#AdminRequired').addClass('d-none');
    }

    if (!isEmptyCMSAdmin && $('#hdnValidInput_CLA' + '_' + this.peoplepickerIndex).val() === 'false') {
      $('#AdminValid').removeClass('d-none');
      $('#AdminValid').addClass('d-block');
      $('#AdminRequired').addClass('d-none');
      isInvalidCMSAdmin = true;
    } else {
      $('#AdminValid').removeClass('d-block');
      $('#AdminValid').addClass('d-none');
    }

    if (this.client.middleName === undefined || this.client.middleName === null || this.client.middleName === '') {
      clientMiddleNameLength = 0
    } else {
      clientMiddleNameLength = this.client.middleName.length;
    }

    if (this.isPersonalClientType === false &&  ((this.isCodeMatched &&!this.clientSearchFeatureFlagEnabled)||this.clientSearchFeatureFlagEnabled)) {
      if (this.client.stockExchangeId == -1 && (!this.clientSearchFeatureFlagEnabled || (this.clientSearchFeatureFlagEnabled && this.isStockDataRequired && !this.isStockExchangeData))) {
        isEmptyStockEx = true;
        $('#stockExchangeRequired').removeClass('d-none');
        $('#stockExchangeRequired').addClass('d-block');
      } else {
        $('#stockExchangeRequired').removeClass('d-block');
        $('#stockExchangeRequired').addClass('d-none');
      }
    } // end if isPersonalClientType = false

    if (this.checkMandatoryValidations(this.arrControlValidation, this.client) &&
      !isEmptyCMSAdmin && !isInvalidCMSAdmin && !isEmptyStockEx) {
      this.loadingScreenService.startLoading();
      if (this.isPersonalClientType) {
        if (clientMiddleNameLength > 0) {
          this.client.clientName = this.client.lastName + ', ' + this.client.firstName + ' ' + this.client.middleName;
        }
        else {
          this.client.clientName = this.client.lastName + ', ' + this.client.firstName;
        }
      }

      let clientSearchModel = new ClientSearch();
      clientSearchModel.clientName = this.client.clientName;
    if (!this.clientSearchFeatureFlagEnabled)
    {
      this.clientService.GetEDPClientDetailsByName(clientSearchModel).subscribe(objClient => {
        if (!this.EditMode ) {
          if (objClient !== null) {
            console.log('Given client exists in EDP', objClient.clientName);
            if (objClient.clientId > 0) {
              clientExists = true;
              this.BusinessValidationForClient(clientExists);
            } else {
              this.clientService.GetCMSClientDetailsByName(this.client.clientName).subscribe(result => {
                this.cmsClientList = result;
                if (this.cmsClientList != null || this.cmsClientList !== undefined) {
                  if (this.cmsClientList.length > 0) {
                    for (var i = 0; i < this.cmsClientList.length; i++) {
                      if (this.cmsClientList[i].client_name.toLowerCase() === this.client.clientName.toLowerCase()) {
                        clientExists = true;
                      }
                    } // end for loop
                  }// end length >0
                }
              });
            }
          } else {
            this.BusinessValidationForClient(clientExists);
          }
        } // end if editmode==false

      }); 
    }
    else {
      if (!this.EditMode ) {this.BusinessValidationForClient(clientExists);}

    }
    }// end subscribe GetCMSClientDetailsByName
    }

  } // end add new client()

  // get master data for creating a client.
  GetCountries() {
    this.countryService.GetCountries().subscribe(result => {
      this.countries = result;
      if (this.countries.length > 0) {
        let countryIndex = this.countries.findIndex(country => country.countryCode.trim().toUpperCase() === 'USA');
        this.countries.splice(0, 0, this.countries.splice(countryIndex, 1)[0]);
      }
    },
      error => {
        this.countries = null;
      });
  }
  GetStateMasters() {
    this.states = [];
    this.stateService.GetStateMasters(this.client.countryId).subscribe(result => {
      this.states = result;
    },
      error => { this.states = null; });
  }


  GetClientStatusList() {
    this.clientService.GetClientStatus().subscribe(
      result => {
        this.clientStatusList = result;
        this.clientStatusList.splice(0, 1);
      },
      error => {
        this.clientStatusList = null;
      }
    );

  }
  GetClientTypes() {
    this.clientService.GetClientTypes().subscribe(
      result => {
        this.clientTypes = result;
        this.clientTypes.forEach(element => {
          if (element.typeValue === ClientTypesEnum.Organization) {
            this.client.clientTypeId = element.id;
            this.client.clientType = element.typeValue;
          }
        });
        // document.getElementById('ddClientType').addEventListener('change', (e) => {
        //   this.ClientTypeChange();
        //});
      },
      error => {
        this.clientTypes = null;
      }
    );
  }
  GetClientProgramTypes() {
    this.applicationMasterService.GetAllClientPrograms().subscribe(
      result => {
        this.clientProgramTypes = this.sorClientProgramTypes(result);
        this.clientProgramTypes.forEach(element => {
          if (element.value === '') {
            this.client.clientProgramId = element.applicationMasterId;
            this.client.clientProgram = element.code;
          }
        });
      },
      error => {
        this.clientProgramTypes = null;
      }
    );
  }

  sorClientProgramTypes(clientProgramTypes: Applicationmaster[]) {
    return clientProgramTypes.sort((a, b) => {
      if (a.displayOrder < b.displayOrder)
        return -1;
      if (a.displayOrder > b.displayOrder)
        return 1;
      return 0;
    });
  }

  GetIndustryTypes() {
    this.clientService.GetIndustryTypes().subscribe(
      result => {
        this.industryTypes = result;
      },
      error => {
        this.industryTypes = null;
      }
    );
  }
  GetStockExchangeValues() {
    this.clientService.GetStockExchangeValues().subscribe(
      result => {
        this.stockExchangeObjList = result;
        if (this.stockExchangeObjList != undefined && this.stockExchangeObjList.length > 0) {
          result.forEach(element => {
            let stockExObj = new StockExchange();
            let elementArr: string[] = element.typeValue.split('|');
            stockExObj.id = element.id;
            stockExObj.typeValue = elementArr[1];
            this.stockExchangeValues.push(stockExObj);
          });
        }
        let stockExchangeObj = new StockExchange();
        stockExchangeObj.id = -1;
        stockExchangeObj.typeValue = 'Select a stock exchange';
        this.stockExchangeValues.unshift(stockExchangeObj);
      },
      error => {
        this.stockExchangeValues = null;
      }
    );
  }
  GetStructEntities() {
    this.clientService.GetStructEntities().subscribe(
      result => {
        this.structEntities = result;
      },
      error => {
        this.structEntities = null;
      }
    );

    this.structEntitiesBind=this.structEntities 
  };


  // end get master data for creating a client.
  searchPartnerNames(searchControlName: string) {
    const searchText = $('#' + searchControlName).val();
    this.GetRelationshipPartnersFromAD(searchText);
    /*
    $('#' + event.currentTarget.id).keyup(function () {
      const searchText = this.value.toLowerCase().trim();

      $('#tblKRPAD tr').each(function (index) {
        if (!index) return;
        $(this).find('td').each(function () {
          var id = $(this).text().toLowerCase().trim();
          var not_found = (id.indexOf(searchText) === -1);
          $(this).closest('tr').toggle(!not_found);
          return not_found;
        });
      });
    });
    */
  }


  clearSearch() {
    $('#searchPartnerName').val('');
  }



  SelectDay(selectedMonth: number) {

    if (this.thirtyOneDaysMonths.includes(Number(selectedMonth))) {

      this.fyeDays = [];
      for (let i = 1; i <= 31; i++) {
        let calendarDays = new CalendarDays();
        calendarDays.id = i;
        calendarDays.value = i;
        this.fyeDays.push(calendarDays);
      }

    } else
      if (this.thirtyDaysMonths.includes(Number(selectedMonth))) {

        this.fyeDays = [];
        for (let i = 1; i <= 30; i++) {
          let calendarDays = new CalendarDays();
          calendarDays.id = i;
          calendarDays.value = i;
          this.fyeDays.push(calendarDays);
        }

        return true;

      } else if (selectedMonth === 2) {
        if (this.IsLeapYear(new Date().getFullYear()) === true) {

          this.fyeDays = [];
          for (let i = 1; i <= 29; i++) {
            let calendarDays = new CalendarDays();
            calendarDays.id = i;
            calendarDays.value = i;
            this.fyeDays.push(calendarDays);
          }

          return true;
        }
        if (this.IsLeapYear(new Date().getFullYear()) === false) {


          this.fyeDays = [];
          for (let i = 1; i <= 28; i++) {
            let calendarDays = new CalendarDays();
            calendarDays.id = i;
            calendarDays.value = i;
            this.fyeDays.push(calendarDays);
          }

          this.fyeDays.splice(28, 1);
          return true;
        }
      }

  }
  ValidateDay(selectedMonth, event) {
    console.log(event.target.textContent);

  }
  IsLeapYear(year) {
    return (year % 100 === 0) ? (year % 400 === 0) : (year % 4 === 0);
  }

  Cancel() {
    // redirect to previous page.
    this.dataService.setIsRedirectedFromCreateClient('true');
    // this.dataService.setIwantToHeadingForClient(this.currentIwantToHeading);
    if (this.returnUrl != null || this.returnUrl != undefined) {
      if (this.returnUrl === 'returnUrl') {
        this.router.navigate(['/MyClients']);
      } else { this.router.navigate([this.returnUrl]); }
    } else {
      this.router.navigate(['/MyClients']);
    }
  }
  CloseAlert() {
    $('#btnCloseAlert').attr('style', 'display:none');
    $('#warnMsg').attr('style', 'display:none');
  }
  ShowAlert() {
    $('#btnCloseAlert').attr('style', 'display:block');
    $('#warnMsg').attr('style', 'display:block');
  }

  StructEntityChange(structEntityId: number, isRemoveTicketSymbol) {
    this.clientService.GetApplicationMasterDataById(structEntityId).subscribe(
      result => {
        this.ApplicationMasterData = result;
        if (this.ApplicationMasterData != null
          && (this.ApplicationMasterData.code === AppMasterCodes.PUBCO.toString() ||
            this.ApplicationMasterData.code === AppMasterCodes.SECAU.toString())) {
          if (this.EditMode === false) {

            
              if(!this.clientSearchFeatureFlagEnabled)
              {
            $('#txtTicketSymbol').removeAttr('disabled');           
            $('#ddStockVal').removeAttr('disabled');
              }

            $('#iconDupeCheckTicketSymbol').removeClass('disabled');
            if (isRemoveTicketSymbol) {
              if(!this.clientSearchFeatureFlagEnabled)
              {
              this.client.stockExchangeId = -1;
              $('#txtTicketSymbol').val('');
              }

            }
          } else if (this.EditMode === true) {
            if (this.client.stockExchangeId == null || this.client.stockExchangeId === undefined) {
              this.client.stockExchangeId = -1;
            }
          }

          this.isCodeMatched = true;
        } else {
          this.isCodeMatched = false;
          if (this.EditMode === false) {
            if (this.client.stockExchangeId == null || this.client.stockExchangeId != null) {
              if(!this.clientSearchFeatureFlagEnabled)
                {
              this.client.stockExchangeId = <any>('undefined');
                }
              if(!this.clientSearchFeatureFlagEnabled)
                {
              $('#txtTicketSymbol').val('');
                }
            }
          } else if (this.EditMode === true) {
            if (this.client.stockExchangeId == null) {
              this.client.stockExchangeId = <any>('undefined');
            } else if (this.client.stockExchangeId != null) {
              this.client.stockExchangeId = <any>('undefined');
              $('#txtTicketSymbol').val('');
            }
            // this.client.stockExchangeId = -1;
            $('#txtTicketSymbol').val('');
          }    if(!this.clientSearchFeatureFlagEnabled)
            {
          $('#txtTicketSymbol').attr('disabled', 'disabled');
          $('#iconDupeCheckTicketSymbol').addClass('disabled');
      
          this.clientViewModel.tickerSymbolModel = JSON.parse(JSON.stringify(new TickerSymbolModel('', '', '')));
            }
          this.setIsFieldExistsInOneView(ClientValidationFields.TickerSymbol, false);
          this.SetCanShowDuplicateNotFoundMessage(ClientValidationFields.TickerSymbol, false);

        }
      },
      error => {
        this.ApplicationMasterData = null;
      }
    

    );
    if(this.clientSearchFeatureFlagEnabled)
    {

    let selectedStruct: StructEntity[] = [];      
    selectedStruct=  this.structEntitiesBind.filter(x => (x.id==structEntityId));
    if (selectedStruct!=null && selectedStruct.length > 0 )
    {
      if(selectedStruct[0].typeValue.toLocaleLowerCase() ==StructEntityEnum.PublicSEC.trim().toLowerCase() || selectedStruct[0].typeValue.toLocaleLowerCase() ==StructEntityEnum.Public.trim().toLowerCase() )
{
  this.isStockDataRequired=true
}
else
{
  this.isStockDataRequired=false
}
    }

    }
    
    
    
  }
  ValidateStock() {
    if (!this.clientSearchFeatureFlagEnabled)
    {
    if ((this.client.stockExchangeId < 0 && this.EditMode === false)
      || (this.EditMode === true && this.isCodeMatched === true)
      || (this.client.stockExchangeId > 0 && this.isCodeMatched === true)) {
      this.isValidStockExchange = false;
      this.isValidTickerSymbol = false;
      return false;
    } else {
      this.isValidStockExchange = true;
      this.isValidTickerSymbol = true;
      return true;
    }
  }
  else if (this.clientSearchFeatureFlagEnabled)
      {
        return this.isStockExchangeData
    }
  }

  IsLoggedInUserSystemAdmin() {
    for (var i = 0, l = this.user.role.length; i < l; i++) {
      if (this.user.role[i].shortRoleName == RoleNames.SystemAdministrator) {
        this.isSystemAdmin = true;
      } else {
        this.isSystemAdmin = false;
      }
    }
  }



  removeRowSelection(tableId, textboxId, errorMessageId) {
    $('#' + textboxId).val('');
    $("#" + errorMessageId).text('');
    var searchText = $('#' + textboxId).val().toLowerCase().trim();
    this.searchResult(tableId, searchText);
    $('#' + tableId + ' tr').each(function () {
      if ($(this).hasClass('highlight')) {
        $(this).removeClass('highlight');
      }
    });
  }

  searchResult(tableId, searchText) {
    $('#' + tableId + ' tr').each(function (index) {
      if (!index) return;
      $(this).find('td').each(function () {
        var id = $(this).text().toLowerCase().trim();
        var not_found = (id.indexOf(searchText) === -1);
        $(this).closest('tr').toggle(!not_found);
        return not_found;
      });
    });
  }

  checkCharCodeForNumber(event) {
    if (((event.keyCode >= 48) && (event.keyCode <= 57) || (event.keyCode === 40) || (event.keyCode === 41) || (event.keyCode === 45) || (event.keyCode === 32))) {
      return true;
    }
    return false;
  }
  checkSpcialChar(event) {
    if (!((event.keyCode >= 65) && (event.keyCode <= 90) || (event.keyCode >= 97) && (event.keyCode <= 122) || (event.keyCode >= 48) && (event.keyCode <= 57))) {
      return false;
    }
    return true;
  }

  checkSpaceChartBegin(event) {
    const text = event.currentTarget.value.trim();
    if (text.length === 0) {
      if (event.target.id === 'txtClientName') {
        this.client.clientName = null;
      } else if (event.target.id === 'txtTicketSymbol') {
        this.client.tickerSymbol = null;
      } else if (event.target.id === 'txtaddressLine1') {
        this.client.addressLine1 = null;
      } else if (event.target.id === 'txtaddressLine2') {
        this.client.addressLine2 = null;
      } else if (event.target.id === 'txtaddressLine3') {
        this.client.addressLine3 = null;
      } else if (event.target.id === 'txtaddressLine4') {
        this.client.addressLine4 = null;
      } else if (event.target.id === 'txtCity') {
        this.client.city = null;
      } else if (event.target.id === 'txtFax') {
        this.client.fax = null;
      } else if (event.target.id === 'txtWebsite') {
        this.client.websiteAddress = null;
      } else if (event.target.id === 'txtClientShortName') {
        this.client.clientShortName = null;
      } else if (event.target.id === ' txtBusinessContactName') {
        this.client.clientContactName = null;
      } else if (event.target.id === ' txtBusinessContactEmail') {
        this.client.clientContactEmail = null;
      }
      return false;
    } else if (text.length > 0) {
      if (event.target.id === 'txtClientName') {
        this.client.clientName = text;
        this.validateClientField(ClientValidationFields.ClientName, true, true);
      } else if (event.target.id === 'txtTicketSymbol') {
        this.client.tickerSymbol = text;
        this.validateClientField(ClientValidationFields.TickerSymbol, true, false);
      } else if (event.target.id === 'txtaddressLine1') {
        this.client.addressLine1 = text;
        this.validateClientField(ClientValidationFields.Address, true, false);
      } else if (event.target.id === 'txtaddressLine2') {
        this.client.addressLine2 = text;
      } else if (event.target.id === 'txtaddressLine3') {
        this.client.addressLine3 = text;
      } else if (event.target.id === 'txtaddressLine4') {
        this.client.addressLine4 = text;
      } else if (event.target.id === 'txtCity') {
        this.client.city = text;
      } else if (event.target.id === 'txtFax') {
        this.client.fax = text;
      } else if (event.target.id === 'txtWebsite') {
        this.client.websiteAddress = text;
        this.validateClientField(ClientValidationFields.WebSite, true, false);
      } else if (event.target.id === 'txtClientShortName') {
        this.client.clientShortName = text;
      } else if (event.target.id === 'txtBusinessContactEmail') {
        this.client.clientContactEmail = text;
        this.validateClientField(ClientValidationFields.BusinessContactEmail, true, false);
      } else if (event.target.id === 'txtBusinessContactName') {
        this.client.clientContactName = text;
      } else if (event.target.id === 'txtBillingcontactEmail') {
        this.client.clientBillingEmail = text
        this.validateClientField(ClientValidationFields.BillingContactEmail, true, false);
      }
    } else { return true; }
  }


  checkSpaceChartBeginInTableRow(event, i, recordListName: string) {
    const text = event.currentTarget.value.trim();
    if (text.length === 0) {
      // if (recordListName === 'contacts') {
      //   if (event.target.id === 'txtContactName' + i) {
      //     this.dynamicClientContacts[i].contactName = null;
      //   } else if (event.target.id === 'txtEmail' + i) {
      //     this.dynamicClientContacts[i].email = null;
      //   }
      //   return false;
      // }
      // if (recordListName === 'partners') {
      //   if (event.target.id === 'txtPartnerPhone' + i) {
      //     this.dynamicRelationshipPartners[i].phoneNumber = null;
      //     if (((event.keyCode >= 48) && (event.keyCode <= 57))) {
      //       return false;
      //     }
      //   }
      //   return false;
      // }
      return false;
    } else if (text.length > 0) {
      // if (event.target.id === 'txtContactName' + i) {
      //   this.dynamicClientContacts[i].contactName = text;
      // } else if (event.target.id === 'txtEmail' + i) {
      //   this.dynamicClientContacts[i].email = text;
      // }
    } else { return true; }
    return true;
  }

  isClientShortNameExists() {
    let retvalue: boolean = false;
    this.loadingScreenService.startLoading();
    this.clientService.isClientShortNameExists(this.client.clientShortName).subscribe(
      res => {
        this.clientShortNameCount = res;
        if (this.clientShortNameCount === 0) {
          this._alertService.remove(this.alertClient);
        } else {
          this._alertService.remove(this.alertClient);
          this._alertService.add('Client Short Name already exists.', 'danger', null);
          retvalue = true;
        }
        this.loadingScreenService.stopLoading();
      },
      error => {
        retvalue = true;
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
    return retvalue;
  }

  alertServiceFun(message, type) {
    this._alertService.remove(this.alertClient);
    this._alertService.add(message, type, null);
    // document.body.scrollTop = 0; // For Safari
    // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  onClientTypeChange() {
    if (!this.clientSearchFeatureFlagEnabled)
    {
    this.dataService.setOneViewClientObject(null);
    $('#txtValue' + this.MappedValue + 0).val('');
    this.clientChange();
    this.ClientTypeChange();
    $('.clientCreationDiv').removeClass('hide');
    $('#txtValue' + this.MappedValue + 0).attr('disabled', 'disabled');
    $('#DuplicateCheckOneviewAccountSearch').addClass('disabled');
    }
    else if (this.clientSearchFeatureFlagEnabled)
    {
      this.OneViewClientTypeChange()
    }
  }

  ClientTypeChange() {
    const selectedType = $('#ddClientType option:selected').text().trim();
    if (selectedType.toLowerCase() === ClientTypesEnum.Personal.toLowerCase()) {
      this.isPersonalClientType = true;
      this.client.clientName = '';
      this.client.sicCodeId = '';
      this.client.industryTypeId = 0;
      this.client.tickerSymbol = '';
      this.client.stockExchangeId = 0;
    } else {
      this.isPersonalClientType = false;
      this.client.firstName = '';
      this.client.lastName = '';
      this.client.middleName = '';
    }
  }

  OneViewClientTypeChange() {
    const selectedType = $('#ddClientType option:selected').text().trim();
    if (selectedType.toLowerCase() === ClientTypesEnum.Personal.toLowerCase()) {
      this.isPersonalClientType = true;
      this.client.clientName = '';
      this.client.sicCodeId = '';
      this.client.industryTypeId = 0;
      this.client.tickerSymbol = '';      
       this.client.stockExchangeId = <any>('undefined');      
      this.client.tickerSymbol='';    
      let allstructEntities: StructEntity[] = this.structEntities;          
      this.structEntitiesBind= allstructEntities.filter(x => (x. typeValue.trim().toLowerCase() !=
      StructEntityEnum.Public.trim().toLowerCase())&& (x. typeValue.trim().toLowerCase()!=StructEntityEnum.PublicSEC.trim().toLowerCase()))
      this.client.structEntityId=0
      
     
    } else {
      this.isPersonalClientType = false;
      this.client.firstName = '';
      this.client.lastName = '';
      this.client.middleName = '';
      this.client.structEntityId=0
    }

  
 
    this.fillClientName(this.oneViewClientDetailModel.name);
  }

  onStockChange()
  {
    const selectedType = $('#ddStockVal option:selected').val();
    if (selectedType!=undefined && selectedType!= '-1' && selectedType.trim() !='') {
    let allstructEntities: StructEntity[] = this.structEntities;      
    this.structEntitiesBind= allstructEntities.filter(x => (x. typeValue.trim().toLowerCase() ===
     StructEntityEnum.Public.trim().toLowerCase())|| (x. typeValue.trim().toLowerCase() ===StructEntityEnum.PublicSEC.trim().toLowerCase()))
     this.client.structEntityId=0
this.isValidStockExchange=false;
this.isValidTickerSymbol=false;

          }
      else{
        let allstructEntities: StructEntity[] = this.structEntities;          
        this.structEntitiesBind= allstructEntities.filter(x => (x. typeValue.trim().toLowerCase() !=
         StructEntityEnum.Public.trim().toLowerCase())&& (x. typeValue.trim().toLowerCase()!=StructEntityEnum.PublicSEC.trim().toLowerCase()))
        this.client.structEntityId=0
        this.isValidStockExchange=true;
        this.isValidTickerSymbol=true;

      }


  }

  objectInitialization() {
    this.client = {
      key: '',
      value: '',
      clientId: 0,
      engagementId: 0,
      clientName: '',
      clientProgram: '',
      clientCode: '',
      referralSource: '',
      clientType: '',
      stockExchange: '',
      tickerSymbol: '',
      fye: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      addressLine4: '',
      city: '',
      stateId: 0,
      countryId: 0,
      zipCode: '',
      phoneNumber: '',
      fax: '',
      status: '',
      clientStatusId: 0,
      sicCodeId: '',
      sicCode: '',
      industryType: '',
      websiteAddress: '',
      isAudit: false,
      isTax: false,
      isAdvisory: false,
      userName: '',
      createdby: '',
      createdon: '',
      updatedBy: '',
      updatedon: '',
      clientTypeId: 0,
      clientProgramId: 0,
      industryTypeId: 0,
      stockExchangeId: 0,
      referralSourceId: 0,
      fyeMonth: 1,
      fyeDay: 1,
      isAgreement: false,
      isMSA: false,
      msaType: '',
      elType: '',
      msaDocDate: '',
      msaExpDate: '',
      clientShortName: '',
      isClientDisabled: false,
      firstName: '',
      middleName: '',
      lastName: '',
      clientContactName: '',
      contactTypeCodeId: 0,
      clientContactEmail: '',
      clientBillingName: '',
      billingContactTitleCodeId: 0,
      clientBillingEmail: '',
      structEntityId: 0,
      international: '',
      transnational: '',
      billingRegion: '',
      oneViewAccountId: '',
      clientStatus: ''
    };
    this.mandatoryFieldInitialization();
  }

  // setClientData() {
  //   this.client.referralSource = $('#txtValue' + this.ReferralSourceMappedValue + 0).val();
  //   this.client.referralSourceId = $('#hdnKey' + this.ReferralSourceMappedValue + 0).val();
  //   this.client.sicCode = $('#txtValue' + this.SICCodeMappedValue + 0).val();
  //   this.client.sicCodeId = $('#hdnKey' + this.SICCodeMappedValue + 0).val();
  // }

  setClientValuesFromViewModel() {
    // COPY PASTE FROM setClientData() existing code
    this.client.referralSource = $('#txtValue' + this.ReferralSourceMappedValue + 0).val();
    this.client.referralSourceId = $('#hdnKey' + this.ReferralSourceMappedValue + 0).val();
    this.client.sicCode = $('#txtValue' + this.SICCodeMappedValue + 0).val();
    this.client.sicCodeId = $('#hdnKey' + this.SICCodeMappedValue + 0).val();
    // END PASTE

    if (this.clientViewModel.telephone1Model != undefined) {
      this.client.phoneNumber = this.clientViewModel.telephone1Model.telephone1;
    }
    if (this.clientViewModel.addressLine1Model != undefined) {
      this.client.addressLine1 = this.clientViewModel.addressLine1Model.address1_line1;
    }
    if (this.clientViewModel.tickerSymbolModel != undefined) {
      this.client.tickerSymbol = this.clientViewModel.tickerSymbolModel.tickerSymbol;
    }
    if (this.clientViewModel.webSiteModel != undefined) {
      this.client.websiteAddress = this.clientViewModel.webSiteModel.webSite;
    }
    if (this.clientViewModel.businessContactEmailModel != undefined) {
      this.client.clientContactEmail = this.clientViewModel.businessContactEmailModel.businesscontactemail;
    }
    // if (this.clientViewModel.fax != undefined && this.clientViewModel.fax != '') {
    //   this.client.fax = this.clientViewModel.fax;
    // }
    if (this.clientViewModel.billingContactEmailModel != undefined) {
      this.client.clientBillingEmail = this.clientViewModel.billingContactEmailModel.billingContactEmail;
    }
    // if (this.clientViewModel.businessContactFullNameModel != undefined) {
    //   this.client.clientContactName = this.clientViewModel.businessContactFullNameModel.businesscontactfullname;
    // }
    // if (this.clientViewModel.billingContactFullNameModel != undefined) {
    //   this.client.clientBillingName = this.clientViewModel.billingContactFullNameModel.billingContactFullName;
    // }
    if (this.clientViewModel.clientNameModel != undefined) {
      this.client.clientName = this.clientViewModel.clientNameModel.clientName;
    }
  }

  mandatoryFieldInitialization() {
    this.setClientValuesFromViewModel();
    this.arrControlValidation = [];
    this.arrControlValidation.push(new FieldValidator('clientType', this.client.clientTypeId, false));
    this.arrControlValidation.push(new FieldValidator('structEntityId', this.client.structEntityId, false));
    this.arrControlValidation.push(new FieldValidator('clientProgramId', this.client.clientProgramId, false));
    this.arrControlValidation.push(new FieldValidator('referralSourceId', this.client.referralSourceId, false));
    this.arrControlValidation.push(new FieldValidator('fyeMonth', this.client.fyeMonth, false));
    this.arrControlValidation.push(new FieldValidator('phoneNumber', this.client.phoneNumber, false));
    this.arrControlValidation.push(new FieldValidator('addressLine1', this.client.addressLine1, false));
    this.arrControlValidation.push(new FieldValidator('countryId', this.client.countryId, false));
    this.arrControlValidation.push(new FieldValidator('stateId', this.client.stateId, false));
    this.arrControlValidation.push(new FieldValidator('city', this.client.city, false));
    this.arrControlValidation.push(new FieldValidator('zipCode', this.client.zipCode, false));
    this.arrControlValidation.push(new FieldValidator('clientContactName', this.client.clientContactName, false));
    this.arrControlValidation.push(new FieldValidator('clientContactEmail', this.client.clientContactEmail, false));
    this.arrControlValidation.push(new FieldValidator('clientBillingName', this.client.clientBillingName, false));
    this.arrControlValidation.push(new FieldValidator('clientBillingEmail', this.client.clientBillingEmail, false));
    this.arrControlValidation.push(new FieldValidator('industryTypeId', this.client.industryTypeId, false));
    //if ( !this.clientSearchFeatureFlagEnabled || (this.clientSearchFeatureFlagEnabled && this.isSicCodeData==false))
    if  ($('#txtValue' + this.SICCodeMappedValue + 0).val()==='')
      this.client.sicCodeId ='';
    this.arrControlValidation.push(new FieldValidator('sicCodeId',  this.client.sicCodeId, false));
   
    if (this.isPersonalClientType) {
      this.arrControlValidation.push(new FieldValidator('firstName', this.client.firstName, false));
      this.arrControlValidation.push(new FieldValidator('lastName', this.client.lastName, false));
     

      this.businessContactTitleControlIndex = 19;
      this.billingContactTitleControlIndex = 20;
      
      

    }
    if (!this.isPersonalClientType) {
      this.arrControlValidation.push(new FieldValidator('clientName', this.client.clientName, false));

      //if( !this.clientSearchFeatureFlagEnabled)
      {this.businessContactTitleControlIndex = 18;
        this.billingContactTitleControlIndex = 19;
        }
     /* else if (this.clientSearchFeatureFlagEnabled && this.isStockExchangeData)
          {
            this.businessContactTitleControlIndex = 17;
            this.billingContactTitleControlIndex = 18;
            }
            
      else if (this.clientSearchFeatureFlagEnabled && this.isStockExchangeData==false)
            {
                this.businessContactTitleControlIndex = 18;
                this.billingContactTitleControlIndex = 19;
            }*/
    }

    this.arrControlValidation.push(new FieldValidator('contactTypeCodeId', this.client.contactTypeCodeId, false));
    this.arrControlValidation.push(new FieldValidator('billingContactTitleCodeId', this.client.contactTypeCodeId, false));

  }

  checkMandatoryValidations(fieldValidationArray: FieldValidator[], modalValidateArray) {
    let retVal: boolean = true;
    this.objectArray = Object.entries(modalValidateArray);

    for (let i = 0; i < fieldValidationArray.length; i++) {
      fieldValidationArray[i].setvalues(fieldValidationArray[i].fieldId,
        this.getUpdatedValue(fieldValidationArray[i].fieldId), true);
    }
    for (let i = 0; i < fieldValidationArray.length; i++) {
      if (!fieldValidationArray[i].isvalid) {
        retVal = false;
        break;
      }
    }

    //Business validation
    if (this.isPersonalClientType === false && ((this.isCodeMatched &&!this.clientSearchFeatureFlagEnabled)||this.clientSearchFeatureFlagEnabled)){
      if ((this.client.tickerSymbol == '' || this.client.tickerSymbol == null || this.client.tickerSymbol == undefined || $('#txtTicketSymbol').val() == '' || $('#txtTicketSymbol').val() == ' ')
        && ( !this.clientSearchFeatureFlagEnabled || (this.clientSearchFeatureFlagEnabled && this.isStockDataRequired && !this.isStockExchangeData)))
        {
        $('#tickerSymbolRequired').removeClass('d-none');
        $('#tickerSymbolRequired').addClass('d-block');
        retVal = false;
      } else if( (this.client.tickerSymbol != null && this.client.tickerSymbol != undefined)||(this.clientSearchFeatureFlagEnabled && !this.isStockDataRequired )||(this.clientSearchFeatureFlagEnabled && this.isStockExchangeData) ){
        
        $('#tickerSymbolRequired').removeClass('d-block');
        $('#tickerSymbolRequired').addClass('d-none');
      }
    }

    var clientValidationMessage = $('#clientValidationMessage').text();
    if (clientValidationMessage.length > 0 && clientValidationMessage.startsWith("Client not found in one view") == false) {
      this._alertService.remove(this.alertClient);
      this._alertService.add(clientValidationMessage, 'danger', null);
      retVal = false;
    }

    return retVal;
  } // end checkMandatoryValidations


  getUpdatedValue(id: string) {
    let retval: any = '';

    this.objectArray.forEach(([key, Keyvalue]) => {
      if (key === id) {
        switch (key) {
          case 'clientType':
          case 'clientProgramId':
            retval = Keyvalue.value;
            break;
          default:
            retval = Keyvalue;
            break;
        }
      }
    });

    if (retval != undefined && retval != "" && typeof (retval) == 'string') {
      retval = retval.trim();
    }
    return retval;
  }

  empty(e) {
    switch (e) {
      case '':
      case 0:
      case '0':
      case null:
      case false:
      case typeof (e) == 'undefined':
        return true;
      default:
        return false;
    }
  }

  DislpayDialog(Type: string, Title: string,Description:string) {
    var modalObj = {
      dialogType: Type,
      dialogTitle: Title,
      dialogDescription: Description,
      dialogRedirectUrl: '',
      dialogButton: 1,
      modalDisplay: true
    };
    var modalJSON = JSON.stringify(modalObj);
    console.log(' showDialog func called information');
    this.dataService.displayDialogNotification(modalJSON);
  }
  RedirectUser() {
    this.dataService.setIsRedirectedFromCreateClient('true');
    this.dataService.setClientIdForEngagement(this.clientIdForEngagementRedirection);
    this.dataService.setClientNameForEngagement(this.client.clientName);
    this.dataService.setoneClientViewAccountId(this.searchOneViewAccountId);
    // this.dataService.setIwantToHeadingForClient(this.currentIwantToHeading);
    if (this.returnUrl != null && this.returnUrl != undefined) {
      if (this.returnUrl === 'returnUrl') {
        this.router.navigate(['/NewEngagement']);
      } else { this.router.navigate([this.returnUrl]); }
    } else {
      this.router.navigate(['/NewEngagement']);
    }
  }

  BusinessValidationForClient(clientExists: boolean) {
    if (!clientExists) {
      this.phoneInvalid = this.restrictNumber();
      if (this.phoneInvalid === false) { // phoneNumberInvalid == false so allowed to proceed
        $('#invalid-phone').removeClass('d-block');
        $('#invalid-phone').addClass('d-none');
      } else {
        this.loadingScreenService.stopLoading();
        $('#invalid-phone').removeClass('d-none');
        $('#invalid-phone').addClass('d-block');
      }
      let referalSourceType = $('#txtValue' + this.ReferralSourceMappedValue + 0).val();
      let referalSourceTypeId = $('#hdnKey' + this.ReferralSourceMappedValue + 0).val();
      let sicCodeValue = $('#txtValue' + this.SICCodeMappedValue + 0).val();
      let sicCodeId = $('#hdnKey' + this.SICCodeMappedValue + 0).val();
      let CMSAdminValue = $('#txtPeoplePicker_' + this.adminRoleName + '_' + this.peoplepickerIndex).val();
      let KRPValue = $('#txtPeoplePicker_' + this.KRPRoleName + '_' + this.peoplepickerIndex).val();
      var anyEmptyContacts = false;
      var anyEmptyPartners = false;
      var anyEmptyStockEx = false;
      let anyInvalidEmail = false;
      let isKRPNotEmtpyAndNotValid = false;
      if (!this.checkValidEmail(this.client.clientContactEmail)) {
        anyInvalidEmail = true;
        this.isInvalidClientContactEmail = true;
      }
      else {
        this.isInvalidClientContactEmail = false;
      }
      if (!this.checkValidEmail(this.client.clientBillingEmail)) {
        this.isInvalidClientBillingEmail = true;
        anyInvalidEmail = true;
      } else {
        this.isInvalidClientBillingEmail = false;
      }

      if (KRPValue !== '' && KRPValue !== undefined && $('#hdnValidInput_KRP' + '_' + this.peoplepickerIndex).val() === 'false') {
        $('#KRPValid').removeClass('d-none');
        $('#KRPValid').addClass('d-block');
        $('#KRPRequired').addClass('d-none');
        isKRPNotEmtpyAndNotValid = true;
      }
      else {
        $('#KRPValid').removeClass('d-block');
        $('#KRPValid').addClass('d-none');
        isKRPNotEmtpyAndNotValid = false;
      }
      if (this.client.websiteAddress != null && this.client.websiteAddress != '' && this.client.websiteAddress != undefined && this.client.websiteAddress.length > 0) {
        this.isValidWebsiteAddress = this.ValidateWebsiteAddress(this.client.websiteAddress);
      }

      if (this.isPersonalClientType === false &&  ((this.isCodeMatched &&!this.clientSearchFeatureFlagEnabled)||this.clientSearchFeatureFlagEnabled))  {
        if( (this.client.tickerSymbol == '' || this.client.tickerSymbol == null || this.client.tickerSymbol == undefined || $('#txtTicketSymbol').val() == '' || $('#txtTicketSymbol').val() == ' ') 
          && (!this.clientSearchFeatureFlagEnabled || (this.clientSearchFeatureFlagEnabled && this.isStockDataRequired && !this.isStockExchangeData)))
          {
          $('#tickerSymbolRequired').removeClass('d-none');
          $('#tickerSymbolRequired').addClass('d-block');
          anyEmptyStockEx = true;
        } else if ((this.client.tickerSymbol != null && this.client.stockExchangeId != -1) || (this.clientSearchFeatureFlagEnabled && !this.isStockDataRequired ) || (this.clientSearchFeatureFlagEnabled && this.isStockExchangeData)){
          anyEmptyStockEx = false;
          $('#tickerSymbolRequired').removeClass('d-block');
          $('#tickerSymbolRequired').addClass('d-none');
        }
      } // end if isPersonalClientType = false
      let referralvaluesPresent = false;
      if (anyEmptyContacts === false && anyEmptyPartners === false) {
        if (this.isPersonalClientType === false) {
          if (sicCodeValue !== '' && referalSourceType !== '') {
            referralvaluesPresent = true;
          }
         else if(this.clientSearchFeatureFlagEnabled)
          referralvaluesPresent = true;
        }

        if (this.EditMode === true) {
          if (anyEmptyStockEx === true && this.isPersonalClientType === false) {
            this.loadingScreenService.stopLoading();
            console.log('validation error, required fields can not be empty');

          } else if (this.isSystemAdmin === true) {
            if (this.client.msaType != null && this.client.msaType !== 'N/A') {
              this.client.isMSA = true;
              this.client.isAgreement = true;
            } else {
              this.client.isMSA = false;
              this.client.isAgreement = true;
            }
          }
        }
        this.invalidCharacterDetectionClientName();
        this.client.sicCodeId='';
        if (!this.phoneInvalid && !anyInvalidEmail && anyEmptyStockEx === false && CMSAdminValue !== '' && $('#hdnValidInput_CLA' + '_' + this.peoplepickerIndex).val() === 'true' &&
          (this.isPersonalClientType === true || (this.isPersonalClientType === false && referralvaluesPresent === true))
          && !isKRPNotEmtpyAndNotValid && this.isValidWebsiteAddress && this.client?.industryTypeId > 0 && (this.invalidvalues === '' || this.invalidvalues === null)) {

          this.client.sicCodeId = sicCodeId;
          this.client.referralSourceId = Number(referalSourceTypeId);
          this.client.referralSource = referalSourceType;
          this.client.fye = this.client.fyeMonth + ''; //+ '/' + this.client.fyeDay; fyeday has been depricated.
          this.client.fax = $("#txtFax").val();

          if (this.client.stockExchangeId === -1 || isNaN(this.client.stockExchangeId)) {
            this.client.stockExchangeId = 0;
          }
          this.keyRelationshipPartnersList = [];
          if (KRPValue !== '' && $('#hdnValidInput_KRP' + '_' + this.peoplepickerIndex).val() === 'true') {
            this.keyRelationshipPartner.relationshipPartnerName = $('#UserHiddenProps_KRP_0 #hdnUserName').val();
            this.keyRelationshipPartner.email = $('#UserHiddenProps_KRP_0 #hdnUserEmail').val();
            this.keyRelationshipPartner.phoneNumber = $('#UserHiddenProps_KRP_0 #hdnUserPhone').val();
            this.keyRelationshipPartner.jobTitle = $('#UserHiddenProps_KRP_0 #hdnJobTitle').val();
            this.keyRelationshipPartnersList.push(this.keyRelationshipPartner);
          }
          this.clientAdministratorsList = [];
          if ($('#hdnValidInput_CLA' + '_' + this.peoplepickerIndex).val() === 'true') {
            this.clientAdministrator.contactName = $('#UserHiddenProps_CLA_0 #hdnUserName').val();
            this.clientAdministrator.email = $('#UserHiddenProps_CLA_0 #hdnUserEmail').val();
            this.clientAdministrator.phoneNumber = $('#UserHiddenProps_CLA_0 #hdnUserPhone').val();
            this.clientAdministrator.jobTitle = $('#UserHiddenProps_CLA_0 #hdnJobTitle').val();
            this.clientAdministratorsList.push(this.clientAdministrator);
          }
          // looping through tables of client contact and relationship partners and
          // save it in cloned object of respective class objects.
          //const contactsCount = this.dynamicClientContacts.length;
          const CMSAdminList = this.clientAdministratorsList; //this.dynamicClientContacts;
          // const partnersCount = this.dynamicRelationshipPartners.length;
          const partnersList = this.keyRelationshipPartnersList; //this.dynamicRelationshipPartners;
          if(this.isPersonalClientType)
          {
          this.clientTypes.forEach(element => {
            if (element.typeValue === ClientTypesEnum.Personal) {
              this.client.clientTypeId = element.id;
              this.client.clientType = element.typeValue;
              this.client.tickerSymbol='';
            }
          });
        } 
        else 
        {
          this.clientTypes.forEach(element => {
            if (element.typeValue === ClientTypesEnum.Organization) {
              this.client.clientTypeId = element.id;
              this.client.clientType = element.typeValue;
            }
          });
        }
          this.createClientViewModel.client = this.client;
          this.createClientViewModel.cMSClient = null;

          this.createClientViewModel.clientAdministrators = CMSAdminList;
          //this.createClientViewModel.relationshipPartners = partnersList;

          console.log(' Add New Client ', this.client, this.createClientViewModel);
          this.clientService.AddNewClient(this.createClientViewModel, this.EditMode).subscribe(result => {
            console.log(result);
            this.loadingScreenService.stopLoading();
            if (result.indexOf(this.client.clientName) === 0) {
              this._alertService.remove(this.alertClient);
              this._alertService.add(this.client.clientName + ' already exists in the system.', 'danger', null);
              document.documentElement.scrollTop = 0;
            }
            else if (Number(result) > 0) {
              this.clientIdForEngagementRedirection = result;
              this.clientOneViewAccountIDetails.clientId = Number(result);
              if(!this.clientSearchFeatureFlagEnabled)
              {
              this.loadingScreenService.startLoading();
              this.clientService.CheckClientOneViewAccountIdExists(this.clientOneViewAccountIDetails.clientId).subscribe({
                next: isExists => {
                  if (isExists == 0) {
                    this.SetOneViewClientData();
                    this.clientService.AddNewClientOneView(this.createOneViewClientDetailModel).subscribe({
                      next: oneview => {
                        if (oneview.status == "Success") {
                          this.clientOneViewAccountIDetails.oneViewAccountId = oneview.response;
                        } else {
                          // this.clientOneViewAccountIDetails.oneViewAccountId = "ACT-00000-000000";
                        }
                        this.clientService.UpdateOneViewAccountId(this.clientOneViewAccountIDetails).subscribe({
                          next: res => {
                            this.loadingScreenService.stopLoading();
                            this.DislpayDialog('success', ClientDialogTitles.createClient,ClientDialogTitles.createClientDescription);
                          },
                          error: errorUpdate => {
                            this.loadingScreenService.stopLoading();
                            this._alertService.remove(this.alertClient);
                            this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
                          }
                        });
                      }, error: checkexisterror => {
                        this.loadingScreenService.stopLoading();
                        this._alertService.remove(this.alertClient);
                        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
                      }
                    }
                    );
                  } else {
                    this.loadingScreenService.stopLoading();
                    this.DislpayDialog('success', ClientDialogTitles.createClient,ClientDialogTitles.createClientDescription);
                  }
                },
                error: err => {
                  this.loadingScreenService.stopLoading();
                  this._alertService.remove(this.alertClient);
                  this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
                }
              });
            }
            else {
              this.loadingScreenService.stopLoading();
              this.DislpayDialog('success', ClientDialogTitles.createClient,ClientDialogTitles.createClientDescription);
            }
            }
            if (this.EditMode == true) {
              this.router.navigate(['/ClientSummary/' + this.requestedClientId]);
            }
          },
            error => {
              this.loadingScreenService.stopLoading();
              this._alertService.remove(this.alertClient);
              this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
              // document.documentElement.scrollTop = 0;
            });
        } else {
          this.loadingScreenService.stopLoading();
        }

      }
    } // end if (clientExists == false)
    else {
      this.loadingScreenService.stopLoading();
      this.cmsClientList = [];
      if (!this.EditMode) { // when edit mode is false
        this._alertService.remove(this.alertClient);
        this._alertService.add(this.client.clientName + ' already exists in the system.', 'danger', null);
        //  document.documentElement.scrollTop = 0;
      }
    }
  }

  checkValidEmail(email: string) {
    const regex = (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gm);
    if (regex.test(email))
      return true;
    else
      return false;
  }

  numberOnly(event): boolean {
    this.checkSpcialChar(event);
    const charCode = (event.which) ? event.which : event.keyCode;
    if (/[a-zA-Z0-9- ]/.test(String.fromCharCode(charCode))) {
      return true;
    }
    return false;
  }

  ValidateWebsiteAddress(website) {
    const regex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/igm;
    if (regex.test(website))
      return true;
    else
      return false;

  }
  invalidCharacterDetectionClientName() {
    this.invalidvalues = "";
    var value = this.clientViewModel.clientNameModel.clientName;
    this.invalidvalues = value.trim().startsWith('(DUP)') ? '(DUP)' : '';
    const restrictedvalues = "|";
    for (let char of value) {
      if (restrictedvalues.indexOf(char) != -1) {
        this.invalidvalues = char;
      }
    }
  }

  blockScriptTags(event): boolean {
    this.invalidCharacterDetectionClientName();
    if (this.invalidvalues != '' || this.invalidvalues != null) {

      return true;
    }
    else {
      return false
    }

  }


  GetClientContactTypes() {
    this.applicationMasterService.GetAllClientContactTypes().subscribe(contactTypeResult => {
      if (contactTypeResult.length > 0) {
        this.contactTypes = this.billingContactTypes = contactTypeResult.filter(contactType => contactType.code.trim().toUpperCase() != 'BILL');
      }
    },
      err => {
        this.logService.error('Client contact types fetch failed', err);
      });
  }
  onCMSAdminSelected(event: string) {
    const selectedUser = JSON.parse(event);
    //this.setCMSAdminDefaultValue(selectedUser);
    this.setKeyRelationshipPartnerDefaultValue(selectedUser);
  }
  setKeyRelationshipPartnerDefaultValue(selectedUser) {
    this.keyRelationshipPartner.relationshipPartnerName = selectedUser.displayName;
    this.keyRelationshipPartner.jobTitle = selectedUser.jobTitle;
    this.keyRelationshipPartner.email = selectedUser.mail;
    this.keyRelationshipPartner.phoneNumber = selectedUser.businessPhones;
    $('#' + this.KRPCtrl).val(selectedUser.displayName);
    $('#UserHiddenProps_KRP_0 #hdnUserName').val(selectedUser.displayName);
    $('#UserHiddenProps_KRP_0 #hdnUserEmail').val(selectedUser.mail);
    $('#UserHiddenProps_KRP_0 #hdnUserPhone').val(selectedUser.businessPhones);
    $('#UserHiddenProps_KRP_0 #hdnJobTitle').val(selectedUser.jobTitle);
    this.validateKRP = true;
  }
  ngOnDestroy() {
    this.dataService.setNewClientName('');
    this.modalNewClientDialogRef?.unsubscribe();
    $('#txtValue' + this.MappedValue + 0).val('');
    $('#hdnKeyCode' + this.MappedValue + 0).val('');
    $('#hdnKey' + this.MappedValue + 0).val('');
    this.modalConfirmationDialogRef?.unsubscribe();
  }

  ClientValidation(event) {
    var clientValidationDiv = $('#clientValidationMessage');
    clientValidationDiv.removeClass('d-none');
    clientValidationDiv.text(event);
    this.isValidClient = false;
  }


  clientChange() {
  
    let currentData = this.dataService.getOneViewClientObject();
    console.log('currentData ==>', currentData);
   /* if (this.clientSearch?.IsClientNameChanged == false) {
      $('#txtValue' + this.MappedValue + 0).val($('#txtValue' + this.MappedValue + 0).val().trim());
      return;
    }*/

    $('#clientValidationMessage').text('');
    $('#clientValidationMessage').addClass('d-none');
    $('#clientValidationMessage').removeClass('d-block');
    this._alertService.remove(this.alertClient);

    if (currentData == null) {
      this.initiateClientViewModel();
      this.resetClientModelValues();
      $('#spanSelectedAccountName').text("None selected");
      $('.clientCreationDiv').addClass('hide');
      $('#txtValue' + this.MappedValue + 0).removeAttr('disabled');
      $('#DuplicateCheckOneviewAccountSearch').removeClass('disabled');
      return;
    }

    if (currentData.accountnumber == null) {
      this.HandleOneViewClient();
    }
    else {
      $('.clientCreationDiv').addClass('hide');
      $('#txtValue' + this.MappedValue + 0).removeAttr('disabled');
      $('#DuplicateCheckOneviewAccountSearch').removeClass('disabled');
      this.getClientDetailsByClientCode(currentData.accountnumber);
    }
  }

  getClientDetailsByClientCode(clientCode: string) {
    this.loadingScreenService.startLoading();
    this.clientService.GetClientDetailsByClientCode(clientCode).subscribe(result => {
      this.loadingScreenService.stopLoading();
      let engageClient = result;
      let currentData = this.dataService.getOneViewClientObject();
      if (engageClient?.clientCode != null && engageClient?.clientCode == clientCode) {
        //Redirect user
        let clientName = (engageClient.clientCode === null) ? engageClient.clientName : engageClient.clientName + ' (' + engageClient.clientCode + ')';
        let clientId = engageClient.clientId;

        if (engageClient.clientStatus === 'Closed') {
          this.initiateClientViewModel();
          this.resetClientModelValues();
          $('#clientValidationMessage').text('Client is closed. Submit a Centralized Billing Share request to re-open the client code before creating a new client.');
          $('#clientValidationMessage').removeClass('d-none');
          $('#clientValidationMessage').addClass('d-block');
          $('#txtValue' + this.MappedValue + 0).val(currentData.name.substring(0, 40).trim());
          this.ResetOneViewClientConnection();
        } else {
          this.clientIdForEngagementRedirection = clientId.toString();
          this.client.clientName = clientName;
          this.RedirectUser();
        }
      } else {
        this.initiateClientViewModel();
        this.resetClientModelValues();
        $('#txtValue' + this.MappedValue + 0).val(currentData.name.substring(0, 40).trim());
        let description: string = "The client code for this client is listed in oneView but does not appear in Engage. Please contact support to have this issue resolved.";
        this.displayDialog('warning', "Client code missing", description, 1, false);
        $('#clientValidationMessage').text(description);
      }
    }
    );
  }

  displayDialog(type: string, title: string, description: string, button: number, bigTitle: boolean) {
    var modalObj = {
      dialogType: type,
      dialogTitle: title,
      dialogDescription: description,
      dialogRedirectUrl: '',
      dialogButton: button,
      modalDisplay: true,
      dialogBigTitle: bigTitle
    };
    var modalJSON = JSON.stringify(modalObj);
    console.log(' showDialog func called information');
    this.dataService.displayDialogNotification(modalJSON);
  }

  HandleOneViewClient() {
    $('.clientCreationDiv').removeClass('hide');
    $('#txtValue' + this.MappedValue + 0).attr('disabled', 'disabled');
    $('#DuplicateCheckOneviewAccountSearch').addClass('disabled');
    let currentData = this.dataService.getOneViewClientObject();
    console.log('currentData ==>', currentData);
    var clientName = currentData.name.substring(0, 40).trim();
    $("#hdnIsOneViewClientLinked").val(true);
    $('#spanSelectedAccountName').text(currentData.hs_accountid);
    this.client.clientName = clientName;
    this.clientService.GetClientDetailByAccountId(currentData.accountid).subscribe(result => {
      this.oneViewClientDetailModel = result.response;

      console.log('oneViewClientDetailModel ==>', this.oneViewClientDetailModel);
      if (this.oneViewClientDetailModel != undefined) {
        this.bindClientViewModel();
      }
      this.loadingScreenService.stopLoading();
    },
      (error) => {
        this.loadingScreenService.stopLoading();
        console.log('Error while getting response GetClientDetailByAccountId func ', error);
      });
  }

  validateClientField(fieldName: string, isValidate: boolean, canShowDuplicateNotFoundMessage: boolean) {
    this.searchClientbyFieldRequestModel = undefined;
    switch (fieldName) {
      case ClientValidationFields.PhoneNumber:
        if (this.clientViewModel.telephone1Model.telephone1.trim().length > 0) {
          this.searchClientbyFieldRequestModel = new SearchClientbyFieldRequestModel(this.clientViewModel.telephone1Model.accountid, ClientValidationFields.PhoneNumber, this.clientViewModel.telephone1Model.telephone1.trim());
        } else {
          this.setIsFieldExistsInOneView(ClientValidationFields.PhoneNumber, false);
        }
        break;
      case ClientValidationFields.Address:
        if (this.clientViewModel.addressLine1Model.address1_line1.trim().length > 0) {
          this.searchClientbyFieldRequestModel = new SearchClientbyFieldRequestModel(this.clientViewModel.addressLine1Model.accountid, ClientValidationFields.Address, this.clientViewModel.addressLine1Model.address1_line1.trim());
        } else {
          this.setIsFieldExistsInOneView(ClientValidationFields.Address, false);
        }
        break;
      case ClientValidationFields.TickerSymbol:
        if (this.clientViewModel.tickerSymbolModel.tickerSymbol.trim().length > 0) {
          this.searchClientbyFieldRequestModel = new SearchClientbyFieldRequestModel(this.clientViewModel.tickerSymbolModel.accountid, ClientValidationFields.TickerSymbol, this.clientViewModel.tickerSymbolModel.tickerSymbol.trim());
        } else {
          this.setIsFieldExistsInOneView(ClientValidationFields.TickerSymbol, false);
        }
        break;
      case ClientValidationFields.WebSite:
        if (this.clientViewModel.webSiteModel.webSite.trim().length > 0) {
          this.searchClientbyFieldRequestModel = new SearchClientbyFieldRequestModel(this.clientViewModel.webSiteModel.accountid, ClientValidationFields.WebSite, this.clientViewModel.webSiteModel.webSite.trim());
        } else {
          this.setIsFieldExistsInOneView(ClientValidationFields.WebSite, false);
        }
        break;
      case ClientValidationFields.BusinessContactEmail:
        if (this.clientViewModel.businessContactEmailModel.businesscontactemail.trim().length > 0) {
          this.searchClientbyFieldRequestModel = new SearchClientbyFieldRequestModel(this.clientViewModel.webSiteModel.accountid, ClientValidationFields.BusinessContactEmail, this.clientViewModel.businessContactEmailModel.businesscontactemail.trim());
        } else {
          this.setIsFieldExistsInOneView(ClientValidationFields.BusinessContactEmail, false);
        }
        break;
      case ClientValidationFields.BillingContactEmail:
        if (this.clientViewModel.billingContactEmailModel.billingContactEmail.trim().length > 0) {
          this.searchClientbyFieldRequestModel = new SearchClientbyFieldRequestModel(this.clientViewModel.billingContactEmailModel.accountid, ClientValidationFields.BillingContactEmail, this.clientViewModel.billingContactEmailModel.billingContactEmail.trim());
        } else {
          this.setIsFieldExistsInOneView(ClientValidationFields.BillingContactEmail, false);
        }
        break;
      case ClientValidationFields.ClientName:
        if (this.clientViewModel.clientNameModel.clientName.trim().length > 0) {
          this.searchClientbyFieldRequestModel = new SearchClientbyFieldRequestModel(this.clientViewModel.clientNameModel.accountid, ClientValidationFields.ClientName, this.clientViewModel.clientNameModel.clientName.trim());
        } else {
          this.setIsFieldExistsInOneView(ClientValidationFields.ClientName, false);
        }
        break;
      default:
        this.searchClientbyFieldRequestModel = undefined;
        break;
    }
    if (isValidate) {
     // this.GetClientsBySearchTextField(fieldName, canShowDuplicateNotFoundMessage);
    }

  }

  GetClientsBySearchTextField(fieldName: string, canShowDuplicateNotFoundMessage: boolean) {
    this.matchingClientList = [];
    if (this.searchClientbyFieldRequestModel != undefined) {
      this.loadingScreenService.startLoading();
      this.clientService.GetClientsBySearchTextField(this.searchClientbyFieldRequestModel).subscribe(result => {
        this.matchingClientList = result.response;
        console.log('matchingClientList ==>', this.matchingClientList);
        if (this.matchingClientList != undefined && this.matchingClientList.length > 0) {
          console.log('this.matchingClientList.length ==>', this.matchingClientList.length);
          this.setIsFieldExistsInOneView(fieldName, true);
        } else {
          this.setIsFieldExistsInOneView(fieldName, false);
        }
        this.DisplayUIOptionsForOneViewDuplicateClient(fieldName, canShowDuplicateNotFoundMessage);
        this.loadingScreenService.stopLoading();
      },
        (error) => {
          this.loadingScreenService.stopLoading();
          console.log('Error while getting response GetClientsBySearchTextField func ', error);
        });
    }
  }
  setIsFieldExistsInOneView(fieldName: string, value: boolean) {
    switch (fieldName) {
      case ClientValidationFields.PhoneNumber:
        this.clientViewModel.telephone1Model.isFieldExistsInOneView = value;
        this.clientViewModel.telephone1Model.canShowDuplicateNotFoundMessage = false;
        break;
      case ClientValidationFields.Address:
        this.clientViewModel.addressLine1Model.isFieldExistsInOneView = value;
        this.clientViewModel.addressLine1Model.canShowDuplicateNotFoundMessage = false;
        break;
      case ClientValidationFields.TickerSymbol:
        this.clientViewModel.tickerSymbolModel.isFieldExistsInOneView = value;
        this.clientViewModel.tickerSymbolModel.canShowDuplicateNotFoundMessage = false;
        break;
      case ClientValidationFields.WebSite:
        this.clientViewModel.webSiteModel.isFieldExistsInOneView = value;
        this.clientViewModel.webSiteModel.canShowDuplicateNotFoundMessage = false;
        break;
      case ClientValidationFields.BusinessContactEmail:
        this.clientViewModel.businessContactEmailModel.isFieldExistsInOneView = value;
        this.clientViewModel.businessContactEmailModel.canShowDuplicateNotFoundMessage = false;
        break;
      case ClientValidationFields.BillingContactEmail:
        this.clientViewModel.billingContactEmailModel.isFieldExistsInOneView = value;
        this.clientViewModel.billingContactEmailModel.canShowDuplicateNotFoundMessage = false;
        break;
      case ClientValidationFields.ClientName:
        this.clientViewModel.clientNameModel.isFieldExistsInOneView = value;
        this.clientViewModel.clientNameModel.canShowDuplicateNotFoundMessage = false;
        break;
      default:
        break;
    }
  }

  IsFieldExistsInOneView(fieldName: string) {
    var isFieldExistsInOneView: boolean = false;
    switch (fieldName) {
      case ClientValidationFields.PhoneNumber:
        isFieldExistsInOneView = this.clientViewModel.telephone1Model.isFieldExistsInOneView;
        break;
      case ClientValidationFields.Address:
        isFieldExistsInOneView = this.clientViewModel.addressLine1Model.isFieldExistsInOneView;
        break;
      case ClientValidationFields.TickerSymbol:
        isFieldExistsInOneView = this.clientViewModel.tickerSymbolModel.isFieldExistsInOneView;
        break;
      case ClientValidationFields.WebSite:
        isFieldExistsInOneView = this.clientViewModel.webSiteModel.isFieldExistsInOneView;
        break;
      case ClientValidationFields.BusinessContactEmail:
        isFieldExistsInOneView = this.clientViewModel.businessContactEmailModel.isFieldExistsInOneView;
        break;
      case ClientValidationFields.BillingContactEmail:
        isFieldExistsInOneView = this.clientViewModel.billingContactEmailModel.isFieldExistsInOneView;
        break;
      case ClientValidationFields.ClientName:
        isFieldExistsInOneView = this.clientViewModel.clientNameModel.isFieldExistsInOneView;
        break;
      default:
        isFieldExistsInOneView = false;
        break;
    }

    return isFieldExistsInOneView;
  }

  SetCanShowDuplicateNotFoundMessage(fieldName: string, value: boolean) {
    switch (fieldName) {
      case ClientValidationFields.PhoneNumber:
        this.clientViewModel.telephone1Model.canShowDuplicateNotFoundMessage = false;
        if (this.clientViewModel.telephone1Model.telephone1.trim().length > 0) {
          this.clientViewModel.telephone1Model.canShowDuplicateNotFoundMessage = value;
        }
        break;
      case ClientValidationFields.Address:
        this.clientViewModel.addressLine1Model.canShowDuplicateNotFoundMessage = false;
        if (this.clientViewModel.addressLine1Model.address1_line1.trim().length > 0) {
          this.clientViewModel.addressLine1Model.canShowDuplicateNotFoundMessage = value;
        }
        break;
      case ClientValidationFields.TickerSymbol:
        this.clientViewModel.tickerSymbolModel.canShowDuplicateNotFoundMessage = false;
        if (this.clientViewModel.tickerSymbolModel.tickerSymbol.trim().length > 0) {
          this.clientViewModel.tickerSymbolModel.canShowDuplicateNotFoundMessage = value;
        }
        break;
      case ClientValidationFields.WebSite:
        this.clientViewModel.webSiteModel.canShowDuplicateNotFoundMessage = false;
        if (this.clientViewModel.webSiteModel.webSite.trim().length > 0) {
          this.clientViewModel.webSiteModel.canShowDuplicateNotFoundMessage = value;
        }
        break;
      case ClientValidationFields.BusinessContactEmail:
        this.clientViewModel.businessContactEmailModel.canShowDuplicateNotFoundMessage = false;
        if (this.clientViewModel.businessContactEmailModel.businesscontactemail.trim().length > 0) {
          this.clientViewModel.businessContactEmailModel.canShowDuplicateNotFoundMessage = value;
        }
        break;
      case ClientValidationFields.BillingContactEmail:
        this.clientViewModel.billingContactEmailModel.canShowDuplicateNotFoundMessage = false;
        if (this.clientViewModel.billingContactEmailModel.billingContactEmail.trim().length > 0) {
          this.clientViewModel.billingContactEmailModel.canShowDuplicateNotFoundMessage = value;
        }
        break;
      case ClientValidationFields.ClientName:
        this.clientViewModel.clientNameModel.canShowDuplicateNotFoundMessage = false;
        if (this.clientViewModel.clientNameModel.clientName.trim().length > 0) {
          this.clientViewModel.clientNameModel.canShowDuplicateNotFoundMessage = value;
        }
        break;
      default:
        break;
    }
  }

  resetClientModelValues() {
    $('#txtTicketSymbol').attr('disabled', 'disabled');
    $('#ddStockVal').attr('disabled', 'disabled');

    //Reset clientTypes with default
    this.clientTypes.forEach(element => {
      if (element.typeValue === ClientTypesEnum.Organization) {
        this.client.clientTypeId = element.id;
        this.client.clientType = element.typeValue;
      }
    });

    // Reset Client model values
    this.client.oneViewAccountId = '';
    this.client.clientName = '';
    this.client.industryTypeId = 0;
    this.client.industryType = 'Select from existing industries';
    this.client.structEntityId = 0;
    this.client.stockExchangeId = <any>('undefined');
    this.client.fyeMonth = 0;
    this.client.countryId = undefined;
    this.client.city = '';
    this.client.zipCode = '';
    this.client.stateId = undefined;
    this.client.fax = '';
    this.client.addressLine2 = '';
    this.client.addressLine3 = '';
  }

  fillClientName(clientName: string) {
    let clientNameArr = clientName.split(' ');
    if (clientNameArr.length > 0) {
      this.client.lastName = clientNameArr[0].replace(',', '');
      let otherNameStr: string = '';
      clientNameArr.forEach((word, index) => {
        console.log(word);
        if (index > 0) {
          otherNameStr = otherNameStr + ' ' + word;
        }
      });
      this.client.firstName = otherNameStr.trim();
    }
    else {
      this.client.firstName = clientName.substring(0, 40).trim();
    }
  }

  bindClientViewModel() {
   
    $('#txtValue' + this.MappedValue + 0).val('');
    this.client.oneViewAccountId = this.oneViewClientDetailModel.hs_accountid;
    if(this.oneViewClientDetailModel.hs_siccode!=null && this.oneViewClientDetailModel.hs_siccode!=undefined && this.oneViewClientDetailModel.hs_siccode.trim()!='')
    
this.isSicCodeData=true
 
   else
   this.isSicCodeData=false

   this.mappedSourceSicValue = this.oneViewClientDetailModel.hs_siccode;
    this.mappedSourceReferralValue = this.oneViewClientDetailModel.referralSource;
    this.isStockExchangeData=false;
   
 
    this.stockExchangeObjList.forEach(stock => {
      let elementArr: string[] = stock.typeValue.split('|');
      if (elementArr[0] === this.oneViewClientDetailModel.stockExchange) {
        this.isStockExchangeData=true;
    
      }
   
    });
    if ( this.oneViewClientDetailModel.stockExchange != '' &&
      this.oneViewClientDetailModel.stockExchange != undefined && this.isStockExchangeData) {
        let allstructEntities: StructEntity[] = this.structEntities;
      
this.structEntitiesBind= allstructEntities.filter(x => (x. typeValue.trim().toLowerCase() ===
 StructEntityEnum.Public.trim().toLowerCase())|| (x. typeValue.trim().toLowerCase() ===StructEntityEnum.PublicSEC.trim().toLowerCase()))

  }
  else{
    let allstructEntities: StructEntity[] = this.structEntities;
      
    this.structEntitiesBind= allstructEntities.filter(x => (x. typeValue.trim().toLowerCase() !=
     StructEntityEnum.Public.trim().toLowerCase())&& (x. typeValue.trim().toLowerCase()!=StructEntityEnum.PublicSEC.trim().toLowerCase()))
    
  }

  




  if (this.oneViewClientDetailModel.stockExchange != '' &&
    this.oneViewClientDetailModel.stockExchange != undefined ) {
    // set Public
    this.structEntitiesBind.forEach(entity => {
      if (entity.typeValue.trim().toLowerCase() === StructEntityEnum.Public.trim().toLowerCase()||entity.typeValue.trim().toLowerCase() === StructEntityEnum.PublicSEC.trim().toLowerCase()) {
        this.client.structEntityId = entity.id;
        this.StructEntityChange(this.client.structEntityId, false);
      }
    });

  } else {
    //3.  Bind structureOrEntity drop down      
    if (this.oneViewClientDetailModel.structureOrEntity != '' && this.oneViewClientDetailModel.structureOrEntity != null) {
      this.structEntitiesBind.forEach(entity => {
        if (entity.typeValue.trim().toLowerCase() === this.oneViewClientDetailModel.structureOrEntity.trim().toLowerCase()) {
          this.client.structEntityId = entity.id;
        //  this.StructEntityChange(this.client.structEntityId, true);
        }
      });
    } else {
      this.client.structEntityId = 0;
     // this.StructEntityChange(this.client.structEntityId, true);
    }
  }


    //0. Client Name
    if (this.oneViewClientDetailModel.name != '' && this.oneViewClientDetailModel.name != null) {
      this.client.clientName = this.oneViewClientDetailModel.name.substring(0, 40).trim();
    }
    this.mappedSourceClientNameValue = '';
    // 1. Bind clientType Drop down   
    if (this.oneViewClientDetailModel.clientType != '' && this.oneViewClientDetailModel.clientType != null) {
      if (this.oneViewClientDetailModel.clientType == ClientTypesEnum.Personal) {
        const clientTypesObj = this.clientTypes.find(c => c.typeValue === ClientTypesEnum.Personal);
        this.client.clientTypeId = clientTypesObj.id;
        this.fillClientName(this.oneViewClientDetailModel.name);
      } else {
        const clientTypesObj = this.clientTypes.find(c => c.typeValue === ClientTypesEnum.Organization);
        if (clientTypesObj!=null && clientTypesObj!=undefined)
       { this.client.clientTypeId = clientTypesObj.id;
        this.client.clientType = clientTypesObj.typeValue;
       }
      }
      $('#ddClientType').val(this.client.clientTypeId);
      this.ClientTypeChange();
    }

if (!this.isStockExchangeData && this.clientSearchFeatureFlagEnabled)
    $('#ddClientType' ).removeAttr('disabled');

    //2. industry drop down    
    if (this.oneViewClientDetailModel.industry != '' && this.oneViewClientDetailModel.industry != null) {
      this.industryTypes.forEach(industry => {
        let industryArr: string[] = industry.typeValue.split(' - ');
        let matchindustryName = industryArr[industryArr.length - 1];
        if (matchindustryName != undefined &&
          matchindustryName.trim().toLowerCase() ===
          this.oneViewClientDetailModel.industry.trim().toLowerCase()) {
          this.client.industryTypeId = industry.id;
        }
      });
    } else {
      this.client.industryTypeId = 0;
    }

    //4. Bind stockExchange drop down   
    if (this.oneViewClientDetailModel.stockExchange != '' && this.oneViewClientDetailModel.stockExchange != null) {
      this.stockExchangeObjList.forEach(stock => {
        let elementArr: string[] = stock.typeValue.split('|');
        if (elementArr[0] === this.oneViewClientDetailModel.stockExchange) {
          this.client.stockExchangeId = stock.id;
        }
      });
    } else {
      this.client.stockExchangeId = -1;
    }

    //5. Fiscal year end month
    //this.oneViewClientDetailModel.fiscalYearEndMonth = 'June';
    if (this.oneViewClientDetailModel.hs_fiscalyearend != '' && this.oneViewClientDetailModel.hs_fiscalyearend != null) {
      this.fiscalYearMonthList.forEach(mnth => {
        if (mnth.monthName.trim().toLowerCase() === this.oneViewClientDetailModel.hs_fiscalyearend.trim().toLowerCase()) {
          this.client.fyeMonth = mnth.monthNo;
        }
      });
    } else {
      this.client.fyeMonth = 0;
    }

    // countries
    // TO BE REMOVED
    //this.oneViewClientDetailModel.country = 'United States';
    if (this.oneViewClientDetailModel.country != '' && this.oneViewClientDetailModel.country != null && this.countries  != undefined &&this.countries.length > 0) {
      const countrySearchText = this.oneViewClientDetailModel.country.trim();
      let searchCountryObj = this.countries.filter(e => {
        const entries = Object.entries(e);
        return entries.some(entry =>
          entry[0].toString().trim().includes(countrySearchText) || entry[1].toString().trim().includes(countrySearchText));
      })

      if (searchCountryObj != undefined && searchCountryObj.length > 0) {
        this.client.countryId = searchCountryObj[0].countryId;
        this.states = [];
        this.stateService.GetStateMasters(this.client.countryId).subscribe(result => {
          this.states = result;
          for (let i = 0; i < this.states.length; i++) {
            if (this.states[i].stateCode.trim().toLowerCase() === this.oneViewClientDetailModel.address1_stateorprovince.trim().toLowerCase()) {
              this.client.stateId = this.states[i].stateId;
            }
          }
        })

      }
    }

    // START BINDING VALIDATING FIELDS
    this.clientViewModel.addressLine1Model = JSON.parse(JSON.stringify(new AddressLine1Model(this.oneViewClientDetailModel.accountid, this.oneViewClientDetailModel.address1_line1, this.oneViewClientDetailModel.address1_line1)));
    this.clientViewModel.tickerSymbolModel = JSON.parse(JSON.stringify(new TickerSymbolModel(this.oneViewClientDetailModel.accountid, this.oneViewClientDetailModel.tickersymbol, this.oneViewClientDetailModel.tickersymbol)));
    this.clientViewModel.telephone1Model = JSON.parse(JSON.stringify(new Telephone1Model(this.oneViewClientDetailModel.accountid, this.oneViewClientDetailModel.telephone1, this.oneViewClientDetailModel.telephone1)));
    this.clientViewModel.webSiteModel = JSON.parse(JSON.stringify(new WebSiteModel(this.oneViewClientDetailModel.accountid, this.oneViewClientDetailModel.websiteurl, this.oneViewClientDetailModel.websiteurl)));
    this.clientViewModel.businessContactEmailModel = JSON.parse(JSON.stringify(new BusinessContactEmailModel(this.oneViewClientDetailModel.accountid, '', '')));
    this.clientViewModel.billingContactEmailModel = JSON.parse(JSON.stringify(new BillingContactEmailModel(this.oneViewClientDetailModel.accountid, '', '')));
    //Use this.client.clientName as we substring to 40 characters and assign it to this.client.clientName in above lines of this method.
    this.clientViewModel.clientNameModel = JSON.parse(JSON.stringify(new ClientNameModel(this.oneViewClientDetailModel.accountid, this.client.clientName, this.client.clientName)));
    // END
    
   this.clientViewModel.cmsClientAdministratorEmail = this.oneViewClientDetailModel.cmsClientAdministratorEmail;
   this.clientViewModel.cmsClientAdministratorFullname = this.oneViewClientDetailModel.cmsClientAdministratorFullname;
    this.client.addressLine2 = this.oneViewClientDetailModel.address1_line2;
    this.client.addressLine3 = this.oneViewClientDetailModel.address1_line3;
    this.client.city = this.oneViewClientDetailModel.address1_city;
    this.client.zipCode =( this.oneViewClientDetailModel.address1_postalcode !=undefined && this.oneViewClientDetailModel.address1_postalcode !=null) ? this.oneViewClientDetailModel.address1_postalcode.substring(0, 10).trim():'';
    this.client.fax = this.oneViewClientDetailModel.fax;

    console.log('client ==>', this.client);
    console.log('clientViewModel ==>', this.clientViewModel);

  }


  verifyFieldValueInOneView() {
    console.log('this.matchingClientList', this.matchingClientList);
    console.log('this.clientViewModel.telephone1Model.isFieldExistsInOneView', this.clientViewModel.telephone1Model.isFieldExistsInOneView);
    //Similar entry exists in OneView
  }

  initiateClientViewModel() {
    this.mappedSourceSicValue = '';
    this.mappedSourceReferralValue = '';
    this.mappedSourceClientNameValue = '';
    this.clientViewModel.addressLine1Model = JSON.parse(JSON.stringify(new AddressLine1Model('', '', '')));
    this.clientViewModel.tickerSymbolModel = JSON.parse(JSON.stringify(new TickerSymbolModel('', '', '')));
    this.clientViewModel.telephone1Model = JSON.parse(JSON.stringify(new Telephone1Model('', '', '')));
    this.clientViewModel.webSiteModel = JSON.parse(JSON.stringify(new WebSiteModel('', '', '')));
    this.clientViewModel.businessContactEmailModel = JSON.parse(JSON.stringify(new BusinessContactEmailModel('', '', '')));
    this.clientViewModel.billingContactEmailModel = JSON.parse(JSON.stringify(new BillingContactEmailModel('', '', '')));
    this.clientViewModel.clientNameModel = JSON.parse(JSON.stringify(new ClientNameModel(this.client.oneViewAccountId, this.client.clientName, this.client.clientName)));
    this.clientViewModel.cmsClientAdministratorEmail = '';
    this.clientViewModel.cmsClientAdministratorFullname = '';

    this.clientViewModel.addressLine1Model.accountid = "";
    this.clientViewModel.tickerSymbolModel.accountid = "";
    this.clientViewModel.telephone1Model.accountid = "";
    this.clientViewModel.webSiteModel.accountid = "";
    this.clientViewModel.businessContactEmailModel.accountid = "";
    this.clientViewModel.billingContactEmailModel.accountid = "";
    this.clientViewModel.clientNameModel.accountid = "";

    console.log('load this.clientViewModel==>', this.clientViewModel);
  }

 
  DisplayUIOptionsForOneViewDuplicateClient(fieldName: string, canShowDuplicateNotFoundMessage: boolean) {
    this.validateClientField(fieldName, false, false);
    let isFieldExistsInOneView: boolean = this.IsFieldExistsInOneView(fieldName);

    if (isFieldExistsInOneView) {
      this.DisplayDialogForFieldExistswithClients(fieldName);
    } else {
      this.SetCanShowDuplicateNotFoundMessage(fieldName, canShowDuplicateNotFoundMessage);
      return;
    }
  }

  DisplayDialogForFieldExistswithClients(fieldName: string) {
    const dialogType = 'warning';
    let loggedInUserEmail: string = this.userObject.loggedInUserEmail;
    let dialogDesc: string = '';
    let bigTitle: boolean = false;
    let dialogInput = '';
    let dialogTitle: string;
    let buttonCount: number;
    dialogDesc = '';
    // if (this.client.oneViewAccountId === undefined || this.client.oneViewAccountId === '') {
    buttonCount = 4;
    // } else {
    //   buttonCount = 1;
    // }

    setTimeout(() => {
      switch (fieldName) {
        case ClientValidationFields.PhoneNumber:
          dialogTitle = ClientExistDialogTitles.PhoneNumber;
          break;
        case ClientValidationFields.Address:
          dialogTitle = ClientExistDialogTitles.Address1;
          break;
        case ClientValidationFields.TickerSymbol:
          dialogTitle = ClientExistDialogTitles.TickerSymbol;
          break;
        case ClientValidationFields.WebSite:
          dialogTitle = ClientExistDialogTitles.WebSite;
          break;
        case ClientValidationFields.BusinessContactEmail:
          dialogTitle = ClientExistDialogTitles.BusinessContactEmail;
          break;
        case ClientValidationFields.BillingContactEmail:
          dialogTitle = ClientExistDialogTitles.BillingContactEmail;
          break;
        case ClientValidationFields.ClientName:
          dialogTitle = ClientExistDialogTitles.ClientName;
          break;
        default:
          dialogTitle = undefined;
          break;
      }
      if (dialogTitle != undefined) {
        this.displayDuplicateClientConfirmationPopUp(dialogType, dialogTitle, dialogDesc, buttonCount, dialogInput, false, false, bigTitle,
          '0', loggedInUserEmail, this.searchClientbyFieldRequestModel);
      }
    }, 50);
  }

  displayDuplicateClientConfirmationPopUp(_dialogType: string, _dialogTitle: string, _dialogDescription: string, _buttonCount: number,
    _dialogInput: string, IsAbandonModal: boolean, IsDeclineModal: boolean, bigTitle: boolean, engId: string = '0',
    loggedInUserEmail: string, _searchClientReqModel: SearchClientbyFieldRequestModel) {
    this.loadingScreenService.stopLoading();
    var modalObj = {
      dialogType: _dialogType,
      dialogTitle: _dialogTitle,
      dialogDescription: _dialogDescription,
      dialogRedirectUrl: '', ///NewEngagement
      dialogButton: _buttonCount,
      DeclinedReasonInput: _dialogInput,
      modalDisplay: true,
      dialogAbandonModal: IsAbandonModal,
      dialogDeclineModal: IsDeclineModal,
      dialogBigTitle: bigTitle,
      engagementId: engId,
      loggedInUserEmail: loggedInUserEmail,
      searchClientReqModel: _searchClientReqModel
    };
    const modalJSON = JSON.stringify(modalObj);
    this.dataService.displayDialogNotification(modalJSON);
  }

  fetchOneviewClientDetailsAndBind(selectedAccountId: string) {
    this.loadingScreenService.startLoading();
    this.clientService.GetClientDetailByAccountId(selectedAccountId).subscribe(result => {
      this.oneViewClientDetailModel = result.response;
      console.log('oneViewClientDetailModel ==>', this.oneViewClientDetailModel);
      if (this.oneViewClientDetailModel != undefined) {
        $("#hdnIsOneViewClientLinked").val(true);
        $('#spanSelectedAccountName').text(this.oneViewClientDetailModel.hs_accountid);

        $('#clientValidationMessage').text('');
        $('#clientValidationMessage').addClass('d-none');
        $('#clientValidationMessage').removeClass('d-block');
        this.bindClientViewModel();
        this.dataService.setOneViewClientObject(result.response);
      }
      this.loadingScreenService.stopLoading();
    },
      (error) => {
        this.loadingScreenService.stopLoading();
        console.log('Error while getting response GetClientDetailByAccountId func ', error);
      });
  }

  initinateConfirmationPopUp() {
    this.modalConfirmationDialogRef = this.dataService.getModalDetails$.subscribe(
      dialogData => {
        if (dialogData) {
          const dialogModal = JSON.parse(dialogData);
          const dailogTitle = dialogModal.dialogTitle;
          const dialogButtonName = dialogModal.dialogButtonName;
          const selectedAccountId = dialogModal.selectedAccountId;
          const selectedClientCode = dialogModal.selectedClientCode;
          if ((dailogTitle === ClientExistDialogTitles.TickerSymbol ||
            dailogTitle === ClientExistDialogTitles.PhoneNumber ||
            dailogTitle === ClientExistDialogTitles.Address1 ||
            dailogTitle === ClientExistDialogTitles.WebSite ||
            dailogTitle === ClientExistDialogTitles.BillingContactEmail ||
            dailogTitle === ClientExistDialogTitles.BusinessContactEmail ||
            dailogTitle === ClientExistDialogTitles.ClientName)
            &&
            dialogButtonName === 'ok' &&
            selectedAccountId != undefined &&
            selectedAccountId != '' && selectedClientCode == '') {
            this.client.oneViewAccountId = selectedAccountId;
            this.fetchOneviewClientDetailsAndBind(selectedAccountId);
          } else if (selectedClientCode != '' && selectedClientCode != undefined) {
            this.clientService.GetClientDetailByAccountId(selectedAccountId).subscribe(result => {
              this.dataService.setOneViewClientObject(result.response);
              this.getClientDetailsByClientCode(selectedClientCode);
            },
              (error) => {
                console.log('Error while getting response GetClientDetailByAccountId func ', error);
                this.getClientDetailsByClientCode(selectedClientCode);
              });
          }
          // if (dailogTitle === ClientExistDialogTitles.TickerSymbol &&
          //   dialogButtonName === 'skip') {
          //   //changes for edit quetionnaire           
          // }         
          if (dailogTitle === "Reset account?") {
            this.ResetOneViewClientConnection();
          }
        }
      });
  }

  ResetOneViewClientConnection() {
    $('#spanSelectedAccountName').text("None selected");
    $("#hdnIsOneViewClientLinked").val(false);
    this.ResetAllFormFields();
    this.initiateDefaultValues();
    this.initiateClientViewModel();
    this.GetClientTypes();
    this.GetClientProgramTypes();
    this.ClearAllValidationMessages();
    this._alertService.remove(this.alertClient);
    $('.clientCreationDiv').addClass('hide');
    $('#txtValue' + this.MappedValue + 0).removeAttr('disabled');
    $('#DuplicateCheckOneviewAccountSearch').removeClass('disabled');
  }

  ResetAllFormFields() {
    this.client = new NewClient();
    this.client.sicCodeId = '';
    this.client.industryTypeId = 0;
    $('#txtValue' + this.ReferralSourceMappedValue + 0).val('');
    $('#hdnKey' + this.ReferralSourceMappedValue + 0).val('');
    $('#txtValue' + this.SICCodeMappedValue + 0).val('');
    $('#hdnKey' + this.SICCodeMappedValue + 0).val('');
    $('#txtPeoplePicker_' + this.adminRoleName + '_' + this.peoplepickerIndex).val('');
  }

  ClearAllValidationMessages() {
    for (let i = 0; i < this.arrControlValidation.length; i++) {
      this.arrControlValidation[i].isvalid = true;
    }
    $('#AdminRequired').removeClass('d-block');
    $('#AdminRequired').addClass('d-none');
    $('#AdminValid').removeClass('d-block');
    $('#AdminValid').addClass('d-none');
    $('#stockExchangeRequired').addClass('d-none');
    $('#stockExchangeRequired').removeClass('d-block');
    $('#tickerSymbolRequired').addClass('d-none');
    $('#tickerSymbolRequired').removeClass('d-block');
    $('#clientNameDuplicateExistsInOneViewAccount').addClass('d-none');
    $('#clientNameDuplicateExistsInOneViewAccount').removeClass('d-block');
  }
  confirmModalResetOneViewClientConnection() {
    var modalObj = {
      dialogType: 'information',
      dialogTitle: 'Reset account?',
      dialogDescription: "Are you sure you want to reset the assigned account? This will reset all fields and allow you to start over.",
      dialogRedirectUrl: '',
      dialogButton: 2,
      modalDisplay: true
    };
    var modalJSON = JSON.stringify(modalObj);
    this.dataService.displayDialogNotification(modalJSON);
  }

  OnSkipAccountSearch() {
    var clientName = $('#txtValue' + this.MappedValue + '0').val();
    if (clientName.length > 0 && ($("#hdnIsOneViewClientLinked").val() == false || $("#hdnIsOneViewClientLinked").val() == 'false')) {
      this.client.oneViewAccountId = '';
      this.clientViewModel.clientNameModel = JSON.parse(JSON.stringify(new ClientNameModel('', clientName, clientName)));
    }
    $('#txtValue' + this.MappedValue + '0').val('');
    $('#txtValue' + this.MappedValue + 0).attr('disabled', 'disabled');
    $('#DuplicateCheckOneviewAccountSearch').addClass('disabled');
    $('.clientCreationDiv').removeClass('hide');
  }
  CheckClientNameDuplicates(event) {
    if (event != undefined && event != null && event.type == 'blur' && this.IsPreviousClientSearchOnEnter == true) {
      this.IsPreviousClientSearchOnEnter = false;
      return;
    } else if (event != undefined && event != null && (event.code == 'Enter' || event.type == 'click')) {
      this.IsPreviousClientSearchOnEnter = true;
    } else {
      this.IsPreviousClientSearchOnEnter = false;
    }

    this.clientViewModel.clientNameModel.canShowDuplicateNotFoundMessage = false;
    $('#clientNameDuplicateExistsInOneViewAccount').addClass('d-none');
    $('#clientNameDuplicateExistsInOneViewAccount').removeClass('d-block');

    if (($("#hdnIsOneViewClientLinked").val() == true || $("#hdnIsOneViewClientLinked").val() == 'true')) {
      //If account is already selected and check for duplicates only in client name field and show popup if duplicate found. 
      if (event == null) {
        //Handle Duplicate check event
        this.validateClientField('gt_cmsaccountname', true, true);
      }
      else {
        //Handle blur event
        this.checkSpaceChartBegin(event);
      }
    }
    else {
      //If account is not selected and check for duplicates in clientname, accountname and aka/dba fields and show error message.
      //This duplicate check is similar to acccount search functionality.

      let clientName = this.clientViewModel.clientNameModel.clientName.trim();
      if (clientName.length > 0) {
        this.loadingScreenService.startLoading();
        this.clientService.GetOneViewClients(clientName).subscribe(result => {
          if (result && result.statusCode == "200" && result.response.length > 0) {
            $('#clientNameDuplicateExistsInOneViewAccount').removeClass('d-none');
            $('#clientNameDuplicateExistsInOneViewAccount').addClass('d-block');
          } else {
            this.clientViewModel.clientNameModel.canShowDuplicateNotFoundMessage = true;
            $('#clientNameDuplicateExistsInOneViewAccount').addClass('d-none');
            $('#clientNameDuplicateExistsInOneViewAccount').removeClass('d-block');
          }
          this.loadingScreenService.stopLoading();
        });
      }
    }
  }
  SetOneViewClientData() {
    let accountSource = "Engage New Client"; //Static Value for Account Source in oneView
    let stateName = '';
    let countryName = '';
    let statesData = this.states.filter(st => st.stateId == this.createClientViewModel.client.stateId)
    if (statesData.length > 0)
      stateName = statesData[0].stateName;
    let countryData = this.countries.filter(st => st.countryId == this.createClientViewModel.client.countryId)
    if (countryData.length > 0)
      countryName = countryData[0].countryName;
    this.createOneViewClientDetailModel.name = this.createClientViewModel.client.clientName;
    this.createOneViewClientDetailModel.address1_line1 = this.createClientViewModel.client.addressLine1;
    this.createOneViewClientDetailModel.address1_line2 = this.createClientViewModel.client.addressLine2;
    this.createOneViewClientDetailModel.address1_line3 = this.createClientViewModel.client.addressLine3;
    this.createOneViewClientDetailModel.address4 = this.createClientViewModel.client.addressLine4;
    this.createOneViewClientDetailModel.address1_city = this.createClientViewModel.client.city;
    this.createOneViewClientDetailModel.address1_stateorprovince = stateName;
    this.createOneViewClientDetailModel.address1_postalcode = this.createClientViewModel.client.zipCode;
    this.createOneViewClientDetailModel.country = countryName;
    this.createOneViewClientDetailModel.telephone1 = this.createClientViewModel.client.phoneNumber;
    this.createOneViewClientDetailModel.fax = this.createClientViewModel.client.fax;
    this.createOneViewClientDetailModel.websiteurl = this.createClientViewModel.client.websiteAddress;
    this.createOneViewClientDetailModel.tickersymbol = this.createClientViewModel.client.tickerSymbol;
    this.createOneViewClientDetailModel.gt_engageclientid = this.clientOneViewAccountIDetails.clientId;
    this.createOneViewClientDetailModel.hs_accountsource = accountSource;
     //this.createOneViewClientDetailModel.hs_siccode = this.createClientViewModel.client.sicCode.substring(0,4);
    // this.createOneViewClientDetailModel.hs_segment = this.createClientViewModel.client.clientType;
    // this.createOneViewClientDetailModel.hs_fiscalyearend = this.createClientViewModel.client.fyeMonth.toString();
    // this.createOneViewClientDetailModel.ownerid = this.createClientViewModel.client.createdby;
  }
}
