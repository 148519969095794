import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { Routes } from '@angular/router';
import { DatePipe, CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// Custom Module Import
import { NgxPaginationModule } from 'ngx-pagination';
import { AvatarModule } from 'ngx-avatars';
import { MsalModule, MsalService, MsalGuard, MsalInterceptor, MsalBroadcastService, MsalRedirectComponent, MsalGuardConfiguration, MsalInterceptorConfiguration, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType, BrowserCacheLocation, IPublicClientApplication } from '@azure/msal-browser';

// Component Import
import { AppComponent } from './app.component';
import { NavbarComponent } from './common/navbar/navbar.component';
import { ListviewComponent } from './common/page/listview/listview.component';
import { ClientHomeComponent } from './pages/client/client-home/client-home.component';
import { ClientsummaryComponent } from './pages/clientsummary/clientsummary.component';
import { EngagementHomeComponent } from './pages/engagement/engagement-home/engagement-home.component';
import { DocumentHomeComponent } from './pages/document/document-home/document-home.component';
import { FavoriteHomeComponent } from './pages/favorite/favorite-home/favorite-home.component';
import { RevenueHomeComponent } from './pages/revenue/revenue-home/revenue-home.component';
import { PageNotFoundComponent } from './common/page/page-not-found/page-not-found.component';
import { EngagementlistComponent } from './pages/engagement/engagementlist/engagementlist.component';
import { DocumentListComponent } from './pages/document/document-list/document-list.component';
import { MyReviewsListComponent } from './common/page/my-reviews-list/my-reviews-list.component';
import { EngagementSummaryComponent } from './pages/engagement-summary/engagement-summary.component';
import { WorkspaceComponent } from './pages/review/workspace/workspace.component';
import { AdminConfigurationComponent } from './pages/admin-configuration/admin-configuration.component';
import { ClientCardViewComponent } from './common/page/client-card-view/client-card-view.component';
import { ClientListComponent } from './pages/client/client-list/client-list.component';
import { InterviewsComponent } from './pages/interviews/interviews.component';
// import { AlertsComponent } from './common/alerts/alerts.component';
import { NoaccessComponent } from './common/page/noaccess/noaccess.component';
import { MyreacceptanceengagementsComponent } from './pages/myreacceptanceengagements/myreacceptanceengagements.component';
// import { LoadingScreenComponent } from './common/loading-screen/loading-screen.component';
import { AllclientsComponent } from './pages/allclients/allclients.component';
import { NoDataFoundInListComponent } from './common/page/no-data-found-in-list/no-data-found-in-list.component';
import { EngagementListViewComponent } from './pages/engagement/engagement-list-view/engagement-list-view.component';
import { CreateClientComponent } from './pages/client/create-client/create-client.component';
import { CardRoleMappingConfigComponent } from './pages/card-role-mapping-config/card-role-mapping-config.component';
import { RecentActivityComponent } from './common/page/recent-activity/recent-activity.component';
import { DocumentListViewComponent } from './pages/document/document-list-view/document-list-view.component';
import { InitiateEngagementComponent } from './pages/initiate-engagement/initiate-engagement.component';
import { ClientNamePopupComponent } from './common/popups/client-name-popup/client-name-popup.component';
import { RelationshipPartnerPopupComponent } from './common/popups/relationship-partner-popup/relationship-partner-popup.component';
import { EngagementListPopupComponent } from './common/popups/engagement-list-popup/engagement-list-popup.component';
import { SolutioncodeConfigurationComponent } from './pages/solutioncode-configuration/solutioncode-configuration.component';
import { DocumentListPopupComponent } from './common/popups/document-list-popup/document-list-popup.component';
import { ActivereviewsComponent } from './common/popups/activereviews/activereviews.component';
import { AutoCompleteTextBoxComponent } from './common/component/auto-complete-text-box/auto-complete-text-box.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { QuestionnaireSummaryComponent } from './common/popups/questionnaire-summary/questionnaire-summary.component';
import { EngagementListViewForacptdocComponent } from './pages/engagement/engagement-list-view-foracptdoc/engagement-list-view-foracptdoc.component';
import { EngagementListViewForotherdocComponent } from './pages/engagement/engagement-list-view-forotherdoc/engagement-list-view-forotherdoc.component';
import { EngagementsLettersListViewComponent } from './pages/document/engagements-letters-list-view/engagements-letters-list-view-component';
import { SOWListViewComponent } from './pages/document/sowlist-view/sowlist-view-component';
import { MSAListViewComponent } from './pages/document/msalist-view/msalist-view-component';
import { MemosListViewComponent } from './pages/document/memos-list-view/memos-list-view-component';
import { ClientDeliverableListViewComponent } from './pages/document/client-deliverable-list-view/client-deliverable-list-view.component';
import { ConflictWaiverListViewComponent } from './pages/document/conflict-waiver-list-view/conflict-waiver-list-view-component';
import { AccessLetterListViewComponent } from './pages/document/access-letter-list-view/access-letter-list-view-component';
import { LegalDocumentListViewComponent } from './pages/document/legal-document-list-view/legal-document-list-view-component';
import { ConsentLetterListViewComponent } from './pages/document/consent-letter-list-view/consent-letter-list-view-component';
import { OtherDocumentsListComponent } from './pages/document/other-documents-list/other-documents-list-component';
import { ActivityLogComponent } from './common/popups/activity-log/activity-log.component';
import { MsaClientComponent } from './pages/client/msa-client/msa-client.component';
import { ClientWorkspacePopupComponent } from './common/popups/client-workspace-popup/client-workspace-popup.component';
import { ClientContactPopupComponent } from './common/popups/client-contact-popup/client-contact-popup.component';
import { ClientSmartSearchComponent } from './common/component/client-smart-search/client-smart-search.component';
// import { ShortcutComponent } from './common/shortcut/shortcut.component';
import { HomeComponent } from './common/home/home.component';
import { LeftnavComponent } from './common/leftnav/leftnav.component';
import { ShortcutsConfigurationComponent } from './pages/configuration/shortcuts-configuration/shortcuts-configuration.component';
import { LoginComponent } from './pages/login/login.component';
import { AssignmentListViewComponent } from './pages/assignments/assignment-list-view/assignment-list-view.component';
// import { SearchListViewComponent } from './pages/search/search-list-view/search-list-view.component';
import { CalendercontrolComponent } from './common/component/calendercontrol/calendercontrol.component';
import { PeoplePickerComponent } from './common/component/people-picker/people-picker.component';
import { MessageDialogComponent } from './common/modal/message-dialog/message-dialog.component';
import { ContactPopupComponent } from './common/popups/contact-popup/contact-popup.component';
import { ContinuancehomeComponent } from './pages/continuancehome/continuancehome.component';
import { EditClientComponent } from './pages/client/edit-client/edit-client.component';
import { TemplateMaintenanceComponent } from './pages/configuration/template-maintenance/template-maintenance.component';
import { ReviewEngagementComponent } from './pages/review/review-engagement/review-engagement.component';
import { ReviewResponseComponent } from './common/popups/review-response/review-response.component';
import { ProcessCmsErrorAssignmentComponent } from './process-cms-error-assignment/process-cms-error-assignment.component';
import { ProcessAuditEngagementComponent } from './process-audit-engagement/process-audit-engagement.component';
import { SummaryReportComponent } from './pages/summary-report/summary-report.component';

// Service Import
import { NavbarService } from './services/navbarServices';
import { ClientService } from './services/clientservice';
import { LeftNavService } from './services/leftnavService';
import { AppConfigService } from './services/appConfigService';
import { HotDocsService } from './services/HotDocsService';
import { WorkspaceReviewPointService } from './services/workspaceReviewPointService';
import { EngagementService } from './services/EngagementService';
import { WorkspaceService } from './services/workspace.service';
import { ReacceptanceClientsService } from './services/reacceptanceClientsService';
import { DataService } from './services/data.service';
import { LogService } from './services/log.service';

// Helper Import
import { ReplaceLineBreaks } from './helper/ReplaceLineBreak';
import { AuthInterceptor } from './helper/AuthInterceptor';
import { AuthGuard } from './helper/AuthGaurd';
import { HighlightSearch } from './helper/HighlightSearch';
import { GoverningAgreementActionsComponent } from './common/component/governing-agreement-actions/governing-agreement-actions.component';
import { ReviewNoteCurrentUsernameTransformPipe } from './pages/review/review-notes/review-note-current-username-transform-pipe';
import { DelegateDialogComponent } from './common/modal/delegate-dialog/delegate-dialog.component';
import { MSALInstanceFactory } from './services/Integration/MSAL/MSALInstanceFactory';
import { MSALGuardConfigFactory } from './services/Integration/MSAL/MSALGuardConfigFactory';
import { MSALInterceptorConfigFactory } from './services/Integration/MSAL/MSALInterceptorConfigFactory';
import { EngagementCloseoutClientVoiceSurvey } from './pages/engagement/engagement-closeout-client-voice-survey/engagement-closeout-client-voice-survey.component';
import { ProcessAddParaprofessionalsEngagementComponent } from './process-add-paraprofessionals-engagement/process-add-paraprofessionals-engagement/process-add-paraprofessionals-engagement.component';
import { ProcessAddProfessionalsEngagementService } from './services/process-add-professionals-engagement.service';
import { EngagementCloseoutRetention } from './pages/engagement/engagement-closeout-retention/engagement-closeout-retention.component';
import { ReviewNotesComponent } from './pages/review/review-notes/review-notes.component';
import { AddWorkspaceDocumentComponent } from './pages/review/add-workspace-document/add-workspace-document.component';
import { OneviewClientSmartSearchComponent } from './common/component/oneview-client-smart-search/oneview-client-smart-search.component';
import { EngagementCloseoutCleanUpChecklistComponent } from './pages/engagement/engagement-closeout-clean-up-checklist/engagement-closeout-clean-up-checklist.component';
import { EngagementCloseoutArchivalComponent } from './pages/engagement/engagement-closeout-archival/engagement-closeout-archival.component';
import { EngagementCloseoutInsightMetrixComponent } from './pages/engagement/engagement-closeout-insight-metrix/engagement-closeout-insight-metrix.component';
import { EngagementMessageDialogComponent } from './pages/engagement/model/engagement-message-dialog/engagement-message-dialog.component';
import { EngagementDuedateExtensionComponent } from './pages/engagement/engagement-duedate-extension/engagement-duedate-extension.component';
import { EngagementPrerequestPopupComponent } from './pages/engagement/engagement-prerequest-popups/engagement-prerequest-popup/engagement-prerequest-popup.component';
import { SharepointFileLabelComponent } from './common/popups/sharepoint-file-label/sharepoint-file-label.component';
import { EngagementCloseoutAuditChecklistComponent } from './pages/engagement/engagement-closeout-audit-checklist/engagement-closeout-audit-checklist.component';
import { FilterSpfileLabelPipe } from './common/pipes/filter-spfile-label/filter-spfile-label.pipe';
import { NewDocumentDialogComponent } from './common/popups/new-document-dialog/new-document-dialog.component';
import { AutoCompleteDropdownComponent } from './common/component/auto-complete-dropdown/auto-complete-dropdown.component';
import { DocumentService } from './services/DocumentService';
import { NewDocumentInterviewsComponent } from './pages/new-document-interviews/new-document-interviews.component';
import { ExportDocumentCompletedPopupComponent } from './common/popups/export-document-completed-popup/export-document-completed-popup.component';
import { ExportDocumentSavedPopupComponent } from './common/popups/export-document-saved-popup/export-document-saved-popup.component';
import { SharepointFilePickerComponent } from './common/popups/sharepoint-file-picker/sharepoint-file-picker.component';
import { EngagementCloseoutQualityChecklistComponent } from './pages/engagement/engagement-closeout-quality-checklist/engagement-closeout-quality-checklist.component';
import { ClientConsentService } from './services/ClientConsentService';
import { AuditPartnerDialogComponent } from './common/modal/audit-partner-dialog/audit-partner-dialog.component';
import { DocumentTemplatesDialogComponent } from './common/popups/document-templates-dialog/document-templates-dialog.component';
import { MentionModule } from 'angular-mentions';
import { InitiateChangeOrderComponent } from './pages/initiate-change-order/initiate-change-order.component';
import { ChangeOrderInterviewComponent } from './pages/change-order-interview/change-order-interview.component';
import { LoadingScreenModule } from './common/loading-screen/loading-screen.module';
import { AlertsModule } from './common/alerts/alerts.module';
import { ShortCutModule } from './common/shortcut/shortcut.module';
import { OneviewClientSearchComponent } from './common/component/oneview-client-search/oneview-client-search.component';
import { AutoCreateOpputunityComponent } from './common/popups/auto-create-opputunity/auto-create-opputunity.component';

const appRoutes: Routes = [
  { path: 'NewEngagement', component: InitiateEngagementComponent }
];

// Azure AD Configuration
let msalConfig: IPublicClientApplication;
let gaurdConfig: MsalGuardConfiguration;
let interceptorConfig: MsalInterceptorConfiguration;


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    ListviewComponent,
    ClientHomeComponent,
    EngagementHomeComponent,
    //ClientCardViewComponent,
    //DocumentHomeComponent,
    EngagementlistComponent,
    //DocumentListComponent,
    ClientsummaryComponent,
    EngagementSummaryComponent,
    MyReviewsListComponent,
    //FavoriteHomeComponent,
    //RevenueHomeComponent,
    PageNotFoundComponent,
    WorkspaceComponent,
    //ReplaceLineBreaks,
    AdminConfigurationComponent,
    //ClientListComponent,
    //AllclientsComponent,
    // AlertsComponent,
    MyreacceptanceengagementsComponent,
    InterviewsComponent,
    //AlertsComponent,
    NoaccessComponent,
    CreateClientComponent,
    // LoadingScreenComponent,
    //CardRoleMappingConfigComponent,
    NoDataFoundInListComponent,
    EngagementListViewComponent,
    //DocumentListViewComponent,
    InitiateEngagementComponent,
    //RecentActivityComponent,
    //ClientNamePopupComponent,
    //RelationshipPartnerPopupComponent,
    //EngagementListPopupComponent,
    SolutioncodeConfigurationComponent,
    ActivereviewsComponent,
    DocumentListPopupComponent,
    AutoCompleteTextBoxComponent,
    UserProfileComponent,
    QuestionnaireSummaryComponent,
    EngagementListViewForacptdocComponent,
    EngagementListViewForotherdocComponent,
    EngagementsLettersListViewComponent,
    //SOWListViewComponent,
    //MSAListViewComponent,
    //MemosListViewComponent,
    //ClientDeliverableListViewComponent,
    //ConflictWaiverListViewComponent,
    //AccessLetterListViewComponent,
    //LegalDocumentListViewComponent,
    //ConsentLetterListViewComponent,
    //OtherDocumentsListComponent,
    //ActivityLogComponent,
    //MsaClientComponent,
    ClientWorkspacePopupComponent,
    ClientContactPopupComponent,
    ClientSmartSearchComponent,
    OneviewClientSmartSearchComponent,
    // ShortcutComponent,
    HomeComponent,
    LeftnavComponent,
    //BookmarksListViewComponent,
    ShortcutsConfigurationComponent,
    AssignmentListViewComponent,
    // SearchListViewComponent,
    LoginComponent,
    HighlightSearch,
    CalendercontrolComponent,
    PeoplePickerComponent,
    MessageDialogComponent,
    ContactPopupComponent,
    ContinuancehomeComponent,
    EditClientComponent,
    TemplateMaintenanceComponent,
    ReviewEngagementComponent,
    ReviewResponseComponent,
    ProcessCmsErrorAssignmentComponent,
    ProcessAuditEngagementComponent,
    SummaryReportComponent,
    GoverningAgreementActionsComponent,
    ReviewNoteCurrentUsernameTransformPipe,
    DelegateDialogComponent,
    EngagementCloseoutClientVoiceSurvey,
    ProcessAddParaprofessionalsEngagementComponent,
    EngagementCloseoutRetention,
    ReviewNotesComponent,
    AddWorkspaceDocumentComponent,
    OneviewClientSmartSearchComponent,
    EngagementCloseoutCleanUpChecklistComponent,
    EngagementCloseoutArchivalComponent,
    EngagementCloseoutInsightMetrixComponent,
    EngagementMessageDialogComponent,
    EngagementDuedateExtensionComponent,
    EngagementPrerequestPopupComponent,
    SharepointFileLabelComponent,
    EngagementCloseoutAuditChecklistComponent,
    FilterSpfileLabelPipe,
    NewDocumentDialogComponent,
    AutoCompleteDropdownComponent,
    NewDocumentInterviewsComponent,
    ExportDocumentCompletedPopupComponent,
    ExportDocumentSavedPopupComponent,
    SharepointFilePickerComponent,
    EngagementCloseoutQualityChecklistComponent,
    AuditPartnerDialogComponent,
    DocumentTemplatesDialogComponent,
    InitiateChangeOrderComponent,
    ChangeOrderInterviewComponent,
    OneviewClientSearchComponent,
    AutoCreateOpputunityComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MsalModule.forRoot(msalConfig, gaurdConfig, interceptorConfig),
    NgxPaginationModule,
    AvatarModule,
    BrowserAnimationsModule,
    MentionModule,
    LoadingScreenModule,
    AlertsModule,
    ShortCutModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [AppConfigService]
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AuthGuard,
    NavbarService,
    ClientService,
    EngagementService,
    LeftNavService,
    AppConfigService,
    HotDocsService,
    WorkspaceService,
    WorkspaceReviewPointService,
    ReacceptanceClientsService,
    DataService,
    LogService,
    DatePipe,
    ProcessAddProfessionalsEngagementService,
    DocumentService,
    ClientConsentService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
