
  <div *ngIf="isOpen" class="modal-overlay">
    <div class="modal-content">
  <div class="row m-0">
    <div class="col-md-12">
      <div class="row m-0 p-0">
        <div class="row pl-0 pr-0 mr-0 ml-0">
          <div class="col-md-12 px-0 mb-2">
            <div class="clm-box mb-3">
              <div class=" col-md-12 ">
               
               
                  <h6 style="color:#707070;" class="pt-3 font-weight-bold" style="text-align: left;"> Select opportunity</h6>
                <p class="mb-2 pl-0 pt-1 col-md-12 col-xs-12 col-sm-12"  style="text-align: left;">
                  <!-- The following opportunities are captured in oneView and match the selected solution
                  code.Please review and select the one to associate with this engagement.Related solutions will
                  be highlighted. -->
                  <!-- addded for new mockup -->
                  This continuance engagement is not linked to an opportunity in oneView. Select one from the list below
                  to confirm your intent to initiate continuance.
                </p>
               
                <div class="main-table table-responsive tableOneview">
                  <table class="table table-auto border mb-0">
                    <thead>
                      <tr>
                        <th style="width: 33%;">Opportunity</th>
                        <th>ID</th>
                        <th>Total est. Fees</th>
                        <th>Expected Start Date</th>
                        <th>Pursuit Owner</th>
                      </tr>
                    </thead>
                    <tbody>
                      
                      <tr *ngFor="let item of opportunityList">
                      <td style="width:33%;">
                        <label class="custom-radio auto-radio">
                          {{ item.opportunityName }}
                          <input type="radio" name="opportunityName" 
                          (click)="onOpportunityClick(item.opportunityId)"
                           id="opportunityName">
                         <span class="checkmark"></span>
                        </label>
                       </td>
                      <td>{{ item.opportunityId }}</td>
                      <td>{{ item.opportunityEstimatedFees}}</td>
                      <td>{{ item.expectedStartDate | date:'MM/dd/yyyy' }}</td>
                      <td>{{ item.pursuitTeam }}</td>
                      </tr>
                      
                      </tbody>
                  </table>
                 
                    
                    </div>
                    <div  class="no-record border col-xs-12 col-sm-12 col-md-12 pr-0 mt-2">
                      <div class="row w-100 oneViewNoResult p-3 ">
                            <div class="col-md-12 text-left ml-1" style="background-color: #f5f5f5;">
                                <p class="pt-2">
                                  Can't find what you are looking for? The opportunity is not yet created in oneView. Contact your engagement partner, principal or managing director for next steps.
                                </p>
                            </div>
                      </div>              

                </div>
                <p style="text-align: right; color: red;">
                  {{validationMessage}} 
                  {{errorMessage}}
                </p>
          
              </div>




            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0">
    <button type="button" class="SecondaryButton" style="width: 150px; text-align: center;" data-dismiss="modal"
      (click)="onClose();">
      <span class="d-inline-block mr-1 align-top">
        <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
      </span>
      <span class="d-inline-block align-middle">Cancel</span>
    </button>
    <button *ngIf="opportunityList.length !== 0" type="button" class="PrimaryButton" style="width: 150px; text-align: center;" (click)="onSelect();">
      <span class="d-inline-block mr-1 align-top">
        <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
      </span>
      <span class="d-inline-block align-middle">Select</span>
    </button>
  </div>
  </div>
  </div>